import React, { useRef, useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Moment from 'moment';
import { Box, Typography, Button } from '@mui/material';
import { useAuth } from '@worklist-2/core/src';
import { FormToolTextField } from '@worklist-2/ui/src/components/FormTool';
import { useTranslation } from 'react-i18next';

import { Svg } from '../Svg';

const FormToolSignature = ({
	isReadOnly = false,
	onChangeData,
	translate,
	height = '140px',
	width,
	question,
	isCompact,
	isEditActive,
	fromHistory,
}) => {
	const sigCanvas = useRef();
	const [addSignClicked, setAddSignClicked] = useState(false);
	const [signData, setSignData] = useState(
		question?.answer && question?.answer[question.answer.length - 1]?.value?.length > 0
			? question.answer[question.answer.length - 1].value
			: null
	);
	const [changeSignClicked, setChangeSignClicked] = useState(false);
	const [addFromEmpty, setAddFromEmpty] = useState(false);

	const { signature } = useAuth();

	const { t } = useTranslation('form');

	const getEditActive = () => localStorage.getItem('editActive') === 'true';

	useEffect(() => {
		if (isEditActive !== undefined) {
			localStorage.setItem('editActive', JSON.stringify(isEditActive));
		}
	}, [isEditActive]);

	useEffect(() => {
		if (sigCanvas?.current?.isEmpty()) {
			if (addSignClicked) {
				const newData = signData?.length > 0 ? signData : signature;

				sigCanvas?.current?.fromDataURL(newData);
				onChangeData(newData);
				if (isReadOnly) {
					sigCanvas.current.off();
				}
			} else if (signData?.length > 0) {
				sigCanvas?.current?.fromDataURL(signData);
				if (isReadOnly) {
					sigCanvas.current.off();
				}
			}
		}
	}, [signature, addSignClicked, signData]);

	const handleInput = () => {
		onChangeData(sigCanvas?.current?.isEmpty() ? '' : sigCanvas?.current?.toDataURL());
		setSignData(sigCanvas?.current?.isEmpty() ? '' : sigCanvas?.current?.toDataURL());
		setChangeSignClicked(false);
	};

	useEffect(() => {
		if (signData?.length) {
			sigCanvas.current?.fromDataURL(signData);
			sigCanvas.current?.off();
		}
	}, [signData]);

	const isSignExists = () =>
		!!signature?.length || !!(question?.answer && question?.answer[question.answer.length - 1]?.value?.length > 0);

	return (
		<Box
			sx={{
				color: 'rgba(0, 0, 0, 0.2)',
				height: isCompact && !addFromEmpty ? '50px' : height,
				width: width || 'auto',
				position: 'relative',
				border: isCompact && !addFromEmpty ? 'none' : '1px solid rgba(0, 0, 0, 0.23)',
				borderRadius: '4px',
				'&:hover': {
					'.signIcon': {
						display: 'none',
					},
				},
			}}
		>
			{isCompact && !addFromEmpty && !changeSignClicked ? (
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					{signData?.length > 0 || addSignClicked ? (
						<Box
							data-testid="litle-canvas"
							onClick={() => {
								if (fromHistory) {
									if (
										getEditActive() &&
										question?.answer &&
										question?.answer[question.answer.length - 1]?.value?.length > 0
									) {
										setChangeSignClicked(true);
										setAddFromEmpty(true);
									}
								} else {
									setChangeSignClicked(true);
									setAddFromEmpty(true);
								}
							}}
						>
							<SignatureCanvas
								ref={sigCanvas}
								canvasProps={{
									width: 200,
									height: 50,
									className: 'sigCanvas',
								}}
								penColor="black"
								onEnd={handleInput}
							/>
						</Box>
					) : (
						<Button
							data-testid="add-signature"
							disabled={isReadOnly}
							sx={{
								background: '#42A5F5',
								color: '#FFFFFF',
								':hover': {
									background: '#42A5F5',
								},
								textTransform: 'none',
							}}
							variant="contained"
							onClick={() => {
								if (!!getEditActive() || !fromHistory) {
									if (!isSignExists()) {
										setAddFromEmpty(true);
									} else {
										setAddSignClicked(true);
									}
								}
							}}
						>
							{t('Click to add signature')}
						</Button>
					)}
					<FormToolTextField
						disabled
						label={translate ? translate('Signed On') : 'Signed On'}
						sx={{ height: '50px', width: '50%', '& .MuiOutlinedInput-root': { height: '50px' } }}
						value={
							question?.answer?.length > 0
								? Moment(question.answer[question.answer.length - 1]?.submitterDate).format(
										'M/D/YYYY LTS'
								  )
								: addSignClicked && !sigCanvas?.current?.isEmpty()
								? Moment(new Date()).format('M/D/YYYY LTS')
								: ''
						}
					/>
				</Box>
			) : (
				<>
					<div
						className="signIcon"
						style={{
							textAlign: 'center',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
						}}
					>
						{getEditActive() === false && (isReadOnly || !addFromEmpty) ? (
							<></>
						) : (
							<div>
								<Svg
									name="signature"
									sx={{
										height: 'auto',
										color: 'rgba(0, 0, 0, 0.2)',
									}}
								/>
								<Typography>{t('Draw your signature here')}</Typography>
							</div>
						)}
					</div>
					{getEditActive() === false && (isReadOnly || !addFromEmpty) ? (
						<></>
					) : (
						<>
							<button
								style={{
									position: 'absolute',
									bottom: '6%',
									left: '2%',
									border: 'none',
									background: 'none',
									color: '#42A5F5',
								}}
								onClick={() => {
									sigCanvas.current.clear();
								}}
							>
								{t('Clear')}
							</button>
							<button
								data-testid="sign-done"
								style={{
									position: 'absolute',
									bottom: '6%',
									right: '2%',
									border: 'none',
									background: 'none',
									color: '#42A5F5',
								}}
								onClick={() => {
									handleInput();
									setAddSignClicked(true);
									setAddFromEmpty(false);
								}}
							>
								{t('Done')}
							</button>
						</>
					)}
					<SignatureCanvas
						ref={sigCanvas}
						canvasProps={{
							width: width || 635,
							height: height || 140,
							className: 'sigCanvas',
						}}
						penColor="black"
					/>
				</>
			)}
		</Box>
	);
};

export default FormToolSignature;
