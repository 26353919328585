import * as cornerstone3D from '@cornerstonejs/core';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import { logDebug } from '@rs-core/index';
import dicomParser from 'dicom-parser';

const initCornerstoneWadoImageLoader = ({
	wonIvHardwareconRef,
	wonIvMmDivideHardwareconRef,
	numberOfIVScreens = 1,
}) => {
	cornerstoneWADOImageLoader.external.cornerstone = cornerstone3D;
	cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

	let maxWebWorkers = 1;

	if (wonIvHardwareconRef.current) {
		if (navigator.hardwareConcurrency) {
			maxWebWorkers = Math.min(
				wonIvMmDivideHardwareconRef.current
					? Math.floor(navigator.hardwareConcurrency / numberOfIVScreens)
					: navigator.hardwareConcurrency,
				7
			);
			logDebug('initCornerstoneWadoImageLoader', 'maxWebWorkers: ', maxWebWorkers);
		}
	}

	const config = {
		maxWebWorkers,
		startWebWorkersOnDemand: false,
		taskConfiguration: {
			decodeTask: {
				initializeCodecsOnStartup: false,
				strict: false,
			},
		},
	};

	cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
};

export default initCornerstoneWadoImageLoader;
