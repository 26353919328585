import { ImageProcessStatusCode } from './AutoWindowLevel';

const noiseLevelRatio = 0.2;
const noiseWeightRatio = 0.3;
const maxMedianKSize = 15;
const distTap = 15;
const distTaph = Math.floor(distTap / 2);

const EnsureRange = (value, min, max) => {
	let result = value;
	if (result < min) {
		result = min;
	}
	if (result > max) {
		result = max;
	}
	return result;
};

class ImagePixelAnalyser {
	constructor(imageObject, metaData) {
		this.modality = metaData?.['00080060']?.Value?.[0];
		this.imageAttribute = {
			highBit: metaData?.['00280102']?.Value?.[0],
			columns: imageObject?.imageFrame?.columns,
			samplesPerPixel: imageObject?.imageFrame?.samplesPerPixel,
			bitsAllocated: imageObject?.imageFrame?.bitsAllocated,
			pixelRepresentation: imageObject?.imageFrame?.pixelRepresentation,
			modalityLUTSequence: {
				lutData: null,
				lutMinPixel: 0,
				lutEntries: 0,
			},
			rescaleSlope: imageObject?.slope,
			rescaleIntercept: imageObject?.intercept,
			pixelData: imageObject.getPixelData(),
			maxPixelValue: imageObject.maxPixelValue,
			minPixelValue: imageObject.minPixelValue,
		};
		this.statusCode = ImageProcessStatusCode.NonErr;
		this.autoWinErrorLimit = 0.02;

		// derived pixel attributes
		this.minPVIndex = 0;
		this.maxPVIndex = 0;
		this.maxPixelIndex = 0;
		this.minPixelIndex = 0;
		this.meanPixelIndex = 0;
		this.numColors = 0;
		this.numColorsAlloc = 0;
		this.pixelBytes = 1;
		this.lowerLimit = 0;
		this.upperLimit = 0;

		// dimension
		this.imgRowSize = 0;
		// distribution polarity
		this.pModality = 0;
		this.pPresent = 0;
		// map attributes
		this.isPositiveMap = false;
		this.mapIsInverted = false;
		// control param:
		this.ignorePolarity = false;
		// control param:
		this.distribution = [];
		this.valueMap = null;
		this.feature = [];
		this.filteredDistribution = [];
	}

	initialize() {
		this.mapIsInverted = false;
		this.numColors = 2 << this.imageAttribute.highBit;
		this.numColorsAlloc = 1 << this.imageAttribute.bitsAllocated;

		if (this.imageAttribute.pixelRepresentation == 1) {
			// The minimum sample value displayed as white
			this.minPVIndex = -(this.numColors >> 1);
			this.maxPVIndex = (this.numColors >> 1) - 1;
			this.lowerLimit = -(this.numColorsAlloc >> 1);
			this.upperLimit = (this.numColorsAlloc >> 1) - 1;
		} else {
			this.minPVIndex = 0;
			this.maxPVIndex = this.numColors - 1;
			this.lowerLimit = 0;
			this.upperLimit = this.numColorsAlloc - 1;
		}

		this.pixelBytes = this.imageAttribute.bitsAllocated >> 3;

		if (this.modalityMap(this.maxPVIndex) > this.modalityMap(this.minPVIndex)) {
			this.pModality = 1;
		} else {
			this.pModality = -1;
		}

		if (this.ignorePolarity) {
			this.pPresent = 1;
		} else {
			this.pPresent = -1;
		}

		this.distribution = new Array(this.numColorsAlloc).fill(0);
		this.feature = new Array(this.numColors).fill(0);

		this.imgRowSize = this.imageAttribute.columns * this.imageAttribute.samplesPerPixel;
	}

	modalityMap(index) {
		this.statusCode = ImageProcessStatusCode.NonErr;

		if (this.mapIsInverted) {
			index = this.maxPixelIndex + this.minPixelIndex - index;
		}

		const lutData = this.imageAttribute.modalityLUTSequence.lutData;
		if (lutData) {
			index = Math.max(
				0,
				Math.min(
					index - this.imageAttribute.modalityLUTSequence.lutMinPixel,
					this.imageAttribute.modalityLUTSequence.lutEntries - 1
				)
			);
			return lutData[index];
		} else {
			return index * this.imageAttribute.rescaleSlope + this.imageAttribute.rescaleIntercept;
		}
	}

	invModalityMap(val) {
		const lutData = this.imageAttribute.modalityLUTSequence.lutData;
		if (lutData) {
			let i = 0;
			while (i < lutData.length && val > lutData[i]) {
				i++;
			}
			return Math.min(i, lutData.length - 1) + this.imageAttribute.modalityLUTSequence.lutMinPixel;
		} else {
			return Math.round((val - this.imageAttribute.rescaleIntercept) / this.imageAttribute.rescaleSlope);
		}
	}

	getRegionStatis() {
		let i;
		// reset status flag
		this.statusCode = ImageProcessStatusCode.NonErr;

		// get the pixel value distribution
		if (this.imageAttribute.pixelRepresentation == 1) {
			for (i = 0; i < this.imageAttribute.pixelData.length; i++) {
				this.distribution[Math.round(this.imageAttribute.pixelData[i] - this.minPVIndex)]++;
			}
		} else {
			for (i = 0; i < this.imageAttribute.pixelData.length; i++) {
				this.distribution[Math.round(this.imageAttribute.pixelData[i] - this.imageAttribute.minPixelValue)]++;
			}
		}

		// find the pixel value range
		// find min pixel value
		i = 0;
		while (i < this.numColors) {
			if (this.distribution[i] > 0) {
				break;
			}
			i++;
		}
		this.minPixelIndex = i;

		// find max pixel value
		i = this.numColors - 1;
		while (i >= this.minPixelIndex) {
			if (this.distribution[i] > 0) {
				break;
			}
			i--;
		}
		this.maxPixelIndex = i;
	}

	getFeatureWithoutEnergy() {
		this.feature = new Array(this.numColors).fill(0);

		if (this.isPositiveMap) {
			this.feature[this.minPixelIndex] = this.distribution[this.minPixelIndex];
			for (let i = this.minPixelIndex + 1; i <= this.maxPixelIndex; i++) {
				this.feature[i] = this.feature[i - 1] + this.distribution[i];
			}
		} else {
			let j = this.maxPixelIndex + this.minPixelIndex;
			this.feature[this.minPixelIndex] = this.distribution[this.maxPixelIndex];
			for (let i = this.minPixelIndex + 1; i <= this.maxPixelIndex; i++) {
				this.feature[i] = this.feature[i - 1] + this.distribution[j - i];
			}
		}
	}

	getFeatureWithEnergy() {
		try {
			let eg = 0;
			for (let i = this.minPixelIndex; i <= this.maxPixelIndex; i++) {
				eg += i * this.distribution[i];
			}

			if (this.pPresent > 0) {
				this.feature[this.minPixelIndex] = this.distribution[this.minPixelIndex];
				for (let i = this.minPixelIndex + 1; i <= this.maxPixelIndex; i++) {
					this.feature[i] = this.feature[i - 1] + this.distribution[i];
				}
				this.meanPixelIndex = Math.round(eg / this.feature[this.maxPixelIndex]);
				this.mapIsInverted = false;
			} else {
				let j = this.maxPixelIndex + this.minPixelIndex;
				this.feature[this.minPixelIndex] = this.distribution[this.maxPixelIndex];
				for (let i = this.minPixelIndex + 1; i <= this.maxPixelIndex; i++) {
					this.feature[i] = this.feature[i - 1] + this.distribution[j - i];
				}
				this.meanPixelIndex =
					this.maxPixelIndex + this.minPixelIndex - Math.round(eg / this.feature[this.maxPixelIndex]);
				this.mapIsInverted = true;
			}
		} catch (error) {
			console.error(error);
		}
	}

	smoothDistribution() {
		let i, j, k, m, index, sum, thresh;
		let saveI;
		let buf = [];
		let found;
		// apply smoothing filter to eliminate artificial gaps:
		// note: FDistribution[FMinPixelIndex], FDistribution[FMaxPixelIndex] > 0!
		sum = 0;
		for (i = this.minPixelIndex; i <= this.maxPixelIndex; i++) {
			sum += this.distribution[i];
		}
		sum = Math.round((sum * 2) / (this.maxPixelIndex - this.minPixelIndex));

		i = this.minPixelIndex + 1;
		while (i < this.maxPixelIndex) {
			if (this.distribution[i] > 0) {
				i++;
				continue; // skip non-zero points
			}
			// when 0(s) is encountered, fill it:
			j = i - 1; // record the start non-zero position
			while (this.distribution[i] === 0 && i < this.maxPixelIndex) {
				i++;
			}
			k = i - 1; // end of zero gap

			if (
				j > this.minPixelIndex &&
				this.distribution[j] - this.distribution[j - 1] > sum &&
				k + distTap < this.maxPixelIndex
			) {
				thresh = 0;
				for (i = k + distTap; i <= Math.min(k + 2 * distTap, this.maxPixelIndex); i++) {
					thresh += this.distribution[i];
				}

				if (this.distribution[j] > thresh) {
					this.distribution[j] = this.distribution[j - 1];
				}
			}

			// get the local FMeanPixelIndex value:
			m = Math.floor(this.distribution[j] / (k - j + 1)); // k-j+1 is the width of zero gap neighbourhood

			// fill with the FMeanPixelIndex value:
			i = j;
			if (j <= k) {
				for (saveI = j; saveI <= k; saveI++) {
					this.distribution[saveI] = m;
				}
				i = k + 1;
			}
			i++; // i=k+2
		}

		// apply median filter:
		buf = new Array(distTap); // get the filter buffer
		this.filteredDistribution = new Array(this.numColors);

		// initialize the buffer:
		for (i = 0; i < distTap; i++) {
			buf[i] = this.distribution[i + this.minPixelIndex];
		}

		// sort the buffer:
		for (i = 0; i < distTap; i++) {
			for (j = i + 1; j < distTap; j++) {
				if (buf[i] > buf[j]) {
					// swap the elements:
					k = buf[i];
					buf[i] = buf[j];
					buf[j] = k;
				}
			}
		}

		for (i = this.minPixelIndex; i <= this.minPixelIndex + distTaph; i++) {
			this.filteredDistribution[i] = buf[distTaph];
		}

		for (index = this.minPixelIndex + distTaph + 1; index <= this.maxPixelIndex - distTaph; index++) {
			// get the delete element:
			k = this.distribution[index - (distTaph + 1)];
			j = 0;

			do {
				if (k === buf[j]) {
					// delete it:
					for (i = j; i < distTap - 1; i++) {
						buf[i] = buf[i + 1];
					}
					break;
				}
				j++;
			} while (j <= distTap - 1);

			// get the add element(note: after deleting, the top element is always empty!):
			k = this.distribution[index + distTaph];
			j = 0;
			found = false;
			do {
				if (k <= buf[j]) {
					// add the element (shift right):
					for (i = distTap - 2; i >= j; i--) {
						buf[i + 1] = buf[i];
					}
					// add it:
					buf[j] = k;
					found = true;
					break;
				}
				j++;
			} while (j <= distTap - 1);
			if (!found) {
				// it is put in max position:
				buf[distTap - 1] = k;
			}
			// get the result:
			this.filteredDistribution[index] = buf[distTaph];
		}

		// fill the end edge:
		for (i = this.maxPixelIndex; i >= this.maxPixelIndex - distTaph + 1; i--) {
			this.filteredDistribution[i] = buf[distTaph];
		}

		// get normalized feature function based on the PValue map polarity:
		if (this.isPositiveMap) {
			this.feature[this.minPixelIndex] = this.filteredDistribution[this.minPixelIndex]; //FDistribution[FMinPixelIndex];
			for (i = this.minPixelIndex + 1; i <= this.maxPixelIndex; i++) {
				this.feature[i] = this.feature[i - 1] + this.filteredDistribution[i]; //FDistribution[i];
			}
			this.mapIsInverted = false;
		} else {
			j = this.maxPixelIndex + this.minPixelIndex;
			this.feature[this.minPixelIndex] = this.filteredDistribution[this.maxPixelIndex]; //FDistribution[FMaxPixelIndex];
			for (i = this.minPixelIndex + 1; i <= this.maxPixelIndex; i++) {
				this.feature[i] = this.feature[i - 1] + this.filteredDistribution[j - i]; //FDistribution[j-i];
			}
			this.mapIsInverted = true;
		}
	}

	getFeatureRange(ProcessMGSpecific = false) {
		let i, j, k, x1, x2, x, peak1, peak2, ipeak1, ipeak2, ipeakm;
		let MaxPixelIndex, MinPixelIndex, MeanPixelIndex;
		let thresh, thresherr;
		let margl, margr;
		let Te0, Nw, newNw, trystart, tryend, noiseseed;
		let Eg, r_peak, r_interpeak, r_peak2, r_class, ratio, rwend, c_enhance;
		let noisefound, found, monopeak, calcMonoPeak;
		let wstart, wend;
		let feature = this.feature;

		this.statusCode = ImageProcessStatusCode.NonErr;
		monopeak = false;
		//initialize local variables for safety!!
		ipeak1 = 0;
		ipeak2 = 0;
		ipeakm = 0;
		noiseseed = 0;
		r_peak = 0;
		r_interpeak = 0;
		r_peak2 = 0;
		rwend = 0;
		c_enhance = 1;
		calcMonoPeak = true;

		// first scan to find the trial energy parameters
		try {
			MaxPixelIndex = this.maxPixelIndex;
			MinPixelIndex = this.minPixelIndex;
			MeanPixelIndex = this.meanPixelIndex;

			// get background noise level:
			Te0 = feature[MaxPixelIndex - 1] - feature[MinPixelIndex];
			thresherr = Math.round(this.autoWinErrorLimit * Te0);
			thresh = thresherr + feature[MinPixelIndex];

			// calc the info start:
			i = MinPixelIndex;
			while (i <= MaxPixelIndex) {
				if (feature[i] > thresh) {
					break;
				}
				i++;
			}
			wstart = i;

			// get the second trial start based on higher threshold (to eliminate dark structure noise)
			thresh = 2 * thresherr + feature[MinPixelIndex];
			i = wstart;
			while (i <= MaxPixelIndex) {
				if (feature[i] > thresh) {
					break;
				}
				i++;
			}
			trystart = i;
			if (trystart === MinPixelIndex) {
				ratio = 1;
			} else {
				ratio = (wstart - MinPixelIndex) / (trystart - MinPixelIndex);
			}
			if (ratio < 0.75) {
				// relocate the window start point by polyfitting:
				wstart = Math.round(trystart - (trystart - wstart) * ratio * ratio * ratio);
			}

			// compute the effective bright end:
			thresherr = Math.round(this.autoWinErrorLimit * Te0);
			thresh = feature[MaxPixelIndex - 1] - thresherr; // skip the back noise
			i = MaxPixelIndex - 1;
			while (i >= MeanPixelIndex) {
				if (feature[i] < thresh) {
					break;
				}
				i--;
			}
			wend = i;

			if (wend <= wstart) {
				// poor image quality:
				this.statusCode = ImageProcessStatusCode.InfoPartErr;
				return { wstart, wend };
			}

			if (!this.isPositiveMap) {
				// check the energy bias:
				k = Math.floor((wstart + wend) / 2);
				if (MeanPixelIndex > k) {
					ratio = Math.min((MeanPixelIndex - k) / (wend - wstart), 0.1);
					wend = wend + Math.round(2.5 * ratio * (wend - wstart));
				} else {
					wend = MaxPixelIndex;
				}
				return { wstart, wend };
			}

			// noise analysis / depression:
			noisefound = false;
			// get the noise sample window:
			Nw = Math.round(((wend - wstart) * noiseLevelRatio) / 2);
			if (Nw <= 1) {
				// poor image infomation quality:
				this.statusCode = ImageProcessStatusCode.InfoPartErr;
				return { wstart, wend };
			}

			// mass center:
			Eg = 0;
			for (i = wstart + 1; i <= wend; i++) {
				Eg += i * this.filteredDistribution[i];
			}
			MeanPixelIndex = Math.round(Eg / (feature[wend] - feature[wstart]));

			if (MeanPixelIndex - wstart <= 2) {
				// too strong background noise energy:
				noiseseed = wstart + Nw; // noise depression seed point
				Te0 = feature[wend] - feature[noiseseed]; // forward energy
				thresherr = Math.round(this.autoWinErrorLimit * Te0); // noise energy relative limit
				thresh = thresherr + feature[noiseseed]; // absolute noise threshold
				i = noiseseed;
				// compute the new start point:
				while (i <= wend) {
					if (feature[i] > thresh) {
						break;
					}
					i++;
				}
				wstart = i;
				return { wstart, wend };
			} else {
				// search the main peak:
				peak1 = 0;
				peak2 = 0;
				trystart = EnsureRange(wstart, MinPixelIndex + Nw, MaxPixelIndex - Nw); // get the start point
				tryend = EnsureRange(wend, MinPixelIndex + Nw, MaxPixelIndex - Nw); // get the end point

				// use MeanPixelIndex as the seed point to search peaks in range of [trystart, tryend]:
				if (MeanPixelIndex < trystart) {
					// search peak1 in the range [MinPixelIndex, trystart]:
					newNw = Math.max(2, Math.floor((MeanPixelIndex - MinPixelIndex) / 5)); // set new search filter kernal size
					for (i = trystart; i >= MinPixelIndex + newNw; i--) {
						x1 = feature[i + newNw] - feature[i - newNw];
						if (x1 > peak1) {
							peak1 = x1;
							ipeak1 = i; // record the max position
						}
					}

					// search peak2 in the range [trystart, tryend]:
					for (j = trystart; j <= tryend; j++) {
						x2 = feature[j + Nw] - feature[j - Nw];
						if (x2 > peak2) {
							peak2 = x2;
							ipeak2 = j; // record the max position
						}
					}

					if (ipeak2 === trystart) {
						// peak2 is combined with peak1:
						ipeak2 = ipeak1;
						peak2 = peak1;
					}

					if (ipeak1 === trystart) {
						// peak1 is combined with peak2:
						ipeak1 = ipeak2;
						peak1 = peak2;
					}

					// normalize the peak value (due to different search resolution):
					ratio = Nw / newNw;
					peak1 = Math.round(peak1 * ratio);
					peak2 = Math.round(peak2 * ratio);
				} else if (MeanPixelIndex > tryend) {
					// search peak in the range [tryend, MaxPixelIndex]:
					newNw = Math.max(2, Math.floor((MaxPixelIndex - MeanPixelIndex) / 5)); // set new search filter kernal size
					peak1 = Math.round(
						((feature[MeanPixelIndex + newNw] - feature[MeanPixelIndex - newNw]) * Nw) / newNw
					);
					peak2 = peak1;
					ipeak1 = MeanPixelIndex;
					ipeak2 = ipeak1;
				} else {
					// for the normal search range [trystart, tryend]:
					// search backwards:
					for (i = MeanPixelIndex; i >= trystart; i--) {
						// backward search:
						x1 = feature[i + Nw] - feature[i - Nw];
						if (x1 > peak1) {
							peak1 = x1;
							ipeak1 = i; // record the max position
						}
					}

					// search forwards:
					for (j = MeanPixelIndex; j <= tryend; j++) {
						// forward search:
						x2 = feature[j + Nw] - feature[j - Nw];
						if (x2 > peak2) {
							peak2 = x2;
							ipeak2 = j; // record the max position
						}
					}

					// check if the peak1 is out of range:
					if (ipeak1 === trystart) {
						// peak1 falls in range [MinPixelIndex, trystart], search it in this range:
						newNw = Math.max(2, Math.floor((trystart - MinPixelIndex) / 5)); // set new search filter kernal size
						ratio = Nw / newNw; // set normalization ratio
						peak1 = Math.round(peak1 / ratio);

						if (this.modality === 'MG' && ProcessMGSpecific) {
							for (i = trystart; i >= wstart; i--) {
								if (i >= newNw) {
									x1 = feature[i + newNw] - feature[i - newNw];
									if (x1 > peak1) {
										peak1 = x1;
										ipeak1 = i; // record the max position
									}
								}
							}
							// normalize the peak value:
							peak1 = Math.round(peak1 * ratio);

							if (ipeak1 > wstart && ipeak1 !== ipeak2) {
								calcMonoPeak = false;
								monopeak = false;
							}
						} else {
							for (i = trystart; i >= MinPixelIndex + newNw; i--) {
								x1 = feature[i + newNw] - feature[i - newNw];
								if (x1 > peak1) {
									peak1 = x1;
									ipeak1 = i; // record the max position
								}
							}
							// normalize the peak value:
							peak1 = Math.round(peak1 * ratio);
						}
					}

					if (ipeak1 === MeanPixelIndex) {
						// peak1 is merged into peak2:
						ipeak1 = ipeak2;
						peak1 = peak2;
					}

					if (ipeak2 === MeanPixelIndex) {
						// peak2 is merged into peak1:
						ipeak2 = ipeak1;
						peak2 = peak1;
					}
				}

				// energy peak analysis:
				if (calcMonoPeak) {
					monopeak = false;
					if (this.modality === 'CT') {
						c_enhance = 0.9; // enhance contrast for CT information part
						if (ipeak1 === ipeak2) {
							ipeakm = Math.floor((wstart + ipeak1) / 2);
						} else {
							ipeakm = Math.floor((ipeak1 + ipeak2) / 2);
						}
						noiseseed = Math.max(ipeakm, MinPixelIndex + Nw);
						noisefound = true;
					} else if ((ipeak1 <= trystart && peak1 > 0) || this.modality === 'MR') {
						// check the peak ratio. Note: CT's noise peak (low graylevel) does not belong to this case.
						if (ipeak1 === ipeak2) {
							ratio = 0;
						} else {
							ratio = peak2 / peak1;
						}

						if (ratio < 0.3) {
							// one dorminant peak within dark region:
							// Note: for MR, the low graylevel peak belongs to the information part.
							if (ipeak1 === ipeak2) {
								ipeak2 = Math.round(trystart * 0.2 + tryend * 0.8);
							}
							peak2 = Math.round((feature[ipeak2 + Nw] - feature[ipeak2 - Nw]) / (2 * Nw));
							monopeak = true;
						}
					}
				}

				if (!noisefound) {
					ipeakm = Math.round((ipeak1 + ipeak2) / 2);
					r_interpeak = (ipeak2 - ipeak1) / (wend - wstart);

					// check noise dorminance case:
					i = Math.max(ipeakm, MinPixelIndex + Nw);
					r_peak = (feature[i + Nw] - feature[i - Nw]) / (2 * Nw); // average of window
					ratio = (feature[wend] - feature[wstart]) / (wend - wstart);
					r_peak = r_peak / ratio; // Round((feature[wend]-feature[wend-2*Nw])/3))
					r_peak2 = (feature[ipeak2 + Nw] - feature[ipeak2 - Nw]) / (2 * Nw) / ratio;
					if (monopeak) {
						noisefound = false;
					} else if (r_peak < 0.33 || r_interpeak / r_peak > 3 || (r_peak2 > 2 && r_peak < 0.75)) {
						// the noise peak is found:
						noisefound = true;
						// use ipeakm as seed point:
						noiseseed = i;
					} else if (r_peak > 2.5 && r_interpeak < 0.1) {
						ratio = 0.8 / (1 / r_peak - 0.2);
						// for narrow mono peak structure:
						if (ratio > 5 && ipeakm > MinPixelIndex + Nw) {
							// reduce the noise subpeak:
							ratio = 0.2 * r_peak;
							ratio = 0.2 * (1 - ratio) + 0.4 * ratio;
							thresh = Math.round(((peak1 + peak2) / 2) * ratio); // note: peak1,2 is area within neighbour 2Nw
							i = ipeakm;
							j = Math.max(wstart, MinPixelIndex + Nw);
							found = false;
							// search new start point:
							while (i > j) {
								if (feature[i + Nw] - feature[i - Nw] < thresh) {
									found = true;
									break;
								}
								i--;
							}
							if (found) {
								wstart = i;
							}
						}
					}
				}
			}

			if (noisefound) {
				// get adjusted noise energy level:
				Te0 = feature[wend] - feature[noiseseed]; // infomation energy
				thresherr = Math.round(this.autoWinErrorLimit * Te0); // relative noise threshold
				thresh = thresherr + feature[noiseseed]; // abs threshold
				i = noiseseed;
				// compute the new effective infomation start point:
				while (i <= wend) {
					if (feature[i] > thresh) {
						break;
					}
					i++;
				}
				wstart = i;

				// get the second trial start:
				thresh = 2 * thresherr + feature[noiseseed];
				i = wstart;
				while (i <= MaxPixelIndex) {
					if (feature[i] > thresh) {
						break;
					}
					i++;
				}
				trystart = i;

				ratio = 0.2 * r_peak2;
				ratio = 0.2 * (1 - ratio) + 0.4 * ratio;
				thresh = Math.round(peak2 * ratio); // get the peak extraction threshold

				if (trystart === noiseseed) {
					ratio = 1;
				} else {
					ratio = (wstart - noiseseed) / (trystart - noiseseed);
				}

				if (ratio < 0.85) {
					if (ipeak2 > MinPixelIndex + Nw) {
						// reduce the noise subpeak:
						i = ipeak2;

						found = false;
						// search new start point:
						while (i > wstart) {
							if (feature[i + Nw] - feature[i - Nw] < thresh) {
								found = true;
								break;
							}
							i--;
						}
						if (found) {
							wstart = i;
						}
					}
				} // end of subpeak reduction

				// search end point:
				if (this.modality === 'CT') {
					// enhance contrast based on the info peak:
					i = ipeak2;
					j = Math.min(wend, MaxPixelIndex - Nw);
					thresh += Math.floor(thresh / 10);
					found = false;
					// find the end point of the peak:
					while (i < j) {
						if (feature[i + Nw] - feature[i - Nw] < thresh) {
							found = true;
							break;
						}
						i++;
					}
					if (found) {
						wend = i;
						if (wend <= wstart) {
							this.statusCode = ImageProcessStatusCode.InfoPartErr; // poor image infomation quality:
						}
						return { wstart, wend };
					}
				}

				if (wend <= wstart) {
					// poor image infomation quality:
					this.statusCode = ImageProcessStatusCode.InfoPartErr;
					return { wstart, wend };
				}

				// update the feature parameters:
				ipeakm = ipeak2;
				peak1 = peak2;
				i = Math.min(ipeakm, MaxPixelIndex - Nw);
				r_peak = (feature[i + Nw] - feature[i - Nw]) / (2 * Nw);
				r_peak = r_peak / ((feature[wend] - feature[trystart]) / (wend - trystart));
			}

			// recalc the adjusted bright end:
			Te0 = feature[MaxPixelIndex - 1] - feature[wstart];
			// first search the end point skipping base level bright noise:
			thresherr = Math.round(this.autoWinErrorLimit * Te0);
			thresh = feature[MaxPixelIndex - 1] - thresherr;
			i = MaxPixelIndex - 1;
			while (i >= wstart) {
				if (feature[i] < thresh) {
					break;
				}
				i--;
			}
			// record the base noise end point:
			wend = i;

			// search median point of bright zone:
			thresh = feature[MaxPixelIndex - 1] - thresherr * 3;
			i = MaxPixelIndex - 1;
			while (i >= wstart) {
				if (feature[i] < thresh) {
					break;
				}
				i--;
			}
			x = i; // record median point

			// search the start point of bright zone:
			thresh = feature[MaxPixelIndex - 1] - thresherr * 5;
			i = MaxPixelIndex - 1;
			while (i >= wstart) {
				if (feature[i] < thresh) {
					break;
				}
				i--;
			}
			trystart = i;

			// get the compensation factor based the local feature of brightness:
			r_class = (MaxPixelIndex - trystart) / (wend - wstart);
			if (r_class < 0.5) {
				// no brightness saturation for future use:
				// wend = MaxPixelIndex;
			} else if (r_class > 1 || (r_class > 0.5 && r_peak > 2)) {
				// the case with largely variance of energy distribution (wide range and sharp info peak):
				if (MaxPixelIndex > wend) {
					// adaptive saturation control by polynomial fitness:
					margl = wend - x;
					margr = Math.floor((MaxPixelIndex - wend) / 2);
					rwend = (margl / margr) * (1 - this.autoWinErrorLimit * 2);
					if (rwend > 1 && r_peak > 2 && ipeakm + Nw < wend) {
						peak2 = 0;
						tryend = EnsureRange(wend, MinPixelIndex + Nw, MaxPixelIndex - Nw); // get the end point
						trystart = Math.floor((ipeakm + wend) / 2);
						for (j = Math.floor((ipeakm + wend) / 2); j <= tryend; j++) {
							// forward search:
							x2 = feature[j + Nw] - feature[j - Nw];
							if (x2 > peak2) {
								peak2 = x2;
								ipeak2 = j; // record the max position
							}
						}

						ratio = (feature[ipeak2 + Nw] - feature[ipeak2 - Nw]) / (2 * Nw);
						ratio = ratio / ((feature[wend] - feature[trystart]) / (wend - trystart)); // average of window

						if (
							ratio > 1.1 &&
							ratio < 5 &&
							(ipeak2 - trystart) / (wend - trystart) > 0.75 &&
							ipeak2 !== wend
						) {
							// search new window end point to eliminate the noise energy:
							ratio = Math.sqrt(0.2 * r_peak);
							ratio = 0.2 * (1 - ratio) + 0.3 * ratio;
							thresh = Math.round(((peak1 + peak2) / 2) * ratio); // note: peak1,2 is area within neighbour 2Nw
							i = ipeakm;
							found = false;
							// search forwards:
							while (i < wend - Nw) {
								if (feature[i + Nw] - feature[i - Nw] < thresh) {
									found = true;
									break;
								}
								i++;
							}
							if (found) {
								k = i;
							} else {
								k = Math.min(i + Nw, wend);
							}
							wend = k;
						}
					} else {
						wend = Math.min(
							wend + Math.round((0.6 + 1.4 * rwend * rwend) * margl * c_enhance),
							MaxPixelIndex
						);
					}
				}
			} else {
				// brightness compensation (for feature use):
				// wend = wend + Math.round((MaxPixelIndex - wend) * 0.5);
			}

			return { wstart, wend };
		} catch {
			this.statusCode = ImageProcessStatusCode.EndOfErr;
		}
	}
}

export default ImagePixelAnalyser;
