import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import GenericDrawer from '../GenericDrawer';
import SuggestInfiniteScroll from '@worklist-2/ui/src/components/SuggestInfiniteScroll';
import SuggestMultipleValueInfiniteScroll from '@worklist-2/ui/src/components/SuggestMultipleValueInfiniteScroll';

import { useTranslation } from 'react-i18next';
import { generateAvatarName, getUserFullName } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';

import {
	Avatar,
	Button,
	Box,
	Typography,
	ToggleButton,
	ToggleButtonGroup,
	List,
	ListItem,
	ListItemButton,
	FormControl,
	Select,
	MenuItem,
	Stack,
	Divider,
	Checkbox,
	InputLabel,
	InputAdornment,
} from '@mui/material';
import ContactIcon from '@mui/icons-material/PersonOutlineOutlined';
import OrganizationIcon from '@mui/icons-material/BusinessOutlined';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import OverflowTooltip from '@worklist-2/ui/src/components/OverflowTooltip';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SearchIcon from '@mui/icons-material/Search';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { fhirExtensionUrls } from '@worklist-2/core/src/fhir/extension';
import { useFhirDataLoader, searchScopes } from '@worklist-2/core/src';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { PAYMENT_ORGANIZATION_TYPES_PARAM } from '@rs-ui/views/utils/paymentOrganizationTypes';

const shareObject = [
	{
		code: 'contact',
		display: 'Contact',
		icon: <ContactIcon sx={{ height: '20px', width: '20px', marginRight: '5px' }} />,
	},
	{
		code: 'organization',
		display: 'Organization',
		icon: <OrganizationIcon sx={{ height: '20px', width: '20px', marginRight: '5px' }} />,
	},
];

const accessTypes = [
	{
		label: 'Can view',
		value: 'view-only',
		allowed: ['organization', 'contact'],
	},
	{
		label: 'Can edit',
		value: 'admin',
		allowed: ['contact'],
	},
];

const userSearchScope = {
	resource: 'practitioner',
	label: 'name',
	searchKey: 'text.div',
	extraParam: {
		_summary: 'text',
		_elements: 'name',
	},
};

const ShareDrawer = ({
	drawerOpen,
	setDrawerOpen,
	onClose,
	accessData,
	owner,
	onChangeAccessData,
	resourceName,
	itemName,
	isReadOnly,
}) => {
	// States
	const [isAddNewShare, setIsAddNewShare] = useState(false);

	// States for change tab Contact/Oganization
	const [objectType, setObjectType] = useState('contact');

	// States for Contact list
	const [contactList, setContactList] = useState([]);

	// States for Organization list
	const [organizationList, setOrganizationList] = useState([]);

	// States for opened select index
	const [openedSelectIndex, setOpenedSelectIndex] = useState(null);

	// States for selected privilege when adding new share
	const [selectedPrivilege, setSelectedPrivilege] = useState('view-only');

	// States for selected contact
	const [accessOpened, setAccessOpened] = useState(false);

	// States for selected organization
	const [selectedOrganization, setSelectedOrganization] = useState([]);

	// States for selected contact
	const [selectedContact, setSelectedContact] = useState([]);

	const { t } = useTranslation('teaching');

	const userDataLoader = useFhirDataLoader({
		scope: searchScopes.practitioner,
	});

	const organizationDataLoader = useFhirDataLoader({
		scope: searchScopes.organization,
	});

	const proactEnableOrganizationProvFilter = useBooleanFlagValue('proact-enable-organization-prov-filter');

	const organizationTypeParam = useMemo(
		() =>
			proactEnableOrganizationProvFilter
				? { organizationType: 'PROV' }
				: { 'organizationType:not': PAYMENT_ORGANIZATION_TYPES_PARAM },
		[proactEnableOrganizationProvFilter]
	);

	// Load user data
	useEffect(() => {
		if (accessData) {
			const { user, organization } = accessData;
			user && setContactList(user);
			organization && setOrganizationList(organization);
		} else {
			setContactList([]);
			setOrganizationList([]);
		}
	}, [accessData]);

	// Reset selected contact and organization when drawer open
	useEffect(() => {
		if (drawerOpen) {
			setIsAddNewShare(false);
			setObjectType('contact');
		}
	}, [drawerOpen]);

	// Load contact/organization data
	const sharedList = useMemo(() => {
		if (objectType === shareObject[0].code) {
			return contactList;
		}
		return organizationList;
	}, [contactList, organizationList, objectType]);

	// Get User Name
	const renderUserName = data => {
		let userEmail = '';

		if (data.extension) {
			const loginEmail = data.extension?.find(e => e.url === fhirExtensionUrls.practitioner.loginName);
			userEmail = loginEmail.valueString;
		}

		const nameStr = getUserFullName(data.name[0]);

		return {
			nameString: `${nameStr} | ${userEmail}`,
			avatarAbbr: generateAvatarName(nameStr),
			tagDisplay: nameStr,
		};
	};

	// Render User Name
	const renderUserOptionalLabel = (props, selected, data) => {
		const userNameObj = renderUserName(data);

		return (
			<li {...props}>
				<Checkbox
					checked={selected}
					checkedIcon={<CheckBoxIcon fontSize="small" />}
					icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
				/>
				<Avatar
					sx={{
						bgcolor: 'rgb(160, 155, 213)',
						width: 30,
						height: 30,
						fontSize: '16px',
						marginRight: '10px',
						color: 'white',
					}}
				>
					{userNameObj?.avatarAbbr}
				</Avatar>
				<OverflowTooltip text={userNameObj?.nameString} />
			</li>
		);
	};

	// Render Organization Name
	const renderOrganizationLabel = (props, selected, data) => (
		<li {...props}>
			<Checkbox
				checked={selected}
				checkedIcon={<CheckIcon />}
				icon={<CheckBoxOutlineBlankIcon fontSize="small" sx={{ opacity: 0 }} />}
			/>
			<OverflowTooltip text={data.name} />
		</li>
	);

	// On selected user
	const onSelectedUser = useCallback(
		val => {
			if (val) {
				const usersArr = val.map(item => ({ id: item?.id, display: item?.tagDisplay }));
				setSelectedContact([...usersArr]);
			} else {
				setSelectedContact([]);
			}
		},
		[setSelectedContact]
	);

	// On select organization
	const onSelectOrganization = useCallback(
		val => {
			if (val && val?.id) {
				setSelectedOrganization([{ id: val?.id, display: val?.tagDisplay ?? val?.name }]);
			} else {
				setSelectedOrganization([]);
			}
		},
		[setSelectedOrganization]
	);

	// On share click
	const onShareClick = useCallback(() => {
		const accessDataUpdate = {
			user: [...contactList],
			organization: [...organizationList],
		};

		if (objectType === shareObject[0].code) {
			let updatedContactList = [...contactList];
			selectedContact.forEach(item => {
				if (!updatedContactList.some(contact => contact.id === item.id)) {
					updatedContactList.push({
						...item,
						privilege: selectedPrivilege,
					});
				} else {
					updatedContactList = updatedContactList.map(contact => {
						if (contact.id === item.id) {
							return {
								...item,
								privilege: selectedPrivilege,
							};
						}
						return contact;
					});
				}
			});
			accessDataUpdate.user = updatedContactList;
			setContactList(updatedContactList);
		} else {
			let updatedOrganizationList = [...organizationList];
			selectedOrganization.forEach(item => {
				if (!updatedOrganizationList.some(org => org.id === item.id)) {
					updatedOrganizationList.push({
						...item,
						privilege: selectedPrivilege,
					});
				} else {
					updatedOrganizationList = updatedOrganizationList.map(org => {
						if (org.id === item.id) {
							return {
								...item,
								privilege: selectedPrivilege,
							};
						}
						return org;
					});
				}
			});
			accessDataUpdate.organization = updatedOrganizationList;
			setOrganizationList(updatedOrganizationList);
		}
		setIsAddNewShare(false);
		onChangeAccessData(accessDataUpdate, 'share');
	}, [
		selectedContact,
		selectedOrganization,
		objectType,
		selectedPrivilege,
		contactList,
		setContactList,
		organizationList,
		setOrganizationList,
		setIsAddNewShare,
		onChangeAccessData,
	]);

	// On select privilege
	const onSelectPrivilege = (item, value) => {
		setSelectedPrivilege(value.props.value);
	};

	// On change privilege
	const onChangePrivilege = useCallback(
		(selectedValue, itemId) => {
			const updatedAccess = {
				user: [...contactList],
				organization: [...organizationList],
			};

			if (selectedValue === 'remove') {
				if (objectType === shareObject[0].code) {
					const updatedList = contactList.filter(item => item.id !== itemId);
					setContactList(updatedList);
					updatedAccess.user = updatedList;
				} else {
					const updatedList = organizationList.filter(item => item.id !== itemId);
					setOrganizationList(updatedList);
					updatedAccess.organization = updatedList;
				}
			} else {
				const updatedList = sharedList.map(item => {
					if (item.id === itemId) {
						return { ...item, privilege: selectedValue };
					}
					return item;
				});
				if (objectType === shareObject[0].code) {
					setContactList(updatedList);
					updatedAccess.user = updatedList;
				} else {
					setOrganizationList(updatedList);
					updatedAccess.organization = updatedList;
				}
			}

			onChangeAccessData(updatedAccess, selectedValue);
		},
		[objectType, sharedList, organizationList, setOrganizationList, contactList, setContactList, onChangeAccessData]
	);

	return (
		<GenericDrawer
			PaperProps={{
				sx: {
					zIndex: 1199,
					backgroundColor: '#242424',
					'.headerSection': { pl: '10px' },
				},
			}}
			drawerOpen={drawerOpen}
			headerSx={{ zIndex: 10, backgroundColor: '#242424' }}
			setDrawerOpen={setDrawerOpen}
			title={isAddNewShare ? t(`Share to ${objectType}`) : t('Share')}
			variant="persistent"
			width="400px"
			onBackClick={onClose}
			onCloseClick={() => setDrawerOpen(false)}
		>
			{!isAddNewShare ? (
				<Box
					data-testid="share-container"
					sx={{
						padding: '0 20px',
					}}
				>
					<Stack
						direction="column"
						sx={{
							borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
						}}
					>
						{itemName && (
							<Box sx={{ color: 'white', fontSize: '16px', fontFamily: 'Roboto', marginBottom: '5px' }}>
								<OverflowTooltip text={itemName} />
							</Box>
						)}
					</Stack>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: '#FFFFFF3B',
							borderRadius: '10px',
							margin: '15px 0 15px 0',
						}}
					>
						<ToggleButtonGroup
							exclusive
							aria-label="Platform"
							color="standard"
							sx={{
								height: '40px',
							}}
							value={objectType}
							onChange={(e, value) => value && setObjectType(value)}
						>
							{shareObject.map(item => (
								<ToggleButton key={item.code} style={{ textTransform: 'none' }} value={item.code}>
									{item.icon}
									<Typography>{t(item.display)}</Typography>
								</ToggleButton>
							))}
						</ToggleButtonGroup>
					</Box>
					{!isReadOnly && (
						<Box
							data-testid="add-new-share"
							sx={{
								width: '100%',
								height: '54px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FFFFFF3B' stroke-width='3' rx='10' ry='10' stroke-dasharray='6%2c 10' stroke-dashoffset='34' stroke-linecap='round'/%3e%3c/svg%3e")`,
								marginTop: '5px',
								color: '#FFFFFF3B',
								borderRadius: '10px',
								'&:hover': {
									color: '#FFF',
									backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FFFFFF' stroke-width='3' rx='10' ry='10' stroke-dasharray='6%2c 10' stroke-dashoffset='34' stroke-linecap='round'/%3e%3c/svg%3e")`,
									cursor: 'pointer',
								},
								marginBottom: '10px',
							}}
							onClick={() => {
								setSelectedPrivilege('view-only');
								setIsAddNewShare(true);
							}}
						>
							<AddIcon />
						</Box>
					)}
					<List
						sx={{
							paddingTop: 'unset',
							overflow: 'auto',
							maxHeight: `calc(100vh - 300px)`,
						}}
					>
						{_.concat(objectType === shareObject[0].code && owner ? [owner] : [], sharedList).map(
							(item, index) => (
								<ListItem
									key={`${objectType}-${item.id}`}
									data-testid={`share-side-content-elem-${item.id}`}
									sx={{
										padding: '8px 0',
									}}
								>
									<ListItemButton
										sx={{
											maxWidth: '360px',
											padding: '16px',
											background:
												index === openedSelectIndex
													? 'linear-gradient(0deg, rgba(66, 165, 245, 0.20) 0%, rgba(66, 165, 245, 0.20) 100%), rgba(255, 255, 255, 0.10)'
													: 'rgba(255, 255, 255, 0.1)',
											borderRadius: '10px',
											border: '1px solid transparent',
											justifyContent: 'space-between',
											borderColor: index === openedSelectIndex && 'rgba(66, 165, 245, 0.6)',
											'&:hover': {
												borderColor: 'rgba(66, 165, 245, 0.6)',
											},
										}}
									>
										<Box
											sx={{
												minWidth: '50px',
												height: '50px',
												background: '#6478C0',
												borderRadius: '10px',
												color: '#fff',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Typography>{generateAvatarName(item.display)}</Typography>
										</Box>
										<Stack direction="column">
											<Box
												sx={{
													width: '150px',
													marginLeft: '16px',
													color: '#FFFFFF',
													fontWeight: 700,
													fontFamily: 'Roboto',
												}}
											>
												<OverflowTooltip text={item?.display} />
											</Box>
											{objectType === shareObject[1].code && (
												<Typography
													sx={{
														color: '#FFFFFF',
														fontSize: '10px',
														fontWeight: 100,
														width: '150px',
														marginLeft: '15px',
													}}
												>
													{t(`Users from above organization can access this ${resourceName}`)}
												</Typography>
											)}
										</Stack>
										<FormControl sx={{ m: 1, minWidth: 80 }}>
											<Select
												disableUnderline
												MenuProps={{ disableScrollLock: true }}
												data-testid="report-share-privilege"
												defaultValue="view-only"
												disabled={
													isReadOnly ||
													(objectType === shareObject[0].code && item.id == owner?.id)
												}
												id="privilege"
												sx={{
													fontSize: '12px',
													'& .CheckIcon': {
														display: 'none',
													},
													'& .MuiSelect-select': {
														background: 'transparent !important',
													},
													'& .MuiSelect-icon': {
														top: 'calc(50% - 0.6em) !important',
													},
												}}
												value={
													objectType === shareObject[0].code && item.id === owner?.id
														? 'owner'
														: item.privilege
												}
												variant="standard"
												onChange={e => onChangePrivilege(e.target.value, item.id)}
												onClose={() => setOpenedSelectIndex(null)}
												onOpen={() => setOpenedSelectIndex(index)}
											>
												{objectType === shareObject[0].code && item.id === owner?.id && (
													<MenuItem sx={{ padding: '10px 30px 10px 15px' }} value="owner">
														<Box sx={{ display: 'flex', gap: '10px' }}>{t('Owner')}</Box>
													</MenuItem>
												)}
												{accessTypes.map(type => (
													<MenuItem
														key={type.value}
														sx={{
															padding: '10px 30px 10px 15px',
															display: !type.allowed.includes(objectType) && 'none',
														}}
														value={type.value}
													>
														<Box sx={{ display: 'flex', gap: '10px' }}>
															{item.privilege === type.value ? (
																<CheckIcon
																	className="CheckIcon"
																	sx={{ color: '#42A5F5' }}
																/>
															) : (
																<Box sx={{ width: '24px', height: '24px' }} />
															)}
															{t(type.label)}
														</Box>
													</MenuItem>
												))}
												<Divider />
												<MenuItem sx={{ padding: '10px 30px 10px 15px' }} value="remove">
													<Box sx={{ display: 'flex', gap: '10px' }}>
														<Box sx={{ width: '24px', height: '24px' }} />
														{t('Remove')}
													</Box>
												</MenuItem>
											</Select>
										</FormControl>
									</ListItemButton>
								</ListItem>
							)
						)}
					</List>
				</Box>
			) : (
				<Box
					data-testid="new-share-container"
					sx={{
						padding: '0 20px',
					}}
				>
					<Stack
						spacing={3}
						sx={{
							padding: '28px 0',
							borderTop: '1px solid rgba(255, 255, 255, 0.1)',
						}}
					>
						{objectType === shareObject[0].code && (
							<SuggestMultipleValueInfiniteScroll
								dataLoader={userDataLoader}
								excludedOption={_.concat(
									[owner.id],
									contactList ? contactList.map(item => item.id) : []
								)}
								label={`${t('Shared User')}*`}
								placeHolderText={t('Search user name, email')}
								renderOptionlist={renderUserOptionalLabel}
								renderTag={renderUserName}
								searchParams={{
									search: userSearchScope.label,
									extraParam: userSearchScope?.extraParam,
									sort: 'username',
								}}
								startAdornment={<AccountCircleOutlinedIcon />}
								onSelectItem={onSelectedUser}
							/>
						)}
						{objectType === shareObject[1].code && (
							<SuggestInfiniteScroll
								dataLoader={organizationDataLoader}
								excludedOption={organizationList ? organizationList.map(item => item.id) : []}
								label={`${t('Shared Organization')}*`}
								placeHolderText={t('Search Organization')}
								renderOptionlist={renderOrganizationLabel}
								searchParams={{
									search: 'name',
									extraParam: { isReferring: false, ...organizationTypeParam },
									sort: 'name',
								}}
								startAdornment={<CorporateFareIcon />}
								onSelectItem={onSelectOrganization}
							/>
						)}

						<FormControl fullWidth sx={{ marginTop: '16px !important' }}>
							<InputLabel id="select-privilege">{`${t('Access')}*`}</InputLabel>
							<Select
								displayEmpty
								MenuProps={{ disableScrollLock: true }}
								data-testid="report-share-privilege-new"
								disabled={objectType === shareObject[1].code}
								id="privilege"
								label="Privilege"
								labelId="select-privilege"
								placeholder={t('Set access')}
								startAdornment={
									<InputAdornment position="start">
										<SearchIcon sx={{ margin: '0 5px' }} />
									</InputAdornment>
								}
								sx={{
									boxShadow: 'none',
									padding: '0px',
									'&.MuiOutlinedInput-root .CheckIcon': {
										display: 'none',
									},
									'&.MuiOutlinedInput-root .MuiTypography-root': {
										marginLeft: '0px',
									},
								}}
								value={selectedPrivilege}
								variant="outlined"
								onChange={onSelectPrivilege}
								onClose={() => setAccessOpened(false)}
								onOpen={() => setAccessOpened(true)}
							>
								{accessTypes.map(({ label, value }, index) => (
									<MenuItem key={index + value} index={index} value={value}>
										{value === selectedPrivilege && accessOpened && (
											<CheckIcon className="CheckIcon" sx={{ color: '#42A5F5' }} />
										)}
										<Typography
											sx={{
												marginLeft: accessOpened
													? value === selectedPrivilege
														? '15px'
														: '40px'
													: '0px',
											}}
										>
											{t(label)}
										</Typography>
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>
					<Stack
						direction="row"
						justifyContent="space-between"
						sx={{
							position: 'absolute',
							bottom: '16px',
							padding: '0 20px',
							left: '0',
							right: '0',
						}}
					>
						<Button
							color="success"
							sx={{
								color: 'rgb(144, 202, 249)',
								border: '1px solid rgba(144, 202, 249, 0.5)',
								'&:hover': {
									background: 'rgba(144, 202, 249, 0.08)',
									border: '1px solid rgb(144, 202, 249)',
								},
							}}
							variant="outlined"
							onClick={() => setIsAddNewShare(false)}
						>
							{t('Cancel')}
						</Button>
						<Button
							color="secondary"
							data-cy="share-button"
							sx={{
								color: '#FFF',
								background: 'rgb(66, 165, 245)',
								'&:hover': {
									background: 'rgb(144, 202, 249)',
								},
							}}
							variant="contained"
							onClick={onShareClick}
						>
							{t('Share')}
						</Button>
					</Stack>
				</Box>
			)}
		</GenericDrawer>
	);
};

ShareDrawer.propTypes = {
	/**
	 * Drawer open state
	 */
	drawerOpen: PropTypes.bool,

	/**
	 * Function to set drawer open state
	 */
	setDrawerOpen: PropTypes.func,

	/**
	 * Function to close the drawer
	 * */
	onClose: PropTypes.func,

	/**
	 * Access data
	 */
	accessData: PropTypes.object,

	/**
	 * Owner of the resource
	 * */
	owner: PropTypes.object,

	/**
	 * Function to change access data
	 * */
	onChangeAccessData: PropTypes.func,

	/**
	 * Resource name
	 * */
	resourceName: PropTypes.string,

	/**
	 * Item name
	 */
	itemName: PropTypes.string,

	/**
	 * Is read only
	 */
	isReadOnly: PropTypes.bool,
};

ShareDrawer.defaultProps = {
	drawerOpen: false,
	setDrawerOpen: () => {},
	onClose: () => {},
	accessData: null,
	owner: null,
	onChangeAccessData: () => {},
	resourceName: 'Folder',
	itemName: '',
	isReadOnly: false,
};

export default ShareDrawer;
