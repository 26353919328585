import { create } from 'zustand';

export const useDeviceStore = create(set => ({
	deviceManager: null,

	// ==================== Setters ====================
	setDeviceManager: state => set({ deviceManager: state }),

	// ==================== Re-setters ====================

	resetDeviceStore: () =>
		set({
			deviceManager: null,
		}),
}));
