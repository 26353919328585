import { SOPClass, IsXRay, IsUltrasound } from './SOPClass';
import getSeriesOrientationPlane from '../../utils/getSeriesOrientationPlane';

class SeriesSplitter {
	splitFramesetList = [];
	framesetSubIDCounter = 1;
	framesetSubIDMap = new Map();
	UIDPrefix = 'urn:oid:';

	INSTANCE = 'metadata';
	NUMBER_OF_INSTANCES = '00201209';
	CODE_VALUE = '00080100';

	SplitSeries(seriesJSon) {
		this.splitFramesetList = [];
		this.framesetSubIDCounter = 1;
		this.framesetSubIDMap = new Map();

		let seriesNumber = -1,
			token = this.getSeriesNumber(seriesJSon),
			instanceToken = seriesJSon[this.INSTANCE];

		if (token) {
			seriesNumber = parseInt(token);
		}

		if (seriesNumber > 0 && instanceToken) {
			instanceToken.forEach(instance => {
				let framesetSubID = this.GetFramesetSubID(instance);

				if (framesetSubID) {
					let framesetID,
						modalityCode = this.getModalityCode(seriesJSon),
						laterality = this.getLaterality(seriesJSon);

					if (modalityCode && modalityCode == 'MG' && laterality) {
						framesetID = laterality + '.' + framesetSubID;
					} else {
						framesetID = seriesNumber + '.' + framesetSubID;
					}

					let matchingSeriesJSon = this.GetFrameset(seriesJSon, framesetID);

					this.UpdateFramesetJSon(matchingSeriesJSon, instance);

					const newInstance = instance;
					let instanceArray = matchingSeriesJSon[this.INSTANCE];

					if (!instanceArray) {
						instanceArray = [];
						matchingSeriesJSon[this.INSTANCE] = instanceArray;
					}
					instanceArray.push(newInstance);
					matchingSeriesJSon[this.NUMBER_OF_INSTANCES]['Value'] = [instanceArray.length.toString()];
				}
			});
		}

		return this.splitFramesetList;
	}

	GetFrameset(seriesJSon, framesetID) {
		const matchingSeriesJSon = this.splitFramesetList.find(x => x['frameSetID'] == framesetID);

		if (!matchingSeriesJSon) {
			const resultSeriesJSon = Object.assign({}, seriesJSon);
			if (resultSeriesJSon[this.NUMBER_OF_INSTANCES]) {
				resultSeriesJSon[this.NUMBER_OF_INSTANCES] = JSON.parse(
					JSON.stringify(resultSeriesJSon[this.NUMBER_OF_INSTANCES])
				);
			}

			resultSeriesJSon['frameSetID'] = framesetID;

			delete resultSeriesJSon.metadata;
			this.splitFramesetList.push(resultSeriesJSon);

			return resultSeriesJSon;
		} else {
			return matchingSeriesJSon;
		}
	}

	GetFramesetSubID(instance) {
		return '';
	}

	UpdateFramesetJSon(seriesJSon, instanceToken) {
		//this should be implemented in concrete class
	}

	StripUIDPrefix(UID) {
		return UID.replace(this.UIDPrefix, '');
	}

	getSeriesNumber(seriesJSon) {
		return seriesJSon?.['00200011']?.Value?.[0];
	}

	getModalityCode(seriesJSon) {
		return seriesJSon?.['00080060']?.Value?.[0];
	}

	getLaterality(seriesJSon) {
		return seriesJSon?.['00200060']?.Value?.[0];
	}

	getNumberOfInstance(seriesJSon) {
		return seriesJSon?.['00201209']?.Value?.[0];
	}

	SortByInstanceNumber(seriesList) {
		seriesList.forEach(series => {
			series.metadata = series.metadata.sort((a, b) => {
				const firstInstanceNumber = parseInt(a?.['00200013']?.Value?.[0]);
				const secondInstanceNumber = parseInt(b?.['00200013']?.Value?.[0]);

				return firstInstanceNumber - secondInstanceNumber;
			});
		});

		return seriesList;
	}
}

class MultiEchoSeriesSplitter extends SeriesSplitter {
	GetFramesetSubID = instance => {
		const ECHONUMBERS = '00180086';
		const token = instance?.[ECHONUMBERS]?.Value?.[0];
		let framesetSubID = '';

		if (token) {
			const echoNumber = parseInt(token);

			if (echoNumber > 0) {
				const framesetSubIDEntryValue = this.framesetSubIDMap.get(echoNumber.toString());
				if (!framesetSubIDEntryValue) {
					framesetSubID = this.framesetSubIDCounter.toString();
					this.framesetSubIDMap.set(echoNumber.toString(), framesetSubID);
					this.framesetSubIDCounter++;
				} else {
					framesetSubID = framesetSubIDEntryValue;
				}
			}
		}

		return framesetSubID;
	};
}

class DiffusionSeriesSplitter extends SeriesSplitter {
	SplitSeries(seriesJSon) {
		if (this.IsDiffusion(seriesJSon?.[this.INSTANCE]?.[0])) {
			// try split by 00180083 Number Of Averages first
			this.GetFramesetSubID = instance => {
				const NumberOfAveragesTag = '00180083';
				const token = instance?.[NumberOfAveragesTag]?.Value?.[0];
				let framesetSubID = '';

				if (token) {
					const NumberOfAverages = parseInt(token);

					if (NumberOfAverages > 0) {
						const framesetSubIDEntryValue = this.framesetSubIDMap.get(NumberOfAverages.toString());
						if (!framesetSubIDEntryValue) {
							framesetSubID = this.framesetSubIDCounter.toString();
							this.framesetSubIDMap.set(NumberOfAverages.toString(), framesetSubID);
							this.framesetSubIDCounter++;
						} else {
							framesetSubID = framesetSubIDEntryValue;
						}
					}
				}

				return framesetSubID;
			};

			super.SplitSeries(seriesJSon);

			// length is 1 meaning 00180083 Number Of Averages is the same for all images
			if (this.splitFramesetList?.length === 1) {
				// try split by 00189087 Diffusion b-value
				this.GetFramesetSubID = instance => {
					const DiffusionBValueTag = '00189087';
					// Give a default value to thoes instance don't have a DiffusionBValueTag
					const token = instance?.[DiffusionBValueTag]?.Value?.[0] ?? Number.MIN_SAFE_INTEGER;
					let framesetSubID = '';

					if (token) {
						const DiffusionBValue = parseFloat(token);

						if (DiffusionBValue) {
							const framesetSubIDEntryValue = this.framesetSubIDMap.get(DiffusionBValue.toString());
							if (!framesetSubIDEntryValue) {
								framesetSubID = this.framesetSubIDCounter.toString();
								this.framesetSubIDMap.set(DiffusionBValue.toString(), framesetSubID);
								this.framesetSubIDCounter++;
							} else {
								framesetSubID = framesetSubIDEntryValue;
							}
						}
					}

					return framesetSubID;
				};

				super.SplitSeries(seriesJSon);
			}

			// if IsDiffusion series cannot be split, sort by instance
			if (this.splitFramesetList?.length < 2) {
				this.SortByInstanceNumber(this.splitFramesetList);
			}

			return this.splitFramesetList;
		} else {
			return [];
		}
	}

	IsDiffusion = instance => {
		const sequenceName = instance?.['00180024']?.Value?.[0];
		const imageTypeArray = instance?.['00080008']?.Value;
		const diffusionBValue = instance?.['00189087']?.Value?.[0];
		const diffusionGradientDirection = instance?.['00189089']?.Value?.[0];
		const seriesDescription = instance?.['0008103e']?.Value?.[0];
		const protocolName = instance?.['00181030']?.Value?.[0];

		const diffusionRegex = /dwi|diffusion/i;
		const diffusionImageType = imageTypeArray?.find(x => diffusionRegex.test(x));

		if (
			diffusionImageType ||
			diffusionRegex.test(sequenceName) ||
			parseInt(diffusionBValue) > 0 ||
			(diffusionGradientDirection && !diffusionGradientDirection.includes('0')) ||
			diffusionRegex.test(seriesDescription) ||
			diffusionRegex.test(protocolName)
		) {
			return true;
		}
		return false;
	};
}

class AnatomicRegionSeriesSplitter extends SeriesSplitter {
	GetFramesetSubID = instance => {
		const token = this.getAnatomicRegionCode(instance);

		let framesetSubID = '',
			framesetSubName = '';

		if (token) {
			const anatiomicRegion = token.toString();

			if (anatiomicRegion.length > 0) {
				const anatomicRegionModifierCode = this.getAnatomicRegionModifierCode(instance);

				if (!anatomicRegionModifierCode) {
					framesetSubName = anatiomicRegion;
				} else {
					framesetSubName = anatiomicRegion + anatomicRegionModifierCode;
				}

				let framesetSubIDEntryValue = this.framesetSubIDMap.get(framesetSubName);

				if (!framesetSubIDEntryValue) {
					framesetSubID = this.framesetSubIDCounter.toString();
					this.framesetSubIDMap.set(framesetSubName, framesetSubID);
					this.framesetSubIDCounter++;
				} else {
					framesetSubID = framesetSubIDEntryValue;
				}
			}
		}

		return framesetSubID;
	};

	getAnatomicRegionCode(instanceJSon) {
		return instanceJSon?.['00080063']?.Value?.find(x => x[this.CODE_VALUE])?.[this.CODE_VALUE]?.Value?.[0];
	}

	getAnatomicRegionModifierCode(instanceJSon) {
		return instanceJSon?.['00082220']?.Value?.find(x => x[this.CODE_VALUE])?.[this.CODE_VALUE]?.Value?.[0];
	}
}

class PrimaryAnatomicStructureSeriesSplitter extends SeriesSplitter {
	GetFramesetSubID = instance => {
		const token = this.getPrimaryAnatomicStructureCode(instance);
		let framesetSubID = '',
			framesetSubName = '';

		if (token) {
			const primaryAnatomicStructure = token.toString();

			if (primaryAnatomicStructure.length > 0) {
				const primaryAnatomicStructureModifierCode = this.getPrimaryAnatomicStructureModifierCode(instance);

				if (!primaryAnatomicStructureModifierCode) {
					framesetSubName = primaryAnatomicStructure;
				} else {
					framesetSubName =
						primaryAnatomicStructure + instance['primaryAnatomicStructureModifierCode'].toString();
				}

				let framesetSubIDEntryValue = this.framesetSubIDMap.get(framesetSubName);

				if (!framesetSubIDEntryValue) {
					framesetSubID = this.framesetSubIDCounter.toString();
					this.framesetSubIDMap.set(framesetSubName, framesetSubID);
					this.framesetSubIDCounter++;
				} else {
					framesetSubID = framesetSubIDEntryValue;
				}
			}
		}

		return framesetSubID;
	};

	getPrimaryAnatomicStructureCode(instanceJson) {
		return instanceJson?.['00082228']?.Value?.find(x => x[this.CODE_VALUE])?.[this.CODE_VALUE]?.Value?.[0];
	}

	getPrimaryAnatomicStructureModifierCode(instanceJson) {
		return instanceJson?.['00082230']?.Value?.find(x => x[this.CODE_VALUE])?.[this.CODE_VALUE]?.Value?.[0];
	}
}

class SingleImageSeriesSplitter extends SeriesSplitter {
	IsSingleImageSplitterImage = (SOPClassUID, modality, imageTypeArray) => {
		const MODALITY_RG = 'RG';
		const MODALITY_DX = 'DX';
		const MODALITY_MG = 'MG';

		const sopClass = SOPClass.find(x => x.code == SOPClassUID);
		const isSingleFrameImageType = !imageTypeArray?.some(
			element =>
				element.includes('TOMO') ||
				element.includes('SYN') ||
				element.includes('RECON') ||
				element.includes('VOLUME')
		);

		if (sopClass) {
			return (
				IsXRay(sopClass) ||
				modality == MODALITY_RG ||
				modality == MODALITY_DX ||
				(modality == MODALITY_MG && isSingleFrameImageType)
			);
		} else {
			return (
				modality == MODALITY_RG ||
				modality == MODALITY_DX ||
				(modality == MODALITY_MG && isSingleFrameImageType)
			);
		}
	};

	GetFramesetSubID = instance => {
		const framesetSubID = this.framesetSubIDCounter.toString();
		this.framesetSubIDCounter++;

		return framesetSubID;
	};

	SplitSeries = seriesJSon => {
		this.splitFramesetList = [];
		let shouldSplit = false;
		const instanceToken = seriesJSon[this.INSTANCE];
		const SOP_CLASS_UID = '00080016';

		if (instanceToken) {
			for (const instance of instanceToken) {
				const modalityCode = this.getModalityCode(seriesJSon);
				const imageTypeArray = instance?.['00080008']?.Value;

				if (
					modalityCode &&
					this.IsSingleImageSplitterImage(
						this.StripUIDPrefix(instance[SOP_CLASS_UID]?.Value?.[0]),
						modalityCode,
						imageTypeArray
					)
				) {
					shouldSplit = true;
					break;
				}
			}
		}

		if (shouldSplit) {
			return super.SplitSeries(seriesJSon);
		} else {
			return seriesJSon;
		}
	};
}

class FrameOfReferenceUIDSeriesSplitter extends SeriesSplitter {
	GetFramesetSubID = instance => {
		const FRAME_OF_REFERENCE_UID = '00200052';
		const token = instance[FRAME_OF_REFERENCE_UID]?.Value?.[0];
		let framesetSubID = '';

		if (token) {
			const frameOfReferenceUID = token.toString();

			if (frameOfReferenceUID.length > 0) {
				const framesetSubIDEntryValue = this.framesetSubIDMap.get(frameOfReferenceUID);
				if (!framesetSubIDEntryValue) {
					framesetSubID = this.framesetSubIDCounter.toString();
					this.framesetSubIDMap.set(frameOfReferenceUID, framesetSubID);
					this.framesetSubIDCounter++;
				} else {
					framesetSubID = framesetSubIDEntryValue;
				}
			}
		}

		return framesetSubID;
	};
}

class PlaneSplitter extends SeriesSplitter {
	GetFramesetSubID = instance => {
		const PLANE = '00701305';
		let framesetSubID = '',
			instancePlane = '',
			planeToken = instance[PLANE]?.Value?.[0];

		if (!planeToken) {
			planeToken = getSeriesOrientationPlane(instance);
		}

		if (planeToken) {
			instancePlane = planeToken.toString();
		}

		const framesetSubIDEntryValue = this.framesetSubIDMap.get(instancePlane);
		if (!framesetSubIDEntryValue) {
			framesetSubID = this.framesetSubIDCounter.toString();
			this.framesetSubIDMap.set(instancePlane, framesetSubID);
			this.framesetSubIDCounter++;
		} else {
			framesetSubID = framesetSubIDEntryValue;
		}

		return framesetSubID;
	};
}

class EnhancedMultiFrameSplitter extends SeriesSplitter {
	SplitSeries = seriesJSon => {
		this.splitFramesetList = [];
		this.framesetSubIDCounter = 1;

		let objectNumber,
			seriesNumber = -1,
			token = this.getSeriesNumber(seriesJSon),
			instanceToken = seriesJSon[this.INSTANCE];

		if (token) {
			seriesNumber = parseInt(token);
		}

		if (seriesNumber > 0 && instanceToken) {
			instanceToken.forEach(instance => {
				const INSTANCE_NUMBER = '00200013';
				const NUMBER_OF_FRAMES = '00280008';

				objectNumber = instance[INSTANCE_NUMBER]?.Value?.[0] ? instance[INSTANCE_NUMBER]?.Value?.[0] : '0';
				let numOfFrame = instance[NUMBER_OF_FRAMES]?.Value?.[0] ? instance[NUMBER_OF_FRAMES]?.Value?.[0] : '0',
					frameToken = instance['frames'],
					parseResult = parseInt(numOfFrame);

				if (frameToken && parseResult && parseResult > 1) {
					frameToken.forEach(frame => {
						let framesetSubID = this.GetFramesetSubID(frame, objectNumber);

						if (framesetSubID) {
							let framesetID = seriesNumber.toString() + '.' + framesetSubID,
								matchingSeriesJSon = this.GetFrameset(seriesJSon, framesetID);

							this.UpdateFramesetJSon(matchingSeriesJSon, frame);

							let instanceArray = matchingSeriesJSon['instance'],
								newInstance;

							if (!instanceArray) {
								instanceArray = [];
								matchingSeriesJSon['instance'] = instanceArray;
							}

							let exists = false;
							if (instanceArray.length > 0) {
								newInstance = instanceArray.at(0);
								exists = true;
							} else {
								newInstance = instance;
								newInstance['frames'] = [];
								matchingSeriesJSon['plane'] = frame['plane'];
								newInstance['plane'] = frame['plane'];
							}
							newInstance['frames'] = [frame];

							newInstance['numberOfFrames'] = newInstance['frames'].length;

							if (!exists) {
								instanceArray.push(newInstance);
							}
						}
					});
				}
			});
		}

		return this.splitFramesetList;
	};

	UpdateFramesetJSon = (seriesJSon, instanceToken) => {
		seriesJSon['plane'] = instanceToken['plane'];
	};

	GetFramesetSubID = (frame, ObjectNumber) => {
		let frameSetSubID, frameSetKey;

		if (!ObjectNumber) {
			frameSetSubID = '';
		} else {
			frameSetKey = ObjectNumber + '.' + frame['stackID'] + '.' + frame['effectiveEchoTime'];

			const framesetSubIDEntryValue = this.framesetSubIDMap.get(frameSetKey);
			if (!framesetSubIDEntryValue) {
				frameSetSubID = this.framesetSubIDCounter.toString();
				this.framesetSubIDMap.set(frameSetKey, frameSetSubID);
				this.framesetSubIDCounter++;
			} else {
				frameSetSubID = framesetSubIDEntryValue;
			}
		}

		return frameSetSubID;
	};
}

class CineSeriesDescriptorSplitter extends SeriesSplitter {
	SplitSeries = seriesJSon => {
		this.splitFramesetList = [];
		this.framesetSubIDCounter = 1;

		let lastImageWasStill = false,
			seriesNumber = -1,
			token = this.getSeriesNumber(seriesJSon),
			instanceToken = seriesJSon[this.INSTANCE];

		if (token) {
			seriesNumber = parseInt(token);
		}

		if (seriesNumber > 0 && instanceToken) {
			instanceToken.forEach(instance => {
				let numOfFrames = 1;
				const NUMBER_OF_FRAMES = '00280008',
					TRANSFER_SYNTAX_UID = '00020010';

				if (instance[NUMBER_OF_FRAMES]?.Value?.[0]) {
					numOfFrames = parseInt(instance[NUMBER_OF_FRAMES]?.Value?.[0]);
				}

				if (
					numOfFrames > 1 ||
					(instance[TRANSFER_SYNTAX_UID]?.Value?.[0] &&
						this.IsMediaImage(instance[TRANSFER_SYNTAX_UID]?.Value?.[0]) &&
						lastImageWasStill)
				) {
					this.framesetSubIDCounter++;
				}

				let frameSetSubID = this.GetFramesetSubID(instance),
					matchingSeriesJSon = this.GetFrameset(seriesJSon, seriesNumber.toString() + '.' + frameSetSubID),
					instanceArray = matchingSeriesJSon[this.INSTANCE];
				const newInstance = instance;

				if (!instanceArray) {
					instanceArray = [];
					matchingSeriesJSon[this.INSTANCE] = instanceArray;
				}
				instanceArray.push(newInstance);
				matchingSeriesJSon[this.NUMBER_OF_INSTANCES]['Value'] = [instanceArray.length.toString()];

				if (
					numOfFrames > 1 ||
					(instance[TRANSFER_SYNTAX_UID]?.Value?.[0] &&
						this.IsMediaImage(instance[TRANSFER_SYNTAX_UID]?.Value?.[0]))
				) {
					this.framesetSubIDCounter++;
					lastImageWasStill = false;
				} else {
					lastImageWasStill = true;
				}
			});
		}
		return this.splitFramesetList;
	};

	IsMediaImage = transferSyntaxUID => {
		const DicomTransferSyntax_MediaUID = [
			'1.2.840.10008.1.2.4.100',
			'1.2.840.10008.1.2.4.101',
			'1.2.840.10008.1.2.4.102',
			'1.2.840.10008.1.2.4.103',
			'1.2.840.10008.1.2.4.104',
			'1.2.840.10008.1.2.4.105',
			'1.2.840.10008.1.2.4.106',
		];

		return DicomTransferSyntax_MediaUID.find(x => x == transferSyntaxUID) ? true : false;
	};

	GetFramesetSubID = instance => {
		return this.framesetSubIDCounter.toString();
	};
}

class MultiPhaseAxialCTSplitter extends SeriesSplitter {
	MinNumberOfSlicesJumpToSplit;
	ZAxisIndex = 2;
	curPositionDiff = 0;
	curPositionDiffNeedToInitialized = true;

	constructor(minNumberOfSlicesJumpToSplit = 5) {
		super();
		this.MinNumberOfSlicesJumpToSplit = minNumberOfSlicesJumpToSplit;
	}

	ImagePlaneType = {
		ptUnknown: 0,
		ptOblieue: 1,
		ptSagittal: 2,
		ptCoronal: 3,
		ptAxial: 4,
		ptMixed: 5,
	};

	SplitSeries = seriesJSon => {
		this.splitFramesetList = [];
		let seriesNumber = -1,
			token = this.getSeriesNumber(seriesJSon),
			instanceToken = seriesJSon[this.INSTANCE];

		if (token) {
			seriesNumber = parseInt(token);
		}
		this.curPositionDiff = 0;

		const PLANE = '00701305';
		const seriesPlaneType = seriesJSon[PLANE]?.Value?.[0]
			? parseInt(seriesJSon[PLANE]?.Value?.[0])
			: this.ImagePlaneType.ptUnknown;

		if (seriesNumber > 0 && instanceToken && seriesPlaneType == this.ImagePlaneType.ptAxial) {
			const instances = instanceToken;
			let matchingSeriesJSon;

			for (let i = 0; i < instances.length - 2 - (this.MinNumberOfSlicesJumpToSplit - 1); i++) {
				let curInstance = instances[i],
					nextInstance = instances[i + 1];

				if (this.CanSplitMultiPhaseAxial(curInstance) && this.CanSplitMultiPhaseAxial(nextInstance)) {
					const IMAGE_POSITION_PATIENT = '00200032';
					let curImagePositionPatient = curInstance[IMAGE_POSITION_PATIENT]?.Value?.[0],
						nextImagePositionPatient = nextInstance[IMAGE_POSITION_PATIENT]?.Value?.[0],
						positionDiff =
							nextImagePositionPatient.at(this.ZAxisIndex) - curImagePositionPatient.at(this.ZAxisIndex),
						frameSetSubID = this.GenerateFrameSetSubID(positionDiff),
						needToSplit = false;

					if (positionDiff * this.curPositionDiff < 0) {
						//If direction changed
						for (let j = i + 2; j <= i + this.MinNumberOfSlicesJumpToSplit; j++) {
							let splitCandidateImagePositionPatient = parseFloat(
								instances[j][IMAGE_POSITION_PATIENT]?.Value?.[0]
							);
							positionDiff =
								splitCandidateImagePositionPatient.at(this.ZAxisIndex) -
								curImagePositionPatient.at(this.ZAxisIndex);
							needToSplit = positionDiff * this.curPositionDiff < 0;
							if (!needToSplit) {
								break;
							}
						}

						if (needToSplit) {
							this.framesetSubIDCounter++;
							this.curPositionDiffNeedToInitialized = true;
						}

						if (frameSetSubID.length > 0) {
							matchingSeriesJSon = this.GetFrameset(
								seriesJSon,
								seriesNumber.toString() + '.' + frameSetSubID
							);
							let instanceArray = matchingSeriesJSon[this.INSTANCE];
							const newInstance = curInstance;

							if (!instanceArray) {
								instanceArray = [];
								matchingSeriesJSon[this.INSTANCE] = instanceArray;
							}
							instanceArray.push(newInstance);
							matchingSeriesJSon[this.NUMBER_OF_INSTANCES][Value] = [instanceArray.length.toString()];
						}
					}
				}
			}

			let lastInstance = instances.at(-1);
			if (this.CanSplitMultiPhaseAxial(lastInstance)) {
				if (this.curPositionDiffNeedToInitialized && instances.length > 1) {
					matchingSeriesJSon = this.GetFrameset(
						seriesJSon,
						seriesNumber.toString() + '.' + (this.framesetSubIDCounter + 1).toString()
					);
				} else {
					matchingSeriesJSon = this.GetFrameset(
						seriesJSon,
						seriesNumber.toString() + '.' + this.framesetSubIDCounter.toString()
					);
				}
				let instanceArray = matchingSeriesJSon[this.INSTANCE];
				const newInstance = lastInstance;

				if (!instanceArray) {
					instanceArray = [];
					matchingSeriesJSon[this.INSTANCE] = instanceArray;
				}
				instanceArray.push(newInstance);
				matchingSeriesJSon[this.NUMBER_OF_INSTANCES]['Value'] = [instanceArray.length.toString()];
			}
		}
		return this.splitFramesetList;
	};

	GenerateFrameSetSubID = positionDiff => {
		let frameSetSubID = this.framesetSubIDCounter.toString();
		if (this.curPositionDiffNeedToInitialized) {
			this.curPositionDiff = positionDiff;
			this.curPositionDiffNeedToInitialized = false;
		}

		return frameSetSubID;
	};

	CanSplitMultiPhaseAxial = instance => {
		const SOP_CLASS_UID = '00080016',
			FRAME_OF_REFERENCEUID = '00200052',
			sopClassValue = instance[SOP_CLASS_UID]?.Value?.[0],
			frameOfReferenceUID = instance[FRAME_OF_REFERENCEUID]?.Value?.[0];

		if (!sopClassValue || !frameOfReferenceUID) {
			return false;
		} else {
			let SOPClassUID = this.StripUIDPrefix(sopClassValue),
				sopClass = SOPClass.find(x => x.code == SOPClassUID),
				canSplit =
					sopClass &&
					(sopClass.name == 'SC_CT' || sopClass.name == 'SC_MR' || sopClass.name == 'SC_PET') &&
					frameOfReferenceUID.length > 0;

			return canSplit;
		}
	};
}

class DifferentDimensionsSplitter extends SeriesSplitter {
	SplitSeries = seriesJSon => {
		this.splitFramesetList = [];
		this.framesetSubIDCounter = 1;
		let rows,
			cols,
			lastDimensions = { x: '-1', y: '-1' },
			currDimensions = { x: '-1', y: '-1' },
			seriesNumber = -1,
			token = this.getSeriesNumber(seriesJSon),
			instanceToken = seriesJSon[this.INSTANCE];

		if (token) {
			seriesNumber = parseInt(token);
		}

		if (seriesNumber > -1 && instanceToken) {
			instanceToken.forEach(instance => {
				const ROWS = '00280010',
					COLUMNS = '00280011';

				rows = instance[ROWS]?.Value?.[0] ?? '';
				cols = instance[COLUMNS]?.Value?.[0] ?? '';
				if (rows && cols) {
					currDimensions = { x: rows, y: cols };
				}

				if (lastDimensions.x == '-1' && lastDimensions.y == '-1') {
					lastDimensions = currDimensions;
				}

				if (lastDimensions.x != currDimensions.x && lastDimensions.y != currDimensions.y) {
					this.framesetSubIDCounter++;
					lastDimensions = currDimensions;
				}
				let frameSetSubID = this.GetFramesetSubID(instance),
					matchingSeriesJSon = this.GetFrameset(seriesJSon, seriesNumber.toString() + '.' + frameSetSubID),
					instanceArray = matchingSeriesJSon[this.INSTANCE];
				const newInstance = instance;

				if (!instanceArray) {
					instanceArray = [];
					matchingSeriesJSon[this.INSTANCE] = instanceArray;
				}
				instanceArray.push(newInstance);
				matchingSeriesJSon[this.NUMBER_OF_INSTANCES]['Value'] = [instanceArray.length.toString()];
			});
		}

		return this.splitFramesetList;
	};

	GetFramesetSubID = instance => {
		return this.framesetSubIDCounter.toString();
	};
}

export {
	MultiEchoSeriesSplitter,
	AnatomicRegionSeriesSplitter,
	PrimaryAnatomicStructureSeriesSplitter,
	SingleImageSeriesSplitter,
	FrameOfReferenceUIDSeriesSplitter,
	PlaneSplitter,
	EnhancedMultiFrameSplitter,
	CineSeriesDescriptorSplitter,
	MultiPhaseAxialCTSplitter,
	DifferentDimensionsSplitter,
	DiffusionSeriesSplitter,
};
