/**
 * Adds a cache buster parameter to the given URL.
 * If the URL already contains a cache buster parameter (_dc), it will be replaced with the current timestamp.
 * If the URL does not contain a cache buster parameter, it will be appended to the URL.
 * @param {string} url - The URL to add the cache buster parameter to.
 * @returns {string} The modified URL with the cache buster parameter.
 */
export const addCacheBuster = url => {
	if (url.includes('_dc')) {
		return url.replace(/_dc=\d+/, `_dc=${Date.now()}`);
	}
	return `${url}${url.includes('?') ? '&' : '?'}_dc=${Date.now()}`;
};

/**
 * Adds a parameter to the given URL.
 *
 * @param {string} url - The URL to add the parameter to.
 * @param {string} key - The key of the parameter.
 * @param {string} value - The value of the parameter.
 * @returns {string} The modified URL with the added parameter.
 */
export const addParameter = (url, key, value) => {
	if (url.includes(key)) {
		return url.replace(new RegExp(`${key}=[^&]+`), `${key}=${value}`);
	}
	return `${url}${url.includes('?') ? '&' : '?'}${key}=${value}`;
};
