import axios from 'axios';
import moment from 'moment';
import fhirExtensionUrls from '../fhir/extension/fhirExtensionUrls';

const P_VERIFY_RESPONSE = {
	SUCCESS: 0,
	ERROR: 1,
};

const sampleEligibilityPayload = {
	payerCode: '00001',
	provider: { firstName: '', middleName: '', lastName: 'SAM MD', npi: '1234567890' },
	subscriber: { firstName: 'John', middleName: '', lastName: 'Deo', dob: '10/02/1985', memberID: '123456789A' },
	isSubscriberPatient: 'True',
	doS_StartDate: '03/15/2023',
	doS_EndDate: '03/15/2023',
	requestSource: 'RestAPI',
	PracticeTypeCode: '27',
	referenceId: '',
	Location: '',
	IncludeTextResponse: false,
};

const sampleEligibilityInquiryPayload = {
	payerCode: '00001',
	provider: {
		firstName: '',
		middleName: '',
		lastName: 'SAM MD',
		npi: '1234567890',
	},
	subscriber: {
		firstName: 'John',
		middleName: '',
		lastName: 'Deo',
		memberID: '123456789A',
		dob: '10/02/1985',
	},
	isSubscriberPatient: 'True',
	doS_StartDate: '03/17/2023',
	doS_EndDate: '03/17/2023',
	serviceCodes: ['30'],
	requestSource: 'RestAPI',
};

const pVerifyAxios = axios.create?.();

const composeBearerTokenHeader = (clientId, token) => {
	return {
		headers: {
			authorization: 'Bearer ' + token,
			'Client-API-Id': clientId,
			'Content-Type': 'application/json',
		},
	};
};

const getPayerCode = code => {
	let str = code?.toString() || '';

	while (str.length < 5) {
		str = '0' + str;
	}
	return str;
};

const getRemainingDeductible = data => {
	if (data && data.networkSections) {
		const deductible = data.networkSections.find(ns => ns.identifier == 'Deductible');
		if (deductible && deductible.inNetworkParameters) {
			const remaining = deductible.inNetworkParameters.find(inp => inp.key == 'Remaining');

			if (remaining?.value) {
				return remaining.value.replace('$', '');
			}
		}
	}

	return 0;
};

const getCopay = data => {
	if (data && data.networkSections) {
		const primaryCare = data.networkSections.find(ns => ns.identifier == 'primary care');
		if (primaryCare && primaryCare.inNetworkParameters) {
			const coPay = primaryCare.inNetworkParameters.find(inp => inp.key == 'Co-Pay');

			if (coPay?.value) {
				return coPay.value.replace('$', '');
			}
		}
	}

	return 0;
};

const getCoInsurance = data => {
	if (data && data.networkSections) {
		const primaryCare = data.networkSections.find(ns => ns.identifier == 'primary care');
		if (primaryCare && primaryCare.inNetworkParameters) {
			const coIns = primaryCare.inNetworkParameters.find(inp => inp.key == 'Co-Ins');

			if (coIns?.value) {
				return coIns.value;
			}
		}
	}

	return 0;
};

const getEligibilitySummary = async (url, clientId, key, payload) => {
	console.log('check eligibility');
	return pVerifyAxios.post(
		url ? url + 'API/EligibilitySummary' : 'https://api.pverify.com/Test/API/EligibilitySummary',
		payload || sampleEligibilityPayload,
		composeBearerTokenHeader(clientId, key)
	);
};

const getEligibilityInquiry = async (url, clientId, key, payload) => {
	return pVerifyAxios.post(
		url ? url + 'API/EligibilityInquiry' : 'https://api.pverify.com/Test/API/EligibilityInquiry',
		payload || sampleEligibilityInquiryPayload,
		composeBearerTokenHeader(clientId, key)
	);
};

const getAllPayerUser = async (url, clientId, key) => {
	return pVerifyAxios.get(
		url ? url + 'API/GetAllPayers' : 'https://api.pverify.com/Test/API/GetAllPayers',
		composeBearerTokenHeader(clientId, key)
	);
};

const getPVerifyToken = async baseURL => {
	let clientId = '';
	let token = '';
	const tokenResp = await axios.get(`${baseURL}/pVerify/token`);

	if (tokenResp && tokenResp.data) {
		clientId = tokenResp.data.key;
		token = tokenResp.data.token;
	}
	return { clientId, token };
};

const estimateCalculation = async (url, clientId, key, requestData) => {
	// Define the API endpoint URL
	const apiUrl = url ? url + 'api/v2/Estimate' : 'https://api.pverify.com/api/v2/Estimate';

	const { eligibilityRequestId, cptDetails, isOutNetwork, location } = requestData;
	// Define the request body
	const requestBody = {
		eligibilityRequestID: eligibilityRequestId,
		location: location,
		isOutNetwork: isOutNetwork,
		isSecondaryActive: false,
		selfPay: false,
		estimationRequestDetails: [
			{
				practiceTypeKey: 'MRI_CT_ScanSummary',
				cptDetails: cptDetails,
			},
		],
	};
	// Define the request headers
	const headers = composeBearerTokenHeader(clientId, key);

	try {
		const res = await pVerifyAxios.post(apiUrl, requestBody, headers);
		const response = res?.data;
		if (!response) {
			throw new Error('Invalid API response');
		} else if (response?.apiResponseCode === 1) {
			throw new Error(response.errorDescription);
		} else if (response?.apiResponseCode === 0 && !response?.estimations?.[0]?.isEstimationDone) {
			throw new Error(response.message);
		}
		return response;
	} catch (error) {
		console.error('Estimation request failed! Error is: ', error.message);
		throw error;
	}
};

/**
 * Given a patient, compose an egligibility check payload, which can be sent to the pVerify
 * getEligibilitySummary API
 */
const composePVerifyPayload = (coverage, payerId, patientData, providerNPI, providerLastName) => {
	return {
		payerCode: payerId,
		provider: {
			npi: providerNPI,
			lastName: providerLastName,
		},
		subscriber: {
			firstName: patientData?.name?.[0].given[0],
			middleName: '',
			lastName: patientData?.name?.[0].family,
			memberId: coverage?.subscriberId,
			dob: moment(patientData.birthDate).format('MM/DD/YYYY'),
		},
		isSubscriberPatient: 'True',
		doS_StartDate: moment(new Date()).format('MM/DD/YYYY'),
		doS_EndDate: moment(new Date()).format('MM/DD/YYYY'),
		practiceTypeCode: 46,
	};
};

/**
 * Used internally, gets the extension of the url if it exists, otherwise add one to the list
 */
const getExtension = (model, url) => {
	let extension = model?.extension?.find(e => e.url == url);
	if (!extension) {
		extension = { url: url };
		model.extension.push(extension);
	}
	return extension;
};

const deleteExtension = (model, url) => {
	model.extension = model.extension?.filter(e => e.url != url);
};

const isAutoCorrectedSubscriberID = response => response.ExceptionNotes?.includes('Request(Prior) Member ID');

const clearRejectionReason = model => {
	deleteExtension(model, fhirExtensionUrls.coverage.rejectionReason);
};

/**
 * Once we get the reuslts back from pVerify, we need to update the coverage information, including:
 * status, co-insurance, co-pay amount, remaining deductible amount?
 */
const updateCoverageModel = (coverage, response) => {
	const insuranceStatusExt = getExtension(coverage, fhirExtensionUrls.coverage.insuranceStatus);

	// This error scenario typically occurs in sitatuions where information is missing, or in the incorrect format. In this case
	// pVerify isn't able to process any of the information, so nothing is returned other than an error message.
	if (response.APIResponseCode == P_VERIFY_RESPONSE.ERROR) {
		insuranceStatusExt.valueCoding = {
			code: 'UNK',
			display: 'Unknown',
		};

		const rejectionReasonExt = getExtension(coverage, fhirExtensionUrls.coverage.rejectionReason);
		rejectionReasonExt.valueString = response.APIResponseMessage;

		return;
	}

	// In certain cases, pVerify will process the results, but actually return an error if information doesn't match.
	if (response.ProcessedWithError) {
		insuranceStatusExt.valueCoding = {
			code: 'IAC',
			display: 'Inactive',
		};

		const rejectionReasonExt = getExtension(coverage, fhirExtensionUrls.coverage.rejectionReason);
		rejectionReasonExt.valueString = response.APIResponseMessage;

		return;
	}

	// update coverage status
	if (response.PlanCoverageSummary?.Status == 'Active') {
		insuranceStatusExt.valueCoding = {
			code: 'ACT',
			display: 'Active',
		};

		deleteExtension(coverage, fhirExtensionUrls.coverage.rejectionReason);
	} else {
		insuranceStatusExt.valueCoding = {
			code: 'IAC',
			display: 'Inactive',
		};
	}

	// if response.ExceptionMessage contains text like this: Request(Prior) Member ID : 00314666 - Actual Member ID : 0031466686,
	// then we know that the member id is incorrect, and we should update it
	if (isAutoCorrectedSubscriberID(response)) {
		const actualMemberIdMatch = response.ExceptionNotes.match(/Actual Member ID : (\d+)/);
		if (actualMemberIdMatch) {
			coverage.subscriberId = actualMemberIdMatch[1];
		}
	}

	// update requestId
	if (response.RequestID) {
		const requestExt = getExtension(coverage, fhirExtensionUrls.coverage.requestId);
		requestExt.valueString = response.RequestID;
	}
};

// Error messages
// Error Reason : Patient DOB Does Not Match That for the Patient on the Database \r\nFollow-up Action : Please Correct and Resubmit
// 'Insufficient information to generate patient search.\r\nAllowed Search Criteria(s) for the Payer are :\r\n1.Member ID,Sub Last Name,Sub First Name,Sub DOB.\r\n2.Subscriber SSN,Sub First Name,Sub Last Name,Sub DOB'
// "Request(Prior) Member ID : 00313 - Actual Member ID : 0031366686,\r\n",

export {
	P_VERIFY_RESPONSE,
	getEligibilitySummary,
	getEligibilityInquiry,
	getRemainingDeductible,
	getCopay,
	getCoInsurance,
	getPayerCode,
	getAllPayerUser,
	clearRejectionReason,
	isAutoCorrectedSubscriberID,
	composePVerifyPayload,
	updateCoverageModel,
	estimateCalculation,
	getPVerifyToken,
};
