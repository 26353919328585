import React from 'react';
import PropTypes from 'prop-types';
import GenericDrawer from '../GenericDrawer';
import axios from 'axios';

import { MergeDrawerCardsWrap, MergeDrawerContentSection } from './styles';
import { MergeOrderDrawerCard, MergeDrawerButtons, MergeOrderDrawerTooltip, MergeOrderDrawerHeader } from './partials';

import { useConfig, useSelection } from '@worklist-2/core/src';

const drawerWidth = 487;

export const MergeStudyDrawer = ({ drawerOpen, setDrawerOpen, t, toastUtility, setStudiesToRemove, ...otherProps }) => {
	const __config = useConfig();

	const drawerTitle = t('workList:selectedResourcesTooltip.mergeStudy');
	const { selectedDuplicates, selectedMergeStudy, handleResourceReset, resetStudiesSelection } = useSelection();

	const patientName = selectedDuplicates[0]?.subject?.display;
	const patientID = selectedDuplicates[0]?.subject?.id;
	const isCardListVisible = selectedDuplicates.length > 0;

	const mergeStudiesFn = async (targetStudyId, studyId, internalOrgId) => {
		if (!!targetStudyId && !!studyId && !!internalOrgId) {
			const URL = `${__config.data_sources.dicom_web}/mergeById/${studyId
				.map(study => study.id)
				.toString()}?destStudy=${targetStudyId}&internalManagingOrganizationId=${internalOrgId}`;
			try {
				const postResult = await axios.post(URL);
				if (postResult.status !== 200) {
					return false;
				}
				setStudiesToRemove(studyId.map(study => ({ id: study.id })));
			} catch {
				return false;
			}
			return true;
		}
	};

	const mergeHandler = async () => {
		const mergeStudies = selectedDuplicates.filter(study => study.id !== selectedMergeStudy.id);

		const mergeResult = await mergeStudiesFn(
			selectedMergeStudy.id,
			mergeStudies,
			selectedMergeStudy.internalManagingOrganizationID
		);

		if (!mergeResult) {
			toastUtility(true, `Failed to merge studies.`);
			return;
		}

		resetStudiesSelection();

		toastUtility(true, `The selected studies have been merged.`);
		setDrawerOpen(false);
		handleResourceReset();
	};

	return (
		<>
			{isCardListVisible && (
				<GenericDrawer
					headerDividerFlag
					showBackBtn
					customHeaderTextProps={{
						variant: 'h6',
						sx: {
							fontSize: '20px',
							lineHeight: '24px',
							letterSpacing: '0.15px',
							mr: 'auto',
						},
					}}
					drawerOpen={drawerOpen}
					setDrawerOpen={setDrawerOpen}
					title={drawerTitle}
					width={drawerWidth}
					{...otherProps}
				>
					<MergeOrderDrawerHeader patientID={patientID} patientName={patientName} t={t} />

					<MergeDrawerContentSection>
						<MergeDrawerCardsWrap>
							{isCardListVisible &&
								selectedDuplicates.map((i, idx) => (
									<MergeOrderDrawerCard
										key={i.id + idx}
										cardStyles={{ width: '75%', mb: '17px' }}
										data={i}
										t={t}
									/>
								))}
						</MergeDrawerCardsWrap>
					</MergeDrawerContentSection>

					<MergeDrawerButtons
						isActive={selectedMergeStudy}
						t={t}
						tooltip={MergeOrderDrawerTooltip}
						onSubmit={mergeHandler}
					/>
				</GenericDrawer>
			)}
		</>
	);
};

MergeStudyDrawer.propTypes = {
	/**
	 * Lifted State - determines if the drawer is open or not
	 */
	drawerOpen: PropTypes.bool,
	/**
	 * Setter function for the drawerOpen state
	 */
	setDrawerOpen: PropTypes.func,
	/**
	 * Setter function to set the ID of a merged study that should be removed from the worklist grid.
	 */
	setStudyToRemove: PropTypes.func,
};
