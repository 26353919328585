import React, { useCallback, useState, useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

import { searchScopes, useFhirDataLoader } from '@worklist-2/core/src';
import { loadValueSet } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING = 8;
const MAX_MENU_ITEMS = 8;

const SingleSelect = ({
	hideIcon,
	className,
	fullWidth,
	readOnly,
	label,
	itemList,
	menuHeader,
	size,
	labelField,
	valueField,
	width,
	style,
	canDeselect,
	selectedOption,
	valueSetType,
	capitalizeOptions = false,
	displayValue = 'display',
	testId,
	onSelect,
	select,
}) => {
	const { t } = useTranslation('Search');
	const [autoWidth, setAutoWidth] = useState(0);
	const [adornmentWidth, setAdornmentWidth] = useState(0);
	const [selectedValue, setSelectedValue] = useState(
		selectedOption ? { id: selectedOption } : _.isEmpty(itemList) || canDeselect ? {} : itemList[0]
	);
	const [optionsList, setOptionsList] = useState(itemList.length > 0 ? itemList : []);
	const ref = useRef();
	const adornmentRef = useRef();
	const fhirDataLoader = useFhirDataLoader({
		scope: searchScopes.valueSet,
	});

	// For single select options, get valueSet from API when component loads
	useEffect(() => {
		const getValueSet = async () => {
			if (!valueSetType || (itemList && itemList.length > 0)) {
				return itemList;
			}
			return loadValueSet(fhirDataLoader, valueSetType, displayValue, capitalizeOptions);
		};
		getValueSet().then(output => {
			_.map(output, item => {
				if (item.display && item.display === selectedValue[valueField]) {
					setSelectedValue(item);
				}
			});
			setOptionsList(output || []);
		});
	}, [itemList, valueSetType]);

	useEffect(() => {
		const value = select;
		let name = '';
		let placeholder = '';
		if (value && value !== selectedValue[valueField]) {
			const selected = _.find(optionsList, elem => elem[valueField] && elem[valueField] === value);

			if (selected) {
				setSelectedValue(selected);
				name = placeholder = selected.placeholder;
				if (onSelect) {
					onSelect(value, name, placeholder);
				}
			}
		}
	}, [select, optionsList]);

	useLayoutEffect(() => {
		setAutoWidth(ref.current.offsetWidth);
	}, []);

	useLayoutEffect(() => {
		setAdornmentWidth(adornmentRef.current?.offsetWidth);
	}, [adornmentRef]);

	const handleChange = useCallback(
		(event, child) => {
			let { value } = event.target;
			let { name } = child.props;
			let placeholder = name;
			if (value !== selectedValue[valueField]) {
				const selected = _.find(optionsList, elem => elem[valueField] && elem[valueField] === value);

				if (selected) {
					setSelectedValue(selected);
					placeholder = selected.placeholder;
				}
			} else if (canDeselect) {
				value = '';
				setSelectedValue({});
				name = '';
				placeholder = '';
			}
			if (onSelect) {
				onSelect(value, name, placeholder);
			}
		},
		[onSelect, selectedValue, optionsList]
	);

	const menuProps = useMemo(
		() => ({
			// ml is calculated to account for the width of the adornment plus a padding
			// of 14px. Divide by 2 to account for the popover expanding from the center
			sx: { ml: hideIcon ? 0 : `${-(adornmentWidth + 14) / 2}px` },
			// sx: { ml: hideIcon ? 0 : '-19px' },
			PaperProps: {
				variant: 'outlined',
				elevation: 0,
				style: {
					maxHeight: ITEM_HEIGHT * MAX_MENU_ITEMS + ITEM_PADDING * 2,
					// -2 to account for border
					width: fullWidth ? autoWidth - 2 : width,
					...style,
				},
			},
		}),
		[width, autoWidth]
	);

	return (
		<FormControl className="select" fullWidth={fullWidth}>
			{label ? <InputLabel>{label}</InputLabel> : ''}
			<Select
				ref={ref}
				MenuProps={menuProps}
				className={classnames(className)}
				data-cy={testId}
				inputProps={{ readOnly }}
				label={label}
				notched={!!label}
				renderValue={selected =>
					_.isEmpty(selected) ? (
						<label style={{ opacity: 0.5 }}>Search</label>
					) : (
						t(
							(_.find(optionsList, element => element[valueField] === selected[valueField]) ||
								{ [labelField]: '' }[labelField])[labelField]
						)
					)
				}
				size={size || 'medium'}
				startAdornment={
					!hideIcon && (
						<InputAdornment ref={adornmentRef} position="start">
							<SearchIcon />
						</InputAdornment>
					)
				}
				sx={{ width: fullWidth ? null : width }}
				value={selectedValue}
				onChange={handleChange}
			>
				{menuHeader && (
					<MenuItem disabled value="">
						{menuHeader}
					</MenuItem>
				)}
				{optionsList?.map(c => (
					<MenuItem
						key={c[valueField]}
						data-cy={c[labelField]}
						data-testid={`select-menu-item-${c[valueField]}`}
						name={c[labelField]}
						sx={{ p: 1.5 }}
						value={c[valueField]}
					>
						<ListItemIcon variant="checklist">
							{selectedValue ? (
								selectedValue.id === c[valueField] ||
								selectedValue.context === c[valueField] ||
								selectedValue[valueField] === c[valueField] ? (
									<Check />
								) : (
									''
								)
							) : (
								''
							)}
						</ListItemIcon>
						<ListItemText primary={t(c[labelField])} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

SingleSelect.propTypes = {
	/**
	 * Hides the magnifier icon
	 */
	hideIcon: PropTypes.bool,

	/**
	 * Whether the component should consume full width
	 */
	fullWidth: PropTypes.bool,
	/**
	 * Determines if the component should be read only or not
	 */
	readOnly: PropTypes.bool,
	/**
	 * A header value for the menu
	 */
	menuHeader: PropTypes.string,
	/**
	 * List of items available to be selected.
	 */
	itemList: PropTypes.array,
	/**
	 * Label for the component
	 */
	label: PropTypes.string,
	/**
	 * Size of the component. Can be `small` or `medium`.
	 */
	size: PropTypes.oneOf(['small', 'medium']),
	/**
	 * Callback for when an item in the multi-select is selected.
	 */
	onSelect: PropTypes.func,
	/**
	 * The property name of the value to be selected manually
	 */
	select: PropTypes.string,
	/**
	 * The property name of the itemList objects that should be referred to for the item's label
	 */
	labelField: PropTypes.string,
	/**
	 * The property name of the itemList objects that should be referred to for the item's value
	 */
	valueField: PropTypes.string,
	/**
	 * The width of the field
	 */
	width: PropTypes.number,
	/**
	 * Indicates whether the selected value can be deselected
	 */
	canDeselect: PropTypes.bool,
};

SingleSelect.defaultProps = {
	labelField: 'label',
	valueField: 'value',
	width: 210,
	readOnly: false,
	itemList: [],
};

export default SingleSelect;
