/* Generated automagically by FhirStarter - but please feel free to edit this file */
import { fhirExtensionUrls } from '../../extension';
const ValueSetMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	url: {
		label: 'Url',
		options: { filter: false, sort: false },
		searchParameter: 'url',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.url;
		},
	},
	identifier: {
		label: 'Identifier',
		options: { filter: false, sort: false },
		searchParameter: 'identifier',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.identifier;
		},
	},
	version: {
		label: 'Version',
		options: { filter: false, sort: false },
		searchParameter: 'version',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.version;
		},
	},
	name: {
		label: 'Name',
		options: { filter: false, sort: false },
		searchParameter: 'name:exact',
		valueSet: {},
		filterType: 'free-text',
		getDisplay: resource => {
			return resource.name;
		},
	},
	title: {
		label: 'Title',
		options: { filter: false, sort: false },
		searchParameter: 'title',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.title;
		},
	},
	status: {
		label: 'Status',
		options: { filter: false, sort: false },
		searchParameter: 'status',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.status;
		},
	},
	experimental: {
		label: 'Experimental',
		options: { filter: false, sort: false },
		searchParameter: 'experimental',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.experimental;
		},
	},
	date: {
		label: 'Date',
		options: { filter: false, sort: false },
		searchParameter: 'date',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.date;
		},
	},
	publisher: {
		label: 'Publisher',
		options: { filter: false, sort: false },
		searchParameter: 'publisher',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.publisher;
		},
	},
	contact: {
		label: 'Contact',
		options: { filter: false, sort: false },
		searchParameter: 'contact',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.contact;
		},
	},
	description: {
		label: 'Description',
		options: { filter: false, sort: false },
		searchParameter: 'description',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.description;
		},
	},
	useContext: {
		label: 'Use Context',
		options: { filter: false, sort: false },
		searchParameter: 'useContext',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.useContext;
		},
	},
	jurisdiction: {
		label: 'Jurisdiction',
		options: { filter: false, sort: false },
		searchParameter: 'jurisdiction',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.jurisdiction;
		},
	},
	immutable: {
		label: 'Immutable',
		options: { filter: false, sort: false },
		searchParameter: 'immutable',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.immutable;
		},
	},
	purpose: {
		label: 'Purpose',
		options: { filter: false, sort: false },
		searchParameter: 'purpose',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.purpose;
		},
	},
	copyright: {
		label: 'Copyright',
		options: { filter: false, sort: false },
		searchParameter: 'copyright',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.copyright;
		},
	},
	compose_lockedDate: {
		label: 'Compose_locked Date',
		options: { filter: false, sort: false },
		searchParameter: 'compose_lockedDate',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.compose_lockedDate;
		},
	},
	compose_inactive: {
		label: 'Compose_inactive',
		options: { filter: false, sort: false },
		searchParameter: 'compose_inactive',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.compose_inactive;
		},
	},
	compose_include: {
		label: 'Compose_include',
		options: { filter: false, sort: false },
		searchParameter: 'compose_include',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.compose_include;
		},
	},
	compose_exclude: {
		label: 'Compose_exclude',
		options: { filter: false, sort: false },
		searchParameter: 'compose_exclude',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.compose_exclude;
		},
	},
	expansion_identifier: {
		label: 'Expansion_identifier',
		options: { filter: false, sort: false },
		searchParameter: 'expansion_identifier',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.expansion_identifier;
		},
	},
	expansion_timestamp: {
		label: 'Expansion_timestamp',
		options: { filter: false, sort: false },
		searchParameter: 'expansion_timestamp',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.expansion_timestamp;
		},
	},
	expansion_total: {
		label: 'Expansion_total',
		options: { filter: false, sort: false },
		searchParameter: 'expansion_total',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.expansion_total;
		},
	},
	expansion_offset: {
		label: 'Expansion_offset',
		options: { filter: false, sort: false },
		searchParameter: 'expansion_offset',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.expansion_offset;
		},
	},
	expansion_parameter: {
		label: 'Expansion_parameter',
		options: { filter: false, sort: false },
		searchParameter: 'expansion_parameter',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.expansion_parameter;
		},
	},
	expansion_contains: {
		label: 'Expansion_contains',
		options: { filter: false, sort: false },
		searchParameter: 'expansion_contains',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.expansion_contains;
		},
	},
};
export default ValueSetMapping;
