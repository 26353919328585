// core
import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { RULES_SETTINGS } from '../consts/rules';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PROTOCOL, PROTOCOL_DEFAULT_VALUES, STAGE_DEFAULT_VALUE } from '../consts/protocolInstances';
import { useFhirDataLoader, scopeMapping, searchScopes, useAuth, useConfig } from '@worklist-2/core/src';

const HangingProtocolContext = createContext({});

const HangingProtocolContextProvider = ({ children }) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const internalManagingOrganizationID = searchParams.get('internalManagingOrganizationID');

	const __config = useConfig();
	const { sessionId, authorized, loggedInUser } = useAuth();
	const [protocols, setProtocols] = useState([]);
	const [currentProtocol, setCurrentProtocol] = useState({
		...PROTOCOL_DEFAULT_VALUES,
		[PROTOCOL.organization_id]: internalManagingOrganizationID,
	});
	const [currentStage, setCurrentStage] = useState(STAGE_DEFAULT_VALUE);
	const [stages, setStages] = useState([STAGE_DEFAULT_VALUE]);
	const [activeStageIndex, setActiveStageIndex] = useState(0);
	const [modality, setModality] = useState([]);
	const [bodyPart, setBodyPart] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [sidebarType, setSidebarType] = useState(null);
	const [activeMonitorId, setActiveMonitorId] = useState(null);
	const [draggableCode, setDraggableCode] = useState(null);
	const [studyStatusList, setStudyStatusList] = useState([]);
	const dataLoader = useFhirDataLoader({ scope: searchScopes.organization });

	const setDefaultProtocolValues = () => {
		setCurrentProtocol({
			...PROTOCOL_DEFAULT_VALUES,
			[PROTOCOL.organization_id]: internalManagingOrganizationID,
		});
		setCurrentStage(STAGE_DEFAULT_VALUE);
		setStages([STAGE_DEFAULT_VALUE]);
	};

	const getLayout = layout => {
		if (layout) {
			if (!layout.x) {
				layout.x = 0;
			}

			if (!layout.y) {
				layout.y = 0;
			}

			return layout;
		}

		return { x: 0, y: 0 };
	};

	const convertLegacyModel = protocol => {
		if (protocol.Stages && Array.isArray(protocol.Stages) && protocol.Stages.length > 0) {
			return protocol;
		}
		return {
			..._.omit(protocol, [[PROTOCOL.layout], [PROTOCOL.viewport]]),
			Stages: [
				{
					monitors: protocol.ViewPorts || [],
					layout: getLayout(protocol.Layout),
				},
			],
		};
	};

	const deleteProtocol = useCallback(
		async id => {
			const URL = `${__config.data_sources.dicom_web}/hangingprotocol/?ProtocolId=${id}`;
			try {
				setProtocols(protocols.filter(({ ProtocolID }) => ProtocolID !== id));
				await axios.delete(URL);
			} catch (err) {
				console.error(err);
			}
		},
		[protocols]
	);

	useEffect(() => {
		if (sessionId && authorized) {
			loadProtocolsAndUpdateCurrentProtocol();
			loadDropdownValues('modality', setModality);
			loadDropdownValues('bodyPart', setBodyPart);
			fetchStudyStatusList();
		}
	}, [sessionId, authorized]);

	const loadDropdownValues = async (value, setValue) => {
		const timestamp = new Date().getTime();
		try {
			const res = await axios.get(
				`${__config.data_sources.fhir}/ValueSet?_dc=${timestamp}&name:exact=${value}&_count=1000`
			);

			const _data = res.data?.entry[0]?.resource?.compose?.include[0]?.concept || [];

			setValue(
				_data.map(({ display }) => ({
					label: display,
					value: display,
				}))
			);
		} catch (err) {
			console.error(err);
		}
	};

	const updateDataLoaderScope = scope => {
		dataLoader.updateScope({
			scope,
			endpoint: scopeMapping[scope],
		});
	};

	const fetchStudyStatusList = () => {
		updateDataLoaderScope(searchScopes.studyStatus);
		dataLoader
			.load(
				{
					_sort: 'statusValue',
					_summary: false,
					organization: internalManagingOrganizationID,
				},
				true
			)
			.then(res => {
				setStudyStatusList(
					res.entry?.map(({ resource }) => ({
						label: resource.status,
						value: resource.statusValue,
					})) || []
				);
			});
	};

	const loadProtocolsAndUpdateCurrentProtocol = async () => {
		try {
			const res = await axios.get(`${__config.data_sources.dicom_web}/HangingProtocol`, {
				params: {
					JsonQuery: { Item: 'Item 1' },
					InternalManagingOrgId: internalManagingOrganizationID,
				},
			});

			const data = res.data
				.replaceAll('---------', '')
				.replaceAll('Content-Type: application/json', '')
				.split('-------')
				.filter(val => {
					try {
						JSON.parse(val);
						return true;
					} catch (error) {
						return false;
					}
				})
				.map(el => JSON.parse(el));

			let loadedProtocolsData = _.uniqBy(data, 'ProtocolID');

			if (loadedProtocolsData.length > 0) {
				loadedProtocolsData = loadedProtocolsData.map(protocol => convertLegacyModel(protocol));
			}

			setProtocols(loadedProtocolsData);
			updateCurrentProtocol(loadedProtocolsData);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		if (!internalManagingOrganizationID) {
			navigate('/home');
		}
	}, [internalManagingOrganizationID]);

	const updateCurrentProtocol = loadedProtocols => {
		const savedProtocol =
			currentProtocol.ProtocolID &&
			loadedProtocols.find(protocol => protocol.ProtocolID === currentProtocol.ProtocolID);
		if (savedProtocol) {
			setCurrentProtocol(savedProtocol);
			setStages(savedProtocol.Stages);
			let activeIndex = activeStageIndex;
			if (savedProtocol.Stages.length - 1 < activeStageIndex) {
				activeIndex = savedProtocol.Stages.length - 1;
			}
			setActiveStageIndex(activeIndex);
			setCurrentStage(savedProtocol.Stages[activeIndex]);
		}
	};

	const onChangeSelectedProtocol = protocol => {
		setCurrentProtocol(protocol);
		setStages(protocol.Stages);
		setActiveMonitorId(null);
		setActiveStageIndex(0);
		setCurrentStage(protocol.Stages[0]);
	};

	const addStage = () => {
		setIsEdit(true);
		setStages([...stages, STAGE_DEFAULT_VALUE]);
		setActiveStageIndex(stages.length);
		setCurrentStage(STAGE_DEFAULT_VALUE);
		setActiveMonitorId(null);
	};

	const deleteStage = index => {
		const targetIndex = index > 0 ? index - 1 : 0;
		const newStages = [...stages];
		newStages.splice(index, 1);
		setActiveStageIndex(targetIndex);
		setCurrentStage(newStages[targetIndex]);
		setStages(newStages);
		setActiveMonitorId(null);
	};

	const onChangeActiveStageIndex = targetStage => {
		setActiveMonitorId(null);
		setActiveStageIndex(targetStage);
		setCurrentStage(stages[targetStage]);
	};

	const wheelOptions = useMemo(() => {
		if (!currentStage) return;
		const activeMonitorRules = currentStage.monitors.find(monitor => monitor.id === activeMonitorId)?.rules || [];
		return Object.entries(RULES_SETTINGS).map(([type, settings]) => {
			const typeRules = activeMonitorRules.filter(rule => rule.type === type);
			const disabled = !settings.validate(typeRules);
			return {
				type,
				icon: settings.icon,
				label: settings.label,
				disabled,
			};
		});
	}, [currentStage, activeMonitorId]);

	const onStageChanged = targetViewport => {
		setIsEdit(true);
		setCurrentStage(targetViewport);
		const newStages = [...stages];
		newStages.splice(activeStageIndex, 1, targetViewport);
		setStages(newStages);
	};

	const handleChangeRule = useCallback(
		(newValues, index) => {
			onStageChanged({
				...currentStage,
				monitors: currentStage.monitors.map(monitor => {
					if (monitor.id === activeMonitorId) {
						return {
							...monitor,
							rules: monitor.rules.map((rule, i) => {
								if (index === i) {
									return {
										...rule,
										values: {
											...rule.values,
											...newValues,
										},
									};
								}
								return rule;
							}),
						};
					}
					return monitor;
				}),
			});
		},
		[currentStage, activeMonitorId]
	);

	const handleAddRule = useCallback(
		rule => {
			onStageChanged({
				...currentStage,
				monitors: currentStage.monitors.map(monitor => {
					if (monitor.id === activeMonitorId) {
						return {
							...monitor,
							rules: [...monitor.rules, rule],
						};
					}
					return monitor;
				}),
			});
		},
		[currentStage, activeMonitorId]
	);

	const handleDeleteRule = useCallback(
		ruleIndex => {
			onStageChanged({
				...currentStage,
				monitors: currentStage.monitors.map(monitor => {
					if (monitor.id === activeMonitorId) {
						return {
							...monitor,
							rules: monitor.rules.filter((m, index) => index !== ruleIndex),
						};
					}
					return monitor;
				}),
			});
		},
		[currentStage, activeMonitorId]
	);

	const setViewportPriorSelection = prior => {
		onStageChanged({
			...currentStage,
			monitors: currentStage.monitors.map(monitor => {
				if (monitor.id === activeMonitorId) {
					return {
						...monitor,
						prior,
					};
				}
				return monitor;
			}),
		});
	};

	const closeActions = useCallback(() => {
		setSidebarType(null);
	}, []);

	const assignPriorMatchingModel = MatchingModelID => {
		setCurrentProtocol({ ...currentProtocol, MatchingModelID });
	};

	const saveProtocol = async (values = {}) => {
		setIsLoading(true);
		const ProtocolID = uuid();
		const payload = {
			...currentProtocol,
			[PROTOCOL.protocol_id]: ProtocolID,
			[PROTOCOL.internal_user_id]: loggedInUser.id,
			[PROTOCOL.creator]: loggedInUser.fullName,
			[PROTOCOL.organization_id]: values.organization?.value || currentProtocol[PROTOCOL.organization_id],
			[PROTOCOL.organization_name]: values.organization?.label,
			[PROTOCOL.name]: values.protocolName,
			[PROTOCOL.level]: values.level,
			[PROTOCOL.default_for_modality]: values.defaultForModality,
			[PROTOCOL.definition_sequence]: [
				{
					'Item 1': {
						Modality: values.modality,
						'Anatomic Region Sequence': {
							'Item 1': ['T-D3000', 'SRT', 'Chest'],
							Laterality: values.laterality,
							BodyPart: values.bodyPart,
							'Procedure Code Sequence': values.procedureCode,
							'Reason for Requested Procedure Code Sequence': '',
						},
					},
				},
			],
			[PROTOCOL.timestamp]: +new Date(),
			[PROTOCOL.stages]: stages,
		};

		const query = {
			InternalManagingOrgId: payload[PROTOCOL.organization_id],
			ProtocolID,
		};

		const URL = `${__config.data_sources.dicom_web}/hangingprotocol`;

		try {
			await axios.post(URL, payload, {
				params: query,
			});
			searchParams.set('editProtocol', ProtocolID);
			setSearchParams(searchParams, { replace: true });
			closeActions();
			await loadProtocolsAndUpdateCurrentProtocol();
		} catch (err) {
			console.error(err);
		}

		setIsLoading(false);
	};

	const updateProtocol = useCallback(
		async values => {
			setIsLoading(true);
			const { ProtocolID } = currentProtocol;
			let payload = {
				...currentProtocol,
				[PROTOCOL.stages]: stages,
			};
			if (values) {
				payload = {
					...payload,
					[PROTOCOL.organization_id]: values.organization?.value || currentProtocol[PROTOCOL.organization_id],
					[PROTOCOL.organization_name]: values.organization?.label,
					[PROTOCOL.name]: values.protocolName,
					[PROTOCOL.level]: values.level,
					[PROTOCOL.default_for_modality]: values.defaultForModality,
					[PROTOCOL.definition_sequence]: [
						{
							'Item 1': {
								Modality: values.modality,
								'Anatomic Region Sequence': {
									'Item 1': ['T-D3000', 'SRT', 'Chest'],
									Laterality: values.laterality,
									BodyPart: values.bodyPart,
									'Procedure Code Sequence': values.procedureCode,
									'Reason for Requested Procedure Code Sequence': '',
								},
							},
						},
					],
				};
			}

			const query = {
				InternalManagingOrgId: payload[PROTOCOL.organization_id],
				ProtocolID,
			};

			const URL = `${__config.data_sources.dicom_web}/hangingprotocol`;

			try {
				await axios.put(URL, payload, {
					params: query,
				});
				closeActions();
			} catch (err) {
				console.error('err', err);
			}
			await loadProtocolsAndUpdateCurrentProtocol();
			setIsLoading(false);
		},
		[__config?.data_sources?.dicom_web, currentProtocol, loadProtocolsAndUpdateCurrentProtocol]
	);

	return (
		<HangingProtocolContext.Provider
			value={{
				isEdit,
				setIsEdit,
				handleChangeRule,
				handleAddRule,
				handleDeleteRule,
				draggableCode,
				setDraggableCode,
				closeActions,
				wheelOptions,
				onStageChanged,
				modality,
				bodyPart,
				studyStatusList,
				isLoading,
				sidebarType,
				setSidebarType,
				activeMonitorId,
				setActiveMonitorId,
				saveProtocol,
				updateProtocol,
				deleteProtocol,
				stages,
				viewport: currentStage,
				addStage,
				deleteStage,
				activeStageIndex,
				onChangeActiveStageIndex,
				protocols,
				currentProtocol,
				setDefaultProtocolValues,
				onChangeSelectedProtocol,
				setViewportPriorSelection,
				assignPriorMatchingModel,
				setCurrentStage,
				setStages,
			}}
		>
			{children}
		</HangingProtocolContext.Provider>
	);
};

const useHangingProtocolContext = () => useContext(HangingProtocolContext);

export { HangingProtocolContextProvider, useHangingProtocolContext, HangingProtocolContext };
