// core
import React, { useState, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
// mui
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Autocomplete from '@mui/lab/Autocomplete';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
// mui icons
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
// partials
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import DateTimeRangePicker from '../DateTimeRangePicker/DateTimeRangePicker';
import moment from 'moment';

export const FieldInput = ({
	isLoading,
	field,
	fieldValuesObj,
	onDateSelectionChange,
	onFieldChange,
	onToggleIsFieldChecked,
}) => {
	const FIELD_TYPES = {
		TEXT_FIELD: 'textField',
		SELECT: 'select',
		DATE_PICKER: 'datePicker',
		AUTOCOMPLETE: 'autoComplete',
	};
	const { t } = useTranslation('Search');

	const [dateRangeOpened, setDateRangeOpened] = useState(false);
	const datePeriodRef = useRef(null);

	return field.type && Object.values(FIELD_TYPES).includes(field.type) ? (
		<div key={field.id} className="field-texts-input">
			{field.type === FIELD_TYPES.TEXT_FIELD ? (
				<TextField
					fullWidth
					InputProps={{
						autoComplete: 'off',
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={onFieldChange(field, '')}>
									<CloseIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
					className="mt-2"
					data-cy={`checked-field-${field.label}`}
					data-testid={`checked-field-${field.label}`}
					label={t(field.label)}
					name="value"
					placeholder={t('Search')}
					sx={{
						'input:-webkit-autofill': {
							boxShadow: '0 0 0 100px #393939 inset !important',
							borderRadius: 0,
						},
					}}
					value={fieldValuesObj[field.label] || ''}
					variant="outlined"
					onChange={onFieldChange(field)}
				/>
			) : field.type === FIELD_TYPES.SELECT ? (
				<FormControl fullWidth className="mt-2">
					<InputLabel id="demo-simple-select-label">{field.label}</InputLabel>

					{isLoading ? (
						<Select label={field.label}>
							<CircularProgress color="inherit" size={20} />
						</Select>
					) : (
						<Select
							multiple
							data-cy={`checked-field-${field.label}`}
							data-testid={`checked-field-${field.label}`}
							endAdornment={
								<InputAdornment
									position="end"
									sx={{
										paddingRight: '16px',
										marginLeft: '-110px',
										zIndex: 0,
										pointerEvents: 'auto',
									}} // Add pointerEvents style here
								>
									<IconButton onClick={onFieldChange(field, '')}>
										<CloseIcon />
									</IconButton>
								</InputAdornment>
							}
							id="demo-simple-select"
							label={t(field.label)}
							labelId="demo-simple-select-label"
							renderValue={selected => selected.join(', ')}
							value={fieldValuesObj[field.label] || []}
							onChange={onFieldChange(field)}
						>
							{field.data?.map(item => (
								<MenuItem key={item.id} value={item.display}>
									<Checkbox checked={fieldValuesObj[field.label]?.includes(item.display)} />
									<ListItemText primary={item.display} />
								</MenuItem>
							))}
						</Select>
					)}
				</FormControl>
			) : field.type === FIELD_TYPES.DATE_PICKER ? (
				<FormControl className="mt-2 w-100" data-testid="pl-date-range">
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimeRangePicker
							disableTabs
							withCustomHandlers
							customAnchor={datePeriodRef}
							dateRange={fieldValuesObj[field.label]}
							dateTimeFormat={moment().creationData().locale.longDateFormat('L')}
							label={t(field.label)}
							openedFromOutside={dateRangeOpened}
							setDateRangeOpened={setDateRangeOpened}
							onChange={value => {
								onDateSelectionChange(value, field);
							}}
						/>
					</LocalizationProvider>
				</FormControl>
			) : field.type === FIELD_TYPES.AUTOCOMPLETE ? (
				<Autocomplete
					disablePortal
					freeSolo
					fullWidth
					className="mt-2"
					data-cy={`checked-field-${field.label}`}
					getOptionLabel={option => option.display}
					id="combo-box-demo"
					label={t(field.label)}
					options={field.data}
					renderInput={params => (
						<TextField
							{...params}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{isLoading ? <CircularProgress color="inherit" size={20} /> : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
							label={field.label}
							variant="outlined"
						/>
					)}
					onSelect={onFieldChange(field)}
				/>
			) : null}

			<div className="delete-icon">
				<DeleteOutline
					data-testid={`delete-${field.label}`}
					onClick={() => onToggleIsFieldChecked(field.id, false)}
				/>
			</div>
		</div>
	) : (
		<div key={field.id} className="field-text">
			{fieldValuesObj[field.label] || ''}
		</div>
	);
};

FieldInput.propTypes = {
	isLoading: PropTypes.bool,
	field: PropTypes.object,
	fieldValuesObj: PropTypes.object,
	onDateSelectionChange: PropTypes.func,
	onFieldChange: PropTypes.func,
	onToggleIsFieldChecked: PropTypes.func,
};
