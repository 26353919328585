export const getViewerURLs = ({
	patientID,
	internalPatientId,
	orderId,
	internalStudyId,
	internalManagingOrganizationID,
	issuerOfPatientId,
	referringFacilityId,
	studyInstanceUid,
	documentViewerUrl,
	imageViewerUrl,
	creatorBlumeId = null,
	documentId = null,
}) => {
	let documentViewerNavigateURL;
	let imageViewerNavigateURL;

	if (documentViewerUrl) {
		documentViewerNavigateURL = documentViewerUrl.replace('<studyuid>', studyInstanceUid);
	} else {
		const _path = '/document-viewer-v3';
		const _internalPatientId = `patientId=${internalPatientId}`;
		const _orderId = `orderId=${orderId}`;
		const _studyId = `studyId=${internalStudyId}`;
		const _internalManagingOrganizationId = `internalManagingOrganizationID=${internalManagingOrganizationID}`;
		const _issuerOfPatientId = `IssuerOfPatientID=${issuerOfPatientId}`;
		const _studyInstanceUid = `StudyInstanceUIDs=${studyInstanceUid}`;
		const _creatorBlumeId = `creatorBlumeId=${creatorBlumeId}`;
		const _documentId = `documentId=${documentId}`;

		documentViewerNavigateURL = `${_path}?${_internalPatientId}&${_orderId}&${_studyId}&${_internalManagingOrganizationId}&${_issuerOfPatientId}&${_studyInstanceUid}&${_creatorBlumeId}&${_documentId}`;
	}

	if (imageViewerUrl) {
		imageViewerNavigateURL = imageViewerUrl.replace('<studyuid>', studyInstanceUid);
	} else {
		const _path = `/imageviewer3d`;
		const _studyId = `StudyId=${internalStudyId}`;
		const _studyInstanceUid = `StudyInstanceUIDs=${studyInstanceUid}`;
		const _internalPatientId = `PatientInfo=${internalPatientId}`;
		const _issuerOfPatientId = `IssuerOfPatientID=${issuerOfPatientId}`;
		const _patientId = `PatientID=${patientID}`;
		const _internalManagingOrganizationId = `internalManagingOrganizationID=${internalManagingOrganizationID}`;
		const _referringFacilityId = `referringFacilityId=${referringFacilityId}`;
		const _creatorBlumeId = `creatorBlumeId=${creatorBlumeId}`;

		imageViewerNavigateURL = `${_path}?${_studyId}&${_studyInstanceUid}&${_internalPatientId}&${_issuerOfPatientId}&${_patientId}&${_internalManagingOrganizationId}&${_referringFacilityId}&${_creatorBlumeId}`;
	}

	return { documentViewerNavigateURL, imageViewerNavigateURL };
};
