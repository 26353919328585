import { compressObjectToArrayBuffer, decompressArrayBufferToObject } from '@rs-core/utils';

const zustandShare = (key, api, { ref = 'shared-', initialize = false, wonIvBroadcastMsgCompress = false } = {}) => {
	const channelName = `${ref}-${key.toString()}`;

	const channel = new BroadcastChannel(channelName);
	let externalUpdate = false;
	let timestamp = 0;

	const cleanup = api.subscribe(
		state => state[key],
		state => {
			if (!externalUpdate) {
				timestamp = Date.now();
				const message = { timestamp, state };

				if (wonIvBroadcastMsgCompress) {
					const byteArray = compressObjectToArrayBuffer(message);
					channel.postMessage(byteArray);
				} else {
					channel.postMessage(message);
				}
			}
			externalUpdate = false;
		}
	);

	channel.onmessage = evt => {
		if (evt.data === undefined) {
			const message = { timestamp, state: api.getState()[key] };

			if (wonIvBroadcastMsgCompress) {
				if (timestamp !== 0) {
					channel.postMessage(compressObjectToArrayBuffer(message));
				}
			} else {
				channel.postMessage(message);
			}

			return;
		}

		let message = evt.data;
		if (wonIvBroadcastMsgCompress) {
			// decompress message after receiving
			message = decompressArrayBufferToObject(evt.data);
		}

		if (message.timestamp <= timestamp) {
			return;
		}

		if (api.getState()[key] === message.state) {
			return;
		}

		externalUpdate = true;
		timestamp = message.timestamp;

		api.setState({ [key]: message.state });
	};

	const sync = () => channel.postMessage(undefined);

	const unshare = () => {
		cleanup();
		channel.close();
	};

	if (initialize) {
		sync();
	}

	return [sync, unshare];
};

export { zustandShare };
