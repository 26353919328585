// @flow

// core
import React, { useMemo, type Node } from 'react';

// components
import PriorityIcon from './PriorityIcon';

// libs
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import Typography from '@mui/material/Typography';
// utils
import { getUserFullName } from '@worklist-2/core/src';
import { formatExtensionsPriority, renderPriorityIcon } from '../../../PrioritySelector/helperMethods';
import { Box } from '@mui/material';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

interface ICellContentProps {
	/**
	 *  Label of the autocomplete dropdown
	 */
	cellLabel: string;
	/**
	 *  Value of the cell
	 */
	cellValue: string;
	/**
	 *  A string that will be used for fetching default data in CodePool
	 */
	searchValueSet: string;
	/**
	 *  Flag to identify if it's needed to highlight folder icon
	 */
	shouldHighlightIcon?: boolean;
	/**
	 *  Name of the selected practitinoners
	 */
	userName: string;
	/**
	 *  Translation function
	 */
	t: (key: string) => string;
}

const CellContent = ({
	userName,
	cellValue,
	t,
	searchValueSet,
	cellLabel,
	shouldHighlightIcon,
}: ICellContentProps): Node => {
	const proactEnableCustomStudyPriority = useBooleanFlagValue('proact-enable-custom-study-priority');
	const practitionerColumns = [
		'performingPhysician',
		'performingTechnologist',
		'readingPhysician',
		'referringPhysician',
		'transcriptionist',
	];
	const translatedValue = searchValueSet && cellValue ? t(`codePool:${searchValueSet}.${cellValue}`) : cellValue;
	const name = userName ? getUserFullName(userName, true) : t('worklist:UNASSIGNED');
	const displayText = useMemo(() => {
		if (searchValueSet) {
			if (proactEnableCustomStudyPriority && cellLabel === 'priority') {
				return cellValue?.display;
			}
			return translatedValue;
		}
		return name;
	}, [cellValue, searchValueSet, cellLabel, proactEnableCustomStudyPriority, name, translatedValue]);

	return (
		<>
			{practitionerColumns.includes(cellLabel) && (
				<FolderSharedIcon fontSize="small" sx={SX.folderSharedIconStyle(shouldHighlightIcon)} />
			)}

			{proactEnableCustomStudyPriority && cellLabel === 'priority' ? (
				<Box sx={SX.customPriorityIconStyles(formatExtensionsPriority(cellValue))}>
					{renderPriorityIcon(formatExtensionsPriority(cellValue))}
				</Box>
			) : (
				cellLabel === 'priority' &&
				cellValue && <PriorityIcon optionName={cellValue} sx={SX.priorityIconStyle} />
			)}

			<Typography sx={SX.priorityLabelStyle}>{displayText}</Typography>
		</>
	);
};

const SX = {
	priorityLabelStyle: {
		width: '100%',
		fontSize: '14px',
		lineHeight: '1.43',
		'&:hover': {
			color: 'rsPrimary.main',
		},
	},
	priorityIconStyle: { width: '24px', height: '18px' },
	customPriorityIconStyles: cellValue => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		mr: '8px',
		width: '24px' ?? '16px',
		height: '18px' ?? '16px',
		borderRadius: '50px !important',
		backgroundColor: cellValue?.color,

		border: cellValue?.color,
	}),
	folderSharedIconStyle: shouldHighlightIcon => ({
		mr: '8px',
		width: '20px',
		height: '20px',
		borderRadius: 4,
		color: shouldHighlightIcon ? '#F5BF0E' : '#FFFFFF99',

		'&:hover': {
			color: 'rsPrimary.main',
			backgroundColor: 'transparent',
		},
	}),
};

export default CellContent;
