// core
import React, { createContext, useContext, useEffect, useState } from 'react';

// mockData
import axios from 'axios';

// utils
import { useConfig } from '@worklist-2/core/src';
import { useLocation } from 'react-router';

const BlumeAssistantContext = createContext({});

const BlumeAssistantContextProvider = ({ children }) => {
	const __config = useConfig();
	const [completions, setCompletions] = useState([]);
	const [prompt, setPrompt] = useState(null);
	const [fullScreenView, setFullScreenView] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();
	const maxToken = 3950;
	const maxResponseToken = 300;

	const countWords = sentence => {
		const wordArray = sentence.split(' ');
		return wordArray.filter(word => word !== '').length;
	};

	useEffect(() => {
		if (prompt) {
			const completion = [{ role: 'User', content: prompt }];
			setCompletions(completion);
			const requestArray = [];
			let msgTokens = 0;
			completion
				.slice()
				.reverse()
				.forEach(eachMsg => {
					const msgLength = countWords(eachMsg.content);
					msgTokens += msgLength;
					if (msgTokens + maxResponseToken < maxToken) {
						requestArray.unshift(eachMsg);
					}
				});
			setIsLoading(true);
			axios
				.post(`${__config.data_sources.blume}BlumeAssistant/chat`, requestArray, {
					headers: {
						'Content-Type': 'application/json',
					},
				})
				.then(res => {
					if (res?.status === 200) {
						setCompletions([
							...completion,
							{ role: 'Assistant', content: res?.data?.choices[0]?.message?.content },
						]);
						if (location.pathname === '/home') {
							toggleFullScreenView(true);
						}
						setIsLoading(false);
					}
				});
		}
	}, [prompt]);

	const askAQuestion = question => {
		setPrompt(question);
	};

	const toggleFullScreenView = value => {
		setFullScreenView(value);
	};

	return (
		<BlumeAssistantContext.Provider
			value={{
				completions,
				askAQuestion,
				fullScreenView,
				toggleFullScreenView,
				isLoading,
				setCompletions,
			}}
		>
			{children}
		</BlumeAssistantContext.Provider>
	);
};

const useBlumeAssistantContext = () => useContext(BlumeAssistantContext);

export { BlumeAssistantContext, BlumeAssistantContextProvider, useBlumeAssistantContext };
