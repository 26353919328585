import { initializeApp } from '@firebase/app';
import { getAnalytics, logEvent } from '@firebase/analytics';
import { LOGIN_SUCCESS } from './eventTypes';
import { logInfo } from '@worklist-2/core/src';

const config = JSON.parse(process.env.NODE_CONFIG || '{}');

// Initialize Firebase
let app = null;
let analytics = null;
if (config.firebase && Object.keys(config.firebase).length && config.firebase.appId !== '<APP_ID>') {
	app = initializeApp(config.firebase);
	if (app) {
		analytics = getAnalytics(app);
	}
}
let defaultParams = {};

function sendAnalyticsEvent(eventName, eventParams) {
	if (!analytics) {
		return;
	}
	if (eventName === LOGIN_SUCCESS) {
		defaultParams = eventParams;
	}
	const payload = { ...defaultParams, ...eventParams };
	logEvent(analytics, eventName, payload);
	logInfo('Analytics event sent:', eventName, payload);
}

export default sendAnalyticsEvent;
