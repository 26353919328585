import { fhirExtensionUrls } from '@worklist-2/core/src';

export function mapStatusesTransitionsByRole(studyStatusData) {
	const transitionByRoleExtension = studyStatusData.extension?.find(
		({ url }) => url === fhirExtensionUrls.studyStatus.transitionsByRole
	)?.extension;
	if (transitionByRoleExtension?.length > 0) {
		return transitionByRoleExtension.map(role => ({
			roleId: role.valueDecimal,
			allowedTransitionStatusIds: role.extension
				? role.extension.map(({ valueDecimal }) => Number(valueDecimal))
				: [],
		}));
	}
	return [];
}

function getUserRoleIdOnOrganization(userPractitionerRolesPerOrganization, organizationId) {
	const userPractitionerRoleOnOrganization = userPractitionerRolesPerOrganization?.find(
		({ organization }) => Number(organization.id) === Number(organizationId)
	);
	if (userPractitionerRoleOnOrganization) {
		const userRoleId = userPractitionerRoleOnOrganization.extension.find(
			({ url }) => url === fhirExtensionUrls.practitionerRole.role
		)?.valueReference?.id;
		return Number(userRoleId);
	}
}

export function filterAllowedStatusBasedOnAllowedTransitionsByRole(
	userPractitionerRolesPerOrganization,
	organizationId,
	currentStatus,
	allStatuses,
	sprinterOaiStudystatusWorkflowstepsV2
) {
	const userRoleIdOnOrganization = getUserRoleIdOnOrganization(userPractitionerRolesPerOrganization, organizationId);
	let filteredStatuses = allStatuses?.length > 0 ? [...allStatuses] : [];
	if (currentStatus && sprinterOaiStudystatusWorkflowstepsV2 && userRoleIdOnOrganization) {
		const statusTransitionsByRoleForStatus = mapStatusesTransitionsByRole(currentStatus);
		const userRoleAllowedStatusesIds = statusTransitionsByRoleForStatus.find(
			({ roleId }) => Number(roleId) === userRoleIdOnOrganization
		)?.allowedTransitionStatusIds;
		if (userRoleAllowedStatusesIds) {
			filteredStatuses = allStatuses.filter(({ id }) => {
				return userRoleAllowedStatusesIds.includes(Number(id));
			});
		}
	}
	return sprinterOaiStudystatusWorkflowstepsV2
		? filteredStatuses.sort((a, b) => a.order - b.order)
		: filteredStatuses;
}
