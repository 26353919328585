import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { useAuth, mediaMobile } from '@worklist-2/core/src';

import { useShareDrawerStore } from '../../../stores';
import { useToastMessageContext } from '../../../context/ToastMessageContext';
import { STUDY_DATA } from '../../../__mocks__/mockData';
import { getPatientId } from '../../../utils/getPatientId';
import MobileMyContactList from './MobileMyContactList';
import MobileFixedButtons from '../../MobileBottomDrawer/MobileDrawer/MobileFixedButtons';
import ShareOptionButtons from './ShareOptionButtons';
import sendAnalyticsEvent from '../../../analytics';
import { SHARE_DICOM, SHARE_DOCUMENT } from '../../../analytics/eventTypes';

const ShareContactList = () => {
	const { profiles, patientMapping } = useAuth();
	const { setToastMsg } = useToastMessageContext();
	const {
		documentId,
		studyId,
		user: userData,
		users,
		sharedUsers,
		facilities,
		sharedFacilities,
		toggleUserSelect,
		toggleFacilitySelect,
		shareStudy,
		shareStudyWithFacility,
		shareDocumentWithFacility,
		deselectAll,
		toggleDrawer,
		isLoading,
	} = useShareDrawerStore(state => ({
		documentId: state.documentId,
		studyId: state.studyId,
		user: state.user,
		users: state.users,
		sharedUsers: state.sharedUsers,
		facilities: state.facilities,
		sharedFacilities: state.sharedFacilities,
		toggleUserSelect: state.toggleUserSelect,
		toggleFacilitySelect: state.toggleFacilitySelect,
		shareStudy: state.shareStudy,
		shareStudyWithFacility: state.shareStudyWithFacility,
		shareDocumentWithFacility: state.shareDocumentWithFacility,
		deselectAll: state.deselectAll,
		toggleDrawer: state.toggleDrawer,
		isLoading: state.isLoading,
	}));
	const { t } = useTranslation('drawer');

	const isDemoData = STUDY_DATA.filter(e => e.id === studyId).length > 0;

	const canShare = !isDemoData && (studyId || documentId);
	const showContactsList = !documentId && users.length > 0;
	const showFacilitiesList = facilities.length > 0;
	const showContactsSharedList = !documentId && sharedUsers.length > 0;
	const showFacilitiesSharedList = sharedFacilities.length > 0;
	const showShareButton = Boolean(
		users.filter(usr => usr?.selected).length || facilities.filter(usr => usr?.selected).length
	);
	const showOptionButtons = true;

	const onShareStudy = useCallback(
		async newlySharedUsers => {
			try {
				const patientId = getPatientId({
					patientMapping,
					profileId: userData.profileId,
					profiles,
				});

				await shareStudy({ users: newlySharedUsers, patientId });
				sendAnalyticsEvent(SHARE_DICOM, { sharedUsers: newlySharedUsers, type: 'Contacts' });
				setToastMsg(
					`${t('Shared with')} ${newlySharedUsers.length} ${
						newlySharedUsers.length > 1 ? 'users' : 'user'
					} ${t('successfully')}`
				);
			} catch (err) {
				setToastMsg(t('Share failed, try again'));
			}
		},
		[patientMapping, profiles, setToastMsg, shareStudy, t, userData.profileId]
	);

	const onShareStudyWithFacility = useCallback(
		async newlySharedFacilties => {
			try {
				const sharedFacilitiesRes = await shareStudyWithFacility({ facilities: newlySharedFacilties });
				const sharedFacilitiesLength = sharedFacilitiesRes?.filter(facility => facility !== null)?.length;

				if (sharedFacilitiesLength === newlySharedFacilties.length) {
					sendAnalyticsEvent(SHARE_DICOM, { facility: newlySharedFacilties, type: 'Facility' });
					setToastMsg(
						// prettier-ignore
						`${t('Shared with')} ${sharedFacilitiesLength} ${sharedFacilitiesLength > 1 ? t('facilities') : t('facility')} ${t('successfully')}`
					);
				} else {
					const failedSharedFacilitiesLength = sharedFacilitiesRes?.filter(
						facility => facility === null
					)?.length;
					sendAnalyticsEvent(SHARE_DICOM, { facility: sharedFacilitiesRes, type: 'Facility' });
					setToastMsg(
						// prettier-ignore
						`${t("Failed to share with")} ${Number(failedSharedFacilitiesLength)} ${failedSharedFacilitiesLength > 1 ? t('facilities') : t("facility")}, ${t("Please retry")}`
					);
				}
			} catch (err) {
				setToastMsg(t('Share failed, try again'));
			}
		},
		[setToastMsg, shareStudyWithFacility, t]
	);

	const onShareDocumentWithFacility = useCallback(
		async newlySharedFacilties => {
			try {
				const sharedFacilitiesRes = await shareDocumentWithFacility({
					facilities: newlySharedFacilties,
					hash: userData?.profileId,
				});
				const sharedFacilitiesLength = sharedFacilitiesRes?.filter(facility => facility !== null)?.length;

				if (sharedFacilitiesLength === newlySharedFacilties.length) {
					sendAnalyticsEvent(SHARE_DOCUMENT, { facility: newlySharedFacilties, type: 'Facility' });
					setToastMsg(
						// prettier-ignore
						`${t('Shared with')} ${sharedFacilitiesLength} ${sharedFacilitiesLength > 1 ? t('facilities') : t('facility')} ${t('successfully')}`
					);
				} else {
					const failedSharedFacilitiesLength = sharedFacilitiesRes?.filter(
						facility => facility === null
					)?.length;
					sendAnalyticsEvent(SHARE_DOCUMENT, { facility: sharedFacilitiesRes, type: 'Facility' });
					setToastMsg(
						// prettier-ignore
						`${t("Failed to share with")} ${Number(failedSharedFacilitiesLength) > 1 ? t('facilities') : t("facility")}, ${t("Please retry")}`
					);
				}
			} catch (err) {
				setToastMsg(t('Share failed, try again'));
			}
		},
		[setToastMsg, shareDocumentWithFacility, t, userData?.profileId]
	);

	const onShareClick = useCallback(() => {
		const selectedContacts = users.filter(usr => usr.selected);
		const selectedFacilities = facilities.filter(usr => usr.selected);

		// If studyId is defined documentId will be null
		if (studyId && selectedContacts.length) onShareStudy(selectedContacts);
		if (studyId && selectedFacilities.length) onShareStudyWithFacility(selectedFacilities);

		// If documentId is defined studyId will be null
		if (documentId && selectedFacilities.length) onShareDocumentWithFacility(selectedFacilities);
	}, [users, facilities, documentId, onShareStudy, studyId, onShareStudyWithFacility, onShareDocumentWithFacility]);

	if (isLoading) {
		return (
			<Skeleton
				data-testid="share-drawer-loader"
				sx={{ height: '100%', maxWidth: '400px', borderRadius: '10px', margin: '0 10px 10px' }}
				variant="rectangular"
			/>
		);
	}

	return (
		<Box sx={{ [mediaMobile]: { paddingBottom: showShareButton ? '70px' : undefined, height: '100%' } }}>
			{/* Copy link and Add new contact buttons */}
			{showOptionButtons && (
				<ShareOptionButtons
					canShare={canShare}
					documentId={documentId}
					setIsOpenShare={toggleDrawer}
					studyId={studyId}
				/>
			)}
			<Box
				sx={{
					padding: '30px 38px',
					flex: '1',
					display: 'flex',
					gap: '24px',
					flexDirection: 'column',
					height: '100%',
					[mediaMobile]: { padding: '20px 0px 0px 0px' },
				}}
			>
				{/* Contacts List */}
				{showContactsList && (
					<MobileMyContactList isContact sx={{ flex: 0 }} users={users} onClick={toggleUserSelect} />
				)}
				{/* Facilities List */}
				{showFacilitiesList && (
					<MobileMyContactList
						isFacility
						sx={{ flex: 0 }}
						users={facilities}
						onClick={toggleFacilitySelect}
					/>
				)}
				{/* Shared Contacts List */}
				{showContactsSharedList && (
					<MobileMyContactList
						isContact
						isShared
						sx={{ flex: 0 }}
						users={sharedUsers}
						onClick={toggleUserSelect}
					/>
				)}
				{/* Shared Facilities List */}
				{showFacilitiesSharedList && (
					<MobileMyContactList
						isFacility
						isShared
						sx={{ flex: 0 }}
						users={sharedFacilities}
						onClick={toggleFacilitySelect}
					/>
				)}
				{showShareButton && (
					<MobileFixedButtons
						actionButtonText={t('Share')}
						onAction={isDemoData ? null : onShareClick}
						onCancel={deselectAll}
					/>
				)}
			</Box>
		</Box>
	);
};

export default ShareContactList;
