import React from 'react';
import { Typography, styled } from '@mui/material';

export const DetailsContentRow = styled(props => <Typography {...props} />)({
	fontSize: '15px',
	lineHeight: '20px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	letterSpacing: '0.25px',
	color: 'rgba(255, 255, 255, 0.6)',
	width: '210px',
});

export const styles = {
	paper: {
		borderRadius: '16px',
		mb: '10px',
		display: 'flex',
		boxShadow: 0,
		background: 'rgba(65, 65, 65, 1)',
		p: '12px 15px',
		'&:hover': {
			background: '#333333',
		},
	},
	monthDateWrap: {
		minWidth: '40px',
		width: '40px',
	},
	month: {
		background: 'rgba(210, 124, 98, 1)',
		color: 'rgba(255, 255, 255, 1)',
		fontSize: '12px',
		height: '17px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '6px 6px 0 0',
		textTransform: 'uppercase',
	},
	date: {
		background: 'rgba(210, 124, 98, 0.2)',
		color: 'rgba(255, 255, 255, 0.6)',
		fontSize: '18px',
		fontWeight: '300',
		height: '30px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '0 0 6px 6px',
	},
	content: {
		ml: '10px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
	},
};
