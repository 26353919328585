// core
import React, { useContext, useState, useEffect, useCallback } from 'react';

// contexts
import FormContext from '../../../formContexts/FormContext';

// libraries
import { Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';

// MUI
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
// components
import FormFieldDefault from './FormFieldDefault';

// types
import FormFieldVariantType from '../../../formTypes/FormFieldVariantType';
import { useTranslation } from 'react-i18next';
import Box from '@mui/system/Box';
import { getAutocompleteClearAndPopupTooltips } from '@rs-ui/helpers/tooltipUtils.js';
import { getUniqueOrganizations } from '@rs-components/Drawers/NewOrderDrawer/utils';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const FormAPIAutocompleteVariant = ({
	formHook,
	name,
	TextFieldProps,
	props,
	buttonIcon,
	buttonLabel,
	options,
	sx,
	modifySelectedValue,
	setOptions,
	onSearch,
	onChange,
	onButtonClick,
	displayCustomDropDown,
	CustomDropDown,
	fieldValue,
	hideClear = false,
}) => {
	const [open, setOpen] = useState(false);
	const { readOnly } = useContext(FormContext);
	const [isLoading, setIsLoading] = useState(false);
	const [hideClearIcon, setHideClearIcon] = useState(hideClear);
	const [opts, setOpts] = useState([]);
	const [organizations, setOrganizations] = useState([]);
	const { control, getValues, setValue } = formHook;
	const metaEnableAiInNewOrder = useBooleanFlagValue('meta-enable-ai-in-new-order');
	const { t } = useTranslation('formapiautocompletevariant');

	useEffect(() => {
		if (!!options && Array.isArray(options)) setOpts(options);
	}, [options]);

	const onInputChange = async (event, newInputValue, reason, field, oldValue) => {
		if (metaEnableAiInNewOrder) {
			const extractedDataSelected = getValues('extractedDataSelected');

			if (extractedDataSelected && (reason === 'clear' || reason === 'input')) {
				extractedDataSelected[name] = false;
				setValue('extractedDataSelected', extractedDataSelected);
			}
			if (extractedDataSelected && extractedDataSelected[name]) {
				return;
			}
		}

		if (newInputValue) {
			!hideClear && hideClearIcon && setHideClearIcon(false);

			// Do not execute onSearch when onChange is fired - select an option in the list (event.type === 'click')
			if (event?.type === 'change') {
				setIsLoading(true);
				const res = await onSearch(newInputValue);
				setIsLoading(false);
				if (res) {
					if (name === 'orderSet') {
						const uniqueOrganizations = getUniqueOrganizations(res);
						setOrganizations(uniqueOrganizations);
					}

					setOpts(res);
					setOptions && setOptions(res);
				}
			}
		} else {
			setOpts([]);
			setOptions && setOptions([]);
			!hideClearIcon && setHideClearIcon(true);
		}

		if (props?.freeSolo && event && event?.type !== 'click') {
			// When an autocomplete field is mandatory and freeSolo, need to trigger onChange, so the free text can be validated (refer to InsurancePayerDetailForm)
			handleOnChange(event, newInputValue, reason, field, oldValue);
		}
	};

	const debounceOnInputChange = useCallback(
		debounce(
			(event, newInputValue, reason, field, oldValue) =>
				onInputChange(event, newInputValue, reason, field, oldValue),
			500
		),
		[onSearch]
	);

	const handleOnChange = async (event, newValue, reason, field, oldValue) => {
		if (metaEnableAiInNewOrder) {
			const extractedDataSelected = getValues('extractedDataSelected');

			if (extractedDataSelected) {
				extractedDataSelected[name] = false;
				setValue('extractedDataSelected', extractedDataSelected);
			}
		}

		let modifiedValue = null;
		if (modifySelectedValue) {
			modifiedValue = await modifySelectedValue(newValue, reason, oldValue);
		}

		const value = modifiedValue || newValue;

		field.onChange(value);
		onChange?.(value);
	};

	const renderInput = params => (
		<Box sx={{ position: 'relative' }}>
			<FormFieldDefault
				formHook={formHook}
				name={name}
				props={{
					...params,
					...TextFieldProps,
					InputProps: {
						...params.InputProps,
						...TextFieldProps,
						endAdornment: (
							<>
								{isLoading && <CircularProgress color="inherit" size={20} />}
								{params.InputProps.endAdornment}
							</>
						),
						...TextFieldProps?.InputProps,
					},
				}}
			/>
		</Box>
	);

	const renderButtonPaper = ({ containerProps, children }) => (
		<Paper
			sx={{
				background: '#393939',
				display: 'flex',
				flexDirection: 'column',
				border: '0px',
				'.MuiAutocomplete-noOptions': {
					fontFamily: 'fontFamily',
				},
				'.MuiAutocomplete-loading': {
					fontFamily: 'fontFamily',
				},
				'.MuiAutocomplete-option': {
					fontFamily: 'fontFamily',
				},
			}}
			{...containerProps}
		>
			{displayCustomDropDown ? (
				<CustomDropDown />
			) : (
				onButtonClick && (
					<>
						<Button
							startIcon={buttonIcon || ''}
							sx={{
								'& .MuiButton-startIcon': {},
								justifyContent: 'left',
								height: '36px',
							}}
							onMouseDown={onButtonClick}
						>
							{buttonLabel}
						</Button>
						<Divider />
						{children}
					</>
				)
			)}
		</Paper>
	);

	const groupStudySetProps =
		name === 'orderSet'
			? {
					groupBy: option => option?.organization?.id,
					renderGroup: params => {
						const orgName = organizations.find(item => String(item.id) === params.group)?.display;

						return (
							<li key={params.key}>
								<Box
									sx={{
										padding: '4px 0 4px 10px',
										backgroundColor: '#414141',
										color: 'currentColor',
									}}
								>
									{orgName}
								</Box>
								<Box sx={{ padding: '0' }}>{params.children}</Box>
							</li>
						);
					},
			  }
			: {};

	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => {
				const [oldValue, setOldValue] = useState(null);

				useEffect(() => {
					setOldValue(field.value);
					!hideClear && !field.value && setHideClearIcon(true);
				}, [field.value]);

				const isSingleOption = name === 'facility' && options?.length === 1;

				if (isSingleOption && field.value === '') {
					field.onChange(options[0]);
				}

				return (
					<Autocomplete
						data-testid={`autocomplete-field-${TextFieldProps?.label}`}
						id={`autocomplete-field-${TextFieldProps?.label}`}
						{...field}
						{...props}
						fullWidth
						disabled={readOnly || props?.disabled}
						loading={isLoading}
						open={open}
						options={opts}
						renderInput={renderInput}
						sx={{
							...sx,
							...getAutocompleteClearAndPopupTooltips({
								clearText: t('clearText'),
								openText: open ? '' : t('openText'),
							}),
						}}
						value={isSingleOption ? options[0] : field.value || fieldValue || ''}
						onChange={(event, newValue, reason) => handleOnChange(event, newValue, reason, field, oldValue)}
						onClose={() => setOpen(false)}
						onInputChange={(event, newInputValue, reason) =>
							debounceOnInputChange(event, newInputValue, reason, field, oldValue)
						}
						onOpen={() => setOpen(props?.notOpen ? !props?.notOpen : true)}
						{...groupStudySetProps}
						PaperComponent={buttonLabel && renderButtonPaper}
						clearText={null}
						closeText={t('closeText')}
						disableClearable={hideClear || hideClearIcon}
						loadingText={t('loading')}
						noOptionsText={t('noOptions')}
						openText={null}
					/>
				);
			}}
		/>
	);
};

FormAPIAutocompleteVariant.propTypes = FormFieldVariantType();

export default FormAPIAutocompleteVariant;
