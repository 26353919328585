// core
import React, { useEffect } from 'react';
// libraries
import Lottie from 'lottie-web';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAppModeContext } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';
//assets
import animation from '@worklist-2/ui/src/assets/animation/web/No Results.json';

export const NoResultsCallout = () => {
	const { appMode } = useAppModeContext();
	const { t } = useTranslation('Search');

	useEffect(() => {
		const noResultsAnimationData = document.querySelector('#NoResultsAnimationData');

		Lottie.loadAnimation({
			container: noResultsAnimationData,
			animationData: animation,
			autoplay: true,
			loop: true,
		});

		return () => {
			Lottie.destroy();
		};
	}, []);

	return (
		<Box
			sx={{
				height: appMode === 'worklist' || appMode === 'marketplace' ? '100%' : undefined,
				width: '100%',
				paddingLeft: '40px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				boxSizing: 'border-box',
			}}
		>
			<Box data-testid="NoResultsAnimationData" id="NoResultsAnimationData" />
			<Box
				sx={{
					position: 'absolute',
					marginTop: '300px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Typography
					data-testid="NoResultsFound"
					sx={{
						textAlign: 'center',
						fontWeight: '500',
						fontSie: '16px',
						marginBottom: '8px',
						color: 'text.primary',
					}}
				>
					{t('No Result Found')}
				</Typography>
				<Typography
					sx={{
						fontSize: '14px',
						color: 'text.primary',
						maxWidth: '350px',
						textAlign: 'center',
					}}
				>
					{t('Please try other key words to search again.')}
				</Typography>
			</Box>
		</Box>
	);
};
