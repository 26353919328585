import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from '@worklist-2/core/src/hooks';

const RecognitionContext = createContext({});

const WORKLIST_COMMAND_CODES = ['open study', 'go back home', 'undo report open'];

const RecognitionContextProvider = ({ children }) => {
	const [receivedTranscription, setReceivedTranscription] = useState({ transcript: '', isFinal: true });
	const [command, setCommand] = useState(null);
	const [commands, setCommands] = useState([]);
	const [language, setLanguage] = useState('');
	const [active, setActive] = useState(true);
	const [vrViaDvToolbar, setVrViaDvToolbar] = useState(false);
	const [requestDevice, toggleRequestDevice] = useState(false);
	const [getDevice, toggleGetDevice] = useState(false);
	const [activeDevice, setActiveDevice] = useState({ id: 0, name: 'Default System Microphone' });
	const [activeFootControlDevice, setActiveFootControlDevice] = useState({
		id: 0,
		name: 'Select Foot Control',
	});
	const [allDevices, setAllDevices] = useState([{ id: 0, name: 'Default System Microphone' }]);
	const [buttonEventListeners, setButtonEventListeners] = useState([]);
	const [footPedalButtonAction, setFootPedalButtonAction] = useState(null);
	const [executeRepeatedCommand, setExecuteRepeatedCommand] = useState(true);
	const [finalTranscript, setFinalTranscript] = useState('');
	const [transcript, setTranscript] = useState('');
	const [closeIsHovered, setCloseIsHovered] = useState(false);
	const [welcomeTranscription, setWelcomeTranscription] = useState(null);
	const [microphoneEnabled, setMicrophoneEnabled] = useState(null);
	const [augnitoLmid, setAugnitLmid] = useState('213803201'); // defaulting to lanugage model for US Spelling and American English Accent
	const [collapseSearchBarForVR, setCollapseSearchBarForVR] = useState(true);
	const [connectionError, setConnectionError] = useState(null);
	const [speechMicEventForRadPair, setSpeechMicEventForRadPair] = useState({ eventType: '' });
	const [speechMikeButtonEvent, setSpeechMikeButtonEvent] = useState({ device: null, bitMask: null });

	const { goTo, location } = useRouter();

	useEffect(() => {
		if (!commands.length || !receivedTranscription?.transcript) return;

		//GET THE WORKLIST COMMANDS
		const worklistCommands = commands.filter(d => WORKLIST_COMMAND_CODES.includes(d.code));

		//CHECK IF USER SPOKE A WORKLIST ACTION COMMAND
		const spoken = receivedTranscription.transcript;
		if (worklistCommands.some(d => spoken.toLowerCase().includes(d.title.toLowerCase()))) {
			const { code } = worklistCommands.find(d => spoken.toLowerCase().includes(d.title.toLowerCase()));

			switch (code) {
				case 'open study':
					if (location.pathname !== '/home') return;
					setCommand({ type: 'open study' });
					break;
				case 'go back home':
				case 'undo report open':
					goTo.home();
					break;
			}
		}
	}, [receivedTranscription, commands]);

	const triggerButtonEvent = (device, bitMask) => {
		buttonEventListeners.forEach(listener => listener(device, bitMask));
	};

	const addButtonEventListener = listener => {
		setButtonEventListeners(prevState => [...prevState, listener]);
	};

	const removeButtonEventListener = listener => {
		setButtonEventListeners(prevState => prevState.filter(l => l !== listener));
	};

	return (
		<RecognitionContext.Provider
			value={{
				receivedTranscription,
				setReceivedTranscription,
				command,
				setCommand,
				commands,
				setCommands,
				language,
				setLanguage,
				active,
				setActive,
				vrViaDvToolbar,
				setVrViaDvToolbar,
				requestDevice,
				toggleRequestDevice,
				activeDevice,
				setActiveDevice,
				allDevices,
				setAllDevices,
				getDevice,
				toggleGetDevice,
				triggerButtonEvent,
				buttonEventListeners,
				addButtonEventListener,
				removeButtonEventListener,
				activeFootControlDevice,
				setActiveFootControlDevice,
				footPedalButtonAction,
				setFootPedalButtonAction,
				executeRepeatedCommand,
				setExecuteRepeatedCommand,
				finalTranscript,
				setFinalTranscript,
				transcript,
				setTranscript,
				closeIsHovered,
				setCloseIsHovered,
				welcomeTranscription,
				setWelcomeTranscription,
				microphoneEnabled,
				setMicrophoneEnabled,
				augnitoLmid,
				setAugnitLmid,
				collapseSearchBarForVR,
				setCollapseSearchBarForVR,
				connectionError,
				setConnectionError,
				speechMicEventForRadPair,
				setSpeechMicEventForRadPair,
				speechMikeButtonEvent,
				setSpeechMikeButtonEvent,
			}}
		>
			{children}
		</RecognitionContext.Provider>
	);
};

const useRecognitionContext = () => useContext(RecognitionContext);

export { RecognitionContext, RecognitionContextProvider, useRecognitionContext };
