/* Generated automagically by FhirStarter - but please feel free to edit this file */
import React from 'react';
import ActiveIcon from '../../../../../ui/src/components/ActiveIcon/ActiveIcon';
import { fhirExtensionUrls } from '../../extension';

const WorkflowAutomationMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	active: {
		label: 'Active',
		filterType: 'none',
		getDisplay: resource => {
			return resource.active;
		},
		options: {
			customBodyRender: value => {
				return <ActiveIcon active={!!value.props.text} />;
			},
		},
	},
	enable: {
		label: 'Enable',
		filterType: 'none',
		getDisplay: resource => {
			return resource.enable;
		},
	},
	guiid: {
		label: 'guiid',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'guiid',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.guiid;
		},
	},
	// external: {
	// 	label: 'External',
	// 	filterType: 'none',
	// 	options: {
	// 		filter: false,
	// 		sort: true,
	// 		setCellHeaderProps:() => ({ style: { width: '6.5%' } }),
	// 	},
	// 	getDisplay: (resource) => {
	// 		return resource.external === true ? 'Yes' : 'No';
	// 	},
	// },
	name: {
		label: 'Workflow Automation Name',
		filterType: 'text-search',
		searchParameter: 'name',
		options: {
			filter: true,
			sort: true,
			setCellHeaderProps: () => ({ style: { width: '11%' } }),
		},
		getDisplay: resource => {
			return resource.name;
		},
	},
	managingOrganization: {
		label: 'Managing Organization',
		options: {
			filter: true,
			sort: true,
			setCellHeaderProps: () => ({ style: { width: '21%' } }),
		},
		filterType: 'suggest',
		searchParameter: 'managingorganizationid',
		suggestScope: {
			resource: 'organization',
			label: 'name',
			extraParam: {
				ismanaging: true,
				active: true,
				_summary: 'text',
				_elements: 'name',
			},
		},
		getDisplay: resource => {
			return resource.managingOrganization?.display;
		},
	},
	lastUpdateUser: {
		label: 'Destination',
		options: { filter: false, sort: false },
		searchParameter: 'lastUpdateUser',
		filterType: 'none',
		getDisplay: resource => {
			return resource.extension
				? _.find(resource.extension, extension => extension.url === fhirExtensionUrls.common.lastUpdateUser)
						?.valueReference?.display
				: '';
		},
	},
	workflow: {
		label: 'Workflow Automation Setting',
		filterType: 'text-search',
		options: {
			filter: false,
			sort: false,
			setCellHeaderProps: () => ({ style: { width: '23%' } }),
		},
		getDisplay: resource => {
			return resource.workflow;
		},
	},
	displaysetting: {
		label: 'Workflow Automation Display Setting',
		filterType: 'text-search',
		options: {
			filter: false,
			sort: false,
			setCellHeaderProps: () => ({ style: { width: '23%' } }),
		},
		getDisplay: resource => {
			return resource.displaysetting;
		},
	},
};

export default WorkflowAutomationMapping;
