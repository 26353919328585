/* Generated automagically by FhirStarter - but please feel free to edit this file */
import { fhirExtensionUrls } from '../../extension';
import React from 'react';
import ActiveIcon from '../../../../../ui/src/components/ActiveIcon/ActiveIcon';

const CodeSystemMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	active: {
		label: 'Active',
		filterType: 'none',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.codeSystem.active)?.valueString;
		},
		options: {
			customBodyRender: value => {
				return <ActiveIcon active={!!value.props.text} />;
			},
			setCellProps: () => ({ style: { width: '0%' } }),
		},
	},
	code: {
		label: 'Code',
		filterType: 'text-search',
		options: {
			filter: false,
			sort: false,
			setCellHeaderProps: () => ({ style: { width: '150px' } }),
		},
		searchParameter: 'code',
		getDisplay: resource => {
			return resource.code;
		},
	},
	display: {
		label: 'Description',
		filterType: 'text-search',
		searchParameter: 'display',
		options: {
			filter: false,
			sort: false,
		},
		getDisplay: resource => {
			return resource.display?.toUpperCase();
		},
	},
	modalityCode: {
		label: 'Code',
		filterType: 'text-search',
		options: {
			filter: false,
			sort: false,
			setCellHeaderProps: () => ({ style: { width: '50px' } }),
		},
		searchParameter: 'code',
		getDisplay: resource => {
			return resource.code;
		},
	},
	allowed_modalities: {
		label: 'Allowed Modalities',
		filterType: 'text-search',
		searchParameter: 'display',
		options: {
			filter: false,
			sort: false,
			setCellHeaderProps: () => ({ style: { width: '50px' } }),
		},
		getDisplay: resource => {
			return resource['extension'].find(ele => ele.url === fhirExtensionUrls.codeSystem.mapModalities)
				?.valueString;
		},
	},
	is_modality: {
		label: 'Modality',
		filterType: 'none',
		options: {
			filter: false,
			sort: false,
			setCellHeaderProps: () => ({ style: { width: '50px' } }),
		},
		getDisplay: resource => {
			return resource['extension'].find(ele => ele.url === fhirExtensionUrls.codeSystem.isModality)
				?.valueString === '1'
				? 'True'
				: 'False';
		},
	},
	description: {
		label: 'Description',
		filterType: 'text-search',
		searchParameter: 'description',
		options: {
			filter: false,
			sort: false,
			setCellHeaderProps: () => ({ style: { width: '25%' } }),
		},
		getDisplay: resource => {
			return resource['extension']
				.find(ele => ele.url === fhirExtensionUrls.codeSystem.modalityDescription)
				?.valueString?.toUpperCase();
		},
	},
	is_schedule: {
		label: 'Schedule',
		filterType: 'none',
		options: {
			filter: false,
			sort: false,
			setCellHeaderProps: () => ({ style: { width: '50px' } }),
		},
		getDisplay: resource => {
			return resource['extension'].find(ele => ele.url === fhirExtensionUrls.codeSystem.isSchedule)
				?.valueString === '1'
				? 'True'
				: 'False';
		},
	},
};

export default CodeSystemMapping;
