import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const MergeDrawerCardsWrap = styled(Box)(() => ({
	flex: '1 0 auto',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	height: '100%',
	overflow: 'scroll',
	scrollbarWidth: 'none',
	msOverflowStyle: 'none',

	'&::-webkit-scrollbar': {
		display: 'none',
	},
}));

export const MergeDrawerContentSection = styled(Box)(() => ({
	flex: '1 0 auto',
	height: '69%',
	paddingTop: '20px',
}));
