// core
import React, { useState, useEffect, useCallback } from 'react';
// mui
import { Divider, Stack, Box, Switch, FormHelperText, Typography, TextField, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
// components
import Suggest from '@worklist-2/ui/src/components/Suggest';
import SingleSelect from '@worklist-2/ui/src/components/SingleSelect';
import SecondaryButton from '@worklist-2/ui/src/components/SecondaryButton';
import PrimaryButton from '@worklist-2/ui/src/components/PrimaryButton';
import AddNewButton from '@worklist-2/ui/src/components/AddNewButton';
import { fhirExtensionUrls } from '@worklist-2/core/src/fhir/extension';
import { searchScopes, useFhirDataLoader, useConfig, getExtensionValueString } from '@worklist-2/core/src';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const useAlertIconStyles = makeStyles()({
	cookieAlert: {
		backgroundColor: '#393939',
		color: '#FFFFFF',
		borderTopLeftRadius: '20px',
		borderTopRightRadius: '20px',
		'& .MuiAlert-icon': {
			fontSize: 20,
		},
	},
});
const SendStudyTab = ({ studies, setToast, onCancel, sendPermissions }) => {
	const { t } = useTranslation('sendStudy');

	const externalUserPermission = sendPermissions ? sendPermissions[0]?.['send study']?.['external user'] : null;
	const { classes: alertClass } = useAlertIconStyles();
	const __config = useConfig();
	const [organization, setOrganization] = useState(null);
	const [device, setDevice] = useState(null);
	const [selectedSendTo, setSelectedSendTo] = useState(null);
	const [listEmail, setListEmail] = useState([{ key: uuid(), value: '', error: null }]);
	const [anonymized, setAnonymized] = useState(false);
	const fhirDataLoaderTask = useFhirDataLoader({
		scope: searchScopes.task,
	});
	const [dataLoaderTask] = useState(fhirDataLoaderTask);
	const proactEnableUacHomeSend = useBooleanFlagValue('proact-enable-uac-home-send');
	const [disableSubmit, setDisableSubmit] = useState(true);

	const [canPriority, setCanPriority] = useState(false);
	const [priority, setPriority] = useState('Low');
	const [canImagingOrganization, setCanImagingOrganization] = useState(false);

	const emailRegex =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	const sendToOptions = [
		{
			key: 1,
			label: t('Imaging Organization'),
			value: 'ImageOrganization',
		},
		...(externalUserPermission?.read && studies && studies.length === 1
			? [{ key: 2, label: t('External User'), value: 'ExternalUser' }]
			: []),
	];

	const saveData = useCallback(async (loader, props, payload) => {
		let result = {};
		setToast(true, 'Preparing to send study');
		try {
			result = await loader.save(props, payload).then(res => {
				setToast(true, 'Your study has been added to the queue');
				return res.data;
			});
		} catch (e) {
			setToast(true, 'Failed to queue the study for sending');
		}
		return result;
	}, []);

	const handleAddEmail = useCallback(() => {
		const newArray = [...listEmail];

		newArray.unshift({ key: uuid(), value: '', error: null });
		setListEmail(newArray);
	}, [listEmail]);

	const handleInputEmail = useCallback(
		(newValue, index) => {
			const newArray = [...listEmail];

			newArray[index].value = newValue;
			setListEmail(newArray);
		},
		[listEmail]
	);

	const handleImagingOrgSelect = useCallback((valueField, searchValue, optionId, record) => {
		setOrganization(record);
	}, []);

	const handlePrioritygSelect = useCallback(value => {
		setPriority(value);
	}, []);

	const handleSendToSelect = useCallback(value => {
		setSelectedSendTo(value);
		setDisableSubmit(true);
	}, []);

	const switchHandler = e => {
		setAnonymized(e.target.checked);
	};

	const onDeviceSelect = (valueField, searchValue, optionId, record) => {
		setDevice(record);
	};

	const handleDeleteEmail = deleteIndex => {
		const updatedEmails = listEmail.filter((value, index) => index !== deleteIndex);
		setListEmail(updatedEmails);
	};

	const onSubmit = useCallback(
		async (data, e) => {
			for (let index = 0; index < studies.length; index++) {
				const study = studies[index];
				if (selectedSendTo == 'ExternalUser') {
					const issuerOfPatientID = study.rawData?.extension?.find(
						e => e.url === fhirExtensionUrls.organization.issuer
					)?.valueReference?.display;

					const jsonData = {
						resourceType: 'PublicLink',
						studyId: study.rawData.id,
						internalPatientID: study.rawData.subject.id,
						patientInfoID: study.rawData.patientID,
						patientDOB: study.rawData.birthDate,
						studyUID: study.rawData.identifier[0]?.value.replace(/^(urn:oid:)/, ''),
						issuerOfPatientID,
						internalManagingOrganizationID: study.rawData.internalManagingOrganizationID,
						managingOrganizationName: study.rawData.managingOrganization,
						recipient: listEmail
							.filter(email => !!email.value && email.value.match(emailRegex))
							.map(elm => elm.value),
					};

					setToast(true, t('Sending Study'));
					await axios.post(`${__config.data_sources.fhir}/PublicLink`, jsonData).then(res => {
						if (res.status != 201) {
							setToast(true, res.data?.issue[0]?.diagnostics);
						} else {
							setToast(true, t('Study has been sent to external user'));
						}
					});

					onCancel();
				} else {
					const targetAeTitle = _.find(device.extension, [
						'url',
						fhirExtensionUrls.device.aeTitle,
					])?.valueString;

					const targetPort = _.find(device.extension, [
						'url',
						fhirExtensionUrls.device.dicomServerPort,
					])?.valueString;

					const targetHostName = _.find(device.extension, [
						'url',
						fhirExtensionUrls.device.dicomServerHostName,
					])?.valueString;

					const accession = study.rawData.identifier.find(
						elem => elem.type && elem.type.coding && elem.type.coding.some(item => item.code === 'ACSN')
					);

					let extension = null;

					for (const ext of device.extension) {
						if (
							ext.url === 'http://www.ramsoft.com/fhir/dicomserverconfig/associateddevice' ||
							ext.url === 'http://www.ramsoft.com/fhir/dicomserverconfig/managingorganization'
						) {
							extension = ext;
							break;
						}
					}
					if (extension) {
						const taskPayload = {
							id: '-3',
							resourceType: 'Task',
							status: 'requested',
							intent: 'plan',
							priority: priorityList.find(p => p.value === priority)?.mapping,
							note: [
								{
									text: getExtensionValueString(
										study.rawData,
										fhirExtensionUrls.imagingStudy.notes.clinical
									),
								},
							],
							code: {
								coding: [
									{
										code: 'approve',
										display: 'Activate/approve the focal resource  ',
									},
								],
							},
							reasonCode: {
								text: 'DicomStore',
							},
							for: {
								id: device.id,
								reference: `Device/${device.id}`,
								display: device.distinctIdentifier,
								type: device.rawData.type.coding[0].code,
								extension: [
									{
										url: 'http://www.ramsoft.com/fhir/StructureDefinition/ForAETitle',
										valueString: targetAeTitle,
									},
									{
										url: 'http://www.ramsoft.com/fhir/StructureDefinition/PeerPort',
										valueString: targetPort,
									},
									{
										url: 'http://www.ramsoft.com/fhir/StructureDefinition/PeerHost',
										valueString: targetHostName,
									},
									{
										url: 'http://www.ramsoft.com/fhir/StructureDefinition/UseTLS',
										valueBoolean: false,
									},
									{
										url: 'http://www.ramsoft.com/fhir/extension/DeviceType',
										valueString: device.rawData.type.coding[0].code,
									},
									{
										url: 'http://www.ramsoft.com/fhir/dicomserverconfig/authenticationtype',
										valueInteger: 2,
									},
									{
										url: 'http://www.ramsoft.com/fhir/dicomserverconfig/coerceinfo',
										valueBoolean: true,
									},
								],
							},
							peerHost: targetHostName,
							owner: {
								reference: `Device/${extension.valueReference.id}`,
								id: extension.valueReference.id,
								display: extension.valueReference.display,
							},
							requester: {
								display: 'ADMIN', //to do
							},
							input: [
								{
									type: {
										text: 'QueryRetrieveInformationModelSOPClassUID',
									},
									valueString: '',
								},
								{
									type: {
										text: 'DicomJson',
									},
									valueString: '',
								},
								{
									type: {
										text: 'StudyInstanceUID',
									},
									valueString: study.rawData.identifier[0]?.value.replace(/^(urn:oid:)/, ''),
								},
								{
									type: {
										text: 'Anonymize',
									},
									valueBoolean: anonymized,
								},
							],
							extension: [
								{
									url: 'http://www.ramsoft.com/fhir/extension/organization',
									valueReference: {
										id: study.rawData.internalManagingOrganizationID,
										reference: `Organization/${study.rawData.internalManagingOrganizationID}`,
										display: study.rawData.managingOrganization,
									},
								},
								{
									url: 'http://www.ramsoft.com/fhir/extension/imagingOrganization',
									valueReference: {
										id: study.rawData.imagingOrganizationId,
										reference: `imagingorganization/${study.rawData.imagingOrganizationId}`,
										display: study.rawData.imagingOrganization,
									},
								},
								{
									url: 'http://www.ramsoft.com/fhir/extension/Patient',
									valueReference: study.rawData.subject,
								},
								{
									url: 'AccessionNumber',
									valueReference: {
										id: accession.value,
										reference: `accessNumber/${accession.value}`,
										display: accession.value,
									},
								},
								{
									url: 'issuer',
									valueReference: {
										id: study.rawData.imagingOrganizationId, //to do
										reference: `issuer/${study.rawData.internalManagingOrganizationID}`,
										display: study.rawData.managingOrganization,
									},
								},
								{
									url: 'VisitNumber',
									valueString: study.rawData.visitNumber,
								},
								{
									url: 'StudyDateTime',
									valueDateTime: study.rawData.started,
								},
								{
									url: 'PatientID',
									valueString: study.rawData.patientID,
								},
								{
									url: 'InternalStudyID',
									valueString: study.rawData.id,
								},
								{
									url: fhirExtensionUrls.task.studyDescription,
									valueString: study.rawData.description,
								},
							],
							encounter: {
								id: study.rawData.encounter.id,
							},
						};

						setToast(true, 'Preparing to send study');
						await saveData(dataLoaderTask, {}, taskPayload);
					}
				}
			}

			onCancel();
		},
		[device, organization, selectedSendTo, listEmail, anonymized, priority]
	);

	useEffect(() => {
		if (selectedSendTo == 'ExternalUser') {
			if (
				listEmail.length > 0 &&
				listEmail.find(e => !!e.value && emailRegex.test(String(e.value).toLowerCase()))
			) {
				setDisableSubmit(false);
			} else {
				setDisableSubmit(true);
			}
		} else if (!!organization && !!device) {
			setDisableSubmit(false);
		} else {
			setDisableSubmit(true);
		}
	}, [selectedSendTo, organization, device, listEmail]);

	useEffect(() => {
		if (sendPermissions && sendPermissions.length > 0) {
			sendPermissions.forEach(permission => {
				if (permission?.['send study']?.priority?.read) {
					setCanPriority(true);
				}
				if (permission?.['send study']?.['imaging organization']?.read) {
					setCanImagingOrganization(true);
				}
			});
		}
	}, [sendPermissions]);

	const priorityList = [
		{ label: t('Low'), value: 'Low', mapping: 'routine' },
		{ label: t('Medium'), value: 'Medium', mapping: 'urgent' },
		{ label: t('High'), value: 'High', mapping: 'asap' },
		{ label: t('Highest'), value: 'Highest', mapping: 'stat' },
	];

	const handleBlurEmail = (e, index) => {
		const { value } = e.target;
		const newArray = [...listEmail];
		if (value && !emailRegex.test(String(value).toLowerCase())) {
			newArray[index].error = t('Invalid Email address');
		} else {
			newArray[index].error = null;
		}
		setListEmail(newArray);
	};

	return (
		<>
			<Box>
				<Stack
					flexGrow={1}
					spacing={4}
					sx={{
						py: 4,
						padding: '16px 40px',
						overflow: 'auto',
						'.MuiTypography-root': {
							margin: '5px 0px 0px 0px',
							fontSize: '12px',
							color: 'rgba(255, 255, 255, 0.6)',
						},
					}}
				>
					<SingleSelect
						fullWidth
						itemList={sendToOptions}
						label={t('Send to')}
						testId="SendDrawerSendTo"
						onSelect={handleSendToSelect}
					/>
					<Typography>{t('Required')}</Typography>
					{selectedSendTo == 'ExternalUser' ? (
						<>
							<SingleSelect
								key="ExternalUserSendMethod"
								fullWidth
								itemList={[{ label: t('Email'), value: 'Email' }]}
								label={t('Send Method')}
							/>
							<Typography>{t('Required')}</Typography>
						</>
					) : (
						<>
							<SingleSelect
								key="OrgSendMethod"
								fullWidth
								readOnly
								itemList={[
									{
										key: 1,
										label: t('DICOM Send'),
										value: 'DicomSend',
									},
								]}
								label={t('Send Method')}
							/>
							<Typography>{t('Read-only')}</Typography>
						</>
					)}
					<Divider />
				</Stack>
				{selectedSendTo == 'ExternalUser' ? (
					<Stack
						flexGrow={1}
						spacing={4}
						sx={{
							py: 4,
							padding: '16px 40px',
							overflowX: 'hidden',
							overflowY: 'auto',
							height: '270px',
							'.MuiFormHelperText-root': {
								marginLeft: '0px',
							},
						}}
					>
						<AddNewButton key="addNewButton" width={405} onClick={handleAddEmail} />
						{listEmail.map((elm, index) => (
							<TextField
								key={elm.key}
								InputProps={{
									endAdornment: (
										<IconButton onClick={() => handleDeleteEmail(index)}>
											<CloseIcon
												sx={{
													pointerEvents: 'none',
													color: 'rgba(255,255,255,0.6)',
													fontSize: '22px',
													marginLeft: 'auto',
												}}
											/>
										</IconButton>
									),
								}}
								data-testid="SendToExternalUserEmail"
								defaultValue={elm.value}
								error={!!elm.error}
								helperText={elm.error ? elm.error : t('Required')}
								label={t('Email')}
								type="email"
								onBlur={e => handleBlurEmail(e, index)}
								onChange={e => {
									handleInputEmail(e.target.value, index);
								}}
							/>
						))}
					</Stack>
				) : (
					<>
						<Stack
							flexGrow={1}
							spacing={4}
							sx={{
								py: 4,
								padding: '16px 40px',
								overflow: 'auto',
								'.MuiTypography-root': {
									margin: '5px 0px 0px 0px',
									fontSize: '12px',
									color: 'rgba(255, 255, 255, 0.6)',
								},
							}}
						>
							{!proactEnableUacHomeSend || canPriority ? (
								<>
									<SingleSelect
										fullWidth
										hideIcon
										itemList={priorityList}
										label={t('Priority')}
										testId="SendStudyPriority"
										onSelect={handlePrioritygSelect}
									/>
									<Typography>{t('Required')}</Typography>
								</>
							) : null}

							{!proactEnableUacHomeSend || canImagingOrganization ? (
								<>
									<Suggest
										fullWidth
										required
										disabled={false}
										label="ImagingOrganization"
										listSearchScope={{
											resource: 'organization',
											label: 'name',
											extraParam: {
												isimaging: true,
												active: true,
												_summary: 'text',
												_elements: 'name',
											},
										}}
										optionId="text.div"
										placeholder={t('Search')}
										testid="SendStudyImagingOrg"
										text={t('Imaging Organization')}
										onSelect={handleImagingOrgSelect}
									/>
									<Typography>{t('Required')}</Typography>
								</>
							) : null}

							{!proactEnableUacHomeSend || canImagingOrganization ? (
								<>
									<Suggest
										fullWidth
										required
										disabled={false}
										label={t('Destination (Device)')}
										listSearchScope={{
											resource: 'device',
											label: 'deviceName',
											searchKey: 'distinctIdentifier',
											extraParam: {
												isimaging: true,
												active: true,
												type: 'dicom-entity',
												managingorganization: organization && organization.id,
												_summary: false,
												_elements: 'name',
											},
										}}
										optionId="text.div"
										placeholder={t('Search')}
										testid="SendStudyDevice"
										text={t('Destination (Device)')}
										onSelect={onDeviceSelect}
									/>
									<Typography>{t('Required')}</Typography>
								</>
							) : null}
						</Stack>
						<Box
							component="div"
							sx={{
								color: 'primary.contrastText',
								flexGrow: 2.3,
								ml: 2.5,
								marginLeft: '42px',
							}}
						>
							<>
								<FormHelperText
									style={{
										position: 'relative',
										top: -8,
										marginLeft: '32px',
										display: 'contents',
									}}
								>
									{t('Anonymize Study')}
								</FormHelperText>
								<Switch color="info" onChange={switchHandler} />
							</>
						</Box>
					</>
				)}
			</Box>
			<Box
				sx={{
					position: 'fixed',
					bottom: 0,
					flexDirection: 'column',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: 487,
				}}
			>
				{selectedSendTo == 'ExternalUser' ? (
					<Box
						sx={{
							width: '100%',
						}}
					>
						<Alert className={alertClass.cookieAlert} severity="warning">
							<span style={{ fontSize: '12px' }}>
								{t('External User')}
								<br />
								<br />
								{t('notPartOfOrganizationMessage')}
								<br />
								<br />
								{t('anyoneWithLinkMessage')}
							</span>
						</Alert>
					</Box>
				) : null}
				<Box
					sx={{
						flexDirection: 'row',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						py: '20px',
						px: '48px',
						width: '391px',
					}}
				>
					<SecondaryButton label={t('CANCEL')} testId="SendStudyCancel" onClick={onCancel} />
					<PrimaryButton
						className="send-button"
						disabled={disableSubmit}
						label={t('SEND STUDY')}
						onClick={onSubmit}
					/>
				</Box>
			</Box>
		</>
	);
};

export default SendStudyTab;
