import { create } from 'zustand';
import _ from 'lodash';
import moment from 'moment';

import { scanTypes } from '../components/Appointment/AppointmentUtils/AppointmentUtils';
import getOrganizations from '../api/getOrganizations';
import getOrgModality from '../api/getOrgModality';
import getOrgImagingCenters from '../api/getOrgImagingCenters';
import getAvailableTimeslots from '../api/getAvailableTimeslots';
import bookSelfSchedulingAppointment from '../api/bookSelfSchedulingAppointment';
import getLocationData from '../utils/getLocationData';
import { commonMiddlewares } from './middlewares';

import { fhirExtensionUrls } from '@worklist-2/core/src';

export const useScheduleStore = create(
	commonMiddlewares((set, get) => ({
		// State
		__config: null,
		accountExists: false,
		appointmentBooked: false,
		currentStep: 0,
		isLoading: false,
		locations: [],
		modalities: [],
		timeslots: [],
		orgDetails: null,
		patientList: [],
		steps: [
			{ id: 1, completed: false },
			{ id: 2, completed: false },
			{ id: 3, completed: false },
			{ id: 4, completed: false },
			{ id: 5, completed: false },
		],

		// Actions
		nextStep: () => {
			const { steps, currentStep } = get();
			const next = currentStep + 1;

			if (next > steps.length - 1) return;

			set(
				() => ({
					currentStep: next,
					steps: steps.map(step => (currentStep === step.id ? { ...step, completed: true } : step)),
				}),
				false,
				'scheduling/nextStep'
			);
		},

		prevStep: () => {
			const { currentStep } = get();

			if (currentStep === 0) return;

			set(() => ({ currentStep: currentStep - 1 }), false, 'scheduling/prevStep');
		},

		setModalities: list => {
			if (!list || !Array.isArray(list)) return;
			set(() => ({ modalities: [...list] }), false, 'scheduling/setModalities');
		},

		jumpToStep: step => {
			const { steps } = get();

			if (step > steps.length - 1 || step < 0) return;

			set(() => ({ currentStep: step }), false, 'scheduling/jumpToStep');
		},

		setPatientList: list => {
			set(() => ({ patientList: list }), false, 'scheduling/setPatientList');
		},

		setSharedStates: ({ __config }) => set(() => ({ __config }), false, 'scheduling/setSharedStates'),

		getOrgDetails: async ({ orgId }) => {
			try {
				const timezoneExtUrl = fhirExtensionUrls.organization.timezone;
				const timezoneIANAExtUrl = fhirExtensionUrls.organization.ianaTimezone;

				if (!orgId) return;

				const { __config } = get();

				const result = await getOrganizations({ __config, orgIds: orgId });
				const org = result?.entry[0]?.resource;

				const orgDetails = {
					id: org.id,
					name: org.name,
					email: _.get(_.find(org.telecom, { system: 'email' }), 'value', ''),
					fax: _.get(_.find(org.telecom, { system: 'fax' }), 'value', ''),
					phone: _.get(_.find(org.telecom, { system: 'phone' }), 'value', ''),
					website: _.get(_.find(org.telecom, { system: 'url' }), 'value', ''),
					timezone: _.get(_.find(org.extension, { url: timezoneExtUrl }), 'valueString', ''),
					timezoneIANA: _.get(_.find(org.extension, { url: timezoneIANAExtUrl }), 'valueString', ''),
					active: org.active,
				};

				set(() => ({ orgDetails }), false, 'scheduling/getOrgDetails');
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		getModalities: async ({ orgId }) => {
			try {
				if (!orgId) return;

				set(() => ({ isLoading: true }), false, 'scheduling/getModalities');

				const { __config } = get();

				const result = await getOrgModality({ __config, orgId });

				const orgModalities = result?.entry?.flatMap(item =>
					item?.resource?.extension?.flatMap(ext => {
						if (ext?.url?.includes('StructureDefinition/modality')) {
							return ext?.extension?.map(m => m.valueCode).filter(Boolean) || [];
						}

						return [];
					})
				);

				const modalities = _.flatMap(_.uniq(orgModalities), item =>
					scanTypes.filter(scanType => scanType.DicomModalities.includes(item))
				);

				set(
					() => ({ modalities: _.uniqBy(modalities, 'Name'), isLoading: false }),
					false,
					'scheduling/getModalities'
				);
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		getOrgImagingCenterLocations: async ({ orgId, modality, lat, long }) => {
			try {
				if (!orgId || !modality) return;

				set(() => ({ isLoading: true }), false, 'scheduling/getOrgImagingCentersLocations');

				const { __config } = get();

				const result = await getOrgImagingCenters({
					__config,
					orgId,
					modality,
					// lat,
					// long,
					// ! Test Toronto
					lat: '43.653226',
					long: '-79.3831843',
				});

				if (!result.length) throw new Error('No imaging center locations found!');

				// Convert the data into the desired format
				const locations = result.map(item => {
					const locationData = getLocationData(item.extJson);

					return {
						name: locationData.name,
						address: locationData.address,
					};
				});

				set(() => ({ locations, isLoading: false }), false, 'scheduling/getOrgImagingCentersLocations');
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		getAvailableTimeslots: async ({ orgId, modality, date }) => {
			try {
				if (!orgId || !modality || !date) return;

				const { __config } = get();

				set(() => ({ isLoading: true }), false, 'scheduling/getTimeslots');

				const result = await getAvailableTimeslots({ __config, orgId, modality, date });

				const timeslots = result.map((slot, index) => {
					const startTime = moment().startOf('day').add(slot.start, 'minutes').format('hh:mm A');
					const endTime = moment().startOf('day').add(slot.end, 'minutes').format('hh:mm A');

					return {
						...slot,
						id: index + 1,
						selected: false,
						time: `${startTime} - ${endTime}`,
					};
				});

				set(() => ({ timeslots, isLoading: false }), false, 'scheduling/getTimeslots');
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		checkAccountExists: async ({ phone }) => {
			try {
				if (!phone) return;

				// set(() => ({ isLoading: true }));

				// const { __config } = get();

				// set(() => ({ isLoading: false, accountExists: true }), false, 'scheduling/checkAccountExists');
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		bookAppointment: async ({ data }) => {
			try {
				if (!data) return null;

				set(() => ({ isLoading: true }), false, 'scheduling/bookAppointment');

				const { __config } = get();

				await bookSelfSchedulingAppointment({ __config, data });

				set(() => ({ isLoading: false, appointmentBooked: true }), false, 'scheduling/bookAppointment');
			} catch (err) {
				console.error(err);
				throw err;
			}
		},
	}))
);
