import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useImageViewerExternalContext } from '../../ImageViewerExternal/ImageViewerExternalContext';
import { useSearchParams } from 'react-router-dom';
import { useConfig, useAppModeContext } from '@worklist-2/core/src';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import { generateThumbnail } from '../../features/Import/utils/importUtils';
import { useImageViewerSeriesNavigatorContext } from '../../contexts/ImageViewerSeriesNavigatorContext';

const ImageRender = ({
	studyUid,
	seriesUid,
	instanceSOPUID,
	managingOrganizationId,
	onBlobGenerated,
	onError = () => {},
}) => {
	const __config = useConfig();
	const [image, setImage] = useState(null);
	const [searchParams] = useSearchParams();
	const { isPatientPortalMode } = useAppModeContext();
	const isBlumeApp = isPatientPortalMode();
	const { cachedThumbnailsRef } = useImageViewerSeriesNavigatorContext();

	const { creatorUserId, isUploaded, internalManagingOrganizationID } = useImageViewerExternalContext();

	const generateImage = async () => {
		let url = `${__config.data_sources.dicom_web}/studies/${studyUid}/series/${seriesUid}/instances/${instanceSOPUID}/thumbnail?BlumePatientId=${creatorUserId || null}`;
		if (isBlumeApp) {
			url = `${__config.data_sources.blume}/studies/${studyUid}/series/${seriesUid}/instances/${instanceSOPUID}/thumbnail?creatorBlumeUserId=${creatorUserId}&isUploaded=${isUploaded}`;
		}
		const cached = cachedThumbnailsRef?.current[url];

		if (cached) {
			setImage(cached);
			onBlobGenerated && onBlobGenerated(cached);

			return;
		}

		try {
			const response = await axios.get(url, {
				responseType: 'arraybuffer',
				headers: {
					Accept: 'image/jpeg',
					'ramsoft-internalmanagingorganizationid':
						managingOrganizationId ||
						internalManagingOrganizationID ||
						searchParams.get('internalManagingOrganizationID'),
				},
			});
			if (response.data) {
				const blob = new Blob([response.data], { type: 'image/jpeg' });
				const imageSrc = await generateThumbnail(blob, [100, 100]);
				setImage(imageSrc);
				onBlobGenerated && onBlobGenerated(imageSrc);

				if (cachedThumbnailsRef?.current) {
					cachedThumbnailsRef.current[url] = imageSrc;
				}
			}
		} catch (error) {
			onError();
		}
	};

	useEffect(() => {
		if (studyUid && seriesUid && instanceSOPUID) generateImage();
	}, [studyUid, seriesUid, instanceSOPUID]);

	return image ? (
		<Box
			component="img"
			data-testid="image-preview-thumb"
			src={image}
			sx={{
				objectFit: 'contain',
				height: '100%',
				width: '100%',
				pointerEvents: 'none',
			}}
			onDragStart={e => {
				e.preventDefault();
			}}
		/>
	) : (
		<Skeleton
			component="div"
			data-testid="image-renderer-skeleton"
			sx={{
				objectFit: 'contain',
				height: '100%',
				width: '100%',
			}}
			variant="rectangular"
		/>
	);
};

export default ImageRender;
