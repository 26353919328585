// Please consider workflow automation feature when changing this
const AppointmentStatusOptions = [
	{ label: 'All', status: '', icon: 'all', iconColor: '#FFFFFF', id: 'all' },
	{
		label: 'Requested',
		status: 'proposed',
		icon: 'requested',
		iconColor: '#FFC045',
		id: 'requested',
	},
	{
		label: 'Scheduled',
		status: 'pending',
		icon: 'scheduled',
		iconColor: '#FE9C7E',
		id: 'scheduled',
	},
	{
		label: 'Confirmed',
		status: 'booked', // todo: need proper mapping defined
		icon: 'confirmed',
		iconColor: '#93B7FF',
		id: 'confirmed',
	},
	// The status must be properly mapped based off of the Fhir Standard
	// {
	// 	label: 'Pending',
	// 	status: 'pending',
	// 	icon: 'statuspending',
	// 	iconColor: '#ED9C25',
	// },
	// {
	// 	label: 'Approved',
	// 	status: 'approved',
	// 	icon: 'approved',
	// 	iconColor: '#42A5F5',
	// },
	{
		label: 'Arrived',
		status: 'arrived',
		icon: 'arrived',
		iconColor: '#48C496',
		id: 'arrived',
	},
	{
		label: 'Ready for Scan',
		status: 'checked-in',
		icon: 'readyforscan',
		iconColor: '#25BDED',
		id: 'readyForScan',
	},
	{
		label: 'No Show',
		status: 'noshow',
		icon: 'noshow',
		iconColor: '#ED9C25',
		id: 'noShow',
	},
	{
		label: 'Cancelled',
		status: 'cancelled',
		icon: 'statuscancel',
		iconColor: '#CF6697',
		id: 'cancelled',
	},
	{
		label: 'Completed',
		status: 'fulfilled',
		icon: 'confirmed',
		iconColor: '#3731CC',
		id: 'fulfilled',
		ignore: true,
	},
];

export default AppointmentStatusOptions;
