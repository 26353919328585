import { isDateValid } from './dateUtils';

// Converting a date string without timezone will give utc time;
// when that date is converted into localetime, the date will be shifted one day back.
// This function fixes that issue
export const convertUtcBirthDateToLocaleDate = birthDate => {
	const utcBirthDate = new Date(birthDate);
	return new Date(utcBirthDate.getTime() + utcBirthDate.getTimezoneOffset() * 60000);
};

export const getReadableBirthDate = birthDate => {
	if (!birthDate || !isDateValid(new Date(birthDate))) return 'N/A';
	return convertUtcBirthDateToLocaleDate(birthDate).toLocaleDateString();
};
