import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GenericDrawer from '../GenericDrawer';

import { MergeDrawerCardsWrap, MergeDrawerContentSection } from './styles';
import {
	MergePatientDrawerCardV2,
	MergeDrawerButtons,
	MergePatientDrawerBasicTooltip,
	MergePatientDrawerMatchNameTooltip,
} from './partials';

import { useSelection, getUserFullName } from '@worklist-2/core/src';
import { useMergePatient } from './hooks';

const drawerWidth = 487;

const findDifferentFields = array => {
	const fieldNames = [
		'patientName',
		'managingOrganization',
		'patientID',
		'birthDate',
		'gender',
		'patientAddress',
		'patientEmail',
		'patientLastName',
		'patientFirstName',
		'state',
		'country',
		'city',
	];
	const differentFields = [];

	for (const fieldName of fieldNames) {
		const valueSet = new Set();

		for (const item of array) {
			const value = fieldName === 'patientName' ? item.subject?.display : item[fieldName];
			valueSet.add(value ? value.toLowerCase() : value);
			if (valueSet.size > 1) {
				differentFields.push(fieldName);
				break;
			}
		}
	}

	return differentFields;
};

export const getSelectedStudiesToRemove = (selectedResources, mergeStudyId) =>
	selectedResources.filter(s => s.id !== mergeStudyId).map(s => ({ id: s.id }));

export const MergePatientDrawerV2 = ({
	drawerOpen,
	setDrawerOpen,
	t,
	toastUtility,
	setStudiesToRemove,
	...otherProps
}) => {
	const {
		selectedResources,
		selectedMergeStudy,
		handleResourceReset,
		selectedMergeStudyModified,
		modifySelectedMergeStudy,
		selectedResourcesHelper,
		updateResourcesHelper,
		replacedFields,
	} = useSelection();

	const [isMergeAlreadyClicked, setMergeAlreadyClicked] = useState(false);

	const getPatientFirstAndLastNames = name => {
		if (!name || name.length === 0) return ['', ''];

		const nameSlices = getUserFullName(name).split(' ');

		return nameSlices.length > 1 ? [...nameSlices] : [name, ''];
	};

	useEffect(() => {
		const updated = selectedResources.map((it, idx) => {
			const [firstName, lastName] = getPatientFirstAndLastNames(it.subject?.display);
			it.patientFirstName = firstName;
			it.patientLastName = lastName;
			it.city = `Mock City ${idx}`;
			it.country = `Mock Country ${idx}`;
			return it;
		});
		updateResourcesHelper(updated);
	}, [selectedResources]);

	const isCardListVisible = selectedResourcesHelper.length > 0;

	const differentFields = useMemo(() => findDifferentFields(selectedResourcesHelper), [selectedResourcesHelper]);

	const mergePatient = useMergePatient();

	const handleMerge = () => {
		if (!isMergeAlreadyClicked && differentFields.length) {
			setMergeAlreadyClicked(true);

			mergePatient(selectedResources, selectedMergeStudy, replacedFields)
				.then(res => {
					if (res?.status !== 200) throw new Error();

					toastUtility(true, t('workList:mergePatientToast'));

					handleResourceReset();
					updateResourcesHelper([]);
					const studiesToRemove = getSelectedStudiesToRemove(selectedResources, selectedMergeStudy.id);
					setStudiesToRemove(studiesToRemove);

					setMergeAlreadyClicked(false);
					setDrawerOpen(false);
				})
				.catch(err => {
					toastUtility(true, t('Failed to merge patients'));
					setMergeAlreadyClicked(false);
				});
		}
	};

	const renderCards = useCallback(
		() =>
			selectedResourcesHelper.map((i, idx) => {
				if (selectedMergeStudyModified && selectedMergeStudyModified.studyID === i.studyID) {
					return (
						<MergePatientDrawerCardV2
							key={selectedMergeStudyModified.id}
							cardStyles={{ width: '75%', mb: '17px' }}
							data={selectedMergeStudyModified}
							differentFields={differentFields}
							modifySelectedMergeStudy={modifySelectedMergeStudy}
							t={t}
						/>
					);
				}
				return (
					<MergePatientDrawerCardV2
						key={i.id + idx}
						cardStyles={{ width: '75%', mb: '17px' }}
						data={i}
						differentFields={differentFields}
						modifySelectedMergeStudy={modifySelectedMergeStudy}
						t={t}
					/>
				);
			}),
		[selectedMergeStudyModified, selectedResources, selectedResourcesHelper]
	);

	return (
		<>
			{isCardListVisible && (
				<GenericDrawer
					headerDividerFlag
					showBackBtn
					customHeaderTextProps={{
						variant: 'h6',
						sx: {
							fontSize: '20px',
							lineHeight: '24px',
							letterSpacing: '0.15px',
							mr: 'auto',
						},
					}}
					drawerOpen={drawerOpen}
					setDrawerOpen={setDrawerOpen}
					title={t('workList:selectedResourcesTooltip.mergePatient')}
					width={drawerWidth}
					{...otherProps}
				>
					<MergeDrawerContentSection>
						<MergeDrawerCardsWrap>
							{selectedResourcesHelper.length > 0 && renderCards()}
						</MergeDrawerCardsWrap>
					</MergeDrawerContentSection>

					<MergeDrawerButtons
						isActive={selectedMergeStudy}
						t={t}
						tooltip={
							isMergeAlreadyClicked || !differentFields.length
								? MergePatientDrawerBasicTooltip
								: MergePatientDrawerMatchNameTooltip
						}
						onSubmit={handleMerge}
					/>
				</GenericDrawer>
			)}
		</>
	);
};

MergePatientDrawerV2.propTypes = {
	/**
	 * Lifted State - determines if the drawer is open or not
	 */
	drawerOpen: PropTypes.bool,
	/**
	 * Setter function for the drawerOpen state
	 */
	setDrawerOpen: PropTypes.func,
};
