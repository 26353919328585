import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

import { searchScopes, useAuth, useFhirDataLoader, useSelection } from '@worklist-2/core/src';
import { MergePatientIcon, MergeStudyIcon } from './SelectedResourcesIcons';
import { SelectedResourcesButton } from './SelectedResourcesBtn';
import { useTooltipContentWidth } from './useTooltipContentWidth';
import { MergePatient } from '@rs-ui/views/PatientInfoView/model/MergePatient';
import { usePatientStore } from '@rs-ui/views/PatientInfoView/patientStore';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const VerticalDivider = props => <Box sx={styles.verticalDivider} {...props} />;

const SelectedResourcesTooltip = ({
	onMergeStudyDrawerOpen,
	onMergePatientDrawerOpen,
	onLinkPatientDrawerOpen,
	t,
	setIsLinkMergeDrawerLoading,
}) => {
	const { selectedResources, handleResourceReset, selectedDuplicates, setSelectedMergeStudy, setSelectedPatients } =
		useSelection();
	const proactEnablePatientInfoMergeLink = useBooleanFlagValue('proact-enable-patient-info-merge-link');
	const proactLinkPatient = useBooleanFlagValue('proact-link-patient');
	const proactMergeStudy = useBooleanFlagValue('proact-merge-study');
	const proactEnableMergePatient = useBooleanFlagValue('proact-enable-merge-patient');
	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');

	const { setLinkedPatients, patient, setPatient } = usePatientStore();
	const { hasPermissionByOrganization, hasPermissionByOrganizationAsync } = useAuth();

	const [shouldShowLinkAndMergeButtons, setShouldShowLinkAndMergeButtons] = useState(false);

	const [tooltipContent, tooltipContentWidth] = useTooltipContentWidth();

	const patientLoader = useFhirDataLoader({
		scope: searchScopes.patient,
	});

	const selectedCount = selectedResources.length;

	const selectedResourcesText = `${t('workList:select')} ${selectedCount} ${
		selectedCount > 1 ? t('workList:selectedResourcesTooltip.items') : t('workList:selectedResourcesTooltip.item')
	}`;

	const isBtnsSectionVisible = proactLinkPatient || proactMergeStudy || proactEnableMergePatient;

	const isMergeStudyVisible = useMemo(() => {
		if (!selectedResources.length) return false;

		const referenceId = selectedResources[0].subject?.id;
		const internalOrgId = selectedResources[0].internalManagingOrganizationID;

		return (
			!selectedResources.some(i => i.subject?.id !== referenceId) &&
			!selectedResources.some(i => i.internalManagingOrganizationID !== internalOrgId)
		);
	}, [selectedResources]);

	const mergeStudyDrawerOpenHandler = () => {
		setSelectedMergeStudy(null);
		onMergeStudyDrawerOpen(true);
	};

	const linkPatientDrawerOpenHandler = async () => {
		setIsLinkMergeDrawerLoading(true);

		onLinkPatientDrawerOpen(true);
		const firstPatient = await patientLoader.load({ 'patientid:exact': selectedResources[0]?.patientID });
		const secondPatient = await patientLoader.load({ 'patientid:exact': selectedResources[1]?.patientID });
		await handleResourceReset();

		const firstLinkPatient = new MergePatient(firstPatient[0]);
		const secondLinkPatient = new MergePatient(secondPatient[0]);

		setSelectedPatients([firstLinkPatient, secondLinkPatient]);

		setPatient(firstLinkPatient);
		setLinkedPatients(firstPatient?.[0]?.link || []);

		setSelectedMergeStudy(null);

		setIsLinkMergeDrawerLoading(false);
	};

	const mergePatientDrawerOpenHandler = async () => {
		setIsLinkMergeDrawerLoading(true);

		onMergePatientDrawerOpen(true);

		const firstPatient = await patientLoader.load({ 'patientid:exact': selectedResources[0]?.patientID });
		const secondPatient = await patientLoader.load({ 'patientid:exact': selectedResources[1]?.patientID });

		const firstLinkPatient = new MergePatient(firstPatient[0]);
		const secondLinkPatient = new MergePatient(secondPatient[0]);

		setSelectedPatients([firstLinkPatient, secondLinkPatient]);

		setPatient(firstLinkPatient);

		setSelectedMergeStudy(null);

		setIsLinkMergeDrawerLoading(false);
	};

	useEffect(() => {
		const checkButtonPrivilege = async () => {
			const studiesManagingOrgsArray = [selectedResources[0], selectedResources[1]].map(
				s => s.internalManagingOrganizationID
			);
			const studiesManagingOrgsSet = new Set(studiesManagingOrgsArray);

			const linkAndMergeStudyPermissionPath = 'worklist.link and merge patient';

			const patientsBelongToSameMasterOrganization =
				selectedResources[0]?.internalMasterOrganizationID ===
				selectedResources[1]?.internalMasterOrganizationID;

			const patientsAreDifferent = selectedResources[0].subject?.id !== selectedResources[1].subject?.id;

			if (crossGetPrivilegeOnDemand) {
				const permissionChecks = await Promise.all(
					Array.from(studiesManagingOrgsSet).map(async internalManagingOrganizationId =>
						hasPermissionByOrganizationAsync(
							internalManagingOrganizationId,
							'Home',
							linkAndMergeStudyPermissionPath,
							'update'
						)
					)
				);
				const hasPermissionsToLinkAndMergeStudies = proactEnablePatientInfoMergeLink
					? permissionChecks.every(Boolean)
					: true;
				const enableButtons =
					hasPermissionsToLinkAndMergeStudies &&
					patientsBelongToSameMasterOrganization &&
					patientsAreDifferent &&
					selectedResources.length === 2;
				setShouldShowLinkAndMergeButtons(enableButtons);
			} else {
				const hasPermissionsToLinkAndMergeStudies = proactEnablePatientInfoMergeLink
					? studiesManagingOrgsSet.every(internalManagingOrganizationId =>
							hasPermissionByOrganization(
								internalManagingOrganizationId,
								'Home',
								linkAndMergeStudyPermissionPath,
								'update'
							)
					  )
					: true;
				const enableButtons =
					hasPermissionsToLinkAndMergeStudies &&
					patientsBelongToSameMasterOrganization &&
					patientsAreDifferent &&
					selectedResources.length === 2;
				setShouldShowLinkAndMergeButtons(enableButtons);
			}
		};
		if (selectedResources?.length > 1) {
			checkButtonPrivilege();
		}
	}, [selectedResources, proactEnablePatientInfoMergeLink, crossGetPrivilegeOnDemand]);

	return selectedCount > 0 ? (
		<Box sx={styles.box}>
			<Box
				ref={tooltipContent}
				data-testid="selected-resources-tooltip-content"
				sx={{
					...styles.tooltipContent,
					left: `calc(50%  - ${tooltipContentWidth / 2}px)`,
				}}
			>
				<Typography sx={styles.tooltipText}>{selectedResourcesText}</Typography>
				<CloseIcon data-testid="close-icon" sx={styles.closeIcon} onClick={handleResourceReset} />

				{selectedResources.length > 1 && isBtnsSectionVisible && (
					<>
						<VerticalDivider style={{ margin: '0px 10px' }} />
						{proactEnablePatientInfoMergeLink && shouldShowLinkAndMergeButtons && (
							<SelectedResourcesButton
								icon={InsertLinkIcon}
								title={t('workList:selectedResourcesTooltip.linkPatient')}
								onClick={linkPatientDrawerOpenHandler}
							/>
						)}

						{proactEnableMergePatient && shouldShowLinkAndMergeButtons && (
							<SelectedResourcesButton
								icon={MergePatientIcon}
								title={t('workList:selectedResourcesTooltip.mergePatient')}
								onClick={mergePatientDrawerOpenHandler}
							/>
						)}

						{proactMergeStudy && !!selectedDuplicates.length && isMergeStudyVisible && (
							<>
								<VerticalDivider style={{ margin: '0px 10px' }} />
								<SelectedResourcesButton
									icon={MergeStudyIcon}
									title={t('workList:selectedResourcesTooltip.mergeStudy')}
									onClick={mergeStudyDrawerOpenHandler}
								/>
							</>
						)}
					</>
				)}
			</Box>
		</Box>
	) : null;
};

export default SelectedResourcesTooltip;

const styles = {
	box: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		bottom: '10px',
		gap: '105px',
		height: '150px',
		width: '100%',
		pointerEvents: 'none',
		left: 0,
	},
	tooltipContent: {
		backgroundColor: '#3588CB',
		height: '45px',
		boxSizing: 'border-box',
		borderRadius: '29px',
		zIndex: 10,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0 16px',
		pointerEvents: 'all',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		transition: 'background-color .3s ease',
		position: 'absolute',
		bottom: 0,
		'&:hover': {
			backgroundColor: '#28679A',
		},
	},
	tooltipText: {
		fontSize: '10px',
		color: 'icon.primary',
		textTransform: 'uppercase',
		borderRightColor: 'icon.primary',
		lineHeight: '13px',
		letterSpacing: '1.5px',
		display: 'flex',
		alignItems: 'center',
	},
	closeIcon: {
		color: '#fff',
		backgroundColor: 'rgba(66, 165, 245, 0.6);',
		borderRadius: '50%',
		width: '14px',
		height: '14px',
		marginLeft: '10px',
		padding: '3px',
		cursor: 'pointer',
	},
	verticalDivider: {
		height: '13px',
		width: '1px',
		backgroundColor: '#C4C4C4',
	},
};
