import React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const MERGE_ORDER_DRAWER_TOOLTIP_PROPS = {
	title: 'sureToMerge',
	description: 'unselectedWillMerge',
};

const MERGE_PATIENT_DRAWER_BASIC_TOOLTIP_PROPS = {
	title: 'sureToMergePatient',
	description: 'nameDoNotMatch',
};

const MERGE_PATIENT_DRAWER_MATCH_NAME_TOOLTIP_PROPS = {
	title: 'sureToMergePatient',
	description: 'nameDoNotMatch',
};

const StyledErrorIcon = styled(ErrorOutlineIcon)({
	fill: '#CF6679',
});

export const BasicMergeDrawerTooltip = ({ title, description }) => {
	const { t } = useTranslation('workList');

	return (
		<Box
			sx={{
				width: '76%',
				margin: 'auto',
				my: '10px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					mb: '12px',
				}}
			>
				<StyledErrorIcon data-testid="error-icon" />
				<Typography
					sx={{
						display: 'flex',
						alignItems: 'center',
						fontSize: '14px',
						ml: '12px',
					}}
				>
					{t(title)}
				</Typography>
			</Box>

			<Typography
				sx={{
					fontSize: '14px',
					color: 'rgba(255, 255, 255, 0.6)',
					fontStyle: 'italic',
					whiteSpace: 'pre-line',
				}}
			>
				{t(description)}
			</Typography>
		</Box>
	);
};

const withTitleAndDescription = (title, description) => () => (
	<BasicMergeDrawerTooltip description={description} title={title} />
);

export const MergeOrderDrawerTooltip = withTitleAndDescription(
	MERGE_ORDER_DRAWER_TOOLTIP_PROPS.title,
	MERGE_ORDER_DRAWER_TOOLTIP_PROPS.description
);

export const MergePatientDrawerBasicTooltip = withTitleAndDescription(
	MERGE_PATIENT_DRAWER_BASIC_TOOLTIP_PROPS.title,
	MERGE_PATIENT_DRAWER_BASIC_TOOLTIP_PROPS.description
);

export const MergePatientDrawerMatchNameTooltip = withTitleAndDescription(
	MERGE_PATIENT_DRAWER_MATCH_NAME_TOOLTIP_PROPS.title,
	MERGE_PATIENT_DRAWER_MATCH_NAME_TOOLTIP_PROPS.description
);
