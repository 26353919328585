// @flow

// core
import React, { type Node } from 'react';

// libs
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

// components
import SecondaryButton from '../../../SecondaryButton';

interface ILinkDrawerButtonsProps {
	closeDrawer: () => void;
	isActive: boolean;
	tooltip: Node;
	onSubmit: () => void;
	t: (key: string) => string;
}

export const LinkDrawerButtons = ({
	closeDrawer,
	isActive,
	tooltip: Tooltip,
	onSubmit,
	t,
}: ILinkDrawerButtonsProps): Node => (
	<Box data-testid="buttons-wrapper">
		{isActive && Tooltip && <Tooltip />}

		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				margin: '16px 48px',
			}}
		>
			<SecondaryButton label={t('Cancel')} testId="cancelSettings" onClick={closeDrawer} />

			<Button
				color="rsPrimary"
				data-testid="link-button"
				disabled={!isActive}
				sx={{
					'&:disabled': {
						backgroundColor: '#3588CB',
						cursor: 'not-allowed',
					},
				}}
				variant="contained"
				onClick={onSubmit}
			>
				{t('Link')}
			</Button>
		</Box>
	</Box>
);
