/* Generated automagically by FhirStarter - DO NOT EDIT */
const workListLayoutSchema = {
	resourceType: '__string__',
	id: '__string__',
	name: '__string__',
	active: '__string__',
	practitioner: '__Reference(Practitioner)__',
	role: '__Reference(Role)__',
	organization: '__Reference(Organization)__',
	flagsave: '__Extension(valueString)__',
	isCurrent: '__boolean__',
	isDefault: '__boolean__',
};
export default workListLayoutSchema;
