// @flow

// core
import React, { lazy, Suspense, useEffect, useState, useCallback } from 'react';
// utils
import { fhirExtensionUrls, searchScopes, useFhirDataLoader, useSelection } from '@worklist-2/core/src';
import { PAYMENT_ORGANIZATION_TYPES_PARAM } from '@rs-ui/views/utils/paymentOrganizationTypes';
import { coverageMapper } from '@worklist-2/ui/src/views/OrderInfoView/utils';

const PreauthorizationDrawer = lazy(() => import('@worklist-2/ui/src/components/Drawers/PreauthorizationDrawer'));

interface IWorklistPreAuthDrawerProps {
	t: (key: string) => string;
}

export const WorklistPreAuthDrawer = ({ t }: IWorklistPreAuthDrawerProps) => {
	// ==================== State ====================
	const [isPreauthDrawerOpen, setIsPreauthDrawerOpen] = useState(false);
	const [patientInsuranceInfoList, setPatientInsuranceInfoList] = useState([]);

	// ==================== Hooks ====================
	const { preAuthStudy, setPreAuthStudy } = useSelection();

	const coverageLoader = useFhirDataLoader({ scope: searchScopes.coverage });
	const insurancePayerLoader = useFhirDataLoader({ scope: searchScopes.organization });

	// ==================== Effects ====================
	useEffect(() => {
		if (preAuthStudy) {
			loadCoverage();
		}
	}, [preAuthStudy]);

	const loadCoverage = useCallback(() => {
		coverageLoader
			.load(
				{
					value: [
						{
							label: 'patient',
							values: preAuthStudy?.patientId,
						},
					],
				},
				true
			)
			.then(coverageResource => {
				if (coverageResource?.total > 0) {
					const coverageList = coverageMapper(coverageResource.entry);
					coverageList?.forEach(coverage => {
						if (coverage?.internalPayerId) {
							insurancePayerLoader
								.load(
									{
										id: coverage.internalPayerId,
										type: PAYMENT_ORGANIZATION_TYPES_PARAM,
									},
									true
								)
								.then(res => {
									if (res) {
										coverage.phoneNumber = res?.telecom?.find(tel => tel.system === 'phone')?.value;
										coverage.faxNumber = res?.telecom?.find(tel => tel.system === 'fax')?.value;
									}
								});
						}
					});

					setPatientInsuranceInfoList(coverageList);
				}
				setIsPreauthDrawerOpen(true);
			});
	}, [coverageLoader, insurancePayerLoader, preAuthStudy?.patientId]);

	const onCloseDrawer = () => {
		setPreAuthStudy(null);
		setIsPreauthDrawerOpen(false);
	};
	// #endregion

	return (
		<Suspense fallback={null}>
			<PreauthorizationDrawer
				drawerOpen={isPreauthDrawerOpen}
				insuranceInfoList={patientInsuranceInfoList}
				managingOrgId={preAuthStudy?.internalManagingOrganizationId}
				orderData={{ id: preAuthStudy?.orderId, subject: { id: preAuthStudy?.patientId } }}
				setDrawerOpen={onCloseDrawer}
				studyId={preAuthStudy?.id}
				t={t}
			/>
		</Suspense>
	);
};
