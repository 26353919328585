// core
import React, { useEffect, useRef, useState } from 'react';
// api
import {
	useAppModeContext,
	useHelpCenter,
	useConfig,
	useTabContext,
	searchScopes,
	useFhirDataLoader,
	useAuth,
	mediaTablet,
	fhirExtensionUrls,
} from '@worklist-2/core/src';
// assets
import Svg from '@worklist-2/ui/src/components/Svg/Svg';
// mui
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import styled from '@mui/system/styled';
// mui icons
import AppsIcon from '@mui/icons-material/Apps';
import AssignmentIndOutlined from '@mui/icons-material/AssignmentIndOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CorporateFare from '@mui/icons-material/CorporateFare';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import PersonIcon from '@mui/icons-material/PersonOutline';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// libraries
import axios from 'axios';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// partials
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { getPrimaryText, getSecondaryText } from './searchResultItemTextHelper';
import AvatarGroupIcon from '../AvatarGroupIcon';
import StylusNoteIcon from '../../assets/icons/stylusNote.svg';
import { getViewerURLs } from '../Worklist/WorklistGrid/getViewerURLs';

const SearchResultItem = ({
	alwaysShowGoToButton,
	alwaysShowRetrieveButton,
	isChecked = false,
	item,
	showButton = true,
	showProgress = false,
	type,
	onCheck,
	onGoTo,
	onRetrieve,
	onTrack,
	isAccess,
	onCloseDialog,
	setIsClickAwayDisabled,
	studyBtnClassName,
	SendDrawer,
}) => {
	const navigate = useNavigate();
	const { isPatientPortalMode, isWorklistMode, isMarketplaceMode, isHelpCenterMode, setIsEmergencySearch } =
		useAppModeContext();
	const appModeIsWorklist = isWorklistMode();
	const appModeIsPatientPortal = isPatientPortalMode();
	const appModeIsMarketplace = isMarketplaceMode();
	const appModeIsHelpCenter = isHelpCenterMode();
	const [dataSet, setDataSet] = useState([]);
	const [accessStep, setAccessStep] = useState(null);
	const { t } = useTranslation('Search');
	const { selectedLanguage } = useAuth();
	const itemRef = useRef();

	useEffect(() => {
		if (item?.SharedInformation?.length > 0) {
			const newDataSet = item?.SharedInformation.map(share => ({
				name: share.Name,
				source: '',
			}));

			setDataSet(newDataSet);
		}
	}, [item]);

	const goToButtonText =
		{
			ImagingStudyWorklist: 'Study',
			ImagingStudy: 'Study',
			WorkListLayout: 'Study',
			DICOMResult: 'Study',
			BlumeStudy: 'Study',
			StudySharedWithMe: 'Study',
			Patient: 'Patient',
			Organization: 'Organization',
			Practitioner: 'User',
			MarketplaceApp: 'App',
			helpcenter: 'Article',
		}[item.resourceType ?? item.ResourceType] || '';

	const iconColor =
		{
			ImagingStudyWorklist: '#A66BA3',
			ImagingStudy: '#A66BA3',
			WorkListLayout: '#A66BA3',
			DICOMResult: '#A66BA3',
			BlumeStudy: '#A66BA3',
			Patient: '#C56F7F',
			Organization: '#7566CF',
			StudySharedWithMe: '#E3B269',
			Practitioner: '#C480DC',
			helpcenter: '#CF6666',
			MarketplaceApp: '#667DCF',
		}[item.resourceType ?? item.ResourceType] || '#CF6666';

	const icon = {
		ImagingStudyWorklist: <DescriptionOutlined />,
		ImagingStudy: <DescriptionOutlined />,
		WorkListLayout: <DescriptionOutlined />,
		DICOMResult: <DescriptionOutlined />,
		BlumeStudy: <DescriptionOutlined />,
		Patient: <AssignmentIndOutlined />,
		Organization: <CorporateFare />,
		Practitioner: <PersonIcon />,
		StudySharedWithMe: <ShareRoundedIcon />,
		helpcenter: <HelpCenterOutlinedIcon />,
		MarketplaceApp: <AppsIcon />,
	}[item.resourceType ?? item.ResourceType] || <HelpCenterOutlinedIcon />;

	const onGoToClick =
		onGoTo ||
		(resultItem => {
			const url = getItemPageUrl(resultItem, null, appModeIsWorklist || appModeIsHelpCenter, selectedLanguage);
			if (url) navigate(url);
		});

	const handleGoToButtonClick = event => {
		event.stopPropagation();

		if (isAccess) {
			setIsEmergencySearch(false);
			onGoToClick(item);
		} else {
			setAccessStep('notification');
		}
	};

	const canShowStudyButtons = (item?.resourceType || item?.ResourceType) === 'ImagingStudyWorklist';

	const canShowGoToButton =
		type === 'search' &&
		(showButton || alwaysShowGoToButton) &&
		!showProgress &&
		(getItemPageUrl(item, null, appModeIsWorklist || appModeIsHelpCenter, selectedLanguage) ||
			item.resourceType === 'Practitioner') &&
		(appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter) &&
		!accessStep &&
		!canShowStudyButtons;

	const canShowRetrieveTrackButton = type === 'search' && (onRetrieve || onTrack);

	return (
		<>
			<ListItemAvatar>
				{showProgress ? (
					<div style={{ marginLeft: '7.5px' }}>
						<CircularProgress className="progress-bar" size={25} />
					</div>
				) : (
					<div data-testid="check-btn" id="avatarWrapper" style={{ position: 'relative' }} onClick={onCheck}>
						<Avatar
							sx={{
								bgcolor: iconColor,
								transition: 'opacity .3s ease',
								opacity: isChecked ? 0 : 1,
								width:
									appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? '40px' : '35px',
								height:
									appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? '40px' : '35px',
							}}
						>
							{icon}
						</Avatar>

						{!!onCheck && <CheckBoxIcon isChecked={isChecked} />}
					</div>
				)}
			</ListItemAvatar>

			<ListItemText
				ref={itemRef}
				className="list-item"
				primary={
					<span
						dangerouslySetInnerHTML={{
							__html: getPrimaryText(
								item,
								appModeIsPatientPortal,
								appModeIsWorklist || appModeIsHelpCenter
							),
						}}
						style={{
							display: 'block',
							color: appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? '' : '#272727',
							fontWeight: appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? '' : '400px',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
						}}
					/>
				}
				secondary={
					<span
						dangerouslySetInnerHTML={{
							__html: getSecondaryText(
								item,
								appModeIsPatientPortal,
								appModeIsWorklist || appModeIsHelpCenter
							),
						}}
						style={{
							display: 'block',
							color: appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? '' : '#393939',
							fontWeight: appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter ? '' : '400px',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
						}}
					/>
				}
				sx={{
					[mediaTablet]: {
						'& .MuiListItemText-primary': {
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '0.25px',
						},
						'& .MuiListItemText-secondary': {
							fontSize: '12px',
							lineHeight: '20px',
							letterSpacing: '0.25px',
						},
					},
				}}
			/>

			{(item.resourceType ?? item.ResourceType) === 'StudySharedWithMe' ? (
				<AvatarGroupIcon
					dataSet={dataSet}
					maxIcon="2"
					shape="cirular"
					sx={{
						'& >.MuiAvatar-root:nth-of-type(2)': {
							width: '35px',
							height: '35px',
						},
					}}
				/>
			) : (
				''
			)}

			{canShowGoToButton && (
				<ItemButton isForceShown={alwaysShowGoToButton} onClick={event => handleGoToButtonClick(event)}>
					{!isAccess && (
						<ErrorOutlineOutlinedIcon
							sx={{ marginRight: '8px', fontSize: '16px', fill: '#CF6679 !important' }}
						/>
					)}
					{t(_.upperCase(`Go to ${goToButtonText}`))}
				</ItemButton>
			)}

			{canShowStudyButtons && (
				<StudyButtons
					SendDrawer={SendDrawer}
					record={item}
					setIsClickAwayDisabled={setIsClickAwayDisabled}
					studyBtnClassName={studyBtnClassName}
				/>
			)}

			{accessStep === 'notification' && (
				<AccessNotification onClick={() => setAccessStep('reason')} onClose={() => setAccessStep(null)} />
			)}
			<ReasonsSelect
				anchorEl={itemRef}
				item={item}
				open={accessStep === 'reason'}
				onClose={() => setAccessStep(null)}
				onCloseDialog={onCloseDialog}
				onGoToClick={() => onGoToClick(item)}
			/>

			{canShowRetrieveTrackButton && (
				<ItemButton
					data-testid="retrieve-btn"
					isForceShown={onTrack || alwaysShowRetrieveButton}
					onClick={onRetrieve || onTrack}
				>
					{onRetrieve ? 'Retrieve study' : onTrack ? 'Track' : null}
				</ItemButton>
			)}
		</>
	);
};

const StudyButtons = ({ record, setIsClickAwayDisabled, studyBtnClassName, SendDrawer }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [sendDrawerOpen, setSendDrawerOpen] = useState(false);

	const navigate = useNavigate();
	const { loggedInUser } = useAuth();

	const patientID = record?.patientID;
	const internalPatientId = record?.subject?.id;
	let studyInstanceUid = record?.identifier?.find(recordItem => recordItem.system === 'urn:dicom:uid')?.value;
	studyInstanceUid = studyInstanceUid?.replace('urn:oid:', '');
	const issuerOfPatientId = record?.extension?.find(ext => ext.url === fhirExtensionUrls.organization.issuer)
		?.valueReference?.display;
	const internalStudyId = record?.id;
	const orderId = record?.basedOn?.[0]?.id;
	const internalManagingOrganizationID = record?.internalManagingOrganizationID;
	const { referringFacilityId } = record;

	const handleIWOpen = () => {
		const { imageViewerNavigateURL } = getViewerURLs({
			patientID,
			internalPatientId,
			orderId,
			internalStudyId,
			internalManagingOrganizationID,
			issuerOfPatientId,
			referringFacilityId,
			studyInstanceUid,
			imageViewerUrl: loggedInUser.imageViewerUrl,
			documentViewerUrl: loggedInUser.documentViewerUrl,
		});

		navigate(imageViewerNavigateURL);
	};

	const handleDVOpen = () => {
		const { documentViewerNavigateURL } = getViewerURLs({
			patientID,
			internalPatientId,
			orderId,
			internalStudyId,
			internalManagingOrganizationID,
			issuerOfPatientId,
			referringFacilityId,
			studyInstanceUid,
		});

		navigate(documentViewerNavigateURL);
	};

	const handleStudySend = () => {
		const data = { ...record, rawData: record };
		if (studyInstanceUid) {
			if (setIsClickAwayDisabled) {
				setIsClickAwayDisabled(true);
			}

			setSelectedRecord([data]);
			setSendDrawerOpen(true);
		}
	};

	const iconStyle = { fontSize: '16px', color: '#FFFFFF' };
	const BUTTONS = [
		{
			isVisible: isOpen,
			icon: <ImageOutlinedIcon sx={{ ...iconStyle, opacity: '1 !important' }} />,
			bgColor: '#6F78C5',
			onClick: handleIWOpen,
		},
		{
			isVisible: isOpen,
			icon: <Svg name="documentviewersmall" sx={{ width: 'unset', height: 'unset', opacity: '1 !important' }} />,
			bgColor: '#6F97C5',
			onClick: handleDVOpen,
		},
		{
			isVisible: isOpen,
			icon: <SendOutlinedIcon sx={{ ...iconStyle, opacity: '1 !important' }} />,
			bgColor: '#7A61C2',
			onClick: handleStudySend,
		},
		{
			isVisible: true,
			icon: <MoreVertIcon sx={iconStyle} />,
			bgColor: isOpen ? 'transparent' : '#585858',
			onClick: () => setIsOpen(prev => !prev),
		},
	];

	return (
		<Box
			className={studyBtnClassName}
			sx={{
				display: 'flex',
				padding: '5px',
				borderRadius: '30px',
				border: '2px solid #272727',
				background: '#3A3A3A ',
			}}
			onClick={e => e.stopPropagation()}
			onMouseLeave={() => setIsOpen(false)}
		>
			{BUTTONS.map((item, index) =>
				item.isVisible ? (
					<IconButton
						key={index}
						sx={{
							display: 'inline-flex !important',
							padding: `${index === 1 ? '0' : '8px'} !important`,
							marginRight: index === 3 ? '0' : '5px',
							backgroundColor: `${item.bgColor} !important`,
							border: 'none !important',
							borderRadius: '50% !important',
							'&:hover': {
								backgroundColor: `${item.bgColor} !important`,
							},
							span: {
								marginRight: '0 !important',
								color: '#FFF !important',
							},
						}}
						onClick={item.onClick}
					>
						{item.icon}
					</IconButton>
				) : null
			)}

			<SendDrawer
				drawerOpen={sendDrawerOpen}
				setDrawerOpen={open => {
					setSendDrawerOpen(open);
					if (!open) {
						setSelectedRecord(null);

						if (setIsClickAwayDisabled) {
							setIsClickAwayDisabled(false);
						}
					}
				}}
				studies={selectedRecord}
			/>
		</Box>
	);
};

const CheckBoxIcon = ({ isChecked }) => (
	<Box
		sx={{
			border: '1px solid rgba(255,255,255,.1)',
			borderColor: isChecked ? 'rgba(66, 165, 245, 0.87)' : undefined,
			borderRadius: '50%',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '40px',
			height: '40px',
			backgroundColor: isChecked ? 'rgba(66, 165, 245, 0.87)' : 'rsSecondary.medium',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			opacity: isChecked ? 1 : 0,
			transition: 'opacity .3s ease, background-color .3s ease, border-color .3s ease',
		}}
	>
		<CheckIcon
			sx={{
				height: '24px',
				transition: 'opacity .3s ease',
				opacity: isChecked ? 1 : 0.3,
				fill: isChecked ? '#393939 !important' : 'white',
			}}
		/>
	</Box>
);

const ItemButton = styled(({ isForceShown: __, ...props }) => <Button {...props} variant="outlined" />)(
	({ isForceShown }) => ({
		display: isForceShown ? 'block !important' : 'none',
	})
);

const AccessNotification = ({ onClick, onClose }) => {
	const handleButtonClick = event => {
		event.stopPropagation();
		onClick();
	};

	return (
		<Box
			sx={{
				position: 'relative',
				minWidth: '388px',
				padding: '9px 16px',
				display: 'flex',
				alignItems: 'flex-start',
				borderRadius: '6px',
				backgroundColor: '#323232',
			}}
			onMouseLeave={onClose}
		>
			<ErrorOutlineOutlinedIcon sx={{ marginRight: '10px', fontSize: '20px', fill: '#CF6679 !important' }} />
			<p
				style={{
					margin: 0,
					fontFamily: 'Roboto',
					fontSize: '12px',
					fontStyle: 'italic',
					fontWeight: 400,
					lineHeight: '20px',
					color: 'rgba(255, 255, 255, 0.60)',
					textWrap: 'wrap',
				}}
			>
				This access will be recorded in the Audit log and will be reviewed for compliance with law. Are you sure
				to continue?
			</p>
			<Button
				sx={{
					position: 'absolute',
					bottom: '7px',
					right: '17px',
					padding: '0 !important',
					display: 'flex !important',
					fontSize: '12px !important',
					fontWeight: '400 !important',
					lineHeight: '24px !important',
					letterSpacing: '0.15px !important',
					border: 'none !important',

					'& .MuiTouchRipple-root': {
						marginRight: '0 !important',
					},
				}}
				variant="text"
				onClick={e => handleButtonClick(e)}
			>
				CONTINUE
			</Button>
		</Box>
	);
};

const ReasonsSelect = ({ item, open, onClose, anchorEl, onGoToClick, onCloseDialog }) => {
	const __config = useConfig();
	const { setInternalPatients } = useTabContext();
	const { loggedInUserId } = useGoToUser();
	const [selectedReason, setSelectedReason] = useState('');

	const setAttestation = () => {
		try {
			const internalPatientID = item.resourceType === 'Patient' ? item.id : item.subject.id;
			const payload = {
				patient: {
					id: internalPatientID,
					reference: `patient/${internalPatientID}`,
				},
				physician: {
					id: loggedInUserId,
					reference: `Practitioner/${loggedInUserId}`,
				},
				attestation: selectedReason,
			};

			axios.post(`${__config.data_sources.fhir}/patient/attestation`, payload).then(resp => {
				if (resp?.status === 201) {
					setInternalPatients(prev => [...prev, internalPatientID]);
					onGoToClick();
				}
			});
		} catch (e) {
			console.error(e);
		}
	};

	const handleButtonClick = event => {
		event.stopPropagation();
		setAttestation();
		onClose();
		if (onCloseDialog) {
			onCloseDialog();
		}
	};

	const handleReasonClick = (event, reason) => {
		event.stopPropagation();
		setSelectedReason(reason);
	};

	const handleClose = event => {
		event.stopPropagation();
		setSelectedReason('');
		onClose();
	};

	const REASON_LIST = [
		'EMERGENCY CARE',
		'Direct Patient Care - Ambulatory Care',
		'Direct Patient Cans - Consultant',
		'Direct Patient Care - Home Care / Hospice',
		'Direct Patient Care - Hospital',
		'Direct Patient Care - Long Term / Post Acute Care',
		'Direct Patient Care - Mental Health',
		'Direct Patient Care - Pharmacy',
		'Direct Patient Care - Specialist',
		'Support Admin - Care Coordination',
		'Quality Data Support',
		'Information Technology Support',
	];

	return (
		<Popover
			anchorEl={anchorEl?.current}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open={open}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			onClose={event => handleClose(event)}
		>
			<Box
				sx={{
					paddingBottom: '10px',
					borderRadius: '6px',
					display: 'flex',
					flexDirection: 'column',
					border: '1px solid rgba(255, 255, 255, 0.10)',
					backgroundColor: '#393939',
					boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
				}}
			>
				<Box
					sx={{
						padding: '6px 0 6px 20px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<StylusNoteIcon />
					<span
						style={{
							marginLeft: '8px',
							fontFamily: 'Roboto',
							fontSize: '14px',
							fontWeight: '400',
							lineHeight: '20px ',
							color: 'rgba(255, 255, 255, 0.60)',
						}}
					>
						Access Reason
					</span>
				</Box>
				<Box sx={{ height: '140px', overflowY: 'scroll' }}>
					{REASON_LIST.map(reason => (
						<Box
							key={reason}
							sx={{
								padding: '4px 0 4px 20px',
								display: 'flex',
								alignItems: 'center',
								fontSize: '14px',
								fontWeight: '400',
								lineHeight: '20px ',
								backgroundColor: selectedReason === reason ? '#504F4F' : 'none',
								cursor: 'pointer',

								'&:hover': {
									backgroundColor: '#504F4F',
								},
								'&:hover svg': {
									visibility: 'visible',
								},
							}}
							onClick={event => handleReasonClick(event, reason)}
						>
							<CheckIcon
								sx={{
									fontSize: '16px',
									fill: selectedReason === reason ? '#42A5F5 !important' : '#3F3F3F !important',
									visibility: selectedReason === reason ? 'visible' : 'hidden',
								}}
							/>
							<span
								style={{ marginLeft: '8px', fontFamily: 'Roboto', color: 'rgba(255, 255, 255, 0.87)' }}
							>
								{reason}
							</span>
						</Box>
					))}
				</Box>
				{selectedReason && (
					<Button
						sx={{
							marginRight: '18px',
							padding: '0 !important',
							alignSelf: 'end',
							display: 'flex !important',
							fontSize: '12px !important',
							fontWeight: '400 !important',
							lineHeight: '24px !important',
							color: '#42A5F5',
							letterSpacing: '0.15px !important',
							border: 'none !important',

							'& .MuiTouchRipple-root': {
								marginRight: '0 !important',
							},
						}}
						variant="text"
						onClick={event => handleButtonClick(event)}
					>
						CONTINUE
					</Button>
				)}
			</Box>
		</Popover>
	);
};

SearchResultItem.propTypes = {
	item: PropTypes.object.isRequired,
	isChecked: PropTypes.bool,
	showProgress: PropTypes.bool,
};

export default SearchResultItem;

export const checkAccess = (
	item,
	organizations = [],
	patients = [],
	loggedInUserId = '',
	metaEmergencySearch = false
) => {
	if (metaEmergencySearch) {
		switch (item.resourceType) {
			case 'Patient':
				return (
					organizations.includes(item?.managingOrganization?.id) ||
					patients.includes(item.id) ||
					patients.includes(`-${loggedInUserId}`)
				);
			case 'ImagingStudyWorklist':
				return (
					organizations.includes(item.internalManagingOrganizationID) ||
					patients.includes(item.subject.id) ||
					patients.includes(`-${loggedInUserId}`)
				);
			default:
				return true;
		}
	} else {
		return true;
	}
};

export const hasOrderId = item => item.basedOn && item.basedOn.length > 0;

export const getItemPageUrl = (item, loggedInUserId, isWorklistMode, userLanguage) => {
	let url = '';
	let category;
	let article;
	let reg;

	if (item.resourceType === 'Practitioner' && loggedInUserId && item?.id === loggedInUserId) {
		url = `/userinfo`;
	} else {
		switch (item.resourceType ?? item.ResourceType ?? item.resource.ResourceType) {
			case 'ImagingStudyWorklist':
			case 'ImagingStudy':
			case 'WorkListLayout':
				url = hasOrderId(item) ? `/order/${item.basedOn[0].id}#study-list` : '';
				break;
			case 'DICOMResult':
				url = `/study/${item.for.id}`;
				break;
			case 'Patient':
				url = `/patient/${item.id}`; // #TODO: this needs to be refactored and safe-typed
				break;
			case 'Organization':
				url = `/organization/${item.id}`;
				break;
			case 'MarketplaceApp':
				url = `/marketplace/apps/${item.id}`;
				break;
			case 'BlumeStudy':
			case 'StudySharedWithMe':
				const studyStatus = _.lowerCase(item.Status === 'INPROCESS' ? 'processing' : item.Status);
				url = `/studyDetail/${item.id}/${studyStatus}/details`;
				break;
			case 'helpcenter':
				if (isWorklistMode) {
					if (userLanguage === undefined || userLanguage?.length < 2) {
						userLanguage = 'en';
					}
					category = item.Category || item.resource.Category || item.resource.category;
					article = item.Article || item.resource.Article || item.resource.article;
					url = `/help/${userLanguage}`;

					if (category) {
						url = `${url}/${category}`;
					}

					if (article) {
						url = `${url}/${article}`;
					}
				} else {
					category = item.Category || item.category;
					article = item.Article || item.article;
					url = '/help/category';
					reg = /\D+/g;

					if (category && article) {
						const articleIndex = article.replace(reg, '') - 1;
						url = `${url}/${category}/${articleIndex}`;
					}
				}
				break;
			default:
				break;
		}
	}

	return url;
};

export const getItemPageNavigation = (item, isWorklistMode) => {
	const { omegaAIHelpLinks } = useHelpCenter();
	let nav = '';
	let category;
	let article;

	switch (item.resourceType ?? item.ResourceType ?? item.resource.ResourceType) {
		case 'helpcenter':
			if (isWorklistMode) {
				category = item.Category || item.resource.Category || item.resource.category;
				article = item.Article || item.resource.Article || item.resource.article;

				const cat = omegaAIHelpLinks.find(element => category === element.path);
				let tab = null;
				let subNav = null;

				if (cat) {
					nav = cat.label;
					if (article && cat.tabs) {
						tab = cat.tabs.find(element => element.path.endsWith(article));
					}

					if (tab) {
						nav = `${nav} > ${tab.label}`;
					} else if (article && cat.subNavLink) {
						subNav = cat.subNavLink.find(element => element.path.endsWith(article));

						if (subNav) {
							nav = `${nav} > ${subNav.label}`;
						} else {
							// eslint-disable-next-line no-restricted-syntax, guard-for-in
							for (const sub in cat.subNavLink) {
								tab = cat.subNavLink[sub].tabs.find(element => element.path.endsWith(article));

								if (tab) {
									nav = `${nav} > ${cat.subNavLink[sub].label} > ${tab.label}`;
									break;
								}
							}
						}
					}
				}
			}
			break;
		default:
			break;
	}

	return nav;
};

export const useGoToUser = () => {
	const { authorized, loggedInUser, selectedLanguage } = useAuth();

	const [isLoadingUserId, setIsLoadingUserId] = useState(undefined);
	const [loggedInUserId, setLoggedInUserId] = useState(undefined);
	const { isWorklistMode, isHelpCenterMode } = useAppModeContext();
	const navigate = useNavigate();
	const idRef = useRef(null);

	const userLoader = useFhirDataLoader({
		scope: searchScopes.practitionerRole,
	});

	useEffect(() => {
		if (authorized && loggedInUser) {
			setLoggedInUserId(loggedInUser.id);
		}
	}, [authorized, loggedInUser]);

	return {
		loggedInUserId,
		isLoadingUserId,
		goToUser: async item => {
			if (item.id === loggedInUserId && loggedInUserId) {
				navigate(getItemPageUrl(item, loggedInUserId, isWorklistMode || isHelpCenterMode, selectedLanguage));
			} else if (userLoader) {
				setIsLoadingUserId(item.id);
				idRef.current = item.id;

				await userLoader
					.load({ practitioner: item.id }, true)
					.then(res => {
						if (idRef.current === item.id) {
							setTimeout(() => {
								idRef.current = undefined;
								setIsLoadingUserId(undefined);
							}, 0);
							navigate(`/organization/${res.entry[0].resource.organization.id}/users/${item.id}`);
						}
					})
					.catch(() => {
						if (idRef.current === item.id) {
							idRef.current = undefined;
							setIsLoadingUserId(undefined);
						}
					});
			}
		},
	};
};
