/* Generated automagically by FhirStarter - but please feel free to edit this file */
import { fhirExtensionUrls } from '../../extension';
const MedicationMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	identifier: {
		label: 'Identifier',
		options: { filter: false, sort: false },
		queryParameter: 'identifier',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.identifier;
		},
	},
	code: {
		label: 'Code',
		options: { filter: false, sort: false },
		queryParameter: 'code',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.code;
		},
	},
	status: {
		label: 'Status',
		options: { filter: false, sort: false },
		queryParameter: 'status',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.status;
		},
	},
	manufacturer: {
		label: 'Manufacturer',
		options: { filter: false, sort: false },
		queryParameter: 'manufacturer',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.manufacturer;
		},
	},
	form: {
		label: 'Form',
		options: { filter: false, sort: false },
		queryParameter: 'form',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.form;
		},
	},
	amount: {
		label: 'Amount',
		options: { filter: false, sort: false },
		queryParameter: 'amount',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.amount;
		},
	},
	ingredient: {
		label: 'Ingredient',
		options: { filter: false, sort: false },
		queryParameter: 'ingredient',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.ingredient;
		},
	},
	batch_lotNumber: {
		label: 'Batch_lot #',
		options: { filter: false, sort: false },
		queryParameter: 'batch_lotNumber',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.batch_lotNumber;
		},
	},
	batch_expirationDate: {
		label: 'Batch_expiration Date',
		options: { filter: false, sort: false },
		queryParameter: 'batch_expirationDate',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.batch_expirationDate;
		},
	},
};
export default MedicationMapping;
