import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GenericDrawerButton from './GenericDrawerButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import PropTypes from 'prop-types';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { mediaTabletOnly } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

/**
 * @param {Object} props
 * @param {string} props.anchor - "right", "left", "top", "bottom"
 * @param {string} props.title - sets the name in the Drawer Header
 * @param {boolean} props.toggleDrawerButtonFlag - enables/disables a button that toggles that drawer
 * @param {boolean} props.drawerOpen - boolean: passed in state for if the drawer is opened or closed
 * @param {Function} props.setDrawerOpen - funcion: sets the passed in state of drawerOpen
 */
const GenericDrawer = ({
	anchor,
	title,
	toggleDrawerButtonFlag,
	drawerOpen,
	setDrawerOpen,
	width,
	children,
	showBackBtn,
	borderRadius,
	drawerColor,
	headerDividerFlag,
	customHeaderTextProps,
	headerSx,
	hideBackdrop,
	showHeader,
	toolbar,
	variant,
	left,
	borderBottom,
	borderColor,
	PaperProps,
	innerSx,
	onBackClick,
	HeaderIcon,
	HeaderIconColor,
	onClose,
	onCloseClick,
	dontCloseOnBackdropClick,
	closeTooltipTitle,
	isFullWidthAllowed,
	isDrawerV2,
	drawerWidth,
}) => {
	const [open, setOpen] = setDrawerOpen ? [drawerOpen, setDrawerOpen] : useState(false);
	const { t } = useTranslation('drawer');
	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setOpen(open);
		if (!open && onClose) {
			onClose(event);
		}
	};

	const childrenWithProps = React.Children.map(children, child => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				closeDrawer: toggleDrawer(false),
			});
		}
		return child;
	});

	return (
		<React.Fragment key={anchor}>
			<Box data-testid="generic-drawer-test">
				{toggleDrawerButtonFlag ? <GenericDrawerButton onClick={toggleDrawer(true)} /> : null}
				<Drawer
					BackdropProps={
						// not using `hideBackdrop` prop, because that disables the "clickaway listener" as well
						hideBackdrop ? { sx: { opacity: '0 !important' } } : undefined
					}
					PaperProps={
						isDrawerV2
							? isFullWidthAllowed
								? {
										...PaperProps,
										sx: {
											left: 0,
											right: 0,
											marginLeft: 'auto',
											marginRight: 'auto',
											width: '873px',
										},
								  }
								: { ...PaperProps }
							: {
									...PaperProps,
									sx: {
										overflowY: 'hidden',
										backgroundColor: drawerColor,
										left: `${left}`,
										zIndex: 2000,
										borderRadius: `${borderRadius}px 0px 0px ${borderRadius}px`,
										[mediaTabletOnly]: {
											top: 'var(--app-header-height)',
											height: 'calc(100% - var(--app-header-height))',
										},
										...PaperProps?.sx,
									},
							  }
					}
					anchor={anchor}
					hideBackdrop={Boolean(dontCloseOnBackdropClick)}
					open={open}
					sx={
						isDrawerV2
							? SX.drawerStyles(isFullWidthAllowed, drawerColor, drawerWidth, left)
							: { backgroundColor: dontCloseOnBackdropClick && '#00000060' }
					}
					variant={variant}
					onClose={
						onClose
							? () => {
									onClose({ message: 'drawer-native-on-close' });
									toggleDrawer(false);
							  }
							: toggleDrawer(false)
					}
				>
					{showHeader && (
						<Stack
							alignItems="center"
							className="headerSection"
							direction="row"
							justifyContent="space-between"
							sx={
								isDrawerV2
									? SX.headerDivStyles
									: {
											height: 54,
											minHeight: 54,
											width: `calc(${width}px - 47px)`, // subtract the horizontal padding
											pl: '27px',
											pr: '20px',
											pt: '15px',
											borderBottom,
											borderColor,
											...headerSx,
									  }
							}
						>
							{showBackBtn && (
								<Box sx={{ mr: '6px' }}>
									<IconButton id="go-back" onClick={onBackClick || toggleDrawer(false)}>
										<ChevronLeft sx={{ fontSize: 28 }} />
									</IconButton>
								</Box>
							)}
							{HeaderIcon && (
								<Box sx={{ mr: '6px' }}>
									<HeaderIcon sx={{ color: HeaderIconColor }} />
								</Box>
							)}
							<Typography
								sx={SX.subtitleHeadingStyle(title, isDrawerV2)}
								variant="subtitle1"
								{...customHeaderTextProps}
							>
								{t(title)}
							</Typography>
							{toolbar}
							<Tooltip title={closeTooltipTitle || t('Close')}>
								<IconButton data-cy={`${title}_close`} onClick={onCloseClick || toggleDrawer(false)}>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						</Stack>
					)}

					{headerDividerFlag && <Divider width="100%" />}
					<Box
						sx={{
							overflowY: 'auto',
							flex: '1',
							display: 'flex',
							flexDirection: 'column',
							transition: '300ms ease-in-out',
							overflow: title === 'NOTIFICATIONS' ? 'auto' : undefined,
							...innerSx,
						}}
						width={isDrawerV2 ? drawerWidth : width}
					>
						{childrenWithProps}
					</Box>
				</Drawer>
			</Box>
		</React.Fragment>
	);
};

GenericDrawer.propTypes = {
	/**
	 * Location where the drawer should anchor. ie: left, right, top, bottom
	 */
	anchor: PropTypes.string,
	/**
	 * Title of the drawer
	 */
	title: PropTypes.string,
	/**
	 * Determines if we should have a toggle button rendered
	 */
	toggleDrawerButtonFlag: PropTypes.bool,
	/**
	 * State of whether the drawer is open or not
	 */
	drawerOpen: PropTypes.bool,
	/**
	 * Setter for open state
	 */
	setDrawerOpen: PropTypes.func,
	/**
	 * Width of the drawer
	 */
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Whether or not the back button in the header should be visible
	 */
	showBackBtn: PropTypes.bool,
	/**
	 * Children to render inside the drawer
	 */
	children: PropTypes.node,
	/**
	 * border Radius to round off corners
	 */
	borderRadius: PropTypes.number,
	/**
	 * Sets the background color of the drawer
	 */
	drawerColor: PropTypes.string,
	/**
	 * Determines if a divider is present under the Drawer Header
	 */
	headerDividerFlag: PropTypes.bool,
	/**
	 * Custom props for the Drawer Header text
	 */
	customHeaderTextProps: PropTypes.object,
	/**
	 * Hides the backdrop
	 */
	hideBackdrop: PropTypes.bool,
	/**
	 * Provides a mechanism to inject a Toolbar into the title, which can host buttons, etc.
	 */
	toolbar: PropTypes.object,
	/**
	 * Whether or not the header should be visible
	 */
	showHeader: PropTypes.bool,
	/**
	 * Paper Props
	 */
	PaperProps: PropTypes.object,
	/**
	 * Inner SX
	 */
	innerSx: PropTypes.object,
	/**
	 * Header Icon
	 */
	HeaderIcon: PropTypes.object,
	/**
	 * Header Icon Color
	 */
	HeaderIconColor: PropTypes.string,
};

const SX = {
	subtitleHeadingStyle: (title, isDrawerV2) => {
		if (isDrawerV2) {
			return {
				fontFamily: 'Roboto',
				fontSize: '20px',
				fontWeight: 500,
				lineHeight: '24px',
				letterSpacing: '0.15000000596046448px',
				textAlign: 'left',
			};
		}
		return {
			width: '100%',
			fontSize: title === 'New Appointment' || title === 'Organization Details' ? '20px' : '16px',
			fontWeight: 500,
			lineHeight: '19px',
			letterSpacing: title === 'Organization Details' || title === 'Study Sets' ? '0.15px' : '0.1em',
			textAlign: 'left',
			paddingLeft: '9px',
		};
	},
	drawerStyles: (isFullWidthAllowed, drawerColor, drawerWidth = 491, left = 108) => ({
		flexShrink: 0,
		width: isFullWidthAllowed ? `873px` : drawerWidth,
		'& .MuiDrawer-paper': {
			backgroundColor: drawerColor,
			boxSizing: 'border-box',
			height: `calc(100% - 120px)`,
			top: '85px',
			width: isFullWidthAllowed ? `873px` : drawerWidth,
			left: `${left}px`,
			borderRadius: `10px 10px 10px 10px`,
			border: '1px solid #FFFFFF1C',
			boxShadow: '10px 10px 30px 0px #121212',
		},
	}),
	headerDivStyles: {
		display: 'flex',
		alignItems: 'center',
		color: '#FFFFFF',
		zIndex: 2000,
		position: 'fixed',
		top: '0px',
		width: `calc(100% - 170px)`,
		left: '64px',
		padding: '16px 20px 16px 44px',
		border: '0px 0px 1px 0px',
		justifyContent: 'space-between',
		background: '#272727',
		borderBottom: '1px solid #FFFFFF1A',
	},
};

GenericDrawer.defaultProps = {
	anchor: 'right',
	toggleDrawerButtonFlag: false,
	title: '',
	drawerOpen: null,
	setDrawerOpen: null,
	width: 500,
	borderRadius: 0,
	drawerColor: '#272727',
	variant: 'temporary',
	showHeader: true,
	PaperProps: {},
	innerSx: {},
	HeaderIcon: null,
	HeaderIconColor: '',
};

export default GenericDrawer;
