import React, { useState, useEffect, useRef, useCallback } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';

import DocumentCard from './DocumentCard';
import Filters from './Filters';
import PatientDocumentIcon from '../../../assets/icons/PatientInfoCards/registration-icon';
import { PATIENT_DOCUMENT_FILTERS } from '@worklist-2/worklist/src/DocumentViewerV3/consts/consts';

import { useDocumentViewerContext } from '@worklist-2/worklist/src/DocumentViewerV3/contexts/DocumentViewerContextProvider';
import useOnDocumentDrop from '@worklist-2/worklist/src/DocumentViewerV3/features/DocumentViewer/hooks/useOnDocumentDrop';
import { usePatientStore } from '@rs-ui/views/PatientInformationView/store/patientStore';
import NoDocuments from './NoDocuments';
import createPatientDocumentPayload from '@rs-ui/views/PatientInformationView/utils/createPatientDocumentPayload';
import { useConfig, arrayBufferToBase64 } from '@worklist-2/core/src';
import { usePatientInfoCardStore } from '@rs-ui/components/PatientInfoCards/store/patientInfoCardStore';

import useUploadPatientDocument from '../hooks/useUploadPatientDocument';

import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

// Allowed Document/Reports Formats
const DOCUMENT_MIME_TYPES = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/pdf',
	'text/plain',
];

const FILTER_LABEL = {
	[PATIENT_DOCUMENT_FILTERS.ALL]: 'All Documents',
	[PATIENT_DOCUMENT_FILTERS.UPLOADED_DOCUMENT]: 'Uploaded Documents',
	[PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM]: 'Registration Form',
};

const PatientDocument = ({ rootHeight, isSelected, isHover, setCollapsed, isExpandMain }) => {
	const { documents: patientDocuments, patient } = usePatientStore();
	const { selectedDocFilter, setSelectedDocFilter } = usePatientInfoCardStore();
	const { onDocumentDrop } = useOnDocumentDrop();
	const { viewports } = useDocumentViewerContext();
	const __config = useConfig();
	const galaxyDvGlobalNotes = useBooleanFlagValue('galaxy-dv-global-notes');

	const { onDocumentUploadHandler } = useUploadPatientDocument();

	const [patientDocs, setPatientDocs] = useState([]);

	const [expanded, setExpanded] = useState(true);

	const refInput = useRef();

	useEffect(() => {
		if (Array.isArray(patientDocuments)) {
			if (selectedDocFilter === PATIENT_DOCUMENT_FILTERS.ALL) {
				setPatientDocs(patientDocuments);
			} else {
				setPatientDocs(patientDocuments.filter(x => x.documentType === selectedDocFilter));
			}
		} else {
			setPatientDocs([]); // Set default value or handle error
		}
	}, [patientDocuments, selectedDocFilter]);

	useEffect(() => {
		setExpanded(isExpandMain);
	}, [isExpandMain]);

	const toggleExpanded = e => {
		e?.preventDefault();
		if (isSelected) {
			setExpanded(val => !val);
			setCollapsed(!expanded);
		}
	};

	const handleSelectDocument = document => {
		onDocumentDrop({ viewport: viewports[0], document });
	};

	const isSelectedDocument = id => viewports?.[0]?.documentId === id;

	const onClickUpload = useCallback(() => {
		refInput?.current?.click();
	}, [refInput]);

	const onUpload = async e => {
		e.preventDefault();
		const file = e.target.files[0];
		const patientId = patient?.patient?.id;

		if (file && DOCUMENT_MIME_TYPES.includes(file.type)) {
			const attachment = {
				contentType: file.type,
				creation: new Date(file.lastModified),
				data: await file.arrayBuffer().then(res => arrayBufferToBase64(res)),
				size: file.size,
				title: file?.name,
			};

			const filePayload = createPatientDocumentPayload(patientId, attachment);

			const appURL = `${__config.data_sources.fhir}/DocumentReference?_dc=${new Date().getTime()}`;

			const payload = {
				type: 'PatientDocument',
				appURL,
				resourcePayload: filePayload,
			};

			// Create a copy of the files array
			const filesCopy = Array.from(e.target.files);

			onDocumentUploadHandler(payload, filesCopy, 'DocumentReference');

			// Reset file input value
			refInput.current.value = '';
		}
	};
	return (
		<Box sx={STYLES.CONTAINER}>
			<Accordion disableGutters expanded={expanded} id="doc" sx={STYLES.ACCORDIAN}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					id="doc-summary"
					sx={STYLES.ACCORDIAN_SUMMARY}
					onClick={toggleExpanded}
				>
					<Typography
						sx={{
							display: 'inline-flex',
							alignItems: 'center',
						}}
					>
						<PatientDocumentIcon
							style={{
								width: '13px',
								height: 'auto',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								color: 'white',
								marginRight: '8px',
								fontSize: '18px',
								fontFamily: 'Roboto',
								fontWeight: '400',
							}}
						/>
						Patient Document
					</Typography>
					{patientDocuments?.length > 0 && (
						<Typography sx={STYLES.LIST_COUNT}>{patientDocuments.length}</Typography>
					)}
				</AccordionSummary>
				<AccordionDetails sx={STYLES.ACCORDIAN_DETAILS}>
					<input
						ref={refInput}
						accept=".pdf, .doc, .docx, .txt"
						multiple={false}
						name="files"
						style={STYLES.HIDDEN_FILE_INPUT}
						type="file"
						onChange={onUpload}
					/>
					<Filters selectedFilter={selectedDocFilter} setSelectedFilter={setSelectedDocFilter} />
					{patientDocs?.length > 0 && (
						<Box sx={STYLES.CATEGORY_HEAD}>
							<Typography sx={STYLES.CATEGORY_LABEL}>{FILTER_LABEL[selectedDocFilter]}:</Typography>
							{selectedDocFilter !== PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM && (
								<Tooltip title="Upload Document">
									<IconButton sx={STYLES.UPLOAD_BTN} onClick={onClickUpload}>
										<PublishOutlinedIcon sx={{ fontSize: '20px' }} />
									</IconButton>
								</Tooltip>
							)}
						</Box>
					)}
					<Box
						sx={
							galaxyDvGlobalNotes
								? STYLES.DOC_LIST_CONT({
										rootHeight,
										isSelected,
										isHover,
										isEmpty: patientDocs?.length === 0,
								  })
								: STYLES.DOC_LIST_CONT({
										rootHeight: rootHeight + 30,
										isSelected,
										isHover,
										isEmpty: patientDocs?.length === 0,
								  })
						}
					>
						{patientDocs?.length ? (
							patientDocs.map(x => (
								<DocumentCard
									key={x.id}
									date={x.createdDate}
									fileName={x.fileName}
									isSelected={isSelectedDocument(x.id)}
									type={x.documentType}
									onClick={() => handleSelectDocument(x)}
									onDragStart={e => e.dataTransfer.setData('text/document', JSON.stringify(x))}
								/>
							))
						) : (
							<NoDocuments
								onClick={
									selectedDocFilter !== PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM
										? onClickUpload
										: undefined
								}
							/>
						)}
					</Box>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default PatientDocument;

const STYLES = {
	CONTAINER: {},
	ACCORDIAN: {
		boxShadow: 'unset',
		background: 'unset',
		'&:before': {
			display: 'none',
		},
	},
	ACCORDIAN_SUMMARY: {
		background: '#363636',
		borderRadius: '10px 10px 0 0',
		position: 'sticky',
		top: '0',
		zIndex: '2',
		minHeight: '64px',
		'& .MuiAccordionSummary-content': {
			margin: '0',
		},
		'&:hover': {
			backgroundColor: '#4f4f4f',
		},
	},
	ACCORDIAN_DETAILS: {
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		background: '#303030',
		borderRadius: '4px',
	},
	DOC_LIST_CONT: ({ rootHeight, isSelected, isHover, isEmpty }) => {
		const additionalHeight = isEmpty ? 0 : 24;
		const adjustedHeight = !isSelected && isHover ? 234 : Math.max(rootHeight - 64 - 66 - 90 - additionalHeight, 0); // Height of accordion, filter and top headers
		return {
			display: 'flex',
			flexDirection: 'column',
			maxHeight: `${adjustedHeight}px`,
			height: `${adjustedHeight}px`,
			padding: `${isEmpty ? 13 : 1}px 15px 15px`,
			gap: '10px',
			overflowY: 'auto',
		};
	},
	LIST_COUNT: {
		width: '26px',
		height: '26px',
		borderRadius: '6px',
		background: '#282828',
		fontWeight: '400',
		fontSize: '13px',
		color: '#fff',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		ml: '8px',
	},
	HIDDEN_FILE_INPUT: {
		visibility: 'hidden',
		height: 0,
		overflow: 'hidden',
		width: 0,
		opacity: 0,
		display: 'none',
	},
	CATEGORY_HEAD: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '40px',
		padding: '0 16px',
	},
	CATEGORY_LABEL: {
		fontSize: '14px',
		lineHeight: '16px',
		fontWeight: '400',
	},
	UPLOAD_BTN: {
		width: '30px',
		height: '30px',
		boxSizing: 'border-box',
	},
};
