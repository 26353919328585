// @flow

// core
import React, { type Node } from 'react';
// libs
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// components
import { CardInfoRow } from '@rs-components/Drawers/MergeDrawers/partials/CardInfoRow';
import { MergeDrawerCard as LinkDrawerCard } from '@rs-components/Drawers/MergeDrawers/partials/MergeDrawerCard';
import { useTranslation } from 'react-i18next';
// utils
import { getReadableBirthDate } from '@rs-components/utils/birthdayUtils';

interface ILinkPatientDrawerCardProps {
	patientData: any;
	cardStyles: any;
	highlightedFields: string[];
}

export const LinkPatientDrawerCard = ({
	patientData,
	cardStyles,
	highlightedFields,
}: ILinkPatientDrawerCardProps): Node => {
	const { t } = useTranslation(['patientInfo']);
	const { patientFirstName, patientLastName, patientID, birthDate, gender, managingOrganization } = patientData;

	return (
		<>
			{patientData && (
				<LinkDrawerCard cardStyles={cardStyles} icon={AccountCircleIcon}>
					<Box
						sx={{
							marginTop: 2,
						}}
					>
						<CardInfoRow
							data={[
								{
									label: t('Last Name'),
									value: patientLastName,
									size: 6,
									highlighted: highlightedFields.includes('Patient Name'),
								},
								{
									label: t('First Name'),
									value: patientFirstName,
									size: 6,
									highlighted: highlightedFields.includes('Patient Name'),
								},
							]}
						/>
					</Box>

					<Box
						sx={{
							marginTop: 3,
						}}
					>
						<CardInfoRow
							data={[
								{
									label: t('Birth Date'),
									value: getReadableBirthDate(birthDate),
									size: 6,
									highlighted: highlightedFields.includes('Birth Date'),
								},
								{
									label: t('Gender'),
									value: t(`${gender.toUpperCase()}`),
									size: 6,
									highlighted: highlightedFields.includes('Gender'),
								},
							]}
						/>
					</Box>

					<Box sx={{ marginTop: 2.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('Patient ID'),
									value: patientID,
									size: 6,
									highlighted: highlightedFields.includes('Patient ID'),
								},
								{
									label: t('Issuer of Patient ID'),
									value: managingOrganization,
									size: 6,
									highlighted: highlightedFields.includes('Issuer of Patient ID'),
								},
							]}
						/>
					</Box>
				</LinkDrawerCard>
			)}
		</>
	);
};
