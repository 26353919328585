export const mockProvidedByData = {
	extension: [
		{
			url: 'http://www.ramsoft.com/fhir/extension/organization/managingOrganization',
			valueReference: { id: '1', reference: 'Organization/1', display: 'RAMSOFT' },
		},
		{ url: 'http://www.ramsoft.com/fhir/extension/organization/isManaging', valueBoolean: false },
		{
			url: 'http://www.ramsoft.com/fhir/extension/organization/practiceType',
			valueString: 'Referring|Transcription',
		},
		{
			url: 'http://www.ramsoft.com/fhir/extension/organization/organizationType',
			valueCoding: { code: 'DEPT', display: 'Hospital Department' },
		},
		{
			url: 'http://www.ramsoft.com/fhir/extension/organization/address',
			valueAddress: {
				line: ['130 KING ST W'],
				city: 'TORONTO',
				state: 'ON',
				postalCode: 'M5X 2A2',
				country: 'CANADA',
			},
		},
		{
			url: 'http://www.ramsoft.com/fhir/extension/organization/imagingOrganization',
			valueReference: { id: '1', display: 'RAMSOFT' },
		},
	],
	reference: 'Organization/12414',
	display: 'TEST DISPLAY',
};

export const mockServiceData = {
	resourceType: 'HealthcareService',
	id: '1',
	meta: {},
	extension: [],
	active: true,
	providedBy: mockProvidedByData,
	name: 'Test Name',
	availableTime: [
		{ daysOfWeek: ['mon'], availableStartTime: '08:00:00', availableEndTime: '23:00:00' },
		{ daysOfWeek: ['tue'], availableStartTime: '08:00:00', availableEndTime: '23:00:00' },
		{ daysOfWeek: ['wed'], availableStartTime: '14:00:00', availableEndTime: '23:00:00' },
		{ daysOfWeek: ['thu'], availableStartTime: '08:00:00', availableEndTime: '23:00:00' },
		{ daysOfWeek: ['fri'], availableStartTime: '08:00:00', availableEndTime: '23:00:00' },
		{ daysOfWeek: ['sat'], availableStartTime: '08:00:00', availableEndTime: '23:00:00' },
		{ daysOfWeek: ['sun'], availableStartTime: '08:00:00', availableEndTime: '23:00:00' },
	],
	organization: 'TEST ORG',
	selected: true,
};

export const mockAppointmentData = {
	resourceType: 'Appointment',
	id: 'TEST ID',
	extension: [
		{
			url: 'http://www.ramsoft.com/fhir/StructureDefinition/modality',
			extension: [
				{
					url: 'modality',
					valueCoding: {
						code: 'CT',
						display: 'Computed Tomography',
					},
				},
				{
					url: 'modality',
					valueCoding: {
						code: 'MR',
						display: 'Magnetic Resonance',
					},
				},
			],
		},
	],
	status: 'pending',
	priority: 9,
	description: 'DESCRIPTION',
	supportingInformation: [{ id: '1', reference: 'ImagingStudy/1' }],
	start: new Date('2023-04-26T19:50:00.000Z'),
	end: new Date('2023-04-25T18:50:00.000Z'),
	minutesDuration: 60,
	basedOn: [{ reference: '/ServiceRequest/1' }],
	participant: [
		{
			actor: { id: '0', reference: 'Patient/0', display: 'TEST' },
			required: 'required',
			status: 'accepted',
		},
		{
			actor: { id: '1', reference: 'HealthcareService/1', display: 'TEST' },
			required: 'required',
			status: 'accepted',
		},
	],
	duration: 60,
	focused: false,
};

export const mockCalendarData = {
	data: [mockAppointmentData],
	day: new Date('2023-06-08T22:00:00.000Z'),
	service: mockServiceData,
	isHighlighted: false,
};

export const mockHealthcareService = {
	resourceType: 'HealthcareService',
	id: '141',
	meta: {
		extension: [
			{
				url: 'http://www.ramsoft.com/fhir/StructureDefinition/lastUpdateUser',
				valueReference: {
					id: '157',
					reference: 'Practitioner/157',
					display: 'TRAN^HA',
				},
			},
		],
		versionId: '17280818',
		lastUpdated: '2024-03-08T09:54:50.1981502+00:00',
	},
	extension: [
		{
			extension: [
				{
					url: 'text',
					valueCode: 'CT',
				},
			],
			url: 'http://www.ramsoft.com/fhir/StructureDefinition/modality',
		},
		{
			url: 'Duration',
			valueInteger: 60,
		},
		{
			url: 'http://www.ramsoft.com/fhir/extension/healthcareservice/color',
			valueString: '#3F6993',
		},
		{
			url: 'http://www.ramsoft.com/fhir/StructureDefinition/overBooking',
			valueBoolean: false,
		},
	],
	active: true,
	providedBy: {
		id: '2360',
		extension: [
			{
				url: 'http://www.ramsoft.com/fhir/extension/organization/managingOrganization',
				valueReference: {
					id: '2360',
					reference: 'Organization/2360',
					display: 'ALPHA MEDICAL',
				},
			},
			{
				url: 'http://www.ramsoft.com/fhir/extension/organization/isManaging',
				valueBoolean: true,
			},
			{
				url: 'http://www.ramsoft.com/fhir/extension/organization/practiceType',
				valueString: 'Imaging|Referring|Transcription',
			},
			{
				url: 'http://www.ramsoft.com/fhir/extension/organization/organizationType',
				valueCoding: {
					code: 'PROV',
					display: 'Healthcare Provider',
				},
			},
			{
				url: 'http://www.ramsoft.com/fhir/extension/organization/address',
				valueAddress: {
					line: ['252 EDISON RAPID'],
					city: 'WEST ALIASHIRE',
					state: 'ILLINOIS',
					postalCode: '58508',
					country: 'GUYANA',
				},
			},
			{
				url: 'http://www.ramsoft.com/fhir/StructureDefinition/phone',
				valueString: '12261860992',
			},
			{
				url: 'http://www.ramsoft.com/fhir/StructureDefinition/timezone',
				valueString: 'Central Standard Time',
			},
			{
				url: 'http://www.ramsoft.com/fhir/StructureDefinition/timezoneIANA',
				valueString: 'America/New_York',
			},
		],
		reference: 'Organization/2360',
		display: 'ALPHA MEDICAL',
	},
	name: 'ALPHA CT',
	availableTime: [
		{
			daysOfWeek: ['mon'],
			availableStartTime: '08:00:00',
			availableEndTime: '23:00:00',
		},
		{
			daysOfWeek: ['tue'],
			availableStartTime: '08:00:00',
			availableEndTime: '23:00:00',
		},
		{
			daysOfWeek: ['wed'],
			availableStartTime: '08:00:00',
			availableEndTime: '23:00:00',
		},
		{
			daysOfWeek: ['thu'],
			availableStartTime: '08:00:00',
			availableEndTime: '20:00:00',
		},
		{
			daysOfWeek: ['fri'],
			availableStartTime: '08:00:00',
			availableEndTime: '23:00:00',
		},
		{
			daysOfWeek: ['sun'],
			availableStartTime: '08:00:00',
			availableEndTime: '23:00:00',
		},
		{
			daysOfWeek: ['sat'],
			availableStartTime: '08:00:00',
			availableEndTime: '23:00:00',
		},
	],
	organization: 'ALPHA MEDICAL',
	selected: true,
};
