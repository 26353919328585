import React from 'react';
import Box from '@mui/material/Box';
import SecondaryButton from '../../../SecondaryButton';
import PrimaryButton from '../../../PrimaryButton';

export const MergeDrawerButtons = ({ closeDrawer, isActive, tooltip: Tooltip, onSubmit, t, isLoading }) => (
	<Box>
		{isActive && Tooltip && <Tooltip />}

		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				m: 2,
			}}
		>
			<SecondaryButton label={t('workList:cancelUpper')} testId="CancelSettings" onClick={closeDrawer} />
			<PrimaryButton
				disabled={!isActive}
				isLoading={isLoading}
				label={t('workList:mergeUpper')}
				testId="merge-button"
				onClick={onSubmit}
			/>
		</Box>
	</Box>
);
