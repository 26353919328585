// @flow

// core
import React, { type Node, useEffect, useState } from 'react';

// libs
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import _ from 'lodash';

// components
import { CardInfoRow } from '../../MergeDrawers/partials/CardInfoRow';
import { MergeDrawerCard } from '../../MergeDrawers/partials/MergeDrawerCard';

// utils
import { useSelection } from '@worklist-2/core/src/context/SelectionContext';
import { getReadableBirthDate } from '../../../utils/birthdayUtils';
import { useTranslation } from 'react-i18next';

interface IMergePatientDrawerCard {
	patientData: any;
	cardStyles: any;
	differentFields: string[];
}

export const MergePatientDrawerCard = ({ patientData, cardStyles, differentFields }: IMergePatientDrawerCard): Node => {
	const { t } = useTranslation(['patientInfo']);

	const {
		patientFirstName,
		patientLastName,
		patientID,
		birthDate,
		gender,
		patientAddress,
		cellPhone,
		managingOrganization,
		city,
		country,
		state,
	} = patientData;

	const {
		selectedMergePatient,
		selectedMergePatientModified,
		setSelectedMergePatient,
		setSelectedMergePatientModified,
		replacedFields,
		setReplacedFields,
	} = useSelection();

	const [isChecked, setChecked] = useState(false);

	useEffect(() => {
		const isCurrentlyChecked = selectedMergePatient?.patientID === patientID;
		setChecked(isCurrentlyChecked);
	}, [selectedMergePatient]);

	const handleCheck = e => {
		if (e.target.checked) {
			setSelectedMergePatient(patientData);
			setSelectedMergePatientModified(patientData);
			window.scrollTo(0, 0);
		} else {
			setSelectedMergePatient(null);
			setSelectedMergePatientModified(null);
		}
	};

	const handlePillClick = (isSelected, fieldName, value) => {
		const primaryPatient = _.cloneDeep(selectedMergePatientModified);

		if (isSelected) {
			switch (fieldName) {
				case 'patientLastName':
					primaryPatient.patientLastName = selectedMergePatient.patientLastName;
					break;
				case 'patientFirstName':
					primaryPatient.patientFirstName = selectedMergePatient.patientFirstName;
					break;
				case 'birthDate':
					primaryPatient.birthDate = selectedMergePatient.birthDate;
					break;
				case 'gender':
					primaryPatient.gender = selectedMergePatient.gender;
					break;
				case 'patientID':
					primaryPatient.patientID = selectedMergePatient.patientID;
					break;
				case 'managingOrganization':
					primaryPatient.managingOrganization = selectedMergePatient.managingOrganization;
					break;
				case 'country':
					primaryPatient.country = selectedMergePatient.country;
					break;
				case 'state':
					primaryPatient.state = selectedMergePatient.state;
					break;
				case 'city':
					primaryPatient.city = selectedMergePatient.city;
					break;
				case 'patientAddress':
					primaryPatient.patientAddress = selectedMergePatient.patientAddress;
					break;
				case 'cellPhone':
					primaryPatient.cellPhone = selectedMergePatient.cellPhone;
					break;
				default:
					break;
			}
			setReplacedFields(replacedFields.filter(it => it !== fieldName));
		} else {
			switch (fieldName) {
				case 'patientLastName':
					primaryPatient.patientLastName = value;
					break;
				case 'patientFirstName':
					primaryPatient.patientFirstName = value;
					break;
				case 'birthDate':
					primaryPatient.birthDate = value;
					break;
				case 'gender':
					primaryPatient.gender = value;
					break;
				case 'patientID':
					primaryPatient.patientID = value;
					break;
				case 'managingOrganization':
					primaryPatient.managingOrganization = value;
					break;
				case 'country':
					primaryPatient.country = value;
					break;
				case 'state':
					primaryPatient.state = value;
					break;
				case 'city':
					primaryPatient.city = value;
					break;
				case 'patientAddress':
					primaryPatient.patientAddress = value;
					break;
				case 'cellPhone':
					primaryPatient.cellPhone = value;
					break;
				default:
					break;
			}
			setReplacedFields([...replacedFields, fieldName]);
		}

		setSelectedMergePatientModified(primaryPatient);
	};

	return (
		<>
			{patientData && (
				<MergeDrawerCard cardStyles={cardStyles} icon={AccountCircleIcon} isSelected={isChecked}>
					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('Last Name'),
									value: patientLastName,
									size: 6,
									isDifferent: differentFields.includes('Patient Name'),
									showConflictPills: selectedMergePatient
										? patientID !== selectedMergePatient.patientID
										: false,
									fieldName: 'patientLastName',
									isFieldReplaced: replacedFields.includes('patientLastName'),
								},
								{
									label: t('First Name'),
									value: patientFirstName,
									size: 6,
									isDifferent: differentFields.includes('Patient Name'),
									showConflictPills: selectedMergePatient
										? patientID !== selectedMergePatient.patientID
										: false,
									fieldName: 'patientFirstName',
									isFieldReplaced: replacedFields.includes('patientFirstName'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>

					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('Birth Date'),
									value: getReadableBirthDate(birthDate),
									dateValue: birthDate,
									size: 6,
									isDifferent: differentFields.includes('Birth Date'),
									showConflictPills: selectedMergePatient
										? patientID !== selectedMergePatient.patientID
										: false,
									fieldName: 'birthDate',
									isFieldReplaced: replacedFields.includes('birthDate'),
								},
								{
									label: t('Gender'),
									value: gender ? t(`${gender.toUpperCase()}`) : 'N/A',
									size: 6,
									isDifferent: differentFields.includes('Gender'),
									showConflictPills: selectedMergePatient
										? patientID !== selectedMergePatient.patientID
										: false,
									fieldName: 'gender',
									isFieldReplaced: replacedFields.includes('gender'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>

					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('Patient ID'),
									value: patientID,
									size: 6,
									isDifferent: differentFields.includes('Patient ID'),
									showConflictPills: selectedMergePatient
										? patientID !== selectedMergePatient.patientID
										: false,
									fieldName: 'patientID',
									isFieldReplaced: replacedFields.includes('patientID'),
								},
								{
									label: t('Issuer of Patient ID'),
									value: managingOrganization,
									size: 6,
									isDifferent: differentFields.includes('Issuer of Patient ID'),
									showConflictPills: selectedMergePatient
										? patientID !== selectedMergePatient.patientID
										: false,
									fieldName: 'managingOrganization',
									isFieldReplaced: replacedFields.includes('managingOrganization'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>

					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('Country'),
									value: country,
									size: 6,
									isDifferent: differentFields.includes('Country'),
									showConflictPills: selectedMergePatient
										? patientID !== selectedMergePatient.patientID
										: false,
									fieldName: 'country',
									isFieldReplaced: replacedFields.includes('country'),
								},
								{
									label: t('State/Province'),
									value: state,
									size: 6,
									isDifferent: differentFields.includes('State'),
									showConflictPills: selectedMergePatient
										? patientID !== selectedMergePatient.patientID
										: false,
									fieldName: 'state',
									isFieldReplaced: replacedFields.includes('state'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>
					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('City'),
									value: city,
									size: 6,
									isDifferent: differentFields.includes('City'),
									showConflictPills: selectedMergePatient
										? patientID !== selectedMergePatient.patientID
										: false,
									fieldName: 'city',
									isFieldReplaced: replacedFields.includes('city'),
								},
								{
									label: t('Address'),
									value: patientAddress,
									size: 6,
									isDifferent: differentFields.includes('Address'),
									showConflictPills: selectedMergePatient
										? patientID !== selectedMergePatient.patientID
										: false,
									fieldName: 'patientAddress',
									isFieldReplaced: replacedFields.includes('patientAddress'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>
					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('Phone Number'),
									value: cellPhone,
									size: 6,
									isDifferent: differentFields.includes('Phone Number'),
									showConflictPills: selectedMergePatient
										? patientID !== selectedMergePatient.patientID
										: false,
									fieldName: 'cellPhone',
									isFieldReplaced: replacedFields.includes('cellPhone'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>

					<Box
						sx={{
							display: 'flex',
							padding: '10px',
						}}
					>
						<Checkbox
							checked={isChecked}
							checkedIcon={<RadioButtonCheckedIcon />}
							icon={<RadioButtonUncheckedIcon sx={{ fill: '#42A5F5' }} />}
							sx={{
								height: '20px',
								width: '20px',
								mr: '8px',
							}}
							onChange={handleCheck}
						/>
						<Typography
							sx={{
								color: 'rgba(255, 255, 255, 0.6)',
								fontSize: '14px',
								whiteSpace: 'nowrap',
							}}
						>
							{t('Select this patient as primary record')}
						</Typography>
					</Box>
				</MergeDrawerCard>
			)}
		</>
	);
};
