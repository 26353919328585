import { fhirExtensionUrls } from '@worklist-2/core/src';
import subDays from 'date-fns/subDays';

export const getAppointmentTriggers = (start, existingExtensions = []) => {
	const isBlocked = existingExtensions?.find(
		({ url }) => url === fhirExtensionUrls.appointment.blockedTime
	)?.valueBoolean;
	const isBusy = existingExtensions?.find(({ url }) => url === fhirExtensionUrls.appointment.busyTime)?.valueBoolean;
	let appointmentExtension = [...existingExtensions];
	if (!isBlocked && !isBusy) {
		const MS_PER_DAY = 86400000;
		let currentDateTime = new Date();
		const timeDifference = Math.abs(start.getTime() - currentDateTime.getTime());
		let appointment24HrsNotificationTrigger = appointmentExtension.find(
			x => x.url == fhirExtensionUrls.appointment.appointment24HrsNotificationTrigger
		);
		let appointment72HrsNotificationTrigger = appointmentExtension.find(
			x => x.url == fhirExtensionUrls.appointment.appointment72HrsNotificationTrigger
		);
		if (timeDifference > MS_PER_DAY) {
			if (appointment24HrsNotificationTrigger) {
				appointment24HrsNotificationTrigger.valueDateTime = subDays(start, 1);
			} else {
				appointmentExtension.push({
					url: fhirExtensionUrls.appointment.appointment24HrsNotificationTrigger,
					valueDateTime: subDays(start, 1),
				});
			}
		}
		if (timeDifference > MS_PER_DAY * 3) {
			if (appointment72HrsNotificationTrigger) {
				appointment72HrsNotificationTrigger.valueDateTime = subDays(start, 3);
			} else {
				appointmentExtension.push({
					url: fhirExtensionUrls.appointment.appointment72HrsNotificationTrigger,
					valueDateTime: subDays(start, 3),
				});
			}
		}
	}
	return appointmentExtension;
};
