import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import StudyIcon from '@worklist-2/ui/src/assets/icons/StudyIcon.svg';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { CardInfoRow } from './CardInfoRow';
import { MergeDrawerCard } from './MergeDrawerCard';

import { useSelection, fhirExtensionUrls } from '@worklist-2/core/src';

const transformDate = value => {
	if (!value) return '';

	const dateTime = new Date(value);
	return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
};

const MergeOrderDrawerCardIcon = () => (
	<Box
		sx={{
			width: '23px',
			height: '23px',
			borderRadius: '50%',
			boxSizing: 'border-box',
			backgroundColor: '#A66BA3',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',

			'& svg': {
				height: '13px',
				width: '10px',
			},
		}}
	>
		<StudyIcon />
	</Box>
);

export const MergeOrderDrawerCard = ({ data, cardStyles, t }) => {
	const { started, extension, imagingOrganization, modality, description } = data;

	const studyStatus = extension?.find(ext => ext.url == fhirExtensionUrls.common.studyStatus)?.valueCoding.display;
	const accessionNumber = data.identifier?.find(
		ext => ext.system === fhirExtensionUrls.imagingStudy.accession
	)?.value;
	const { selectedMergeStudy, setSelectedMergeStudy } = useSelection();
	const [isChecked, setChecked] = useState(false);

	useEffect(() => {
		const isCurrentlyChecked = selectedMergeStudy?.studyID === data?.studyID;
		setChecked(isCurrentlyChecked);
	}, [selectedMergeStudy]);

	const handleCheck = e => {
		if (e.target.checked) {
			setSelectedMergeStudy(data);
		} else {
			setSelectedMergeStudy(null);
		}
	};

	return (
		<>
			{data && (
				<MergeDrawerCard cardStyles={cardStyles} icon={MergeOrderDrawerCardIcon}>
					<Box
						sx={{
							marginTop: 2,
						}}
					>
						<CardInfoRow
							data={[
								{
									label: t('workList:imagingWorkListColumns.studyStatus'),
									value: studyStatus,
									size: 6,
								},
								{
									label: t('workList:imagingWorkListColumns.studyDateTime'),
									value: transformDate(started),
									size: 6,
								},
							]}
						/>
					</Box>

					<Box
						sx={{
							marginTop: 3,
						}}
					>
						<CardInfoRow
							data={[
								{
									label: t('workList:imagingWorkListColumns.accessionNumber'),
									value: accessionNumber,
									size: 6,
								},
								{
									label: t('workList:imagingWorkListColumns.imagingOrganization'),
									value: imagingOrganization,
									size: 6,
								},
							]}
						/>
					</Box>

					<Box sx={{ marginTop: 2.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('workList:imagingWorkListColumns.modality'),
									value: modality[0]?.code,
									size: 6,
								},
								{
									label: t('workList:imagingWorkListColumns.studyDescription'),
									value: description,
									size: 6,
								},
							]}
						/>
					</Box>

					<Box
						sx={{
							display: 'flex',
							padding: '10px',
						}}
					>
						<Checkbox
							checked={isChecked}
							checkedIcon={<RadioButtonCheckedIcon />}
							icon={<RadioButtonUncheckedIcon sx={{ fill: '#42A5F5' }} />}
							sx={{
								height: '20px',
								width: '20px',
								mr: '8px',
							}}
							onChange={handleCheck}
						/>
						<Typography
							noWrap
							sx={{
								color: 'rgba(255, 255, 255, 0.6)',
								fontSize: '14px',
							}}
						>
							{t('workList:mergeStudyDrawer.keepStudy')}
						</Typography>
					</Box>
				</MergeDrawerCard>
			)}
		</>
	);
};
