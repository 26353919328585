// @flow

// utils
import { findExtensionIndex, fhirExtensionUrls, getExtensionValueString } from '@worklist-2/core/src';
import { AlcoholUseCodes, SmokingCodes } from '@worklist-2/ui/src/views/PatientInfoView/utils';

export const preauthStatusOptions = ['accepted', 'denied', 'pending'];

/** #NOTE: currently unused */
export const preauthStatusOptionsDisplays = {
	accepted: 'Accepted',
	denied: 'Denied',
	pending: 'Pending',
};

export const overallPreauthStatusOptions = ['accepted', 'denied', 'someNotClear', 'pending'];

export const COVERAGE_LEVELS = ['PRIMARY', 'SECONDARY', 'TERTIARY'];

// ================================================================================

interface IconvertExtensionIntoPreauthReturn {
	studyId: string;
	preauthInfoList: {
		coverageId?: string,
		insuranceEndDate?: any | null,
		insuranceStartDate?: any | null,
		preauthorizationCode?: string,
		preauthStatus?: string,
	}[];
}

/**
 * @test covered
 */
export const convertExtensionIntoPreauth = (
	extension: any,
	studiesPreauthUrl?: string
): IconvertExtensionIntoPreauthReturn[] => {
	const studiesPreauthIndex = findExtensionIndex(extension, studiesPreauthUrl);
	let studiesPreauthInfoList: IconvertExtensionIntoPreauthReturn[] = [];

	if (studiesPreauthIndex >= 0) {
		const studiesPreauthExtension = extension[studiesPreauthIndex].extension;
		studiesPreauthInfoList = studiesPreauthExtension.map(studyPreauth => ({
			studyId: studyPreauth.valueString,
			preauthInfoList:
				studyPreauth.extension?.map(preauth => ({
					coverageId: preauth.extension[findExtensionIndex(preauth.extension, 'coverageId')]?.valueString,
					insuranceEndDate:
						preauth.extension[findExtensionIndex(preauth.extension, 'insuranceEndDate')]?.valueDateTime ||
						null,
					insuranceStartDate:
						preauth.extension[findExtensionIndex(preauth.extension, 'insuranceStartDate')]?.valueDateTime ||
						null,
					preauthorizationCode:
						preauth.extension[findExtensionIndex(preauth.extension, 'preauthorizationCode')]?.valueString,
					preauthStatus:
						preauth.extension[findExtensionIndex(preauth.extension, 'preauthStatus')]?.valueString,
				})) || [],
		}));
	}

	return studiesPreauthInfoList;
};

// ================================================================================

interface IconvertPreauthIntoExtensionReturn {
	url: string;
	extension: {
		url: string,
		valueString: string,
		extension: {
			url: string,
			extension: {
				url: string,
				valueString: string,
			}[],
		}[],
	}[];
}

/**
 * @test covered
 */
export const convertPreauthIntoExtension = (
	newStudiesPreauthInfoList: any[],
	studyPreauthUrl: string,
	studiesPreauthUrl: string,
	preauthUrl: string
): IconvertPreauthIntoExtensionReturn => {
	const extension = newStudiesPreauthInfoList.map(preauthInfo => ({
		url: studyPreauthUrl,
		valueString: preauthInfo.studyId,
		extension: preauthInfo.preauthInfoList.map(item => ({
			url: preauthUrl,
			extension: [
				{
					url: 'coverageId',
					valueString: item.coverageId,
				},
				{
					url: 'preauthStatus',
					valueString: item.preauthStatus,
				},
				{
					url: 'insuranceStartDate',
					valueDateTime: item.insuranceStartDate,
				},
				{
					url: 'insuranceEndDate',
					valueDateTime: item.insuranceEndDate,
				},
				{
					url: 'preauthorizationCode',
					valueString: item.preauthorizationCode,
				},
			],
		})),
	}));

	return { url: studiesPreauthUrl, extension };
};

// ================================================================================

interface IcoverageMapperReturn {
	coverageLevel?: any;
	errorMessage?: string;
	/**
	 * Hardcoded value
	 * @default ''
	 */
	faxNumber: string;
	groupNumber?: string;
	id?: any;
	insuredsId?: any;
	internalPayerId?: any;
	payer?: string;
	payerId?: string;
	/**
	 * Hardcoded value
	 * @default ''
	 */
	phoneNumber: string;
	subscriberId?: string;
	status?: string;
}

/**
 * @test yes
 */
export const coverageMapper = (coverages: any[]): IcoverageMapperReturn[] => {
	const result: IcoverageMapperReturn[] = [];

	if (!Array.isArray(coverages)) return result;

	coverages.forEach(cov => {
		if (cov.resource?.status === 'active') {
			result.push({
				id: cov.resource?.id,
				internalPayerId: cov.resource?.payor?.[0]?.id,
				payer: cov.resource?.payor?.[0]?.display,
				insuredsId: cov.resource?.subscriber?.id,
				subscriberId: cov.resource?.subscriberId,
				payerId: getExtensionValueString(cov.resource?.payor?.[0], fhirExtensionUrls.organization.payerID),
				eligibilityPayerID: getExtensionValueString(
					cov.resource?.payor?.[0],
					fhirExtensionUrls.organization.eligibilityPayerID
				),
				groupNumber: cov.resource?.class?.find(item => item.name === 'group')?.value,
				status: cov.resource.extension.find(e => e.url === fhirExtensionUrls.coverage.insuranceStatus)
					?.valueCoding?.display,
				coverageLevel: COVERAGE_LEVELS.find((level, ind) => ind === cov.resource?.order - 1), // order is index-1
				errorMessage: getExtensionValueString(cov.resource, fhirExtensionUrls.coverage.rejectionReason),
				faxNumber: '', // Need to add real value
				phoneNumber: '', // Need to add real value
			});
		}
	});

	return result;
};

// ================================================================================

export const getPatientDrinking = (code: string): boolean => code !== AlcoholUseCodes.nonDrinker;

export const getPatientSmoking = (code: string): boolean =>
	code !== SmokingCodes.nonSmoker && code !== SmokingCodes.unknownIfEverSmoked;
