// @flow

// core
import React, { type Node } from 'react';

// libs
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';

// utils
import { getDescriptionText } from '@rs-components/utils/getDescriptionText';

interface ILinkPatientTooltipProps {
	highlightedFields: string[];
	type: string;
}

export const LinkMergePatientTooltip = ({ highlightedFields, type }: ILinkPatientTooltipProps): Node => {
	const { t } = useTranslation('patientInfo');

	return (
		<Box
			sx={{
				width: '76%',
				margin: 'auto',
				my: '10px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					mb: '12px',
				}}
			>
				<ErrorOutlineIcon data-testid="error-icon" sx={{ color: '#CF6679' }} />

				<Typography
					sx={{
						display: 'flex',
						alignItems: 'center',
						fontSize: '14px',
						ml: '12px',
					}}
				>
					{type === 'LINK' ? t('sureToLinkPatients') : t('sureToMergePatients')}
				</Typography>
			</Box>

			<Typography
				sx={{
					fontSize: '14px',
					color: 'rgba(255, 255, 255, 0.6)',
					whiteSpace: 'pre-line',
				}}
			>
				{getDescriptionText(highlightedFields, t)}
			</Typography>
		</Box>
	);
};
