// api
import { fhirExtensionUrls } from '@worklist-2/core/src';
// libraries
import _ from 'lodash';

export const getPrimaryText = (item, isPatientPortalMode, isWorklistMode) => {
	switch (item.resourceType ?? item.ResourceType ?? item.resource.ResourceType) {
		case 'ImagingStudyWorklist':
		case 'ImagingStudy':
			if (isPatientPortalMode) {
				return getMyStudyPrimaryText(item);
			}
			return getImagingStudyWorklistPrimaryText(item);
		case 'Patient':
			return getPatientPrimaryText(item);
		case 'DICOMResult':
			return getDicomResultPrimaryText(item);
		case 'Organization':
			return getOrganizationPrimaryText(item);
		case 'WorkListLayout':
			return `${item.name}`;
		case 'Practitioner':
			return getPractitionerPrimaryText(item);
		case 'BlumeStudy':
			return getMyStudyPrimaryText(item);
		case 'StudySharedWithMe':
			return getSharedByPrimaryText(item);
		case 'helpcenter':
			if (isWorklistMode) {
				return getOmegaAIHelpCenterPrimaryText(item);
			}
			return getHelpCenterPrimaryText(item);
		case 'MarketplaceApp':
			return getMarketplacePrimaryText(item);

		default:
			return '';
	}
};

export const getSecondaryText = (item, isPatientPortalMode, isWorklistMode) => {
	switch (item.resourceType ?? item.ResourceType ?? item.resource.ResourceType) {
		case 'ImagingStudyWorklist':
		case 'ImagingStudy':
			if (isPatientPortalMode) {
				return getMyStudySecondaryText(item);
			}
			return getImagingStudyWorklistSecondaryText(item);
		case 'Patient':
			return getPatientSecondaryText(item);
		case 'DICOMResult':
			return getDicomResultSecondaryText(item);
		case 'Organization':
			return getOrganizationSecondaryText(item);
		case 'WorkListLayout':
			return `${item.id}`;
		case 'Practitioner':
			return getPractitionerSecondaryText(item);
		case 'BlumeStudy':
			return getMyStudySecondaryText(item);
		case 'StudySharedWithMe':
			return getSharedBySecondaryText(item);
		case 'helpcenter':
			if (isWorklistMode) {
				return getOmegaAIHelpCenterSecondaryText(item);
			}
			return getHelpCenterSecondaryText(item);
		case 'MarketplaceApp':
			return getMarketplaceSecondaryText(item);

		default:
			return '';
	}
};

// Imaging study

const getImagingStudyWorklistPrimaryText = study => {
	let patientName = study.subject.display;
	let description = study.description;

	if (study.eTag) {
		const eTag = JSON.parse(study.eTag);

		if (eTag.PatientName) {
			patientName = _.replace(eTag.PatientName, /[\[\]]/g, '');
		}

		if (eTag.StudyDescription) {
			description = _.replace(eTag.StudyDescription, /[\[\]]/g, '');
		}
	}

	return [patientName, description].filter(Boolean).join(' - ').replace(/em>/g, 'mark>').toUpperCase();
};

const getImagingStudyWorklistSecondaryText = study => {
	let studyID = study.studyID || study.id;

	let studystatus = study.extension?.find(({ url }) => url === fhirExtensionUrls.common.studyStatus)?.valueCoding
		?.display;

	let accessionNumber = study.identifier?.find(({ type }) => type?.coding?.some(acc => acc.code === 'ACSN'))?.value;

	const studyDateTime = new Date(study.started);
	const studyDateTimeDisplay = `${studyDateTime.toLocaleDateString()} ${studyDateTime.toLocaleTimeString()}`;

	if (study.eTag) {
		const eTag = JSON.parse(study.eTag);

		if (eTag.StudyID) {
			studyID = _.replace(eTag.StudyID, /[\[\]]/g, '');
		}

		if (eTag.StatusName) {
			studystatus = _.replace(eTag.StatusName, /[\[\]]/g, '');
		}

		if (eTag.AccessionNumber) {
			accessionNumber = _.replace(eTag.AccessionNumber, /[\[\]]/g, '');
		}
	}

	return [studyID, studystatus, studyDateTimeDisplay, accessionNumber]
		.filter(Boolean)
		.join(' - ')
		.replace(/em>/g, 'mark>')
		.toUpperCase();
};

// Patient

const getPatientPrimaryText = patient => {
	let patientName = patient.name?.[0]?.text;

	let patientId = patient.identifier?.find(
		elem => elem.type?.coding[0]?.code === 'MR' && elem.system === 'urn:oid:1.2.36.146.595.217.0.1'
	)?.value;

	if (patient.eTag) {
		const eTag = JSON.parse(patient.eTag);

		if (eTag.PatientName) {
			patientName = _.replace(eTag.PatientName, /[\[\]]/g, '');
		}

		if (eTag.PatientID) {
			patientId = _.replace(eTag.PatientID, /[\[\]]/g, '');
		}
	}

	return [patientName, patientId].filter(Boolean).join(' - ').replace(/em>/g, 'mark>').toUpperCase();
};

const getPatientSecondaryText = patient => {
	let birthDate = patient.birthDate,
		managingOrganization = patient.managingOrganization?.display,
		gender = patient.gender,
		ssn = patient.identifier?.find(
			elem => elem.type?.coding[0]?.code === 'SS' && elem.system === 'urn:oid:1.2.36.146.595.217.0.1'
		)?.value;

	if (patient.eTag) {
		const eTag = JSON.parse(patient.eTag);

		if (eTag.BirthDate) {
			birthDate = eTag.BirthDate.replace(/[\[\]]/g, '');
		}

		if (eTag.Gender) {
			gender = eTag.Gender.replace(/[\[\]]/g, '');
		}

		if (eTag.OrganizationName) {
			managingOrganization = eTag.OrganizationName.replace(/[\[\]]/g, '');
		}

		if (eTag.Ssn) {
			ssn = eTag.Ssn.replace(/[\[\]]/g, '');
		}
	}

	return [birthDate, gender, managingOrganization, ssn]
		.filter(Boolean)
		.join(' - ')
		.replace(/em>/g, 'mark>')
		.toUpperCase();
};

// Organization

const getOrganizationPrimaryText = org => {
	let orgName = org.name;
	let npiOrgId = org.identifier?.find(elem => elem.type?.coding[0]?.code === 'NPI')?.value || org.id;

	if (org.eTag) {
		const eTag = JSON.parse(org.eTag);

		if (eTag.OrganizationName) {
			orgName = eTag.OrganizationName.replace(/[\[\]]/g, '');
		}

		if (eTag.IdentifierID) {
			npiOrgId = eTag.IdentifierID.replace(/[\[\]]/g, '');
		}
	}

	return [orgName, npiOrgId].filter(Boolean).join(' - ').replace(/em>/g, 'mark>').toUpperCase();
};

const getOrganizationSecondaryText = org => {
	let address = [
		org.address?.[0]?.line?.[0],
		org.address?.[0]?.city,
		org.address?.[0]?.state,
		org.address?.[0]?.country,
	]
		.filter(Boolean)
		.join(' ');
	let email = org.telecom?.find(({ system }) => system === 'email')?.value;
	let phone = org.telecom?.find(({ system }) => system === 'phone')?.value;

	if (org.eTag) {
		const eTag = JSON.parse(org.eTag);

		if (eTag.Address) {
			address = eTag.Address.replace(/[\[\]]/g, '');
		}

		if (eTag.Email) {
			email = eTag.Email.replace(/[\[\]]/g, '');
		}

		if (eTag.WorkPhone) {
			phone = eTag.WorkPhone.replace(/[\[\]]/g, '');
		}
	}

	return [address, email, phone].filter(Boolean).join(' - ').replace(/em>/g, 'mark>').toUpperCase();
};

// Practitioner (User)

const getPractitionerPrimaryText = prc => {
	let userName = prc.name?.[0]?.text;
	let loginName = prc.extension?.find(elem => elem.url === fhirExtensionUrls.practitioner.loginName)?.valueString;

	if (prc.eTag) {
		const eTag = JSON.parse(prc.eTag);

		if (eTag.UserName) {
			userName = _.replace(eTag.UserName, /[\[\]]/g, '');
		}

		if (eTag.LoginName) {
			loginName = _.replace(eTag.LoginName, /[\[\]]/g, '');
		}
	}

	return [loginName, userName].filter(Boolean).join(' - ').replace(/em>/g, 'mark>').toUpperCase();
};

const getPractitionerSecondaryText = prc => {
	let phone = prc.telecom?.find(elem => elem.system === 'phone' || elem.system === 'work')?.value;
	let npi = prc.identifier?.find(elem => elem.type?.coding?.some(acc => acc.code === 'NPI'))?.value;

	if (prc.eTag) {
		const eTag = JSON.parse(prc.eTag);

		if (eTag.WorkPhone) {
			phone = _.replace(eTag.WorkPhone, /[\[\]]/g, '');
		}

		if (eTag.NPIID) {
			npi = _.replace(eTag.NPIID, /[\[\]]/g, '');
		}
	}

	return [phone, npi].filter(Boolean).join(' - ').replace(/em>/g, 'mark>').toUpperCase();
};

// DICOM

const getDicomResultPrimaryText = dcm => {
	const patientName = _.get(dcm, '00100010.Value[0].Alphabetic');
	const patientID = _.get(dcm, '00100020.Value[0]');
	const accession = _.get(dcm, '00080050.Value[0]');
	const description = _.get(dcm, '00081030.Value[0]');

	return [patientName, patientID, accession, description].filter(Boolean).join(' - ').toUpperCase();
};

const getDicomResultSecondaryText = dcm => {
	const deviceName = _.get(dcm, 'owner.display');
	const modalities = _.get(dcm, '00080061.Value');
	const status = _.get(dcm, '0032000A.Value[0]');
	const date = _.get(dcm, '00080020.Value[0]');
	const time = _.get(dcm, '00080030.Value[0]');

	let studyDateTime = '';

	if (date && time) {
		const year = date.substring(0, 4);
		const month = date.substring(4, 6);
		const day = date.substring(6, 8);

		const hour = time.substring(0, 2);
		const min = time.substring(2, 4);
		const sec = time.substring(4, 6);

		const dt = new Date(`${[year, month, day].join('/')} ${[hour, min, sec].join(':')}`);

		studyDateTime = `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
	}

	return [deviceName, modalities, status, studyDateTime].filter(Boolean).join(' - ').toUpperCase();
};

// Marketplace

const getMarketplacePrimaryText = item => item.name;

const getMarketplaceSecondaryText = item => [item.categories[0], item.author.name, item.description].join(' - ');

// Others

const getSharedByPrimaryText = study => {
	return getMyStudyPrimaryText(study);
};
const getSharedBySecondaryText = study => {
	return getMyStudySecondaryText(study);
};
const getMyStudyPrimaryText = study => {
	let studyID = study.StudyID || study.id;
	let description = study.Description;

	if (study.eTag) {
		const eTag = JSON.parse(study.eTag);

		if (eTag.Description) {
			description = _.replace(eTag.Description, /[\[\]]/g, '');
		}

		if (eTag.StudyID) {
			studyID = _.replace(eTag.StudyID, /[\[\]]/g, '');
		}
	}

	return [description, studyID].filter(Boolean).join(' - ').replace(/em>/g, 'mark>').toUpperCase();
};
const getMyStudySecondaryText = study => {
	const studyDateTime = new Date(study.ScanDate);
	const studyDateTimeDisplay = `${studyDateTime.toLocaleDateString()}`;
	let organizationName = study.ImagingOrganization?.Name;

	if (study.eTag) {
		const eTag = JSON.parse(study.eTag);

		if (eTag.ImagingOrganization?.Name) {
			organizationName = _.replace(eTag.ImagingOrganization.Name, /[\[\]]/g, '');
		}
	}

	return [studyDateTimeDisplay, organizationName].filter(Boolean).join(' - ').replace(/em>/g, 'mark>').toUpperCase();
};
const getHelpCenterPrimaryText = helpCenter => {
	var content = helpCenter.Content;

	if (helpCenter.eTag) {
		const eTag = JSON.parse(helpCenter.eTag);

		if (eTag.Content) {
			content = _.replace(eTag.Content, /[\[\]]/g, '');
		}
	}

	return [content].filter(Boolean).join(' - ').replace(/em>/g, 'mark>').toUpperCase();
};
const getHelpCenterSecondaryText = helpCenter => {
	var catalogy = helpCenter.Category;

	if (helpCenter.eTag) {
		const eTag = JSON.parse(helpCenter.eTag);

		if (eTag.Category) {
			catalogy = _.replace(eTag.Category, /[\[\]]/g, '');
		}
	}

	return [catalogy].filter(Boolean).join(' - ').replace(/em>/g, 'mark>').toUpperCase();
};

const getOmegaAIHelpCenterPrimaryText = helpCenter => {
	var content = helpCenter.Content || helpCenter.content || helpCenter.resource?.Content;
	var eTagRaw = helpCenter.eTag || helpCenter.response?.eTag;

	if (eTagRaw) {
		const eTag = JSON.parse(eTagRaw);

		if (eTag.Content) {
			content = _.replace(eTag.Content, /[\[\]]/g, '');
		}
	}

	return [content].filter(Boolean).join(' - ').replace(/em>/g, 'mark>').toUpperCase();
};

const getOmegaAIHelpCenterSecondaryText = helpCenter => {
	return helpCenter.Content || helpCenter.content || helpCenter.resource?.Content || '';
};
