// @flow
import React, { useState, type Node } from 'react';

//mui
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { keyframes } from '@emotion/react';

interface ISearchWorklist {
	onSearchClick: () => void;
	onCloseSearchClick: () => void;
	handleSearch: (value: string) => void;
	searchValue: string;
}

const rotateAnimation = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`;

const SearchWorklist = ({ onSearchClick, onCloseSearchClick, handleSearch, searchValue }: ISearchWorklist): Node => {
	const [open, setOpen] = useState(false);
	const [focused, setFocused] = useState(false);

	const handleOpen = () => {
		setOpen(true);
		onSearchClick();
		setFocused(true);
	};

	const handleClose = () => {
		setOpen(false);
		onCloseSearchClick();
	};

	const handleInputChange = (value: string): void => {
		handleSearch(value);
	};

	const searchStyles = {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		borderRadius: '4px',
		marginLeft: 0,
		width: open ? '200px' : '40px',
		transition: 'width 300ms ease-in-out',
		'@media (min-width: 600px)': {
			marginLeft: '8px',
			width: open ? '240px' : '48px',
		},
	};

	const searchIconStyles = {
		padding: '0px 5px',
		height: '100%',
		position: 'absolute',
		left: '7px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	};

	const inputRootStyles = {
		color: 'inherit',
		margin: '20px 0 10px 0',
		borderRadius: '20px',
		cursor: 'pointer',
	};

	const inputInputStyles = {
		padding: '1px 5px 1px 0',
		paddingLeft: 'calc(1em + 18px)',
		border: focused && open ? '1px solid #42A5F5' : 'unset',
		marginLeft: '10px',
		width: '100%',
		color: '#FFFFFFDE',
		fontSize: '14px',
		backgroundColor: open ? '#5050504D' : 'unset',
		outline: open ? '3px solid #5050504D' : 'none',
	};

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
			<div data-testid="search-styles" style={searchStyles}>
				<div style={searchIconStyles}>
					<Box
						sx={{
							position: 'relative',
							margin: '20px 0 10px 0',
							width: '30px',
							height: '30px',
						}}
					>
						<Box
							data-testid="search-icon"
							sx={{
								position: 'absolute',
								top: '0',
								left: '0',
								cursor: 'pointer',
								width: '30px',
								height: '30px',
								background: !open
									? 'conic-gradient(from 153.43deg at 75% 50%, #42A5F5 0deg, rgba(161, 88, 180, 0.867179) 47.82deg, #FFFFFF 56.25deg, #42A5F5 163.09deg, #42F5EA 189.38deg, #42A5F5 360deg)'
									: 'transparent',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '50%',
								boxShadow: !open ? '0px 0px 4px 0px #42A5F5B2' : 'none',
								transition: 'background 0.3s ease-in-out',
								animation: !open ? `${rotateAnimation} 2s linear infinite` : 'none',
							}}
							onClick={() => ''}
						/>

						<Box
							data-cy="searchWorkListIcon"
							sx={{
								position: 'absolute',
								top: '0.5px',
								left: '0.5px',
								width: '29px',
								height: '29px',
								borderRadius: '50%',
								backgroundColor: '#151515',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<SearchIcon
								sx={{
									color: '#fff',
									fontSize: '16px',
								}}
							/>
						</Box>
					</Box>
				</div>
				<InputBase
					data-cy="searchWorkListTextBox"
					data-testid="input-element"
					placeholder="Search worklist"
					style={{
						...inputRootStyles,
						...inputInputStyles,
					}}
					value={searchValue}
					onChange={({ target }) => handleInputChange(target.value)}
					onMouseEnter={handleOpen}
				/>
			</div>

			{open && (
				<Box
					data-testid="close-icon"
					sx={{
						margin: '20px 0 10px 0',
						cursor: 'pointer',
						width: '32px',
						height: '32px',
						borderRadius: '50%',
						backgroundColor: 'rgba(80, 80, 80, 0.30)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					onClick={handleClose}
				>
					<CloseIcon sx={{ color: 'rgba(255, 255, 255, 0.87)' }} />
				</Box>
			)}
		</Box>
	);
};

export default SearchWorklist;
