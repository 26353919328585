/* Generated automagically by FhirStarter - but please feel free to edit this file */
import { fhirExtensionUrls } from '../../extension';
const WorkListLayoutMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	name: {
		label: 'Name',
		options: { filter: false, sort: false },
		queryParameter: 'name',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.name;
		},
	},
	active: {
		label: 'Active',
		options: { filter: false, sort: false },
		queryParameter: 'active',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.active;
		},
	},
	practitioner: {
		label: 'Practitioner',
		options: { filter: false, sort: false },
		queryParameter: 'practitioner',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.practitioner;
		},
	},
	role: {
		label: 'Role',
		options: { filter: false, sort: false },
		queryParameter: 'role',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.role;
		},
	},
	organization: {
		label: 'Organization',
		options: { filter: false, sort: false },
		queryParameter: 'organization',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.organization;
		},
	},
	flagsave: {
		label: 'Flagsave',
		options: { filter: false, sort: false },
		queryParameter: 'flagsave',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.flagsave;
		},
	},
};
export default WorkListLayoutMapping;
