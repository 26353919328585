import { useEffect } from 'react';
import { useImportTabContext } from '@worklist-2/core/src';
import { usePatientStore } from '@rs-ui/views/PatientInformationView/store/patientStore';
import { parseDocument } from '@rs-ui/views/PatientInformationView/utils';
import { PATIENT_DOCUMENT_FILTERS } from '@worklist-2/worklist/src/DocumentViewerV3/consts/consts';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import useOnDocumentDrop from '@worklist-2/worklist/src/DocumentViewerV3/features/DocumentViewer/hooks/useOnDocumentDrop';
import { useDocumentViewerContext } from '@worklist-2/worklist/src/DocumentViewerV3/contexts/DocumentViewerContextProvider';
import { usePatientInfoCardStore } from '@rs-ui/components/PatientInfoCards/store/patientInfoCardStore';

function handleInputFiles({
	files,
	importTabs,
	updateImportTabs,
	updateImportSelectedTab,
	resourceType,
	isFlag = false,
}) {
	const newFiles = [];
	const folders = [];

	for (const file of files) {
		folders.push(file.name);
		file.progress = 0;
		file.folderName = file.name;
		newFiles.push(file);
	}

	if (newFiles.length > 0) {
		const newTabs = [...importTabs];

		const newTabObject = {
			label: 'Ready',
			files: newFiles,
			folders,
			studyObject: null,
			messageEvent: {},
			source: 'PatientDocument',
			resourceType,
		};

		if (isFlag) {
			// Push to the end if the flag is true
			newTabs.push(newTabObject);
			updateImportSelectedTab(newTabs.length - 1); // Set the selected tab to the new one
		} else {
			// Push to the front if the flag is false
			newTabs.unshift(newTabObject);
			updateImportSelectedTab(0); // Set the selected tab to the new one
		}

		updateImportTabs(newTabs);
	}
}

const useUploadPatientDocument = () => {
	const { importTabs, updateImportTabs, updateImportSelectedTab, setDocumentPayload, setSource } =
		useImportTabContext();
	const { setDocuments } = usePatientStore();
	const galaxyDvUploadUiWidgetNew = useBooleanFlagValue('galaxy-dv-upload-ui-widget-new');

	const { onDocumentDrop } = useOnDocumentDrop();
	const { viewports } = useDocumentViewerContext();
	const { selectedCard } = usePatientInfoCardStore();

	const onDocumentUploadHandler = async (payload, file, resourceType) => {
		await setSource('');
		setDocumentPayload(payload);

		handleInputFiles({
			files: file,
			importTabs,
			updateImportTabs,
			updateImportSelectedTab,
			resourceType,
			isFlag: galaxyDvUploadUiWidgetNew,
		});
		setSource('PatientDocument');
	};

	useEffect(() => {
		const handleImportTabs = () => {
			if (importTabs.length) {
				importTabs.forEach(tab => {
					if (tab.source === 'PatientDocument' && tab.label === 'Completed' && tab?.messageEvent) {
						if (tab?.resourceType === 'DocumentReference') {
							const result = tab.messageEvent;
							const parsedDoc = {
								...(result.content ? parseDocument(result) : {}),
								documentType: PATIENT_DOCUMENT_FILTERS.UPLOADED_DOCUMENT,
								resourceType: 'PatientDocument',
								createdDate: new Date().toISOString(),
							};
							// Try isolating this step
							setDocuments(prevDocs => {
								if (prevDocs.some(doc => doc.id === parsedDoc.id)) {
									return prevDocs;
								}
								return [parsedDoc, ...prevDocs];
							});

							// Open the document on successful upload if selected card is Patient Document
							if (selectedCard?.code === 'DOCUMENTS' && result.content) {
								onDocumentDrop({ viewport: viewports[0], document: parsedDoc });
							}
						}
					}
				});
			}
		};

		handleImportTabs();
	}, [importTabs]);

	return {
		onDocumentUploadHandler,
	};
};

export default useUploadPatientDocument;
