import axios from 'axios';

const getOrgImagingCenter = async ({ __config, orgId, modality, lat, long }) => {
	const response = await axios.get(
		`${__config.data_sources.blume}Appointment/findOrganizationsByServiceModalitiesAndPreference?&organizationId=${orgId}&serviceModalities=${modality}&userLat=${lat}&userLong=${long}`,
		{
			headers: { 'X-API-KEY': window.btoa(__config.patient_portal.authentication_key) },
		}
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default getOrgImagingCenter;
