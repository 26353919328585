// core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// libraries
import _ from 'lodash';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import HealthAndSafetyOutlined from '@mui/icons-material/HealthAndSafetyOutlined';
import PhoneEnabledOutlined from '@mui/icons-material/PhoneEnabledOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LinkIcon from '@mui/icons-material/Link';

// utils
import { getUserFullName } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';
import { getNameInitials } from '@worklist-2/worklist/src/SchedulerV2/utils';
import { scopeMapping, searchScopes, useFhirDataLoader } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';
import DateOfBirthIcon from '../../../assets/icons/DateOfBirth.svg';
import PatientIdIcon from '../../../assets/icons/patientId.svg';

// custom componenets
import Avatar from '../../Avatar';
import { PatientAppointmentDetails } from './PatientAppointmentDetails';
import { LinkedPatients } from '../../LinkedPatients/LinkedPatients';
import { LinkAvatar } from '@rs-components/LinkAvatar/LinkAvatar';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const textStyle = {
	width: 'calc(100% - 88px)',
	fontSize: '14px',
	lineHeight: '20px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	letterSpacing: '0.25px',
	color: 'rgba(255, 255, 255, 0.6)',
	alignSelf: 'center',
};

const PatientDetails = ({ patientInfo, isLoading, hasPermissionToDisplayLinkAndMerge }) => {
	const boxStyle = {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		mt: '10px',
	};

	const { t } = useTranslation('scheduler');

	const validLinks = patientInfo?.link?.filter(l => l.type === 'seealso');
	const rows = [
		{
			rowStyle: { ml: '38px', mt: '0px' },
			icon: <PatientIdIcon />,
			dataStyle: {
				ml: '28px',
			},
			label: t('appointmentCard.patientId'),
			isVisible: true,
			isComponent: false,
			data: patientInfo?.identifier?.[0]?.value ?? '',
		},
		{
			rowStyle: { ml: '36px' },
			icon: (
				<HealthAndSafetyOutlined
					sx={{
						Width: '17px',
						Height: '21px',
						color: 'icon.primary',
						background: 'icon.background',
					}}
				/>
			),
			dataStyle: {
				ml: '26px',
			},
			label: t('appointmentCard.confidentiality'),
			isVisible: true,
			isComponent: false,
			data: patientInfo?.meta?.security?.[0]?.display ?? '',
		},
		{
			rowStyle: { ml: '39px' },
			icon: <DateOfBirthIcon />,
			dataStyle: {
				ml: '28px',
			},
			label: t('appointmentCard.dateofBirth'),
			isComponent: false,
			isVisible: true,
			data: patientInfo?.birthDate ?? '',
		},
		{
			rowStyle: { ml: '36px' },
			icon: (
				<PhoneEnabledOutlined
					sx={{
						Width: '20px',
						Height: '20px',
						color: 'icon.primary',
						background: 'icon.background',
					}}
				/>
			),
			dataStyle: {
				ml: '26px',
			},
			label: t('appointmentCard.phoneNumber'),
			isComponent: false,
			isVisible: true,
			data: patientInfo?.telecom?.find(tele => tele.system == 'phone')?.value ?? '',
		},
		{
			rowStyle: { ml: '36px' },
			icon: (
				<LinkIcon
					sx={{
						Width: '20px',
						Height: '20px',
						color: 'icon.primary',
						background: 'icon.background',
					}}
				/>
			),
			dataStyle: {
				ml: '26px',
			},
			isComponent: true,
			getComponent: () => <LinkedPatients linkedPatients={validLinks} />,
			isVisible:
				hasPermissionToDisplayLinkAndMerge &&
				Array.isArray(validLinks) &&
				validLinks.filter(it => Object.keys(it).length > 0)?.length,
			data: patientInfo?.telecom?.find(tele => tele.system == 'phone')?.value ?? '',
		},
	];

	return rows.map((row, rowIdx) =>
		isLoading ? (
			<Box key={rowIdx} sx={{ ...boxStyle, ml: '36px' }}>
				<Skeleton
					sx={{
						width: '20px',
						height: '20px',
					}}
					variant="rectangle"
				/>
				<Skeleton
					sx={{
						ml: '26px',
						width: 'calc(100% - 80px)',
						height: '20px',
					}}
					variant="rectangle"
				/>
			</Box>
		) : (
			<>
				{row.isVisible ? (
					<Box key={rowIdx} sx={{ ...boxStyle, ...row.rowStyle }}>
						{row.icon}
						{row.isComponent ? (
							row.getComponent()
						) : (
							<Typography
								sx={{
									...textStyle,
									...row.dataStyle,
								}}
							>
								{`${row.label}: ${row.data}`}
							</Typography>
						)}
					</Box>
				) : null}
			</>
		)
	);
};

const PatientProfileHoverView = ({ patientId, patientInfoOnly, hasPermissionToDisplayLinkAndMerge }) => {
	const [patientInfo, setPatientInfo] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [expanded, setExpanded] = useState(false);
	const [appointmentList, setAppointmentList] = useState([]);

	const proactDisableTimeZoneFromAppointments = useBooleanFlagValue('proact-disable-time-zone-from-appointments');
	const dataLoader = useFhirDataLoader();

	const loadPatient = () => {
		if (patientId) {
			dataLoader.updateScope({
				scope: searchScopes.patientById,
				endpoint: scopeMapping[searchScopes.patientById],
			});

			return dataLoader
				.load({
					id: patientId,
				})
				.then(res => {
					setPatientInfo(res);
				});
		}
		return Promise.resolve();
	};

	const loadAppointments = () => {
		if (patientId) {
			dataLoader.updateScope({
				scope: searchScopes.appointment,
				endpoint: scopeMapping[searchScopes.appointment],
				// endpoint: `${scopeMapping[searchScopes.appointment]}?date=${moment(
				// 	new Date()
				// ).format('YYYY-MM-DDTHH:mm:ss')}`,
			});

			return dataLoader
				.load(
					proactDisableTimeZoneFromAppointments
						? { participant: `patient/${patientId}`, isWallClock: true }
						: { participant: `patient/${patientId}` },
					true
				)
				.then(res => {
					const filteredAppoitments = res?.entry?.filter(
						entry => new Date(entry.resource?.start).getTime() >= new Date().getTime()
					);

					const upcomingAppointments = !_.isEmpty(filteredAppoitments)
						? filteredAppoitments.map(i => i.resource)
						: [];

					setAppointmentList(upcomingAppointments);
				});
		}
	};

	useEffect(() => {
		setIsLoading(true);
		Promise.all([loadPatient(), loadAppointments()]).finally(() => setIsLoading(false));
	}, [patientId]);

	useEffect(() => {
		if (!isLoading)
			setTimeout(() => {
				setExpanded(true);
			}, 1000);
	}, [isLoading]);

	const cardStyle = {
		boxSizing: 'border-box',
		width: '339px',
		minHeight: patientInfoOnly ? '254px' : '304px',
		background: '#393939',
		boxShadow: '10px 10px 30px #121212',
		borderRadius: '6px',
	};

	const { t } = useTranslation('scheduler');

	return (
		<Card sx={cardStyle}>
			<Box
				data-testid="patient-profile-hover-view"
				sx={{
					display: 'flex',
					flexDirection: 'row',
					mt: '30px',
				}}
			>
				{isLoading ? (
					<>
						<Skeleton
							sx={{
								width: '54px',
								height: '48px',
								ml: '24px',
							}}
							variant="circular"
						/>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								width: 'calc(100% - 34px)',
								ml: '12px',
							}}
						>
							<Skeleton
								sx={{
									height: '24px',
									width: 'calc(100% - 34px)',
								}}
								variant="rectangle"
							/>
							<Skeleton
								sx={{
									width: 'calc(100% - 34px)',
									mt: '6px',
								}}
								variant="rectangle"
							/>
						</Box>
					</>
				) : (
					<>
						<Box sx={{ ml: '24px', position: 'relative' }}>
							<Avatar
								height="48px"
								iconText={getNameInitials(patientInfo?.name?.[0]?.text)}
								label={patientInfo?.name?.[0]?.text}
								transparent={false}
								width="48px"
							/>

							{hasPermissionToDisplayLinkAndMerge && patientInfo?.link?.[0]?.other && (
								<Box sx={{ position: 'absolute', bottom: '0', right: '-2px' }}>
									<LinkAvatar text={patientInfo?.link?.[0]?.other?.display} />
								</Box>
							)}
						</Box>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								width: 'calc(100% - 112px)',
								ml: '16px',
							}}
						>
							<Typography
								sx={{
									...textStyle,
									lineHeight: '24px',
									fontSize: '24px',
									color: 'rgba(255, 255, 255, 0.87)',
									width: '100%',
								}}
							>
								{patientInfo?.name?.[0] && getUserFullName(patientInfo?.name?.[0], true)}
							</Typography>
							<Typography
								sx={{
									...textStyle,
									mt: '6px',
									width: '100%',
								}}
							>
								{t('gender')}:{' '}
								{patientInfo?.gender
									? patientInfo.gender.charAt(0).toUpperCase() + patientInfo.gender.slice(1)
									: ''}
							</Typography>
						</Box>
					</>
				)}
			</Box>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					boxShadow: 0,
					mt: '20px',
				}}
			>
				<PatientDetails
					hasPermissionToDisplayLinkAndMerge={hasPermissionToDisplayLinkAndMerge}
					isLoading={isLoading}
					patientInfo={patientInfo}
				/>
				{!patientInfoOnly && (
					<>
						<Divider
							sx={{
								mt: '20px',
								ml: '32px',
								mr: '32px',
								mb: '20px',
							}}
							variant="middle"
						/>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								ml: '36px',
								mb: '10px',
							}}
						>
							{isLoading ? (
								<>
									<Skeleton
										sx={{
											width: '20px',
											height: '20px',
										}}
										variant="rectangle"
									/>
									<Skeleton
										sx={{
											ml: '26px',
											width: 'calc(100% - 80px)',
											height: '20px',
										}}
										variant="rectangle"
									/>
								</>
							) : (
								<>
									<CalendarMonthOutlinedIcon
										sx={{
											Width: '18px',
											Height: '20px',
											color: 'icon.primary',
											background: 'icon.background',
										}}
									/>
									<Typography
										sx={{
											...textStyle,
											ml: '26px',
										}}
									>
										{`${t('appointmentCard.upcomingAppointment')} (${appointmentList?.length})`}
									</Typography>
								</>
							)}
						</Box>
					</>
				)}
			</Box>
			{appointmentList?.length > 0 && !patientInfoOnly && (
				<Collapse
					in={expanded}
					sx={{
						maxHeight: '256px',
						overflowY: 'auto',
						mt: '8px',
						ml: '16px',
						mr: '16px',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{appointmentList.map((item, aptIdx) => (
						<PatientAppointmentDetails key={aptIdx} appointment={item} />
					))}
				</Collapse>
			)}
		</Card>
	);
};

PatientProfileHoverView.prototypes = {
	/**
	 * Internal patient id used to fetch patient information
	 */
	patientId: PropTypes.number.isRequired,
	/**
	 * When enabled, we will not show the future appointment section in the card
	 */
	patientInfoOnly: PropTypes.bool,
	/**
	 * When enabled, we will show the linked patients
	 */
	hasPermissionToDisplayLinkAndMerge: PropTypes.bool,
};

PatientProfileHoverView.defaultProps = {
	patientInfoOnly: false,
};

export default PatientProfileHoverView;
