import _ from 'lodash';

const getAddress = (addressObj, uppercase = false) => {
	if (!addressObj) return null;

	const tempArray = [];

	Object.entries(addressObj).forEach(([, val]) => {
		if (val) tempArray.push(uppercase ? _.uppercase(val) : _.capitalize(val));
	});

	return tempArray.join(', ');
};

export default getAddress;
