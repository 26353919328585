import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { CardInfoRow } from './CardInfoRow';
import { MergeDrawerCard } from './MergeDrawerCard';

import { useSelection } from '@worklist-2/core/src/context/SelectionContext';
import { getReadableBirthDate } from '../../../utils/birthdayUtils';
import { useTranslation } from 'react-i18next';

export const MergePatientDrawerCardV2 = ({ data, cardStyles, differentFields }) => {
	const {
		birthDate,
		cellPhone,
		gender = '',
		managingOrganization,
		patientAddress,
		patientID,
		state,
		studyID,
		patientLastName,
		patientFirstName,
		city,
		country,
	} = data;

	const {
		selectedMergeStudy,
		setSelectedMergeStudy,
		modifySelectedMergeStudy,
		selectedMergeStudyModified,
		replacedFields,
		setReplacedFields,
	} = useSelection();
	const [isChecked, setChecked] = useState(false);

	const { t } = useTranslation(['workList', 'codePool']);

	useEffect(() => {
		const isCurrentlyChecked = selectedMergeStudy?.studyID === studyID;
		setChecked(isCurrentlyChecked);
	}, [selectedMergeStudy]);

	const handleCheck = e => {
		if (e.target.checked) {
			setSelectedMergeStudy(data);
			modifySelectedMergeStudy(data);
			window.scrollTo(0, 0);
		} else {
			setSelectedMergeStudy(null);
			modifySelectedMergeStudy(null);
		}
	};

	const handlePillClick = (isSelected, fieldName, value) => {
		const current = { ...selectedMergeStudyModified };
		if (isSelected) {
			current[fieldName] = selectedMergeStudy[fieldName];
			setReplacedFields(replacedFields.filter(it => it !== fieldName));
		} else {
			current[fieldName] = value;
			setReplacedFields([...replacedFields, fieldName]);
		}
		modifySelectedMergeStudy(current);
	};

	return (
		<>
			{data && (
				<MergeDrawerCard cardStyles={cardStyles} icon={AccountCircleIcon} isSelected={isChecked}>
					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('workListDrawer.lastName'),
									value: patientLastName,
									size: 6,
									isDifferent: differentFields.includes('patientName'),
									showConflictPills: selectedMergeStudy
										? studyID !== selectedMergeStudy.studyID
										: false,
									fieldName: 'patientLastName',
									isFieldReplaced: replacedFields.includes('patientLastName'),
								},
								{
									label: t('workListDrawer.firstName'),
									value: patientFirstName,
									size: 6,
									isDifferent: differentFields.includes('patientFirstName'),
									showConflictPills: selectedMergeStudy
										? studyID !== selectedMergeStudy.studyID
										: false,
									fieldName: 'patientFirstName',
									isFieldReplaced: replacedFields.includes('patientFirstName'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>

					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('workListDrawer.birthDate'),
									value: getReadableBirthDate(birthDate),
									dateValue: birthDate,
									size: 6,
									isDifferent: differentFields.includes('birthDate'),
									showConflictPills: selectedMergeStudy
										? studyID !== selectedMergeStudy.studyID
										: false,
									fieldName: 'birthDate',
									isFieldReplaced: replacedFields.includes('birthDate'),
								},
								{
									label: t('workListDrawer.sex'),
									value: gender ? t(`codePool:sex.${gender.toUpperCase()}`) : 'N/A',
									size: 6,
									isDifferent: differentFields.includes('gender'),
									showConflictPills: selectedMergeStudy
										? studyID !== selectedMergeStudy.studyID
										: false,
									fieldName: 'gender',
									isFieldReplaced: replacedFields.includes('gender'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>

					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('workListDrawer.patientId'),
									value: patientID,
									size: 6,
									isDifferent: differentFields.includes('patientID'),
									showConflictPills: selectedMergeStudy
										? studyID !== selectedMergeStudy.studyID
										: false,
									fieldName: 'patientID',
									isFieldReplaced: replacedFields.includes('patientID'),
								},
								{
									label: t('workListDrawer.patientIdIssuer'),
									value: managingOrganization,
									size: 6,
									isDifferent: differentFields.includes('managingOrganization'),
									showConflictPills: selectedMergeStudy
										? studyID !== selectedMergeStudy.studyID
										: false,
									fieldName: 'managingOrganization',
									isFieldReplaced: replacedFields.includes('managingOrganization'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>

					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('Country'), // t('workListDrawer.country'),
									value: country || 'N/A',
									size: 6,
									isDifferent: differentFields.includes('country'),
									showConflictPills: selectedMergeStudy
										? studyID !== selectedMergeStudy.studyID
										: false,
									fieldName: 'country',
									isFieldReplaced: replacedFields.includes('country'),
								},
								{
									label: t('State/Province'), // t('workListDrawer.stateProv'),
									value: state || 'N/A',
									size: 6,
									isDifferent: differentFields.includes('state'),
									showConflictPills: selectedMergeStudy
										? studyID !== selectedMergeStudy.studyID
										: false,
									fieldName: 'state',
									isFieldReplaced: replacedFields.includes('managingOrganization'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>
					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('City'), // t('workListDrawer.city')
									value: city || 'N/A',
									size: 6,
									isDifferent: differentFields.includes('city'),
									showConflictPills: selectedMergeStudy
										? studyID !== selectedMergeStudy.studyID
										: false,
									fieldName: 'city',
									isFieldReplaced: replacedFields.includes('city'),
								},
								{
									label: t('Address'), // t('workListDrawer.patientAddress'),
									value: patientAddress || 'N/A',
									size: 6,
									isDifferent: differentFields.includes('patientAddress'),
									showConflictPills: selectedMergeStudy
										? studyID !== selectedMergeStudy.studyID
										: false,
									fieldName: 'patientAddress',
									isFieldReplaced: replacedFields.includes('patientAddress'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>
					<Box sx={{ marginTop: 1.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('Phone Number'), // t('workListDrawer.cellPhone'),
									value: cellPhone || 'N/A',
									size: 6,
									isDifferent: differentFields.includes('patientEmail'), //Only to mock data !!!
									showConflictPills: selectedMergeStudy
										? studyID !== selectedMergeStudy.studyID
										: false,
									fieldName: 'cellPhone',
									isFieldReplaced: replacedFields.includes('cellPhone'),
								},
							]}
							handlePillClick={handlePillClick}
							isCardChecked={isChecked}
							replacedFields={replacedFields}
						/>
					</Box>

					<Box
						sx={{
							display: 'flex',
							padding: '10px',
						}}
					>
						<Checkbox
							checked={isChecked}
							checkedIcon={<RadioButtonCheckedIcon />}
							icon={<RadioButtonUncheckedIcon sx={{ fill: '#42A5F5' }} />}
							sx={{
								height: '20px',
								width: '20px',
								mr: '8px',
							}}
							onChange={handleCheck}
						/>
						<Typography
							noWrap
							sx={{
								color: 'rgba(255, 255, 255, 0.6)',
								fontSize: '14px',
							}}
						>
							{t('workListDrawer.mergePatientMessage')}
						</Typography>
					</Box>
				</MergeDrawerCard>
			)}
		</>
	);
};
