// @flow

// core
import React, { type Node, useMemo } from 'react';

// mui
import { Box } from '@mui/material';

// components
import GenericDrawer from '../GenericDrawer';
import {
	MergeDrawerCardsWrap as LinkDrawerCardsWrap,
	MergeDrawerContentSection as LinkDrawerContentSection,
} from '@rs-components/Drawers/MergeDrawers/styles';
import { LinkDrawerButtons, LinkMergePatientTooltip, LinkPatientDrawerCard } from './partials';
import { Loader } from '@rs-ui/components/Loader/Loader';

// utils
import { searchScopes, useFhirDataLoader, useSelection } from '@worklist-2/core/src';
import { getUserFullName } from '@rs-core/fhir';
import { createLinkPayload } from '@rs-ui/views/PatientInfoView/utils/utils';
import { findDifferentFields } from '@rs-components/Drawers/MergePatientsDrawer/utils';
import { useTranslation } from 'react-i18next';
// store
import { usePatientStore } from '@rs-ui/views/PatientInfoView/patientStore';

const DRAWER_WIDTH = 487;

interface ILinkPatientDrawerProps {
	drawerOpen: boolean;
	toastUtility: any;
	onCloseSearch?: () => void;
	setDrawerOpen: (value: boolean) => void;
}

export const LinkPatientDrawer = ({
	drawerOpen,
	setDrawerOpen,
	onCloseSearch,
	toastUtility,
	isLinkDrawerLoading,
}: ILinkPatientDrawerProps): Node => {
	const { t } = useTranslation('patientInfo');
	// STORE
	const { setLinkedPatients, patient, linkedPatients } = usePatientStore();

	// SELECTION UTIL
	const { handleResourceReset, selectedPatients } = useSelection();

	// FLAG EITHER TO SHOW CARDS STACK OR NOT
	const isCardListVisible = selectedPatients.length > 0;

	// LOADERS
	const patientLoader = useFhirDataLoader({
		scope: searchScopes.patient,
	});

	// HOLDS DIFFERENT FIELDS OF PATIENT CARDS
	const highlightedFields = useMemo(() => findDifferentFields(selectedPatients, 'link'), [selectedPatients]);

	const handleLink = async () => {
		const payload = createLinkPayload(patient.patient, selectedPatients[1], linkedPatients);

		try {
			// Updating patient
			await patientLoader.update(patient.patient.id, payload);
			// Setting lings in store
			setLinkedPatients(payload.link);
			// Close drawer and store on success
			setDrawerOpen(false);
			if (onCloseSearch) {
				onCloseSearch();
			}
			// Show success toast
			toastUtility(
				true,
				`${getUserFullName(selectedPatients[0].patient.name[0])} ${t('and')} ${getUserFullName(
					selectedPatients[1].patient.name[0]
				)} ${t('haveBeenLinked')}`
			);
			// Reset selected resources
			handleResourceReset();
		} catch (e) {
			// Show failed toast in request is failing
			toastUtility(
				true,
				`${t('failedToLink')} ${getUserFullName(selectedPatients[0]?.patient.name[0])} ${t(
					'and'
				)} ${getUserFullName(selectedPatients[1]?.patient.name[0])}`
			);
		}
	};

	// CANCEL DRAWER HANDLER
	const onCancelClick = () => {
		handleResourceReset();
		setDrawerOpen(false);
	};

	return (
		<GenericDrawer
			headerDividerFlag
			showBackBtn
			customHeaderTextProps={{
				variant: 'h6',
				sx: {
					fontSize: '20px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					mr: 'auto',
				},
			}}
			drawerOpen={drawerOpen}
			setDrawerOpen={setDrawerOpen}
			title={t('Link Patient')}
			width={DRAWER_WIDTH}
		>
			<Loader.LinkCards isLoading={isLinkDrawerLoading}>
				<LinkDrawerContentSection>
					<LinkDrawerCardsWrap>
						{isCardListVisible &&
							selectedPatients.map((i, idx) => (
								<LinkPatientDrawerCard
									key={i.id + idx}
									cardStyles={{ width: '75%', mb: '17px' }}
									highlightedFields={highlightedFields}
									patientData={i}
									t={t}
								/>
							))}
					</LinkDrawerCardsWrap>
				</LinkDrawerContentSection>

				{highlightedFields.length > 0 && (
					<Box sx={{ position: 'absolute', bottom: '70px', width: '100%' }}>
						<LinkMergePatientTooltip highlightedFields={highlightedFields} type="LINK" />
					</Box>
				)}

				<LinkDrawerButtons isActive closeDrawer={onCancelClick} t={t} onSubmit={handleLink} />
			</Loader.LinkCards>
		</GenericDrawer>
	);
};
