import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { searchScopes, useAuth, useConfig, useFhirDataLoader } from '@worklist-2/core/src';
import { v4 as uuid } from 'uuid';
import { TIME_STAMP, FN_TYPES, MEASUREMENT_SEQUENCE } from '../consts/measurements';
import { useImageViewerPermissionsContext } from '../contexts/ImageViewerPermissionsContext';
import { useImageViewerLayoutContext } from '../contexts/ImageViewerLayoutContext';
import {
	PERMISSIONS_MODULES,
	MEASUREMENT_PANEL_PERMISSIONS,
} from '@worklist-2/worklist/src/DocumentViewerV3/consts/imageViewerPermissionTags';
import axios from 'axios';

export const useMeasurements = () => {
	const __config = useConfig();
	const { authorized } = useAuth();
	const [searchParams] = useSearchParams();
	const { layoutItemsRef, highlightedViewportRef } = useImageViewerLayoutContext();
	const [studyMeasurement, setStudyMeasurement] = useState({});
	const { imageViewerPermission } = useImageViewerPermissionsContext();
	const internalManagingId = searchParams.get('internalManagingOrganizationID');
	const hasMeasUpdatePermission =
		imageViewerPermission?.[PERMISSIONS_MODULES.MEASUREMENT_PANEL]?.[
			MEASUREMENT_PANEL_PERMISSIONS.ANNOTATIONS_MEASUREMENTS
		]?.update;
	const measurementLoader = useFhirDataLoader({
		scope: searchScopes.measurement,
	});
	const fetchData = async (loader, value) => {
		let data = [];
		let bodyData;
		if (!loader) {
			loader = measurementLoader;
		}

		if (authorized) {
			try {
				const result = await loader[FN_TYPES.load](
					value,
					true,
					bodyData,
					__config.data_sources.dicom_web,
					true
				);
				if (result.status === 200 && result.StudyInstanceUID) {
					data = result[MEASUREMENT_SEQUENCE] || [];
					setStudyMeasurement(prev => ({
						...prev,
						[result.StudyInstanceUID]: result,
					}));
				}
			} catch (error) {
				console.error(error);
			}
		}

		return data;
	};

	const updateMeasurements = async (measurements, inputStudyUid) => {
		const measurementStudyUID = measurements && measurements.length > 0 ? measurements[0].studyId : inputStudyUid;
		const MeasurementID = studyMeasurement[measurementStudyUID]
			? studyMeasurement[measurementStudyUID].MeasurementID
			: uuid();

		const measurementLayoutItem = (
			highlightedViewportRef?.current ? [highlightedViewportRef.current] : layoutItemsRef?.current || []
		).find(layoutItem => layoutItem?.series?.studyInstanceUID === measurementStudyUID);
		const managingOrgId = measurementLayoutItem?.series?.managingOrganizationId || internalManagingId;

		let measurementObj = {
			MeasurementID,
			Managingorganizationid: managingOrgId,
			StudyInstanceUID: measurementStudyUID,
			managingOrgStudyInstanceUid: `${managingOrgId}-${measurementStudyUID}`,
			[MEASUREMENT_SEQUENCE]: cleanUpCachedStats(measurements),
		};

		if (!studyMeasurement[measurementStudyUID]) {
			measurementObj = { ...measurementObj, [TIME_STAMP]: new Date().getTime() };
		}

		if (authorized) {
			if (studyMeasurement[measurementStudyUID]) {
				setStudyMeasurement(prev => ({
					...prev,
					[measurementStudyUID]: measurementObj,
				}));
				if (hasMeasUpdatePermission) {
					return measurementLoader[FN_TYPES.update]('', measurementObj, __config.data_sources.dicom_web, {
						StudyInstanceUID: measurementStudyUID,
						InternalManagingOrgId: managingOrgId,
						MeasurementID,
					});
				}
				return true;
			}
			setStudyMeasurement(prev => ({
				...prev,
				[measurementStudyUID]: measurementObj,
			}));
			if (hasMeasUpdatePermission) {
				return measurementLoader[FN_TYPES.save](
					{
						StudyInstanceUID: measurementStudyUID,
						InternalManagingOrgId: managingOrgId,
						MeasurementID,
					},
					measurementObj,
					'',
					__config.data_sources.dicom_web
				);
			}
			return true;
		}
		throw new Error('Not authorized');
	};

	const updateMeasurementItem = async (measurements, measurementToUpdate) => {
		const measurementID = getMeasurementIDByItem(measurementToUpdate, measurements);
		const measurementStudyUID = measurements && measurements.length > 0 ? measurements[0].studyId : null;
		const isNewMeasurement =
			(studyMeasurement?.[measurementStudyUID]?.['Measurement Sequence'] || []).findIndex(
				item => item.annotationUID === measurementToUpdate.annotationUID
			) === -1;

		if (studyMeasurement[measurementStudyUID]) {
			if (hasMeasUpdatePermission) {
				const extraParams = !isNewMeasurement ? `/${measurementToUpdate.annotationUID}` : '';
				const url = `${__config.data_sources.dicom_web}/measurement/${measurementID}${extraParams}`;
				return axios[isNewMeasurement ? 'post' : 'put'](url, cleanUpCachedStats([measurementToUpdate])[0]).then(
					response => response?.status === 200
				);
			}

			return true;
		}
		return updateMeasurements(measurements);
	};

	const deleteMeasurementItem = async (measurement, measurements, callback) => {
		const updatedMeasurements = measurements.filter(m => m.annotationUID !== measurement.annotationUID);
		const measurementID = getMeasurementIDByItem(measurement, measurements);
		if (measurementID) {
			if (hasMeasUpdatePermission) {
				await axios.delete(
					`${__config.data_sources.dicom_web}/measurement/${measurementID}/${measurement.annotationUID}`
				);

				if (typeof callback === 'function') callback(updatedMeasurements);
			}
		} else {
			console.error('Error while deleting measurement -> measurementID cannot be null');
		}
	};

	const loadSRobjects = async studyUID => {
		try {
			const response = await axios.get(
				`${__config.data_sources.dicom_web}/studyresource?StudyInstanceUID=${studyUID}&ResourceType=StructuredReportMeasurementsObject&InternalManagingOrganizationId=${internalManagingId}`
			);
			if (response?.status === 200 && Array.isArray(response?.data)) {
				return response.data;
			}
			return [];
		} catch (error) {
			console.error(error);
		}
	};

	const getMeasurementIDByItem = (item, measurements) => {
		const measurementID = item.MeasurementID;
		const measurementStudyUID = measurements && measurements.length > 0 ? measurements.find((item) => item.studyId)?.studyId : null;

		if (!measurementStudyUID) {
			throw new Error('Error while deleting measurement -> measurementStudyUID cannot be null');
		}

		if (!measurementID) {
			const foundMeasurementID = studyMeasurement[measurementStudyUID]
				? studyMeasurement[measurementStudyUID].MeasurementID
				: null;

			if (!foundMeasurementID) {
				console.error('Error while deleting measurement -> MeasurementID not found');
			}

			return foundMeasurementID;
		}
		return measurementID;
	};

	const cleanUpCachedStats = (measurements, propsToRemove = ['pointsInShape']) =>
		measurements.map(item => {
			if (item?.data?.cachedStats) {
				Object.keys(item.data.cachedStats).forEach(statKey => {
					propsToRemove.forEach(prop => {
						delete item.data.cachedStats[statKey][prop];
					});
				});
			}
			return item;
		});

	return {
		fetchData,
		loadSRobjects,
		updateMeasurements,
		updateMeasurementItem,
		deleteMeasurementItem,
		measurementLoader,
	};
};
