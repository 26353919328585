// core
import React from 'react';

// MUI
import styled from 'styled-components';
import Zoom from '@mui/material/Zoom';
import Paper from '@mui/material/Paper';

// libraries
import PropTypes from 'prop-types';

const StepperRoot = styled(Paper)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	borderRadius: '20px',
});

const StepperDots = styled('div')(({ dotActive }) => ({
	borderRadius: '50%',
	width: 8,
	height: 8,
	backgroundColor: 'rgba(196, 196, 196, 0.3)',
	...(dotActive && {
		backgroundColor: '#c4c4c4',
	}),
}));

const Stepper = ({ isOpen, pages, activePage, onClick, styles }) =>
	pages > 1 ? (
		<Zoom in={isOpen}>
			<StepperRoot
				steps={pages}
				sx={{
					paddingX: '4px',
					marginTop: '20px',
					marginBottom: '10px',
					marginX: 'auto',
					...styles,
				}}
			>
				{[...new Array(pages)].map((_, index) => (
					// use a div to make the target hit area bigger without affecting the size of the dots
					<div
						key={index}
						style={{ padding: '8px 4px' }}
						onClick={event => {
							onClick && onClick(event, index);
						}}
					>
						<StepperDots key={index} dotActive={index === activePage} />
					</div>
				))}
			</StepperRoot>
		</Zoom>
	) : null;

Stepper.propTypes = {
	/**
	 * Determines if the component should be visible or not based on the isOpen flag
	 */
	isOpen: PropTypes.bool,

	/**
	 * Indicates how many "pages" or "steps" are required to be shown
	 */
	pages: PropTypes.number,

	/**
	 * Determines which "page" is active
	 */
	activePage: PropTypes.number,

	/**
	 * callback handler for when a dot is clicked
	 */
	onClick: PropTypes.func,

	/**
	 * Custom styles
	 */
	styles: PropTypes.object,
};

Stepper.defaultProps = {
	isOpen: false,
	pages: -1,
	activePage: 0,
};

export default Stepper;
