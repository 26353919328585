import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';

const reorderColumn = (draggedColumnId, targetColumnId, draggableColumnOrder) => {
	draggableColumnOrder.splice(
		draggableColumnOrder.indexOf(targetColumnId),
		0,
		draggableColumnOrder.splice(draggableColumnOrder.indexOf(draggedColumnId), 1)[0]
	);
	return [...draggableColumnOrder];
};

const DataGridColumnHeader = ({
	header,
	index,
	draggableTable,
	enableColumnDnd = false,
	flexRender,
	canResizeColumns,
	columnResizeMode,
	setCurrWorklistColumns,
	lastColWidth,
	onMouseOver,
	onMouseOut,
	cellSx,
	rows,
}) => {
	const { getState, setColumnOrder } = draggableTable;
	const { columnOrder } = getState();
	const { column } = header;
	const ref = useRef(null);

	const [, dropRef] = useDrop({
		accept: 'COLUMN',
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		hover: (item, monitor) => {
			if (!ref.current || !enableColumnDnd) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Not allowed to move to same position
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Determine pixels to the left
			const hoverClientX = clientOffset.x - hoverBoundingRect.left;

			// Only move when cursor has crossed half (50%) of column width
			if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
				return;
			}

			// Move the column
			const newColumnOrder = reorderColumn(item.id, column.id, columnOrder);
			setColumnOrder(newColumnOrder);

			// Set Worklist Columns to show save button
			setCurrWorklistColumns?.(newColumnOrder);

			// Assigning index to avoid expensive searches
			item.index = hoverIndex;
		},
	});

	const [, dragRef] = useDrag({
		canDrag: enableColumnDnd,
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
		item: { id: column.id, index },
		type: 'COLUMN',
	});

	return (
		<TableCell
			{...{
				key: header.id,
				colSpan: header.colSpan,
				sx: {
					width: header.getSize(),
					whiteSpace: 'nowrap',
					'&:first-of-type': {
						width: '24px !important',
						'.resizer': {
							display: 'none',
						},
					},
					'&:last-of-type': {
						width: lastColWidth,
						'.resizer': {
							display: 'none',
						},
					},
					...cellSx,
				},
			}}
			{...(onMouseOver ? { onMouseOver } : null)}
			{...(onMouseOver ? { onMouseOut } : null)}
			key={header.id}
			ref={enableColumnDnd ? dragRef(dropRef(ref)) : ref}
		>
			<Box>
				{header.isPlaceholder
					? null
					: flexRender(header.column.columnDef.header, { rows, ...header.getContext() })}

				{canResizeColumns && (
					<div
						{...{
							onMouseDown: e => {
								// This prevents drag action from happening
								e.stopPropagation();
								e.preventDefault();
								const handler = header.getResizeHandler();
								handler(e);
							},
							onTouchStart: header.getResizeHandler(),
							className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
							style: {
								transform:
									columnResizeMode === 'onEnd' && header.column.getIsResizing()
										? `translateX(${draggableTable.getState()?.columnSizingInfo?.deltaOffset}px)`
										: '',
							},
						}}
					/>
				)}
			</Box>
		</TableCell>
	);
};

export const MemoizedDataGridColumnHeader = React.memo(DataGridColumnHeader);

export default DataGridColumnHeader;
