import { fhirExtensionUrls, updateExtension } from '@worklist-2/core/src';

export const clearRescheduleReason = payload => {
	if (payload?.extension) {
		// reschedule reason
		updateExtension(payload.extension, fhirExtensionUrls.appointment.rescheduleReason, {
			valueString: '',
		});
		// reschedule reason details
		updateExtension(payload.extension, fhirExtensionUrls.appointment.rescheduleReasonDetails, {
			valueString: '',
		});
	}
};

export const clearCancelReason = payload => {
	if (payload?.extension) {
		// cancel reason
		updateExtension(payload.extension, fhirExtensionUrls.appointment.cancelReason, {
			valueString: '',
		});
		// cancel reason details
		updateExtension(payload.extension, fhirExtensionUrls.appointment.cancelReasonDetails, {
			valueString: '',
		});
	}
};
