// @flow

// core
import React, { type Node, useCallback, useState } from 'react';
// components
import { LinkedPatientButton } from '@rs-components/LinkedPatientButton/LinkedPatientButton';
// libs
import Box from '@mui/material/Box';
// utils
import { useRouter } from '@worklist-2/core/src/hooks/useRouter';

const avatarsColors = ['#568AF3', '#A871CA', '#AB4E8A', '#8DBB43', '#568AF3', '#A871CA', '#AB4E8A', '#8DBB43'];

interface ILinkedPatient {
	id: string;
	type: string;
	other: {
		display: string,
		id: string,
		reference: string,
		extension: Record<any, any>[],
	};
}

interface ILinkedPatientsProps {
	linkedPatients: ILinkedPatient[];
}

export const LinkedPatients = ({ linkedPatients }: ILinkedPatientsProps): Node => {
	// ==================== State ====================
	const [showRest, setShowRest] = useState(false);

	// ==================== Hooks ====================
	const { goTo } = useRouter();

	const firstPatients = linkedPatients.slice(0, 2);

	const onGoToLinkedPatient = useCallback(
		(patientId?: string) => () => {
			if (!patientId) return;

			goTo.patientDetail({ patientId });
		},
		[goTo]
	);

	return (
		<Box sx={SX.componentWrapper}>
			{firstPatients.map((patient, idx) => (
				<LinkedPatientButton
					key={patient.id}
					avatarBgColor={avatarsColors[idx]}
					params={{
						btnStyles: {
							...SX.btnStyles,
							marginRight: showRest ? '1px' : '-7px',
						},
						iconStyles: SX.iconStyles,
						titleStyles: SX.titleStyles,
						plainTitle: true,
					}}
					title={patient?.other.display}
					onClick={onGoToLinkedPatient(patient?.other?.id)}
				/>
			))}
			{linkedPatients.length > 2 && !showRest && (
				<Box sx={SX.showMoreButton} onClick={() => setShowRest(true)}>
					+2
				</Box>
			)}
			{linkedPatients.length > 2 &&
				showRest &&
				linkedPatients.slice(2).map((patient, idx) => (
					<LinkedPatientButton
						key={patient.id}
						avatarBgColor={avatarsColors[idx]}
						params={{
							btnStyles: {
								...SX.btnStyles,
								marginRight: showRest ? '1px' : '-7px',
							},
							iconStyles: SX.iconStyles,
							titleStyles: SX.titleStyles,
							plainTitle: true,
						}}
						title={patient?.other?.display}
						onClick={onGoToLinkedPatient(patient?.other?.id)}
					/>
				))}
		</Box>
	);
};

const SX = {
	componentWrapper: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#414141',
		padding: '3px',
		borderRadius: '20px',
		marginLeft: '20px',
		width: 'fit-content',
	},
	btnStyles: {
		backgroundColor: '#414141',
		border: '1px solid #FFFFFF1A',
		maxWidth: '26px',
		maxHeight: '26px',
		borderRadius: '20px',
		boxSizing: 'border-box',
		padding: 0,
		'&:last-child': { marginRight: '0' },

		'&.hovered': {
			paddingRight: '5px',
			backgroundColor: '#353535',
			width: 'fit-content',
			maxWidth: '150px',
			marginRight: '3px',

			'& svg': {
				fill: '#fff',
			},
		},
	},
	iconStyles: {
		width: '26px',
		height: '26px',
		color: '#42A5F5',
	},
	titleStyles: {
		fontWeight: '400',
		fontSize: '12px',
	},
	showMoreButton: {
		borderRadius: '50%',
		backgroundColor: '#5A5A5A',
		fontSize: '12px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '24px',
		height: '24px',
		zIndex: '100',
	},
};
