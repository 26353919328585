import _ from 'lodash';
import moment from 'moment';
import { statusIndicatorFilterOptions } from '@worklist-2/core/src/fhir/resource/columnMapping/ImagingStudyWorklistMapping';
import generatePillDateFormat from '@worklist-2/ui/src/views/utils/generatePillDateFormat';

export const wheelMenus = (t, permissions, proactEnableVisitOnOmegaDial = false) => {
	return [
		{
			label: t('workList:study'),
			icon: 'study',
			visible: permissions?.study?.read,
		},
		{
			label: t('workList:documentViewer'),
			icon: 'documentviewer',
			visible: permissions?.['document viewer']?.read,
		},
		{
			label: t('workList:imageViewer'),
			icon: 'imageviewer',
			visible: permissions?.['image viewer']?.read,
		},
		{
			label: t('workList:studyHistory'),
			icon: 'studyexplorer',
			visible: permissions?.['study history']?.read,
		},
		{
			label: t('workList:send'),
			icon: 'sendstudy',
			visible: permissions?.send?.read,
		},
		{
			label: t('workList:billing'),
			icon: 'billing',
			visible: true, // need UAC
		},
		{
			label: t('workList:patient'),
			icon: 'patient',
			visible: permissions?.patient?.read,
		},
		{
			label: t('workList:visit'),
			icon: 'visit',
			visible: proactEnableVisitOnOmegaDial && permissions?.visit?.read,
		},
		{
			label: t('workList:order'),
			icon: 'order',
			visible: permissions?.order?.read,
		},
	];
};

// store filter temporarily
export const updateTemporaryFilters = newColumnArray => {
	const newFilterArray = [];
	_.map(newColumnArray, element => {
		if (
			(_.isString(element.filter) && !_.isEmpty(element.filter)) ||
			(_.isArray(element.filter) && element.filter.filter(str => str !== '').length > 0)
		) {
			let updatedValues = [];
			if (element.filterType === 'multi-select' || element.filterType === 'date-range') {
				updatedValues = element.filter;
			} else {
				updatedValues = [element.filter];
			}
			newFilterArray.push({
				label: element.label,
				columnIdentity: element.name,
				values: updatedValues,
				displayValue: element?.displayValue ? element.displayValue : updatedValues,
				capsuleMenuItems: element.capsuleMenuItems,
				filterType: element.filterType,
				toolTip: element.toolTip,
				valueSet: element.valueSet,
				searchParameter: element.searchParameter,
				searchValueSet: element.valueSet,
			});
		}
	});

	return newFilterArray;
};

export const updateTemporarySort = newColumnArray => {
	const newSort = [];

	_.map(newColumnArray, column => {
		if (column.sort && column.sort !== 'none') {
			newSort.push({
				sort: column.sort === 'asc' ? column.name : `-${column.name}`,
				sortOrder: column.sortOrder,
			});
		}
	});

	return _.orderBy(newSort, ['sortOrder'], ['asc']).map(sortObj => sortObj.sort);
};

export const isValidGUID = guid => {
	const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
	return guidPattern.test(guid);
};

/**
 * Get the columns for settings
 * @param {*} columnList
 * @param {*} filters
 * @param {*} sort
 * @returns
 */
export const getColumnsForSettings = (columnList, filters, sort) => {
	_.remove(columnList, col => col.name === 'id' || col.name === 'flagColTF');
	return columnList?.map(cl => {
		const filter = filters?.find(f => f.columnIdentity === cl.name);
		const sortIndex = _.findIndex(sort, sortValue => cl.name === sortValue || cl.name === sortValue.slice(1));
		let sortFinalValue = sortIndex !== -1 ? sort[sortIndex] : null;
		if (sortFinalValue) {
			sortFinalValue = sortFinalValue?.charAt(0) === '-' ? 'desc' : 'asc';
		}
		return {
			...cl,
			filter: filter?.actualValues ? filter.actualValues : filter?.values,
			displayValue: filter?.displayValue ? filter.displayValue : filter?.values,
			sortOrder: sortIndex !== -1 ? sortIndex : null,
			sort: sortFinalValue,
		};
	});
};

export const getStatusIndicatorOptions = (statusIndicators, filterOptions = statusIndicatorFilterOptions) => {
	const preAuthConfig = statusIndicators?.find(x => x.name === 'Pre-auth');
	const eligibilityConfig = statusIndicators?.find(x => x.name === 'Eligibility');
	const includePreAuthStatus = preAuthConfig ? preAuthConfig.checked : true;
	const includeEligibilityStatus = eligibilityConfig ? eligibilityConfig.checked : true;
	const includeChargeConfig = statusIndicators?.find(x => x.name === 'Charge-posting');
	const includeChargeStatus = includeChargeConfig ? includeChargeConfig.checked : false;
	return filterOptions.filter(filterOption => {
		if (filterOption.id.startsWith('eligibility')) {
			return includeEligibilityStatus;
		}
		if (filterOption.id.startsWith('prior')) {
			return includePreAuthStatus;
		}
		if (filterOption.id.startsWith('charge')) {
			return includeChargeStatus;
		}

		return true;
	});
};

/**
 * Check if the filter value is in the check value
 * @param {*} filterValue Filtering value
 * @param {*} checkValue Checking value
 * @returns
 */
export const isMultiMatchWord = (filterValue, checkValue) => {
	const checkValues = checkValue.split(' ');
	const filterValues = filterValue.split(' ');

	return filterValues.every(fv => checkValues.some(cv => cv.toLowerCase().includes(fv.toLowerCase())));
};

/**
 * Check all filters for the data locally
 * @param {*} studyRes
 * @param {*} filters
 * @param {*} columnMapping
 * @returns
 */
export const localFiltering = (studyRes, filters, columnMapping) => {
	if (filters && !_.isEmpty(filters)) {
		let matched = true;
		let filteredStudyRes = studyRes;

		for (const element of filters) {
			if (!_.isEmpty(element)) {
				matched = checkMatchedFilter(columnMapping, element, filteredStudyRes, matched);

				if (!matched) {
					filteredStudyRes = null;
					break;
				}
			}
		}

		return filteredStudyRes;
	}

	return studyRes;
};

/**
 * Check if the study is within the time window
 * @param {*} datetimeUtc
 * @returns
 */
export const isWithinTimeWindow = datetimeUtc => {
	const studyDateTime = new Date(datetimeUtc);

	if (isNaN(studyDateTime)) {
		return false;
	}

	// Get the current UTC time
	const currentDateTime = moment(new Date().toLocaleString('en-US', { timeZone: 'UTC' }));

	// Calculate the time difference in milliseconds
	const timeDiff = currentDateTime.diff(studyDateTime, 'milliseconds');

	// We can always change the time window if needed
	return timeDiff <= 60000;
};

/**
 * Check if the study data matches the filter
 * @param {*} columnMapping
 * @param {*} element
 * @param {*} studyRes
 * @param {*} matched
 * @returns
 */
const checkMatchedFilter = (columnMapping, element, studyRes, matched) => {
	const mappingField = element.columnIdentity ? element.columnIdentity : element.label;
	const fieldMapping = columnMapping[mappingField];
	const getValueFn = fieldMapping?.accessorFn ? fieldMapping?.accessorFn : fieldMapping?.getDisplay;
	const val = !!getValueFn && getValueFn(studyRes);

	const filterType = fieldMapping?.filterType ? fieldMapping.filterType : 'none';
	const queryType = fieldMapping?.queryType;

	switch (filterType) {
		case 'suggest':
			matched =
				val?.toLowerCase().includes(element.displayValue.toLowerCase()) ||
				studyRes[element.searchParameter] == element.values;
			break;
		case 'text-search':
			if (queryType === 'multi-match') {
				matched = isMultiMatchWord(element.values, val);
			} else {
				matched = val?.toLowerCase().includes(element.values.toLowerCase());
			}
			break;
		case 'single-select':
			matched = val === element.values[0];
			break;
		case 'multi-select':
		case 'checkbox-multi-select':
			matched = element.values.includes(val);
			break;
		case 'date-time':
			matched =
				moment(element.values).format('YYYY-MM-DDTHH:mm:ss') === moment(val).format('YYYY-MM-DDTHH:mm:ss');
			break;
		case 'date-range':
			if (_.isArray(element.values)) {
				matched =
					moment(element.values[0]).format('YYYY-MM-DDTHH:mm:ss') <=
						moment(val).format('YYYY-MM-DDTHH:mm:ss') &&
					moment(val).format('YYYY-MM-DDTHH:mm:ss') <=
						moment(element.values[1]).format('YYYY-MM-DDTHH:mm:ss');
			} else {
				const pillDateRangeValue = generatePillDateFormat(element.values);
				matched =
					moment(pillDateRangeValue[0]).format('YYYY-MM-DDTHH:mm:ss') <=
						moment(val).format('YYYY-MM-DDTHH:mm:ss') &&
					moment(val).format('YYYY-MM-DDTHH:mm:ss') <=
						moment(pillDateRangeValue[1]).format('YYYY-MM-DDTHH:mm:ss');
			}
			break;
		default:
			matched = val.toLowerCase() === element.values.toLowerCase();
			break;
	}
	return matched; // Return the new variable
};
