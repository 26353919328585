import _ from 'lodash';
import { convertArrayToString } from '.';

const getExtensionValueCodingArrayConcat = (resource, fhirUrl, type = 'display') => {
	let valueArray = [];

	const firstLevelExtension = _.find(resource.extension, elem => elem.url === fhirUrl);

	if (!!firstLevelExtension) {
		valueArray = _.map(firstLevelExtension.extension, item => item.valueCoding);
	}

	return convertArrayToString(valueArray, type);
};

export default getExtensionValueCodingArrayConcat;
