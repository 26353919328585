import { logInfo } from '@worklist-2/core/src';

export const storeStudyInfoToLSForRadPair = (studyId, reportId) => {
	const storedInfo = JSON.parse(localStorage.getItem('studyInfoForRadPair')) || [];

	storedInfo.push({ studyId, draftRadPairReport: reportId });

	localStorage.setItem('studyInfoForRadPair', JSON.stringify(storedInfo));
	logInfo('studyInfoForRadPair', `Stored: ${JSON.stringify({ studyId, draftRadPairReport: reportId })}`);
};

export const getRadPairDraftReportFromLS = studyId => {
	const storedInfo = JSON.parse(localStorage.getItem('studyInfoForRadPair')) || [];

	for (const info of storedInfo) {
		if (info.studyId === studyId) {
			return info.draftRadPairReport;
		}
	}

	return null;
};

export const removeRadPairStudyInfo = studyId => {
	let storedInfo = JSON.parse(localStorage.getItem('studyInfoForRadPair')) || [];

	storedInfo = storedInfo.filter(info => info.studyId !== studyId);

	localStorage.setItem('studyInfoForRadPair', JSON.stringify(storedInfo));
	logInfo('studyInfoForRadPair', `Removed studyId: ${studyId}`);
};

export const clearRadPairStudyInfo = () => {
	localStorage.removeItem('studyInfoForRadPair');
	logInfo('studyInfoForRadPair', `Cleared all study info`);
};
