import React from 'react';
import PropTypes from 'prop-types';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PatientProfileHoverView from './PatientProfileHoverView';
import Popup from '../../Popup/Popup';

const PatientProfileIcon = ({ patientId }) => (
	<Popup delay={500} popupComponent={<PatientProfileHoverView patientId={patientId} />}>
		<AccountCircleIcon
			fontSize="small"
			sx={{
				color: '#FFFFFF',
				mr: '8px',
				width: '20px',
				height: '20px',
				borderRadius: 4,
				'&:hover': {
					color: 'rsPrimary.main',
					backgroundColor: 'transparent',
				},
			}}
		/>
	</Popup>
);

PatientProfileIcon.prototypes = {
	/**
	 * patient ID
	 */
	patientId: PropTypes.number.isRequired,
};

export default PatientProfileIcon;
