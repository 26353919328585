import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { DetailsContentRow, styles } from './styles';
import { preparePatientAppointmentDetailsInfo } from './utils';
import { useTranslation } from 'react-i18next';

export const PatientAppointmentDetails = ({ appointment }) => {
	const { time, weekday, modalities, description, month, day } = preparePatientAppointmentDetailsInfo(appointment);
	const { t } = useTranslation('root');

	const handleCalendarOpen = e => {
		e.stopPropagation();

		document.dispatchEvent(
			new CustomEvent('openCalendarFromPatientProfile', {
				detail: { ...appointment, shouldHighlightColumn: false },
			})
		);
	};

	return (
		<Paper data-testid="patient-appointment-details-wrap" sx={styles.paper} onClick={handleCalendarOpen}>
			<Box sx={styles.monthDateWrap}>
				<Typography sx={styles.month}>{t(month)}</Typography>
				<Typography sx={styles.date}>{day}</Typography>
			</Box>
			<Box sx={styles.content}>
				<DetailsContentRow sx={{ color: '#fff' }}>{`${time} ${t(weekday).toUpperCase()}`}</DetailsContentRow>
				<DetailsContentRow>{`${modalities}, ${description}`}</DetailsContentRow>
			</Box>
		</Paper>
	);
};
