import { useRef } from 'react';

export const DEFAULT_TOOLTIP_CONTENT_WIDTH = 150;

export const useTooltipContentWidth = () => {
	const tooltipContent = useRef(null);

	const tooltipContentWidth = tooltipContent?.current
		? tooltipContent?.current?.clientWidth
		: DEFAULT_TOOLTIP_CONTENT_WIDTH;

	return [tooltipContent, tooltipContentWidth];
};
