// core
import React, { useCallback, useEffect, useRef, useState } from 'react';
// libraries
import classnames from 'classnames';
import PropTypes from 'prop-types';
// mui
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// mui icons
import Add from '@mui/icons-material/Add';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
// partials
import { FIELDS } from './data';
import { FieldInput } from './FieldInput';
import { useIsAuthenticated } from '@azure/msal-react';
import { searchScopes, useFhirDataLoader } from '@worklist-2/core/src';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const SearchFieldsSelector = ({
	organization,
	checkedFields,
	fieldValuesObj,
	setCheckedFields,
	setFieldValuesObj,
}) => {
	const fieldsDropdownRef = useRef();

	const isAuthenticated = useIsAuthenticated();
	const [isFieldsDialogVisible, setIsFieldsDialogVisible] = useState(false);
	const [labelFields, setLabelFields] = useState(FIELDS);
	const [fields, setFields] = useState(FIELDS);
	const [isLoading, setLoading] = useState(false);
	const { t } = useTranslation('Search');

	const fhirDataLoader = useFhirDataLoader({
		scope: searchScopes.valueSet,
	});

	const studyStatusDataLoader = useFhirDataLoader({
		scope: searchScopes.studyStatus,
	});

	const recalcFieldsDropdownMaxHeight = () => {
		if (fieldsDropdownRef.current) {
			fieldsDropdownRef.current.style.maxHeight = `${Math.max(
				Math.min(
					(fieldsDropdownRef.current.offsetParent?.offsetParent?.clientHeight || 0) -
						(fieldsDropdownRef.current.offsetParent?.offsetTop || 0) -
						fieldsDropdownRef.current.offsetTop -
						52, // footer button height + spacing
					320
				),
				60
			)}px`;
		}
	};

	const onClickAway = useCallback(() => {
		setIsFieldsDialogVisible(false);
	}, []);

	useEffect(() => {
		if (isAuthenticated) {
			(async function fetchValues() {
				await fetchSelectValues();
			})().catch(setLoading(false));
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (organization && isAuthenticated) {
			(async function fetchValues() {
				await fetchStatusValues();
			})().catch(setLoading(false));
		}
	}, [organization, isAuthenticated]);

	const fetchSelectValues = async () => {
		setLoading(true);
		const genderValues = await fhirDataLoader.load({ 'name:exact': 'sex' });
		setDataValuesToField('Gender', genderValues);

		const modalityValues = await fhirDataLoader.load({
			'name:exact': 'modality',
		});
		setDataValuesToField('Modality', modalityValues);

		const anatomicFocusValues = await fhirDataLoader.load({
			'name:exact': 'AnatomicFocus',
		});
		setDataValuesToField('Anatomic Focus', anatomicFocusValues);

		const studyPriorityValues = await fhirDataLoader.load({
			'name:exact': 'Priority',
		});
		setDataValuesToField('Study Priority', studyPriorityValues);
		setLoading(false);
	};

	const fetchStatusValues = async () => {
		setLoading(true);
		const studyStatusValues = await studyStatusDataLoader.load({
			organizationid: organization.id,
			_sort: 'statusValue',
		});
		const newStudyStatus = [];
		studyStatusValues?.filter(item =>
			newStudyStatus.push({
				display: item.status,
				id: item.statusValue,
				code: item.statusValue?.toString(),
			})
		);
		setDataValuesToField('Study Status', newStudyStatus);
		setLoading(false);
	};

	const setDataValuesToField = (name, output) => {
		const index = _.findIndex(fields, field => field.label === name);
		if (index !== -1) {
			fields[index].data = output;
			setFields([...fields]);
		}
	};

	const onDateSelectionChange = (value, field) => {
		let date1 = '';
		let date2 = '';
		let dateStr = '';
		const existingField = fields.find(item => item.id === field.id);
		const dateTimeFormat = 'YYYYMMDD';

		if (value[0] instanceof Date) {
			date1 = moment(value[0]).format(dateTimeFormat);
			dateStr = `${date1}`;
		}

		if (value[1] instanceof Date) {
			date2 = moment(value[1]).format(dateTimeFormat);
			if (date2 !== date1) {
				dateStr = `${date1}-${date2}`;
			}
		}

		if (existingField) {
			setFieldValuesObj(p => ({
				...p,
				[field.label]: dateStr,
			}));
			existingField.value = dateStr;
		}
	};

	const onFieldChange = (field, value) => e => {
		e.preventDefault?.();

		const existingField = fields.find(item => item.id === field.id);

		if (existingField) {
			setFieldValuesObj(p => ({
				...p,
				[field.label]: value || e.target.value,
			}));
			existingField.value = value || e.target.value;
		}
	};

	const onFieldsSearch = e => {
		e.preventDefault?.();
		const targetValue = e.target.value;

		setLabelFields(
			targetValue
				? fields?.filter(({ label }) => label.toLowerCase().includes(targetValue.toLowerCase()))
				: fields
		);
	};

	const onToggleFieldsDialogIsVisible = e => {
		e?.preventDefault?.();
		e?.stopPropagation?.();

		setIsFieldsDialogVisible(prev => {
			if (!prev) {
				recalcFieldsDropdownMaxHeight();
			}

			return !prev;
		});
		setLabelFields(fields);
	};

	const onToggleIsFieldChecked = (value, shouldToggleTheDialog = true) => {
		const currentIndex = checkedFields.indexOf(value);
		const newChecked = [...checkedFields];

		if (currentIndex === -1) {
			newChecked.unshift(value);
		} else {
			newChecked.splice(currentIndex, 1);
			const existingField = fields.find(item => item.id === value);
			if (existingField) {
				setFieldValuesObj(p => ({
					...p,
					[existingField.label]: null,
				}));
				existingField.value = null;
			}
		}

		setCheckedFields(newChecked);

		if (shouldToggleTheDialog) {
			onToggleFieldsDialogIsVisible();
		}
	};
	// Effects

	useEffect(() => {
		recalcFieldsDropdownMaxHeight();
	}, [checkedFields]);

	return (
		<>
			<MenuItem disabled sx={{ fontSize: '14px', marginTop: '40px' }}>
				{t('SEARCH FIELDS')}
			</MenuItem>
			<div className="field-container">
				<Button
					className={classnames('search-fields', isFieldsDialogVisible && 'active')}
					data-cy="more-btn"
					data-testid="more-btn"
					endIcon={<ArrowDropDown />}
					startIcon={<Add />}
					sx={{ marginTop: '16px' }}
					variant="outlined"
					onClick={onToggleFieldsDialogIsVisible}
				>
					{t('MORE')}
				</Button>
				<ClickAwayListener onClickAway={onClickAway}>
					<div
						ref={fieldsDropdownRef}
						className="field-dialog"
						style={{
							visibility: isFieldsDialogVisible ? 'visible' : 'hidden',
							pointerEvents: isFieldsDialogVisible ? undefined : 'none',
							zIndex: isFieldsDialogVisible ? undefined : -1,
						}}
					>
						{isFieldsDialogVisible && (
							<>
								<TextField
									fullWidth
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
									data-testid="searchField"
									placeholder={t('Search')}
									sx={{
										'input:-webkit-autofill': {
											boxShadow: '0 0 0 100px #393939 inset !important',
											borderRadius: 0,
										},
									}}
									onChange={onFieldsSearch}
								/>

								<List>
									{labelFields.map(value => {
										const labelId = `checkbox-list-label-${value.id}`;

										return (
											<ListItem key={value.id} disablePadding>
												<ListItemButton
													dense
													onClick={() => onToggleIsFieldChecked(value.id, false)}
												>
													<ListItemIcon>
														<Checkbox
															disableRipple
															checked={checkedFields.includes(value.id)}
															color="success"
															edge="start"
															inputProps={{
																'aria-labelledby': labelId,
															}}
															tabIndex={-1}
														/>
													</ListItemIcon>
													<ListItemText id={labelId} primary={t(value.label)} />
												</ListItemButton>
											</ListItem>
										);
									})}
								</List>
							</>
						)}
					</div>
				</ClickAwayListener>
			</div>
			<div className="fields-texts-container">
				{fields
					?.filter(({ id }) => checkedFields.includes(id))
					.map(field => (
						<FieldInput
							field={field}
							fieldValuesObj={fieldValuesObj}
							isLoading={isLoading}
							onDateSelectionChange={onDateSelectionChange}
							onFieldChange={onFieldChange}
							onToggleIsFieldChecked={onToggleIsFieldChecked}
						/>
					))}
			</div>
		</>
	);
};

SearchFieldsSelector.propTypes = {
	organization: PropTypes.object,
	checkedFields: PropTypes.array,
	fieldValuesObj: PropTypes.object,
	setCheckedFields: PropTypes.func,
	setFieldValuesObj: PropTypes.func,
};
