import React, { useState, createContext, useContext } from 'react';

const WorklistContext = createContext({});

const WorklistProvider = ({ children }) => {
	const [filters, setFilters] = useState([]);
	// Used to check if filters has changed
	const [savedFilters, setSavedFilters] = useState([]);
	const [savedSorts, setSavedSorts] = useState([]);
	const [sort, setSort] = useState([]);

	const [selectedWorklistId, setSelectedWorklistId] = useState(0);
	const [selectedWorklistName, setSelectedWorklistName] = useState('');
	const [wheelOpen, setWheelOpen] = useState(false);
	const [visitedWorklist, setVisitedWorklist] = useState();
	const [dataGridRefValue, setDataGridRefValue] = useState(null);
	const [scrollPosition, setScrollPosition] = useState(null);
	// list of columns for the current worklist
	const [currWorklistColumns, setCurrWorklistColumns] = useState([]);
	// Object with col names as keys and col sizes as values
	const [columnSizing, setColumnSizing] = useState({});

	const closeWorklistWheel = () => {
		if (wheelOpen) {
			setWheelOpen(false);
		}
	};

	return (
		<WorklistContext.Provider
			value={{
				filters,
				setFilters,
				savedFilters,
				sort,
				setSort,
				setSavedFilters,
				savedSorts,
				setSavedSorts,
				selectedWorklistId,
				setSelectedWorklistId,
				selectedWorklistName,
				setSelectedWorklistName,
				visitedWorklist,
				setVisitedWorklist,
				wheelOpen,
				setWheelOpen,
				closeWorklistWheel,
				dataGridRefValue,
				setDataGridRefValue,
				scrollPosition,
				setScrollPosition,
				currWorklistColumns,
				setCurrWorklistColumns,
				columnSizing,
				setColumnSizing,
			}}
		>
			{children}
		</WorklistContext.Provider>
	);
};

export const useWorklist = () => useContext(WorklistContext);
export default WorklistProvider;
