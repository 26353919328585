import { create } from 'zustand';
import { PATIENT_DOCUMENT_FILTERS } from '@worklist-2/worklist/src/DocumentViewerV3/consts/consts';

const minCardHeight = 378;
const minCardHeightWithNotes = 408;

export const usePatientInfoCardStore = create(set => ({
	// Store variables with default values
	selectedCard: {
		idx: 2, // Default selected card
	},
	cardOrder: [0, 1, 2], // Mandatory prop to preserve the selected card order
	isCardCollapsed: false,
	selectedDocFilter: PATIENT_DOCUMENT_FILTERS.ALL,
	refHeight: minCardHeight,
	minHeight: minCardHeight,

	// ==================== Setters ====================
	setSelectedCard: card => set({ selectedCard: card }),
	setCardOrder: order => set({ cardOrder: order }),
	setIsCardCollapsed: status => set({ isCardCollapsed: status }),
	setSelectedDocFilter: filter => set({ selectedDocFilter: filter }),
	setRefHeight: height => set({ refHeight: height }), // Root height of the cards
	setMinHeight: height => set({ minHeight: height }), // Minimum height to set for the cards on resize
	// Initialize the card in collapsed state; 90 indicates that the height of each card is 30px in the collapsed state (3 cards x 30px = 90px)
	initAsCollapsed: galaxyDvGlobalNotes => {
		if (galaxyDvGlobalNotes) {
			set({
				refHeight: 90,
				minHeight: 90,
				cardOrder: [0, 1, 2],
				selectedCard: { idx: 2 },
			});
		} else {
			set({
				refHeight: 60,
				minHeight: 60,
				cardOrder: [0, 1],
				selectedCard: { idx: 1 },
			});
		}
	},

	//
	// ==================== Re-setters ====================
	resetPatientInfoCardStore: galaxyDvGlobalNotes => {
		if (galaxyDvGlobalNotes) {
			set({
				// #NOTE: Be sure to include all the store's variables !
				selectedCard: {
					idx: 2, // default selected card
				},
				cardOrder: [0, 1, 2],
				isCardCollapsed: false,
				selectedDocFilter: PATIENT_DOCUMENT_FILTERS.ALL,
				refHeight: minCardHeight,
				minHeight: minCardHeight,
			});
		} else {
			set({
				// #NOTE: Be sure to include all the store's variables !
				selectedCard: {
					idx: 1, // default selected card
				},
				cardOrder: [0, 1],
				isCardCollapsed: false,
				selectedDocFilter: PATIENT_DOCUMENT_FILTERS.ALL,
				refHeight: minCardHeightWithNotes,
				minHeight: minCardHeightWithNotes,
			});
		}
	},
	resetPatientDoc: () =>
		set({
			selectedDocFilter: PATIENT_DOCUMENT_FILTERS.ALL,
		}),
}));
