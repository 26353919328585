import { generateRandomString } from '@worklist-2/core/src';

export const getAllergyDataFromProfile = allergyMapping => {
	const allergiesMapping = allergyMapping || [];
	return allergiesMapping.map(allergy => ({
		id: generateRandomString(16),
		Allergen: allergy.allergen,
		Severity: allergy.severity,
	}));
};
