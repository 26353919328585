// @flow

// core
import React, { type Node, useCallback, useMemo, useState } from 'react';
// components
import { Box } from '@mui/material';
import GenericDrawer from '../GenericDrawer';
import { MergeDrawerCardsWrap, MergeDrawerContentSection } from '../MergeDrawers/styles';
import { MergeDrawerButtons } from '../MergeDrawers/partials';
import { MergePatientDrawerCard } from './partials/MergePatientDrawerCard';
import { Loader } from '@rs-ui/components/Loader/Loader';

// libs
import { useTranslation } from 'react-i18next';
// utils
import { getUserFullName, useRouter, useSelection } from '@worklist-2/core/src';
import { useMergePatient } from '../MergeDrawers/hooks';
import { findDifferentFields } from './utils';
import { LinkMergePatientTooltip } from '@rs-components/Drawers/LinkDrawer/partials';

interface IMergePatientsDrawerProps {
	drawerOpen: boolean;
	isMergeDrawerLoading?: boolean;
	setDrawerOpen: (val: boolean) => void;
	setToastUtility: (b: boolean, s: string) => void;
}

const DRAWER_WIDTH = 487;

export const MergePatientDrawer = ({
	drawerOpen,
	isMergeDrawerLoading,
	setDrawerOpen,
	setToastUtility,
}: IMergePatientsDrawerProps): Node => {
	// ==================== State ====================
	const [isMergeAlreadyClicked, setMergeAlreadyClicked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// ==================== Hooks ====================
	const mergePatient = useMergePatient();
	const {
		selectedMergePatient,
		selectedMergePatientModified,
		replacedFields,
		handleResourceReset,
		updateResourcesHelper,
		selectedPatients,
	} = useSelection();
	const {
		goTo,
		params: { id: patientId },
		refresh,
	} = useRouter();
	const { t } = useTranslation('patientInfo');

	const differentFields = useMemo(() => findDifferentFields(selectedPatients), [selectedPatients]);
	const isCardListVisible = selectedPatients.length > 0;

	// ==================== Events ====================
	const onMerge = () => {
		if (!isMergeAlreadyClicked && differentFields.length) {
			setIsLoading(true);
			setMergeAlreadyClicked(true);
			mergePatient(selectedPatients, selectedMergePatient, replacedFields, 'Patient')
				.then(res => {
					if (res?.status !== 200) throw new Error();

					setToastUtility(
						true,
						`${getUserFullName(selectedPatients[0]?.patient?.name[0])} ${t('and')} ${getUserFullName(
							selectedPatients[1]?.patient?.name[0]
						)} ${t('haveBeenMerged')}`
					);

					handleResourceReset();
					updateResourcesHelper([]);

					setMergeAlreadyClicked(false);
					setIsLoading(false);
					setDrawerOpen(false);

					if (patientId) {
						if (patientId !== selectedMergePatient?.patientInternalID) {
							goTo.patientDetail({ patientId: selectedMergePatient.patientInternalID }); // replace: true
						} else {
							refresh();
						}
					}
				})
				.catch(() => {
					setToastUtility(true, t('Failed to merge patients'));
					setMergeAlreadyClicked(false);
					setIsLoading(false);
				});
		}
	};

	const renderCards = useCallback(
		() =>
			selectedPatients.map((patient, idx) => {
				if (selectedMergePatient && selectedMergePatient.patientID === patient.patientID) {
					return (
						<MergePatientDrawerCard
							key={selectedMergePatient.patientID}
							cardStyles={{ width: '75%', mb: '17px' }}
							differentFields={differentFields}
							patientData={selectedMergePatientModified}
						/>
					);
				}
				return (
					<MergePatientDrawerCard
						key={patient.patientID + idx}
						cardStyles={{ width: '75%', mb: '17px' }}
						differentFields={differentFields}
						patientData={patient}
					/>
				);
			}),
		[selectedMergePatientModified, selectedPatients]
	);

	return isCardListVisible ? (
		<GenericDrawer
			headerDividerFlag
			showBackBtn
			customHeaderTextProps={{
				variant: 'h6',
				sx: {
					fontSize: '20px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					mr: 'auto',
				},
			}}
			drawerOpen={drawerOpen}
			setDrawerOpen={setDrawerOpen}
			title={t('Merge Patient')}
			width={DRAWER_WIDTH}
		>
			<Loader.MergeCards isLoading={isMergeDrawerLoading}>
				<MergeDrawerContentSection>
					<MergeDrawerCardsWrap>{selectedPatients.length > 0 && renderCards()}</MergeDrawerCardsWrap>
				</MergeDrawerContentSection>

				{differentFields.length > 0 && (
					<Box sx={{ width: '100%' }}>
						<LinkMergePatientTooltip highlightedFields={differentFields} type="MERGE" />
					</Box>
				)}

				<MergeDrawerButtons
					closeDrawer={() => setDrawerOpen(false)}
					isActive={selectedMergePatient}
					isLoading={isLoading}
					t={t}
					onSubmit={onMerge}
				/>
			</Loader.MergeCards>
		</GenericDrawer>
	) : null;
};
