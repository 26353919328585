// Core

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getDatePickerLocale, datePickerPaperProps } from '@worklist-2/ui/src/components/utils/calendarLocalization';

// MUI

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

// Custom

import FormToolTextField from './FormToolTextField';

// Component

const formatDate = (inputDate, format) => {
	if (inputDate?.getDate) {
		let day;
		let month;
		let year;

		day = inputDate.getDate();
		month = inputDate.getMonth() + 1;
		year = inputDate.getFullYear();

		day = day.toString().padStart(2, '0');

		month = month.toString().padStart(2, '0');

		return format === 'ymd' ? `${year}/${month}/${day}` : `${month}/${day}/${year}`;
	}
	return '';
};

const FormToolDateField = ({ fieldProps, answer, onChangeData, isCompact, ...props }) => {
	const [date, setDate] = useState(null);
	const { t } = useTranslation('form');
	const { i18n } = useTranslation();

	useEffect(() => {
		if (answer) {
			const dateObj = new Date(answer);
			setDate(formatDate(dateObj));
		}
	}, []);

	return (
		<LocalizationProvider adapterLocale={getDatePickerLocale(i18n)} dateAdapter={AdapterDateFns}>
			<DesktopDatePicker
				PaperProps={datePickerPaperProps}
				renderInput={params => (
					<FormToolTextField
						sx={{
							...fieldProps?.sx,
							'& .MuiOutlinedInput-root': { height: isCompact ? '40px' : '56px' },
						}}
						{...fieldProps}
						{...params}
						error={props?.error}
						inputProps={{ ...params.inputProps, placeholder: t(params.inputProps.placeholder) }}
					/>
				)}
				value={date}
				onChange={newDate => {
					setDate(newDate);
					if (onChangeData) {
						onChangeData(formatDate(newDate, 'ymd'));
					}
				}}
				{...props}
			/>
		</LocalizationProvider>
	);
};

export default FormToolDateField;
