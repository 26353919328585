/* Generated automagically by FhirStarter - but please feel free to edit this file */
import { fhirExtensionUrls } from '../../extension';
const OrganizationAffiliationMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	identifier: {
		label: 'Identifier',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.identifier;
		},
	},
	active: {
		label: 'Active',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.active;
		},
	},
	period: {
		label: 'Period',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.period;
		},
	},
	organization: {
		label: 'Organization',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.organization;
		},
	},
	participatingOrganization: {
		label: 'Participating Organization',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.participatingOrganization;
		},
	},
	network: {
		label: 'Network',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.network;
		},
	},
	code: {
		label: 'Code',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.code;
		},
	},
	specialty: {
		label: 'Specialty',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.specialty;
		},
	},
	location: {
		label: 'Location',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.location;
		},
	},
	healthcareService: {
		label: 'Healthcare Service',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.healthcareService;
		},
	},
	telecom: {
		label: 'Telecom',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.telecom;
		},
	},
	endpoint: {
		label: 'Endpoint',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.endpoint;
		},
	},
	accessionNumber: {
		label: 'Accession #',
		getDisplay: resource => {
			return resource.accessionNumber;
		},
	},
	modality: {
		label: 'Modality',
		filterType: 'multi-select',
		getDisplay: resource => {
			return resource.modality.map(elem => elem.code).join(' | ');
		},
	},
	patientName: {
		label: 'Patient Name',
		getDisplay: resource => {
			return resource.subject.display;
		},
	},
	managingOrganization: {
		label: 'Managing Organization',
		options: { filter: true, sort: true },
		filterType: 'suggest',
		getDisplay: resource => {
			return resource.managingOrganization;
		},
	},
	studyStatus: {
		label: 'Study Status',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.common.studyStatus).valueCoding
				.display;
		},
	},
	studyDescription: {
		label: 'Study Description',
		getDisplay: resource => {
			return resource.description;
		},
	},
};
export default OrganizationAffiliationMapping;
