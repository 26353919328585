// @flow

// core
import React, { type Node } from 'react';
// components
import Avatar from '../Avatar/Avatar';
// libs
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import startCase from 'lodash/startCase';
// utils
import { getNameInitials } from '@worklist-2/worklist/src/SchedulerV2/utils/dataProcessHelpers';

interface ILinkedPatientButtonProps {
	avatarBgColor: string;
	minWidth?: string;
	params: object;
	onClick: () => void;
	title: string;
}

export const LinkedPatientButton = ({
	avatarBgColor,
	minWidth = '24px',
	params,
	onClick,
	title,
}: ILinkedPatientButtonProps): Node => {
	const onOver = event => {
		event.currentTarget.classList.add('hovered');
	};

	const onLeave = event => {
		event.currentTarget.classList.remove('hovered');
	};

	return (
		<Box
			data-testid="selected-resources-button"
			sx={{
				...SX.selectedResourcesButton,
				maxWidth: minWidth,
				...params?.btnStyles,
			}}
			onClick={onClick}
			onMouseEnter={onOver}
			onMouseLeave={onLeave}
		>
			<Avatar
				backgroundColor={avatarBgColor}
				fontSize="12px"
				height="24px"
				iconText={getNameInitials(title)}
				label={title}
				transparent={false}
				width="24px"
			/>

			<Typography
				sx={{
					...SX.patientName,
					...params?.titleStyles,
				}}
			>
				{startCase(title?.toLowerCase())}
			</Typography>
		</Box>
	);
};

const SX = {
	selectedResourcesButton: {
		display: 'flex',
		alignItems: 'center',
		columnGap: '5px',
		borderRadius: '20px',
		padding: '2px 10px',
		transition: 'all .7s ease',
		overflow: 'hidden',
		cursor: 'pointer',

		'&.hovered': {
			backgroundColor: '#2975B2',
			width: 'fit-content',
			maxWidth: '170px',
		},
	},

	patientName: {
		color: '#fff',
		fontSize: '10px',
		padding: '0 2px',
		fontWeight: '700',
		maxWidth: '150px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
};
