// core
import React, { useEffect, useMemo, useState } from 'react';

// mui
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
// icons
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

// components
import Avatar from '../Avatar';
import { StyledSpeedDialAction } from '../SpeedDial/SpeedDial';
import Stepper from './Stepper';
import SearchWorklist from '../SearchWorklist/SearchWorklist';
import StaticDialAction from './StaticDialAction';

// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

const WorklistSpeedDial = ({
	x,
	y,
	worklists,
	showSaveBtn,
	currWorklistId,
	pageSize,
	maxPages,
	onWorklistSelected,
	onSettingsClicked,
	onAddClicked,
	onSaveClicked,
}) => {
	const [selected, setSelected] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [activePage, setActivePage] = useState(0);
	const [currentWorklists, setCurrentWorklists] = useState(worklists);
	const [isStepperVisible, setIsStepperVisible] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const { t } = useTranslation('workList');

	// compose worklists menu
	const menuItems = currentWorklists.map(worklist => ({
		key: worklist.id,
		name: worklist.name,
		icon: <Avatar label={worklist.name} />,
		viewOnly: !worklist.practitioner,
	}));

	// calculates how many pages are required based on number of worklists
	const neededPages = Math.min(Math.max(Math.ceil(worklists.length / pageSize), 0), maxPages);
	const pages = _.chunk(menuItems, pageSize);

	useEffect(() => {
		setSelected(menuItems.find(item => item.key === currWorklistId));
	}, [currWorklistId, currentWorklists]);

	useEffect(() => {
		setCurrentWorklists(worklists);
	}, [worklists]);

	const handleSettingsClicked = event => {
		setIsOpen(false);
		onSettingsClicked(event);
	};

	const handleAddClicked = event => {
		setIsOpen(false);
		onAddClicked(event);
	};

	const handleClickAway = () => {
		setIsOpen(false);
		setIsStepperVisible(true);
		setSearchValue('');
		setCurrentWorklists(worklists);
	};

	const handleMenuOpenClose = () => {
		setIsOpen(value => !value);
	};

	const handleActionItemClick = (event, action) => {
		setIsOpen(false);
		setSelected(action);
		onWorklistSelected?.(action);
	};

	const handleStepperClick = (event, index) => {
		if (activePage != index) {
			setActivePage(index);
		}
	};

	const handleWheel = e => {
		if (isOpen) {
			if (e.deltaY > 0) {
				if (activePage < neededPages - 1) {
					setActivePage(activePage + 1);
				}
			} else if (e.deltaY < 0) {
				if (activePage > 0) {
					setActivePage(activePage - 1);
				}
			}
		}
	};

	const handleSearchClick = () => {
		setIsStepperVisible(false);
	};

	const handleCloseSearchClick = () => {
		setIsStepperVisible(true);
		setSearchValue('');
		setCurrentWorklists(worklists);
	};

	const sx = useMemo(
		() => ({
			position: 'absolute',
			right: x,
			bottom: y,
		}),
		[x, y]
	);

	const onWorklistSearch = value => {
		setSearchValue(value);
		setActivePage(0);
		if (value) {
			const filtered = worklists.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

			setCurrentWorklists(filtered);
		} else {
			setCurrentWorklists(worklists);
		}
	};

	const stepperPosition = 12 - (neededPages - 2) * 10;

	return (
		<Stack
			alignItems="center"
			sx={{ ...sx, minWidth: '150px', maxWidth: '150px', zIndex: '1' }}
			onWheel={handleWheel}
		>
			<Backdrop
				open={isOpen}
				sx={{
					zIndex: 2,
					background: 'linear-gradient(270deg, #121212 0.13%, rgba(18, 18, 18, 0.00) 99.97%)',
				}}
			/>

			<ClickAwayListener onClickAway={handleClickAway}>
				<SpeedDial
					FabProps={{
						onClick: handleMenuOpenClose,
					}}
					ariaLabel="Worklist Speed Dial"
					icon={selected ? <Avatar label={selected.name} testId={selected.name} /> : <SpeedDialIcon />}
					open={isOpen}
					position="relative"
					selected={selected?.key}
					sx={{
						'.MuiSpeedDial-actions': {
							maxHeight: '700px',
						},
						'.MuiSpeedDial-actionsClosed': {
							transition: 'max-height 600ms ease-in-out',
							maxHeight: '0px',
						},
					}}
				>
					<StaticDialAction>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								position: 'relative',
								justifyContent: !isStepperVisible ? 'flex-end' : 'center',
								transition: 'width 300ms ease-in-out',
								minHeight: '24px',
							}}
							tabIndex={1}
						>
							<Box
								sx={{
									position: 'absolute',
									right: `${stepperPosition}px`,
									bottom: '-5px',
									display: 'flex',
									columnGap: '10px',
								}}
							>
								{isOpen && neededPages > 1 && (
									<SearchWorklist
										handleSearch={onWorklistSearch}
										searchValue={searchValue}
										setSearchValue={setSearchValue}
										onCloseSearchClick={handleCloseSearchClick}
										onSearchClick={handleSearchClick}
									/>
								)}
								{isStepperVisible && (
									<Stepper
										activePage={activePage}
										isOpen={isOpen}
										pages={neededPages}
										styles={{ marginX: '0', height: '30px' }}
										onClick={handleStepperClick}
									/>
								)}
							</Box>
						</Box>
					</StaticDialAction>

					<StaticDialAction>
						<div
							style={{
								width: 66,
								height: neededPages > 1 ? 66 * pageSize : 66 * currentWorklists.length,
							}}
						>
							{pages.map((page, i) => (
								// Stack the "pages" on top of each other using absolute positioning for a smoother transition
								<Stack
									key={i}
									sx={{
										justifyContent: 'flex-end',
										position: 'absolute',
										left: '42px',
										bottom: neededPages > 1 ? '192px' : '140px',
										width: '80px',
										height: neededPages > 1 ? 66 * pageSize : 66 * currentWorklists.length,
									}}
								>
									{_.reverse(page).map(
										// regular speed dial shows items from bottom to top - emulate by using reverse
										(item, j) => (
											<StyledSpeedDialAction
												key={item.key || i * j}
												tooltipOpen
												data-cy={item.name}
												delay={
													// hide items with no delay when switching page
													// otherwise show animation when coming into view
													activePage == i ? 30 * (pageSize - j) : 0
												}
												icon={item.icon}
												open={isOpen && activePage == i}
												selected={selected?.key == item.key}
												tooltipTitle={
													<Tooltip
														placement="top"
														title={isOpen && activePage == i ? item.name : ''}
													>
														<Box
															sx={{
																display: 'flex',
																alignItems: 'center',
															}}
														>
															{item.viewOnly ? (
																<VisibilityOutlinedIcon
																	sx={{
																		width: '20px',
																		height: '20px',
																		mr: '6px',
																	}}
																/>
															) : null}
															<Typography
																sx={{
																	width: '100%',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	whiteSpace: 'nowrap',
																}}
															>
																{item.name}
															</Typography>
														</Box>
													</Tooltip>
												}
												onClick={event => {
													handleActionItemClick(event, item);
												}}
											/>
										)
									)}
								</Stack>
							))}
						</div>
					</StaticDialAction>

					{isStepperVisible && onAddClicked ? (
						<SpeedDialAction
							key="Add"
							tooltipOpen
							data-testid="AddButton"
							icon={<AddOutlinedIcon data-cy="AddNewWorklist" />}
							sx={{
								pb: '40px',
								alignSelf: 'center',
							}}
							tooltipTitle={t('workListDrawer.add')}
							onClick={handleAddClicked}
						/>
					) : null}
					{isStepperVisible && onSettingsClicked ? (
						<SpeedDialAction
							key="Settings"
							tooltipOpen
							data-cy="SettingsOutlinedIcon"
							data-testid="SettingsButton"
							icon={<SettingsOutlinedIcon />}
							sx={{
								alignSelf: 'center',
							}}
							tooltipTitle={t('workListDrawer.settings')}
							onClick={handleSettingsClicked}
						/>
					) : null}
				</SpeedDial>
			</ClickAwayListener>
			<Button
				color="rsPrimary"
				data-cy="Save-grid"
				data-testid="saveGrid"
				sx={{
					marginTop: '16px',
					visibility: onSaveClicked && showSaveBtn ? 'display' : 'hidden',
				}}
				variant="outlined"
				onClick={onSaveClicked}
			>
				{t('save')}
			</Button>
		</Stack>
	);
};

WorklistSpeedDial.propTypes = {
	/**
	 * x-coordinate of the speed dial relative to the bottom right hand corner
	 */
	x: PropTypes.number,

	/**
	 * y-coordinate of the speed dial relative to the bottom right hand corner
	 */
	y: PropTypes.number,

	/**
	 * Determines if the save button should be visible or not
	 */
	showSaveBtn: PropTypes.bool,

	/**
	 * Array of worklist
	 */
	worklists: PropTypes.array,

	/**
	 * Number of items per page
	 */
	pageSize: PropTypes.number.isRequired,

	/**
	 * Maximum pages supported
	 */
	maxPages: PropTypes.number.isRequired,
};

WorklistSpeedDial.defaultProps = {
	worklists: [],
	showSaveBtn: false,
	pageSize: -1,
	maxPages: -1,
	x: 16,
	y: 16,
};

/**
 * Speed dial component which will allow users to select an existing worklist or to edit, add, and save the existing worklist.
 */
export default WorklistSpeedDial;
