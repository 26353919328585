import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

const ConflictPill = ({ value, handlePillClick, fieldName, dateValue }) => {
	const [isSelected, setIsSelected] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const { t } = useTranslation('workList');
	return (
		<Tooltip
			PopperProps={{
				sx: {
					'& .MuiTooltip-tooltip': {
						color: 'rgba(255, 255, 255, 0.60)',
						fontStyle: 'italic',
					},
				},
			}}
			title={isSelected ? t('deselectValue') : t('selectValue')}
		>
			<Box
				data-testid="conflict-pill-wrapper"
				sx={{
					width: 'fit-content',
					borderRadius: '41px',
					height: '24px',
					background: isSelected ? '#42A5F5' : 'rgba(255, 255, 255, 0.10)',
					padding: '10px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					boxSizing: 'border-box',
					columnGap: '3px',
					maxWidth: '145px',
					cursor: 'pointer',
					transition: '0.2s ease-out',
				}}
				onClick={() => {
					if (fieldName === 'birthDate') {
						handlePillClick(isSelected, fieldName, dateValue);
					} else {
						handlePillClick(isSelected, fieldName, value);
					}
					setIsSelected(!isSelected);
				}}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<CheckIcon
					fontSize="small"
					sx={{ color: isHovered ? '##FFFFFF80' : isSelected ? '#fff' : '#3D3C3C' }}
				/>
				<Typography
					noWrap
					data-testid="conflict-pill-value"
					sx={{
						color: isSelected ? '#fff' : 'rgba(255, 255, 255, 0.87)',
						fontSize: '14px',
						fontStyle: 'italic',
						whiteSpace: 'nowrap',
					}}
				>
					{value}
				</Typography>
			</Box>
		</Tooltip>
	);
};

export default ConflictPill;
