import { v4 as uuid } from 'uuid';
import moment from 'moment';
import _ from 'lodash';
import { getUserFullName, fhirExtensionUrls } from '@worklist-2/core/src';
import { addCustomPriorityExtension } from '@rs-ui/components/PrioritySelector/helperMethods';
/**
 * Update the requester node of the payload
 * If referringPhysician/referringOrganization is an empty object, it will update the value into empty
 */
const updatePayloadRequester = (payload, referringPhysician, referringOrganization) => {
	if (!payload) {
		return;
	}

	delete payload?.requester;

	const newRequester = createOrderRequesterNode(referringPhysician, referringOrganization);

	if (Object.keys(newRequester).length > 0) {
		payload.requester = newRequester;
	}

	return { ...payload };
};

const createOrderRequesterNode = (referringPhysician, referringOrganization) => {
	let requester = {};

	// For the studies imported from other sources such as HL7, referringPhysician and referring organization may not exist in DB. In this case,
	// referringPhysician.display / referringOrganization.display are not empty but referringPhysician.id / referringOrganization.display are undefined
	if (referringPhysician?.display || referringPhysician?.name?.[0]) {
		requester = { ...createPhysicianNode(referringPhysician) };
	}
	if (referringOrganization?.display || referringOrganization?.name) {
		requester = { ...requester, ...createOrganizationNode(referringOrganization) };
	}

	return requester;
};

export const createConsultingPhysicianNode = consultingPhysician => {
	return consultingPhysician?.map(item => {
		return {
			valueReference: {
				id: item?.id,
				extension: item?.extension?.find(item => item?.url?.includes('organization'))?.valueReference
					? [item?.extension?.find(item => item?.url?.includes('organization'))?.valueReference]
					: [],
				display: getUserFullName(item?.name?.[0]),
			},
		};
	});
};

const createPhysicianNode = physician => {
	// For the physician sent from worklist grid and order page, get the display key
	// For the one sent from Appointment form, get the name

	const physicianInfo = {
		display: physician?.display ?? getUserFullName(physician?.name[0]),
	};

	return physician?.id
		? {
				id: physician?.id,
				reference: `Practitioner/${physician?.id}`,
				...physicianInfo,
		  }
		: physicianInfo;
};

const createOrganizationNode = organization => {
	// For the organization sent from the order page, get the display key
	// For the one sent from Appointment form, get the name
	const organizationInfo = {
		url: 'http://www.ramsoft.com/fhir/extension/organization',
		valueReference: {
			display: organization?.display ?? organization?.name,
		},
	};

	if (organization?.id) {
		organizationInfo.valueReference.id = organization?.id;
		organizationInfo.valueReference.reference = `Organization/${organization?.id}`;
	}

	return {
		extension: [organizationInfo],
	};
};

const createNewOrderPayload = (
	priority,
	patient,
	orderSet,
	date,
	notes,
	urnUuid,
	procedureCodes,
	referringPhysician,
	referringOrganization,
	consultingPhysician,
	loggedInUser,
	generateStudiUid,
	studyCreatedDate,
	faxAttachedToOrder,
	customPriority
) => {
	let payload = {
		resourceType: 'ServiceRequest',
		active: true,
		status: 'active',
		intent: 'order',
		priority: priority.display,
		authoredOn: date,
		subject: {
			id: patient?.id,
			reference: `Patient/${patient?.id}`,
			display: getUserFullName(patient?.name[0]),
			extension: [
				patient.managingOrganization
					? {
							url: fhirExtensionUrls.procedureRequest.managingOrganization,
							valueReference: {
								reference: patient.managingOrganization.reference,
								display: patient.managingOrganization.display,
							},
					  }
					: null,
			],
		},
		encounter: {
			reference: urnUuid,
		},
		code: {
			coding: [
				{
					code: orderSet?.studyType,
					display: orderSet?.description,
					extension: [
						orderSet.modality && {
							url: fhirExtensionUrls?.common?.modality,
							extension: [
								{
									url: 'text',
									valueCoding: {
										code: orderSet?.modality?.code,
										display: orderSet?.modality?.display,
									},
								},
							],
						},
						studyCreatedDate && {
							url: 'http://www.ramsoft.com/fhir/StructureDefinition/studyDateTime',
							valueDateTime: new Date().toISOString(),
						},
					],
				},
			],
		},
	};

	if (procedureCodes) {
		payload.procedureCode = _.cloneDeep(orderSet?.procedureCode);
		payload.procedureCode?.[0]?.coding?.forEach((p, idx) => {
			let reasonCodeExt = {
				url: 'http://www.ramsoft.com/fhir/extension/reasonCode',
				extension: [],
			};

			procedureCodes[idx].forEach(r => {
				reasonCodeExt?.extension.push({
					url: 'detailed',
					valueCoding: {
						id: r?.id,
						code: r?.code,
						display: r?.display,
					},
				});
			});

			p.extension.push(reasonCodeExt);
		});
	}

	if (consultingPhysician && Array.isArray(consultingPhysician)) {
		payload = {
			...payload,
			extension: [
				...(payload.extension || []),
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/consulting-physician',
					extension: [...createConsultingPhysicianNode(consultingPhysician)],
				},
			],
		};
	}
	if (customPriority) {
		payload = addCustomPriorityExtension(payload, customPriority);
	}
	if (notes) {
		payload.note = [
			{
				authorReference: {
					reference: `practitioner/${loggedInUser.id}`,
					display: loggedInUser.fullName,
				},
				authorString: loggedInUser.fullName,
				time: date,
				text: notes,
			},
		];
	}

	const physician = referringPhysician?.id
		? {
				display: getUserFullName(referringPhysician?.name[0]),
				id: referringPhysician?.id,
				reference: `${referringPhysician.resourceType}/${referringPhysician?.id}`,
		  }
		: null;

	const organization = referringOrganization?.id
		? {
				display: referringOrganization?.name,
				id: referringOrganization?.id,
				reference: `${referringOrganization.resourceType}/${referringOrganization?.id}`,
		  }
		: null;

	updatePayloadRequester(payload, physician, organization);

	if (generateStudiUid) {
		payload = {
			...payload,
			extension: [
				...(payload.extension || []),
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/generateStudyUID',
					valueString: '1',
				},
			],
		};
	}

	if (faxAttachedToOrder) {
		payload = {
			...payload,
			extension: [
				...(payload.extension || []),
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/fax',
					valueReference: faxAttachedToOrder,
				},
			],
		};
	}

	return payload;
};

const createNewVisitPayload = (organization, patient, date) => {
	return {
		status: 'planned',
		resourceType: 'Encounter',
		subject: {
			id: patient?.id,
			reference: `Patient/${patient?.id}`,
			display: getUserFullName(patient?.name?.[0]),
		},
		serviceProvider: {
			id: organization?.id,
			reference: `Organization/${organization?.id}`,
			display: organization.name,
		},
	};
};

const createBundlePayload = (
	priority,
	organization,
	patient,
	orderSet,
	notes,
	procedureCodes,
	referringPhysician,
	referringOrganization,
	consultingPhysician,
	loggedInUser,
	generateStudiUid,
	studyCreatedDate,
	faxAttachedToOrder,
	customPriority
) => {
	const urnUuid1 = `urn:uuid${uuid()}`;
	const urnUuid2 = `urn:uuid${uuid()}`;
	const date = moment(new Date()).toISOString();

	const bundlePayload = {
		id: 'bundle-transaction',
		resourceType: 'Bundle',
		type: 'transaction',
		entry: [
			{
				request: {
					method: 'POST',
					url: '/Encounter/',
				},
				fullUrl: urnUuid1,
				resource: createNewVisitPayload(organization, patient, date),
			},
			{
				request: {
					method: 'POST',
					url: '/ServiceRequest/',
				},
				fullUrl: urnUuid2,
				resource: createNewOrderPayload(
					priority,
					patient,
					orderSet,
					date,
					notes,
					urnUuid1,
					procedureCodes,
					referringPhysician,
					referringOrganization,
					consultingPhysician,
					loggedInUser,
					generateStudiUid,
					studyCreatedDate,
					faxAttachedToOrder,
					customPriority
				),
			},
		],
	};

	return bundlePayload;
};

export { createBundlePayload, updatePayloadRequester, createOrderRequesterNode };
