import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInDays from 'date-fns/differenceInDays';

const calculatePatientAgeAtStudy = resource => {
	if (!resource) {
		return;
	}

	let ageAtStudy = resource?.age; // Age in years
	if (ageAtStudy >= 2) {
		ageAtStudy = `${ageAtStudy}y`;
	} else if (ageAtStudy >= 0 && ageAtStudy < 2) {
		if (!resource?.started || !resource?.birthDate) {
			return;
		}
		// Exclude time from Study Date Time because birthDate is Date type
		const studyDate = new Date(resource.started.split('T')[0]);
		const birthDate = new Date(resource.birthDate);
		if (studyDate.toDateString() === birthDate.toDateString()) {
			ageAtStudy = `${1}d`;
		} else {
			const ageInMonths = differenceInMonths(studyDate, birthDate);
			if (ageInMonths >= 1) {
				ageAtStudy = `${ageInMonths}m`;
			} else {
				const ageInDays = differenceInDays(studyDate, birthDate);
				if (ageInDays === 30) {
					// This is where a month has 31 days, ex: studyDate = Dec 08 2023, birthDate = Jan 07 2024
					// differenceInMonths = 0 and ageInDays = 30. It does not make sense to show 30d in this case -> show 1m
					ageAtStudy = `${1}m`;
				} else if (ageInDays > 0) {
					ageAtStudy = `${ageInDays}d`;
				}
			}
		}
	}

	return ageAtStudy;
};

export default calculatePatientAgeAtStudy;
