// core
import { create } from 'zustand';
import { commonMiddlewares } from './middlewares';

// api
import getUserInfo from '../api/getUserInfo';
import patchUser from '../api/patchUser';

// utils
import { extractEmergencyContactsFromProfile } from '../utils/extractEmergencyContactsFromProfile';
import { extractPhysiciansFromProfile } from '../utils/extractPhysiciansFromProfile';
import { getAllergyDataFromProfile } from '../utils/getAllergyDataFromProfile';
import { extractAddressFromProfile } from '../utils/extractAddressFromProfile';

export const useUserStore = create(
	commonMiddlewares((set, get) => ({
		user: null,
		primaryUser: null,
		emergencyContacts: [],
		physicians: [],
		selectedLanguage: 'en',
		userFeedback: null,

		fetchUser: async ({ __config }) => {
			try {
				const userInfo = await getUserInfo({ __config });

				let primaryUserProfileId;
				let primaryUser;

				for (const key in userInfo.aggregatedetails) {
					if (userInfo.aggregatedetails[key].isPrimary) {
						primaryUserProfileId = key;
						primaryUser = userInfo.aggregatedetails[key];
						break;
					}
				}

				const extractedEmergencyContacts = extractEmergencyContactsFromProfile(primaryUser);
				const extractedPhysicians = extractPhysiciansFromProfile(primaryUser);
				const user = {
					...extractAddressFromProfile(primaryUser?.address),
					img: primaryUser?.profilePictureUrl,
					firstName: primaryUser?.firstName || '',
					middleName: primaryUser?.middleName || '',
					lastName: primaryUser?.lastName || '',
					birthDate: primaryUser?.birthDate || '',
					gender: primaryUser?.gender || '',
					phone: userInfo.phone || '',
					email: userInfo.email || '',
					race: primaryUser?.race || '',
					ethnicity: primaryUser?.ethnicity || '',
					birthSex: primaryUser?.birthSex || '',
					language: primaryUser?.language || '',
					ssn: primaryUser?.ssn || '',
					maritalStatus: primaryUser?.maritalStatus || '',
					smokingStatus: primaryUser?.smokingStatus || '',
					alcoholUse: primaryUser?.alcoholUse || '',
					motherMaidenName: primaryUser?.motherMaidenName || '',
					height: primaryUser?.height || '',
					weight: primaryUser?.weight || '',
					abha: primaryUser?.abha || {},
					profileId: primaryUserProfileId,
					isPrimary: true,
					myPhysician: extractedPhysicians,
					emergencyContacts: extractedEmergencyContacts,
					allergyMapping: getAllergyDataFromProfile(primaryUser?.allergyMapping),
				};

				set(() => ({
					user,
					primaryUser: { profileId: primaryUserProfileId, ...primaryUser },
					emergencyContacts: extractedEmergencyContacts,
					physicians: extractedPhysicians,
					selectedLanguage: userInfo.userSettings?.language,
					userFeedback: userInfo.userFeedback || null,
				}));

				patchUser({ __config, data: [{ operation: 'ADD', path: '/isOnline', value: true }] });

				return userInfo.aggregatedetails;
			} catch (error) {
				console.error(`Error while getting current loggedIn user: ${error.message}`);
			}
		},

		setUserFeedback: userFeedback => set(() => ({ userFeedback })),
	}))
);
