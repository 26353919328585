import { logError, logInfo, logWarn } from '@rs-core/index';

export let radPairContentUpdateCounter = 0;

const incrementRadPairContentUpdateCounter = () => {
	radPairContentUpdateCounter++;
	logInfo('updateRadPairContent', `invoked ${radPairContentUpdateCounter} times`);
};

export const resetRadPairContentUpdateCounter = () => {
	radPairContentUpdateCounter = 0;
	logInfo('updateRadPairContent', 'counter reset to 0');
};

const sanitizeRadPairHtml = html => {
	// Parse the HTML string to a DOM object
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');
	const radPairContentDiv = doc.querySelector('div[data-type="radPair-content"]');

	if (radPairContentDiv) {
		// Check and remove <p> tags directly before the div
		const prevSibling = radPairContentDiv.previousSibling;
		if (prevSibling && prevSibling.tagName === 'P' && !prevSibling.textContent.trim()) {
			prevSibling.parentNode.removeChild(prevSibling);
			logInfo('updateRadPairContent', 'Removed empty <p> tag before radPairContentDiv');
		}

		// Remove <p> tags at the start and end of the div
		if (
			radPairContentDiv.firstChild &&
			radPairContentDiv.firstChild.tagName === 'P' &&
			!radPairContentDiv.firstChild.textContent.trim()
		) {
			radPairContentDiv.removeChild(radPairContentDiv.firstChild);
			logInfo('updateRadPairContent', 'Removed empty <p> tag at the end of radPairContentDiv');
		}
		if (
			radPairContentDiv.lastChild &&
			radPairContentDiv.lastChild.tagName === 'P' &&
			!radPairContentDiv.lastChild.textContent.trim()
		) {
			radPairContentDiv.removeChild(radPairContentDiv.lastChild);
			logInfo('updateRadPairContent', 'Removed empty <p> tag after radPairContentDiv');
		}

		// Check and remove <p> tags directly after the div
		const nextSibling = radPairContentDiv.nextSibling;
		if (nextSibling && nextSibling.tagName === 'P' && !nextSibling.textContent.trim()) {
			nextSibling.parentNode.removeChild(nextSibling);
			logInfo('updateRadPairContent', 'Removed empty <p> tag after radPairContentDiv');
		}
	} else {
		logWarn('sanitizeRadPairHtml', '<div data-type="radPair-content"> not found');
	}

	// Serialize the DOM object back to a string
	const serializer = new XMLSerializer();
	return serializer.serializeToString(doc.body);
};

export const updateRadPairContent = ({
	finalReport,
	mainEditor,
	radPairReportAction,
	setFinalReport,
	signReport,
	setIsRadPairModalVisible,
}) => {
	try {
		incrementRadPairContentUpdateCounter();

		if (!finalReport || !mainEditor) {
			logError('updateRadPairContent::error:', 'Missing finalReport or mainEditor');
			return;
		}

		const radPairHtml = `<p>${finalReport}</p>`.replaceAll('\n', '</p><p>').replaceAll('<p></p>', '<p>&nbsp;</p>');
		let radPairContentHTML = `<div data-type="radPair-content">${radPairHtml}</div>`;

		const editorHTML = mainEditor.getHTML();
		if (!editorHTML) {
			logError('updateRadPairContent::error:', 'Failed to get editor HTML');
			return;
		}

		const parser = new DOMParser();
		const doc = parser.parseFromString(editorHTML, 'text/html');
		const radPairContentDivs = doc.querySelectorAll('div[data-type="radPair-content"]');

		if (radPairContentDivs.length > 0) {
			logInfo('updateRadPairContent', 'Updating existing radPair-content div');
			radPairContentDivs.forEach(radPairDiv => {
				radPairDiv.innerHTML = radPairHtml;
			});
			// Serialize the document back to a string
			const serializer = new XMLSerializer();
			let newEditorHTML = serializer.serializeToString(doc.body);

			newEditorHTML = sanitizeRadPairHtml(newEditorHTML);

			mainEditor.commands.setContent(newEditorHTML, { parseOptions: { preserveWhitespace: true } });
			logInfo('updateRadPairContent', 'Updated radPair HTML in the existing document');
		} else {
			mainEditor
				.chain()
				.focus()
				.insertContent(radPairContentHTML, {
					parseOptions: { preserveWhitespace: true },
				})
				.run();
			let updatedEditorHTML = mainEditor.getHTML();

			updatedEditorHTML = sanitizeRadPairHtml(updatedEditorHTML);
			mainEditor.commands.setContent(updatedEditorHTML, { parseOptions: { preserveWhitespace: true } });
			logInfo('updateRadPairContent', 'Inserted radPair HTML in the preliminary document');
		}

		if (radPairReportAction === 'sign') {
			logInfo('updateRadPairContent', 'Initiating sign report request');
			signReport({ moveToNext: false });
		} else {
			setIsRadPairModalVisible(false);
			setFinalReport('');
		}
	} catch (error) {
		logError('updateRadPairContent: An error occurred', error);
	}
};
