import { getInstanceIsDocument, getInstanceModality, getSeriesUID } from '../utils/utils';
import createImageIdsAndCacheMetaData from '../cornerstone/createImageIdsAndCacheMetaData';
import loadMetadata from './loadMetadata';
import SeriesSplitManager from '../features/SeriesSplitter/SeriesSplitManager';
import isAvailableMPR from '../contexts/utils/isAvailableMPR';
import loadCADSR from '../features/StackViewports/CAD/loadCADSR';
import getMiddleFrameIndex from '../utils/getMiddleFrameIndex';
import { calculateSUVScalingFactors } from '@cornerstonejs/calculate-suv';
import getPTImageIdInstanceMetadata from '../cornerstone/getPTImageIdInstanceMetadata';
import SortImageSlices from '../utils/SortImageSlices';

const unsupportedSOPClasses = [
	'1.2.840.10008.5.1.4.1.1.66',
	'1.2.840.10008.5.1.4.1.1.8',
	'1.2.840.10008.5.1.4.1.1.9',
	'1.2.840.10008.5.1.4.1.1.9.1.1',
	'1.2.840.10008.5.1.4.1.1.9.1.2',
	'1.2.840.10008.5.1.4.1.1.9.1.3',
	'1.2.840.10008.5.1.4.1.1.9.2.1',
	'1.2.840.10008.5.1.4.1.1.9.3.1',
	'1.2.840.10008.5.1.4.1.1.10',
	'1.2.840.10008.5.1.4.1.1.11',
	'1.2.840.10008.5.1.4.1.1.481.2',
	'1.2.840.10008.5.1.4.1.1.481.3',
	'1.2.840.10008.5.1.4.1.1.481.4',
	'1.2.840.10008.5.1.4.1.1.481.5',
	'1.2.840.10008.5.1.4.1.1.481.6',
	'1.2.840.10008.5.1.4.1.1.481.7',
	'1.2.840.10008.5.1.4.1.1.4.2',
	'1.2.840.10008.5.1.4.1.1.88.67',
	'1.2.840.10008.5.1.4.1.1.66.4',
	'1.2.840.10008.5.1.4.1.1.104.1',
];

const unsupportedModalities = [
	'IOL',
	'PR',
	'SR',
	'RTDOSE',
	'RTSTRUCT',
	'PLAN',
	'REG',
	'SEG',
	'OAM',
	'OPM',
	'LEN',
	'SMR',
	'AR',
	'KER',
	'VA',
	'SRF',
];

const loadAndAppendMetadataToSeries = ({
	__config,
	headers,
	studyInstanceUID,
	series,
	wonIvSeriesSplitter,
	is3D,
	wonIvPlaneSplitter,
	wonIvDwiseriessplitter,
	wonIvDisablexasplit,
	cacheLinks,
	useCornerstone,
	wonIvInitImageMetadata,
	wonIvEnhancedcurvedsort,
	wonIvRefactorsorting,
	managingOrganizationId,
	isBlumeApp,
	creatorUserId,
	isUploaded,
}) => {
	const CADSRObjectList = [];

	const promises = series
		.filter(s => {
			const seriesModality = s['00080060']?.Value?.[0];

			if (seriesModality && unsupportedModalities.includes(seriesModality)) {
				return false;
			}

			return true;
		})
		.map(async s => {
			const metadata = await loadMetadata({
				__config,
				headers,
				studyInstanceUID,
				seriesUID: getSeriesUID(s),
				isPartialmetadata: true,
				wonIvInitImageMetadata,
				isBlumeApp,
				creatorUserId,
				isUploaded,
			});

			let filteredMetadata = metadata
				.filter(item => {
					const ModalityTag = '00080060';
					const SOPClassTag = '00080016';
					const RowsTag = '00280010';
					const ColumnsTag = '00280011';
					const rows = item[RowsTag]?.Value?.[0];
					const cols = item[ColumnsTag]?.Value?.[0];

					if (item[ModalityTag]?.Value?.[0] == 'CADSR') {
						CADSRObjectList.push(item);
					}

					return (
						!unsupportedSOPClasses.includes(item[SOPClassTag]?.Value?.[0]) &&
						(!unsupportedModalities.includes(item[ModalityTag]?.Value?.[0]) ||
							// If rows and columns great than 0, this instance is considered as an image
							(rows > 0 && cols > 0)) &&
						!getInstanceIsDocument(item)
					);
				})
				.map(item => {
					const imageOrientation = item['00200037']?.Value;

					let hasIncorrectImageOrientation = false;

					if (imageOrientation) {
						hasIncorrectImageOrientation =
							imageOrientation.map(_item => Math.abs(parseFloat(_item))).toString() === '0,0,0,0,0,0';
					}

					if (hasIncorrectImageOrientation) {
						delete item['00200037'];
					}

					return { ...item, managingOrganizationId };
				});

			const middleFrameIndex = getMiddleFrameIndex(filteredMetadata);
			const _metadata = filteredMetadata[middleFrameIndex] || filteredMetadata[0];

			if (getInstanceModality(_metadata)?.toLowerCase?.() === 'pt') {
				try {
					const instanceMetadataArray = [];

					filteredMetadata.forEach(imageId => {
						const instanceMetadata = getPTImageIdInstanceMetadata(imageId);

						if (instanceMetadata) {
							instanceMetadataArray.push(instanceMetadata);
						}
					});

					if (instanceMetadataArray.length) {
						let suvScalingFactors;

						suvScalingFactors = calculateSUVScalingFactors(instanceMetadataArray);

						filteredMetadata = filteredMetadata.map((item, index) => ({
							...item,
							scalingModule: suvScalingFactors[index],
						}));
					}
				} catch (error) {
					console.info(error);
				}
			}

			return {
				...s,
				metadata: filteredMetadata,
				studyInstanceUID,
				isAvailableMPR: filteredMetadata?.length > 1 && isAvailableMPR(filteredMetadata[middleFrameIndex]),
			};
		});

	return Promise.all(promises).then(async _series => {
		_series.forEach(s => {
			s.metadata = SortImageSlices(s.metadata, wonIvEnhancedcurvedsort, wonIvRefactorsorting);
		});

		if (wonIvSeriesSplitter) {
			const splitterManager = new SeriesSplitManager(
				wonIvPlaneSplitter,
				wonIvDwiseriessplitter,
				wonIvDisablexasplit
			);
			_series = splitterManager.SplitSeries(_series, true);
		}

		if (CADSRObjectList.length > 0) {
			_series = _series.map(s => {
				s.metadata = s.metadata.map(metadata => {
					const referencedSOPInstanceUID = metadata?.['00082112']?.Value?.[0]?.['00081155']?.Value?.[0];
					const SOPInstanceUID = metadata?.['00080018']?.Value?.[0];

					let cadsr;
					if (referencedSOPInstanceUID && SOPInstanceUID) {
						cadsr = loadCADSR(CADSRObjectList, studyInstanceUID, referencedSOPInstanceUID, SOPInstanceUID);
					}

					return { ...metadata, cadsr };
				});

				return { ...s };
			});
		}

		_series = await Promise.all(
			_series.map(async s => {
				const imageIds = await createImageIdsAndCacheMetaData({
					studyInstanceUID,
					seriesInstanceUID: getSeriesUID(s),
					headers,
					__config,
					series: s,
					instances: s.metadata,
					cacheLinks,
					is3D,
					useCornerstone,
					isBlumeApp,
					creatorUserId,
					isUploaded,
				});

				return { ...s, imageIds };
			})
		);

		return _series;
	});
};

export default loadAndAppendMetadataToSeries;
