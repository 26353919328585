// @flow

// core
import React, { useEffect, useState, type Node, useMemo } from 'react';
// components
import PatientProfileHoverView from '../../WorklistGridPatientProfile/PatientProfileHoverView';
import Popup from '../../../Popup/Popup';
// mui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircleChecked from '@mui/icons-material/CheckCircleOutline';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

// assets
import LinkedProfileIcon from '../../../../assets/icons/linked-profile-icon.svg';

import { useSelection } from '@worklist-2/core/src/context/SelectionContext';
import getUserFullName, {
	parseDicomName,
} from '@worklist-2/core/src/fhir/resource/columnMapping/utils/getUserFullName';
import { useWorklist } from '../../Worklist/contexts/WorklistContext';

import { useAuth } from '@rs-core/hooks';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

interface IProperty {
	code: string;
	display: string;
}

interface IProcedureCode {
	coding: IProcedureCodeCoding[];
}

interface IProcedureCodeCoding {
	extension: object[];
	code: string;
	display: string;
	id: string;
}

interface ISubject {
	reference: string;
	display: string;
	id: string;
}

interface IWorklistImagingStudy {
	modality: IProperty[];
	procedureCode: IProcedureCode[];
	subject: ISubject;
	numImages: number;
	financialClass: IProperty;
	isActive: boolean;
	referringPhysician: string;
	ssn: string;
	isLinked: string;
	numberOfInstances: number;
	requestedAppointmentDateTime: string;
	id: string;
	text: {
		div: string,
	};
	state: string;
	patientAddress: string;
	identifier: object[];
	homePhone: string;
	internalManagingOrganizationID: string;
	fillerOrderNumber: string;
	timeZone: string;
	createdDateTimeUTC: string;
	imagingOrganizationId: string;
	eligibilityStatus: string;
	timeAtStatus: string;
	imagingOrganization: string;
	meta: {
		lastUpdated: string,
		extension: object[],
		versionId: string,
	};
	orderNotes: string;
	visitClass: string;
	conditions: IProperty[];
	allergies: IProperty[];
	placerOrderNumber: string;
	extension: object[];
	specialArrangement: string;
	referringFacility: string;
	gender: string;
	ethnicity: IProperty[];
	visitNumber: string;
	patientContactMethod: string;
	description: string;
	orderStatus: string;
	chargeStatus: string;
	dateTimeOrdered: string;
	studyID: string;
	reasonCode: Record<string, string>[];
	department: string;
	basedOn: [
		{
			reference: string,
			id: string,
		},
	];
	orderDateTime: string;
	referringPhysicianId: string;
	orderDateTimeUTC: string;
	languages: IProperty[];
	race: IProperty[];
	patientID: string;
	cancellationReason: string;
	referringFacilityId: string;
	started: string;
	encounter: {
		reference: string,
		id: string,
	};
	dateTimeOrderedUTC: string;
	startedUTC: string;
	birthDate: string;
	requestedAppointmentDateTimeUTC: string;
	managingOrganization: string;
	priorStatus: string;
	primaryPriorAuthorizationNumber: string;
	numFrames: number;
	numReports: number;
	patientEmail: string;
	age: number;
	cellPhone: string;
	resourceType: string;
}

interface IPatientProfileCellProps {
	data: IWorklistImagingStudy;
}

export const PatientProfileCell = ({ data }: IPatientProfileCellProps): Node => {
	const patientId = data?.subject?.id;
	const patientName = data?.subject?.display;
	const { selectedResources, handleResourceSelect, handleResourceDelete } = useSelection();
	const { closeWorklistWheel } = useWorklist();
	const { hasPermissionByOrganization, hasPermissionByOrganizationAsync } = useAuth();

	const [isChecked, setChecked] = useState(false);
	const [shouldDisplayLinkAndMerge, setShouldDisplayLinkAndMerge] = useState(false);

	const crossFormatPersonName = useBooleanFlagValue('cross-format-person-name');
	const proactPatientMergeLinkOrder = useBooleanFlagValue('proact-patient-merge-link-order');
	const proactEnablePatientInfoMergeLink = useBooleanFlagValue('proact-enable-patient-info-merge-link');
	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');

	useEffect(() => {
		const fetchPermission = async () => {
			if (!proactEnablePatientInfoMergeLink || data?.isLinked !== '1' || !data?.internalManagingOrganizationID) {
				setShouldDisplayLinkAndMerge(false);
				return;
			}
			const linkAndMergeStudyPermissionPath = 'worklist.link and merge patient';
			if (crossGetPrivilegeOnDemand) {
				const permission = await hasPermissionByOrganizationAsync(
					data?.internalManagingOrganizationID,
					'Home',
					linkAndMergeStudyPermissionPath
				);
				setShouldDisplayLinkAndMerge(permission);
			} else {
				const permission = hasPermissionByOrganization(
					data?.internalManagingOrganizationID,
					'Home',
					linkAndMergeStudyPermissionPath
				);
				setShouldDisplayLinkAndMerge(permission);
			}
		};
		fetchPermission();
	}, [data, proactEnablePatientInfoMergeLink, crossGetPrivilegeOnDemand]);

	const isSelected = selectedResources.some(i => i.id === data.id);

	const handleOver = event => {
		event.currentTarget.classList.add('hovered');
	};

	const handleLeave = event => {
		event.currentTarget.classList.remove('hovered');
	};

	useEffect(() => {
		if (isSelected) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	}, [isSelected]);

	const handleChange = e => {
		const checkboxValue = e.target.checked;

		if (checkboxValue) {
			handleResourceSelect(data);
			setChecked(true);
		} else {
			handleResourceDelete(data);
			setChecked(false);
		}
	};

	return (
		<Box display="flex" flexDirection="row">
			<Box
				data-testid="patient-profile-box"
				sx={{
					mr: '8px',
					width: '20px',
					height: '20px',
				}}
				onMouseEnter={handleOver}
				onMouseLeave={handleLeave}
			>
				{proactPatientMergeLinkOrder ? (
					<>
						<Checkbox
							checked={isChecked}
							checkedIcon={<CircleChecked />}
							data-testid="patient-checkbox"
							icon={<CircleUnchecked />}
							sx={{
								height: '20px',
								width: '20px',
								borderRadius: '6px',
								background: '#42A5F51A',
								display: isSelected ? 'flex' : 'none',
								justifyContent: 'center',
								alignItems: 'center',

								'.hovered &': {
									display: 'flex',
								},
							}}
							onChange={handleChange}
							onClick={e => {
								e.stopPropagation();
								// Close the worklist wheel if it's visible
								closeWorklistWheel();
							}}
						/>
						{shouldDisplayLinkAndMerge ? (
							<Box
								data-testid="linked-profile-icon"
								sx={{
									display: !isSelected ? 'flex' : 'none',
									width: 'fit-content',
									'.hovered &': {
										display: 'none',
									},
								}}
							>
								<LinkedProfileIcon />
							</Box>
						) : (
							<AccountCircleIcon
								data-testid="account-icon"
								fontSize="small"
								sx={{
									color: '#FFFFFF',
									display: !isSelected ? 'flex' : 'none',
									borderRadius: 4,

									'.hovered &': {
										display: 'none',
									},

									'&:hover': {
										color: 'rsPrimary.main',
										backgroundColor: 'transparent',
									},
								}}
							/>
						)}
					</>
				) : (
					<AccountCircleIcon
						fontSize="small"
						sx={{
							color: '#FFFFFF',
							borderRadius: 4,

							'&:hover': {
								color: 'rsPrimary.main',
								backgroundColor: 'transparent',
							},
						}}
					/>
				)}
			</Box>

			<Popup
				delay={500}
				popupComponent={
					<></>
					// it was decided to disable the hover view for now
					// <PatientProfileHoverView hasPermissionToDisplayLinkAndMerge={shouldDisplayLinkAndMerge} patientId={patientId} />
				}
				sxBox={{ width: 'calc(100% - 28px)' }}
			>
				<Typography
					data-testid="patient-name"
					sx={{
						fontSize: '14px',
						lineHeight: '1.43',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{`${crossFormatPersonName ? parseDicomName(patientName) : getUserFullName(patientName, true)}`}
				</Typography>
			</Popup>
		</Box>
	);
};
