// @flow

// core
import React, { type Node } from 'react';

// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// libs
import { useTranslation } from 'react-i18next';

// components
import ConflictPill from './ConflictPill';

// utils
import { useSelection } from '@worklist-2/core/src/context/SelectionContext';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

interface ICardInfoRowData {
	label: string;
	value: string;
	size: number;
	isDifferent: boolean;
}

interface ICardInfoRowProps {
	data: ICardInfoRowData;
	isCardChecked: boolean;
	handlePillClick: () => void;
}

export const CardInfoRow = ({ data, isCardChecked, handlePillClick }: ICardInfoRowProps): Node => {
	const proactMergePatientV2 = useBooleanFlagValue('proact-merge-patient-v-2');
	const { t } = useTranslation('workList');
	const { replacedFields } = useSelection();

	return (
		<Grid container spacing={0} wrap="nowrap">
			{data.map((item, index) => (
				<Grid key={`grid_${index}`} item md={item.size || 6} sm={item.size || 6} xs={6}>
					{proactMergePatientV2 ? (
						<Box sx={SX.Box}>
							<Stack direction="row" spacing={1} sx={SX.LabelRow}>
								{/* LABEL */}
								<Typography sx={SX.Label}>{item.label}</Typography>

								{/* EXCLAMATION ICON - different value than other merge patients */}
								{item.isDifferent && !isCardChecked && (
									<Tooltip
										PopperProps={{
											sx: {
												'& .MuiTooltip-tooltip': {
													color: 'rgba(255, 255, 255, 0.60)',
													fontStyle: 'italic',
												},
											},
										}}
										title={t('Different value')}
									>
										<ErrorOutlineIcon sx={{ color: '#CF6679', fontSize: '16px' }} />
									</Tooltip>
								)}
							</Stack>

							{/* VALUE */}
							{item.isDifferent && item.showConflictPills && !isCardChecked ? (
								<ConflictPill
									dateValue={item.dateValue}
									fieldName={item.fieldName}
									handlePillClick={handlePillClick}
									value={item.value}
								/>
							) : (
								<Typography
									noWrap
									sx={{
										'@keyframes opacity': {
											'0%': {
												opacity: 0.6,
											},

											'90%': {
												opacity: 0.6,
											},

											'100%': {
												opacity: 1,
											},
										},
										animation: replacedFields.includes(item.fieldName) ? 'opacity 2s ease' : '',
										color: item.highlighted ? '#CF6679' : '#fff',
										fontSize: '14px',
									}}
								>
									{item.value}
								</Typography>
							)}
						</Box>
					) : (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								paddingRight: 0.5,
								paddingLeft: 0.5,
							}}
						>
							<Typography
								noWrap
								sx={{
									color: 'rgba(255, 255, 255, 0.6)',
									fontSize: '14px',
								}}
							>
								{item.label}
							</Typography>
							<Typography
								noWrap
								sx={{
									fontSize: '16px',
									color: item.highlighted ? '#CF6679' : '#fff',
								}}
							>
								{item.value}
							</Typography>
						</Box>
					)}
				</Grid>
			))}
		</Grid>
	);
};

const SX = {
	Box: {
		display: 'flex',
		flexDirection: 'column',
		paddingRight: 0.5,
		paddingLeft: 0.5,
	},
	LabelRow: {
		alignItems: 'center',
	},
	Label: {
		color: 'rgba(255, 255, 255, 0.6)',
		fontSize: '14px',
		overflow: 'unset',
		textOverflow: 'unset',
		whiteSpace: 'nowrap',
	},
	Value: {
		color: '#fff',
		fontSize: '16px',
	},
};
