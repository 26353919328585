import { getPatientExternalId } from '@rs-ui/views/PatientInfoView/utils';

export class MergePatient {
	constructor(patient) {
		const patientCellPhone = patient?.telecom?.find(it => it.system === 'phone')?.value;
		const patientManagingOrganization = patient?.managingOrganization?.display;

		const patientAddressObject = patient?.address?.[0];
		const address = patientAddressObject?.line?.[0];
		const patientState = patientAddressObject?.state;

		const patientNameObject = patient?.name?.[0];
		const patientFirstName = patientNameObject?.given?.[0];
		const patientLastName = patientNameObject?.family;
		const patientFullName = patientNameObject?.text;

		this.patient = patient;
		this.birthDate = patient?.birthDate || 'N/A';
		this.cellPhone = patientCellPhone || 'N/A';
		this.gender = patient?.gender || 'N/A';
		this.managingOrganization = patientManagingOrganization || 'N/A';
		this.patientID = getPatientExternalId(patient);
		this.patientInternalID = patient?.id;
		this.patientFullName = patientFullName || 'N/A';
		this.patientLastName = patientLastName || 'N/A';
		this.patientFirstName = patientFirstName || 'N/A';

		this.city = patientAddressObject?.city || 'N/A';
		this.country = patientAddressObject?.country || 'N/A';
		this.state = patientState || 'N/A';
		this.patientAddress = address || 'N/A';
	}
}
