import { create } from 'zustand';
import _ from 'lodash';
import moment from 'moment';

import { fhirExtensionUrls } from '@worklist-2/core/src';

import getAppointments from '../api/getAppointments';
import getHealthcareServiceInfo from '../api/getHealthcareServiceInfo';
import getOrganizations from '../api/getOrganizations';
import patchAppointment from '../api/patchAppointment';
import cancelAppointment from '../api/cancelAppointment';
import getAddress from '../utils/getAddress';
import { statusMapping, createAppointmentWithHealthcareInfo } from '../components/Appointment/V2/utils';
import { commonMiddlewares } from './middlewares';

export const useAppointmentStore = create(
	commonMiddlewares((set, get) => ({
		// State
		__config: null,
		appointments: [],
		isNewUser: false,
		isLoading: false,
		isLoaded: false,

		// Actions
		getAppointments: async __featureFlag => {
			try {
				const { __config, getHealthcareInfo, appointments: appts } = get();

				if (!appts?.length) set(() => ({ isLoading: true }), false, 'appointment/getAppointments');

				const result = await getAppointments({ __config });

				if (!result) {
					set(() => ({ isLoading: false, isLoaded: true }), false, 'appointment/getAppointments');
					return;
				}

				// Get all unique healthcare service Ids
				const healthCareIds = _.join(
					_.uniq(
						result.map(resource => {
							const healthcareServiceId = _.get(
								resource?.participant.find(({ actor }) =>
									actor.reference.toLowerCase().startsWith('healthcareservice')
								),
								'actor.reference'
							)?.split('/')[1];
							return healthcareServiceId;
						})
					).filter(Boolean)
				);

				// Get healthcare service info to add healthcare service details in appointment
				const healthcareInfoById = await getHealthcareInfo(healthCareIds);

				// Create appointments with mapped data from healthcare service info & appointments
				const appointments = result
					.map(resource => {
						const currentHealthCareServiceId = _.get(
							resource.participant.find(({ actor }) =>
								actor.reference.toLowerCase().startsWith('healthcareservice')
							),
							'actor.reference'
						)?.split('/')[1];
						const currentHealthCareInfo = healthcareInfoById
							? healthcareInfoById[currentHealthCareServiceId]
							: null;

						return createAppointmentWithHealthcareInfo({
							resource,
							healthCareInfo: currentHealthCareInfo,
							healthCareServiceId: currentHealthCareServiceId,
						});
					})
					.filter(({ isParked, organization }) => isParked || organization); // Filter out appointments of deleted healthcare services
				const hasParkedAppointment = appointments.some(appt => appt.isParked);

				if (hasParkedAppointment) {
					// Get all the orgIds for parked appointment to fetch their org details
					const parkedAppointmentOrgIds = appointments.reduce((orgIds, appt) => {
						if (appt.isParked) orgIds.push(appt.managingOrganization);
						return orgIds;
					}, []);

					// Fetch org details to add org data for parked appointments
					const orgDetails = await getOrganizations({ __config, orgIds: parkedAppointmentOrgIds });

					// Append org details to parked appointments
					const updatedAppointments = appointments.map(appt => {
						if (appt.isParked && appt.managingOrganization) {
							const {
								resource: { id, name, telecom, address },
							} = orgDetails.entry.find(({ resource }) => resource.id === appt.managingOrganization);

							return {
								...appt,
								id,
								address: getAddress(address[0]),
								organization: name,
								phone: telecom?.find(item => item.system === 'phone')?.value,
							};
						}

						return appt;
					});

					set(
						() => ({
							isLoading: false,
							isLoaded: true,
							appointments: updatedAppointments,
							isNewUser: Boolean(!updatedAppointments.length),
							filteredAppointments: __featureFlag
								? updatedAppointments
								: updatedAppointments?.filter(
										appt =>
											appt.isParked ||
											moment(appt.dateStart).isSameOrAfter(moment().startOf('day'))
								  ),
						}),
						false,
						'appointment/getAppointments'
					);
					return;
				}

				set(
					() => ({
						isLoading: false,
						isLoaded: true,
						appointments,
						isNewUser: Boolean(!appointments.length),
						filteredAppointments: __featureFlag
							? appointments
							: appointments?.filter(appt =>
									moment(appt.dateStart).isSameOrAfter(moment().startOf('day'))
							  ),
					}),
					false,
					'appointment/getAppointments'
				);
			} catch (err) {
				console.error(err);
			}
		},

		updateAttachments: async appointmentId => {
			const { appointments, filteredAppointments } = get();
			set(
				() => ({
					appointments: appointments?.map(d => ({
						...d,
						attachments: d?.id === appointmentId ? [{ valueString: 1 }] : d?.attachments,
					})),
					filteredAppointments: filteredAppointments?.length
						? filteredAppointments?.map(d => ({
								...d,
								attachments: d?.id === appointmentId ? [{ valueString: 1 }] : d?.attachments,
						  }))
						: [],
				}),
				false,
				'appointment/updateAttachments'
			);
		},

		getHealthcareInfo: async healthCareServiceIds => {
			try {
				const timezoneExtUrl = fhirExtensionUrls.organization.timezone;
				const timezoneIANAExtUrl = fhirExtensionUrls.organization.ianaTimezone;
				const addressExtUrl = fhirExtensionUrls.organization.address;
				const phoneExtUrl = fhirExtensionUrls.organization.phone;
				const modalityExtUrl = fhirExtensionUrls.common.modality;
				const durationExtUrl = fhirExtensionUrls.healthcareService.duration;
				const overBookingExtUrl = fhirExtensionUrls.healthcareService.overBooking;

				const { __config } = get();

				if (!healthCareServiceIds) return null;

				const result = await getHealthcareServiceInfo({ __config, healthCareServiceIds });

				const transformedHealthcareServiceInfo = result.entry.map(service => {
					const { providedBy, id, extension } = service.resource;

					return {
						id,
						organization: providedBy.display,
						internalOrganizationID: providedBy.id,
						modality: _.get(_.find(extension, { url: modalityExtUrl }), 'extension[0].valueCode'),
						duration: _.get(_.find(extension, { url: durationExtUrl }), 'valueInteger'),
						overBooking: _.get(_.find(extension, { url: overBookingExtUrl }), 'valueBoolean'),
						phone: _.get(_.find(providedBy.extension, { url: phoneExtUrl }), 'valueString'),
						address: getAddress(
							_.get(_.find(providedBy.extension, { url: addressExtUrl }), 'valueAddress')
						),
						timezone: _.get(_.find(providedBy.extension, { url: timezoneExtUrl }), 'valueString'),
						timezoneIANA: _.get(_.find(providedBy.extension, { url: timezoneIANAExtUrl }), 'valueString'),
					};
				});

				const healthcareInfoById = _.keyBy(transformedHealthcareServiceInfo, 'id');

				return healthcareInfoById;
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		patchAppointment: async ({ id, patchList, cancellationReason = '' }) => {
			try {
				const { __config } = get();

				await patchAppointment({
					__config,
					appointmentId: id,
					cancellationReason,
					patchList,
				});

				set(({ appointments }) => ({
					appointments: appointments.map(appt =>
						appt.id === id
							? {
									...appt,
									status:
										patchList?.path === '/status' ? statusMapping[patchList?.value] : appt.status,
							  }
							: appt
					),
				}));
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		confirmPendingAppointment: async (appointmentId, setToast = Function.prototype) => {
			try {
				await get().patchAppointment({
					id: appointmentId,
					patchList: [
						{
							op: 'add',
							path: '/status',
							value: 'booked',
						},
					],
				});
				setToast('Appointment confirmed successfully!');
				set(({ appointments, filteredAppointments }) => ({
					appointments: appointments.map(appt =>
						appt.id === appointmentId ? { ...appt, status: statusMapping.booked } : appt
					),
					filteredAppointments: filteredAppointments?.length
						? filteredAppointments.map(appt =>
								appt.id === appointmentId ? { ...appt, status: statusMapping.booked } : appt
						  )
						: [],
				}));
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		cancelAppointment: async ({ appointment, reason, phoenixSwitchCancellationApi }) => {
			try {
				if (phoenixSwitchCancellationApi) {
					const { __config } = get();

					const updatedAppointment = {
						...appointment,
						status: 'cancelled',
						extension: [
							...appointment.extension,
							{ url: fhirExtensionUrls.appointment.cancelReason, valueString: reason },
						],
					};

					const result = await cancelAppointment({ __config, appointment: updatedAppointment });
					set(({ appointments, filteredAppointments }) => ({
						appointments: appointments.map(appt =>
							appt.id === result.id ? { ...appt, status: statusMapping[result.status] } : appt
						),
						filteredAppointments: filteredAppointments?.length
							? filteredAppointments.map(appt =>
									appt.id === result.id ? { ...appt, status: statusMapping[result.status] } : appt
							  )
							: [],
					}));
					return;
				}

				await get().patchAppointment({
					id: appointment.id,
					patchList: [
						{ op: 'add', path: '/status', value: 'cancelled' },
						{ op: 'add', path: '/cancelationReason', value: { text: reason } },
					],
					cancellationReason: reason,
				});
			} catch (err) {
				console.error(err);
				throw err;
			}
		},

		setSharedStates: ({ __config }) => set(() => ({ __config }), false, 'appointment/setSharedStates'),
	}))
);
