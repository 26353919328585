import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import _ from 'lodash';
import axios from 'axios';

import { CircularProgress, InputBase } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {
	fhirEndpoints,
	searchScopes,
	useAppModeContext,
	useAuth,
	useFhirDataLoader,
	useHelpCenter,
	useSearchScope,
	useTabContext,
	useConfig,
} from '@worklist-2/core/src';
import { useRecognitionContext } from '@worklist-2/worklist/src/DocumentViewerV3/contexts/RecognitionContext';

import { marketplaceCategoryOptions } from '@rs-ui/components/GlobalSearch/GlobalSearchV2/index';

import { useHelpPageComponent } from '@worklist-2/core/src/hooks/useHelpPage';
import { GLOBAL_SEARCH_HELP_URL } from '@worklist-2/worklist/src/consts/helpPageMapping';
import DicomForm from '@rs-ui/components/DicomForm/DicomForm';
import SearchDialogV2 from '@rs-ui/components/GlobalSearch/GlobalSearchV2/SearchDialogV2';
import { useGoToUser } from '@rs-ui/components/SearchResults/SearchResultItem';

import { getPatientPortalSearchAllBody, getSearchAllBody } from '@rs-ui/components/SearchBar/SearchBar';

// eslint-disable-next-line import/no-relative-packages
import searchApps from '../../../../../../utils/marketplace/searchApps';

import GlobalSearchIcon from '@rs-ui/assets/icons/GlobalSearch/GlobalSearchIcon';
import ClearIcon from '@rs-ui/assets/icons/GlobalSearch/ClearIcon';
import CrossIcon from '@rs-ui/assets/icons/GlobalSearch/CrossIcon';

import styles from './SearchBarV2.module.scss';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const SearchBarV2 = ({ setParentData, additionalSearchParams, select, setSelect, defaultSearchText, initialValue }) => {
	const [searchText, setSearchText] = useState(defaultSearchText || '');
	const [dicomSearchText, setDicomSearchText] = useState('');
	const [isDicomDialog, setDicomDialog] = useState(false);
	const [dialog, setDialog] = useState(false);
	const [isClickAwayDisabled, setIsClickAwayDisabled] = useState(false);

	const [organizations, setOrganizations] = useState([]);
	const [organization, setOrganization] = useState(null);
	const [devices, setDevices] = useState([]);
	const [device, setDevice] = useState(null);

	const [fieldValuesObj, setFieldValuesObj] = useState({
		'Anatomic Focus': [],
		Gender: [],
		Modality: [],
		'Study Priority': [],
		'Study Status': [],
	});
	const [checkedFields, setCheckedFields] = useState([1, 2]);

	const [loading, setLoading] = useState(0);
	const [isRecentSearch, setIsRecentSearch] = useState(false);
	const [showSearchIcon, setShowSearchIcon] = useState(false);
	const [data, setData] = useState([]);
	const [showDialogHeader, setShowDialogHeader] = useState(true);

	const { vrViaDvToolbar, setVrViaDvToolbar, collapseSearchBarForVR } = useRecognitionContext();
	const { updateScope } = useSearchScope(useSearchScope);
	const { loggedInUserId, isLoadingUserId, goToUser } = useGoToUser();
	const { isPatientPortalMode, isWorklistMode, isMarketplaceMode, isHelpCenterMode, getItemCountRecentSearch } =
		useAppModeContext();
	const appModeIsWorklist = isWorklistMode();
	const appModeIsPatientPortal = isPatientPortalMode();
	const appModeIsMarketplace = isMarketplaceMode();
	const appModeIsHelpCenter = isHelpCenterMode();

	const ref = useRef(null);
	const dicomRef = useRef(null);
	const isMounted = useRef(false);
	const fetchTimeout = useRef(null);
	const lastSearchedData = useRef({ scope: '', value: '' });
	const savedDuringLoad = useRef(0);
	const inputRef = useRef(null);

	const isAuthenticated = useIsAuthenticated();
	const { instance, accounts, inProgress } = useMsal();

	const { selectedLanguage, globalPermissionsForLoggedUser } = useAuth();
	const { setSearchResults } = useHelpCenter();

	const {
		scope,
		fhirLoader,
		setLoading: setScopeLoading,
		loading: scopeLoading,
		handleSaveRecentlyViewed,
	} = useSearchScope();
	const __searchScopeContext = useSearchScope();
	const isDicomScope = __searchScopeContext?.scope === 'searchDicom';

	const __config = useConfig();

	const enableHelpManual = useBooleanFlagValue('won-help-launchnewmanual');
	const metaSearchVrBar = useBooleanFlagValue('meta-search-vr-bar');
	const proactIncludeMarketplaceInGlobalSearchAll = useBooleanFlagValue(
		'proact-include-marketplace-in-global-search-all'
	);

	useHelpPageComponent(inputRef.current, GLOBAL_SEARCH_HELP_URL, enableHelpManual);

	const {
		updateSearchTabs,
		updateTabVisibility,
		setSearchData,
		updatePlaceholder,
		setValidOrganizations,
		setInternalPatients,
	} = useTabContext();
	const fhirDataLoader = useFhirDataLoader({
		scope,
		isGlobal: true,
	});

	const orgDataLoader = useFhirDataLoader({
		endpoint: fhirEndpoints.organization,
	});

	const deviceDataLoader = useFhirDataLoader({
		endpoint: fhirEndpoints.device,
	});

	const [, setDataLoader] = useState(fhirDataLoader);

	const navigate = useNavigate();

	const isDicom = select[0]?.label === 'Dicom';

	const addResourceTypeToApps = apps =>
		_.map(apps, app => ({
			...app,
			resourceType: 'MarketplaceApp',
		}));

	const permissions = useMemo(
		() => ({
			study: globalPermissionsForLoggedUser?.SearchStudy,
			patient: globalPermissionsForLoggedUser?.SearchPatient,
			organization: globalPermissionsForLoggedUser?.SearchOrganization,
			user: globalPermissionsForLoggedUser?.SearchUser,
			marketplace: globalPermissionsForLoggedUser?.SearchMarketplace,
			help: globalPermissionsForLoggedUser?.SearchHelp,
			dicomquery: globalPermissionsForLoggedUser?.SearchDicomQuery,
		}),
		[globalPermissionsForLoggedUser]
	);

	const fetchData = async (loader, value, saveResult) => {
		const marketplaceSearchParams = `?_count=10&name=${searchText}${
			additionalSearchParams?.category ? `&categories=${additionalSearchParams.category}` : ''
		}${additionalSearchParams?.vendor ? `&author=${additionalSearchParams.vendor}` : ''}`;

		if (scope === searchScopes.marketplace) {
			let result = [];
			setLoading(p => p + 1);

			await searchApps({
				config: __config,
				queryParamString: marketplaceSearchParams,
			})
				.then(res => addResourceTypeToApps(res))
				.then(res => {
					result = res;
					setData(res);
					setSearchData(res);
				})
				.finally(() => {
					setLoading(p => p - 1);
				});

			return result;
		}
		let fnName = 'load';
		let bodyData;

		if (!loader) {
			if (scope === searchScopes.all) {
				loader = fhirLoader;
				fnName = '__op__load';
				bodyData = getSearchAllBody(encodeURIComponent(searchText), permissions, select);
			} else if (scope === searchScopes.patientPortalAll) {
				loader = fhirLoader;
				fnName = '__op__load';
				bodyData = getPatientPortalSearchAllBody(encodeURIComponent(searchText));
			} else {
				loader = fhirDataLoader;
			}
		}

		if (isAuthenticated) {
			setLoading(p => p + 1);

			try {
				Promise.all([
					loader[fnName](
						value ?? { content: encodeURIComponent(searchText) },
						!!bodyData || undefined,
						bodyData,
						appModeIsPatientPortal ? __config.data_sources.blume : undefined
					),
					scope === searchScopes.all && (proactIncludeMarketplaceInGlobalSearchAll || appModeIsMarketplace)
						? searchApps({
								config: __config,
								queryParamString: marketplaceSearchParams,
						  })
						: Promise.resolve(),
				]).then(values => {
					const result = values[0];
					const apps = values[1];

					let newData =
						scope === searchScopes.all || scope === searchScopes.patientPortalAll
							? _.filter(
									_.flatMap(result?.entry ? result?.entry : [], ({ resource }) =>
										_.map(resource?.entry, e => {
											const item = e.resource;
											item.eTag = e.response?.eTag;
											return item;
										})
									),
									itemValue => !!itemValue
							  )
							: result;

					getValidOrganizations();
					getInternalPatients();

					if (apps?.length > 0) {
						newData = newData.concat(addResourceTypeToApps(apps));
					}

					if (savedDuringLoad.current || saveResult) {
						if (
							lastSearchedData.current.scope === scope &&
							lastSearchedData.current.value === searchText &&
							result !== undefined
						) {
							setSearchData(newData);
							savedDuringLoad.current = Math.max(0, savedDuringLoad.current - 1);
						}
					}

					setTimeout(() => {
						{
							setData(newData);
						}
						setTimeout(() => {
							setLoading(p => Math.max(p - 1, 0));
						}, 0);
					}, 0);
				});
			} catch (e) {
				savedDuringLoad.current = Math.max(0, savedDuringLoad.current - 1);
				setLoading(p => Math.max(p - 1, 0));
				console.error(e);
			}
		}
	};

	const setLoader = searchContext => {
		fhirDataLoader.updateScope({
			scope: searchContext.scope,
			endpoint: searchContext.endpoint,
		});
		setDataLoader(fhirDataLoader);
		return fhirDataLoader;
	};

	const getValidOrganizations = () => {
		try {
			axios
				.get(
					`${__config.data_sources.fhir}/ValidOrgsFromCache/GetValidManagingOrganizations-${loggedInUserId}-Patient`
				)
				.then(resp => {
					if (resp?.status === 200) {
						setValidOrganizations(String(resp.data).split(','));
					}
				});
		} catch (e) {
			console.error(e);
		}
	};

	const getInternalPatients = () => {
		try {
			axios.get(`${__config.data_sources.fhir}/patient/attestation`).then(resp => {
				if (resp?.status === 200) {
					setInternalPatients(String(resp.data).split(','));
				}
			});
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		if (searchText === '' && appModeIsMarketplace) {
			setSearchText(initialValue);
		}
	}, [appModeIsMarketplace]);

	useEffect(() => {
		const updateLoader = async () => {
			if (inProgress !== InteractionStatus.None && accounts.length > 0) {
				setLoader(__searchScopeContext);
			}
		};
		updateLoader().catch(console.error);
	}, [inProgress, instance, accounts, __searchScopeContext]);

	useEffect(() => {
		if (searchText && searchText !== initialValue && !isDicom) {
			if (appModeIsPatientPortal) {
				setShowDialogHeader(true);
				updatePlaceholder('All');
				setData([]);
			}
			if (scope === searchScopes.helpElk) {
				fetchData(null, { content: searchText, appname: 'oai', endpoint: selectedLanguage }).catch(
					console.error
				);
			} else {
				fetchData().catch(console.error);
			}
		} else {
			select.length !== 0 && setLoading(p => Math.max(p - 1, 0));
		}
	}, [searchText, select]);

	useEffect(() => {
		if (isDicomDialog && data) {
			if (organization) {
				setDevices(data);
			} else {
				setOrganizations(data);
			}
		} else if (!isDicomDialog && isRecentSearch && appModeIsMarketplace && !!data) {
			navigate(`/marketplace/appdirectory?name=${searchText}`);
			if (setParentData) {
				setParentData(data);
			}

			setIsRecentSearch(false);
		} else if (isRecentSearch && appModeIsPatientPortal && !!data) {
			setIsRecentSearch(false);
			setShowDialogHeader(false);
		} else if (isRecentSearch && (appModeIsWorklist || appModeIsHelpCenter) && !!data) {
			if (scope === searchScopes.helpElk) {
				openArticlesSearchResult();
			} else {
				setTabsValue();
			}
			setIsRecentSearch(false);
		}
	}, [data]);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleSearchClick = event => {
		if (!metaSearchVrBar) {
			return;
		}

		if (vrViaDvToolbar) {
			event.stopPropagation();
			setVrViaDvToolbar(false);
		}
	};

	useEffect(() => {
		setSearchResults(data);
	}, [data]);

	const openSearchDialog = () => {
		if (isDicom) {
			setDicomDialog(true);
		} else {
			setDataLoader(fhirDataLoader);
			setDialog(true);
			setShowSearchIcon(true);
		}
	};

	const closeSearchDialog = () => {
		setDialog(false);
	};

	const handleChange = event => {
		if (!dialog && event.target.value && !isDicomScope) setDialog(true);
		setSearchText(event.target.value);
	};

	const clearSearchInput = e => {
		if (dicomSearchText && isDicomScope) {
			e?.preventDefault();
			e?.stopPropagation();
			setDevices([]);
			setOrganizations([]);

			setDevice(null);
			setOrganization(null);

			setDicomSearchText('');
			dicomRef.current?.resetFields();
			setDicomDialog(true);
		} else if (!isDicomScope) {
			setSearchText('');

			if (appModeIsMarketplace && _.includes(window.location.pathname, 'appdirectory')) {
				navigate(
					`/marketplace/appdirectory${
						additionalSearchParams?.vendor ? `?author=${additionalSearchParams.vendor}` : ''
					}${
						additionalSearchParams?.category
							? `#${
									_.find(
										marketplaceCategoryOptions,
										option => option.fullCategory === additionalSearchParams.category
									).hash
							  }`
							: ''
					}`
				);
				searchApps({
					config: __config,
					queryParamString: `?_count=10${
						additionalSearchParams?.category ? `&categories=${additionalSearchParams.category}` : ''
					}${additionalSearchParams?.vendor ? `&author=${additionalSearchParams.vendor}` : ''}`,
				})
					.then(res => addResourceTypeToApps(res))
					.then(res => {
						if (setParentData) {
							setParentData(res);
						}
					});
			}
		}
	};

	const handleInputSaved = async event => {
		let newData;
		const URL = `/marketplace/appdirectory?name=${searchText}${
			additionalSearchParams?.vendor ? `&author=${additionalSearchParams.vendor}` : ''
		}`;
		const alreadyNavigated = `${location.pathname}${location.search}` === URL;
		if (alreadyNavigated) return;

		setDialog(false);
		if (loading) savedDuringLoad.current += 1;

		event.preventDefault();
		if (searchText) {
			const recentSearch = localStorage.getItem('recentSearch');
			const item = {
				type: scope,
				content: searchText,
			};

			if (recentSearch) {
				let arrays = JSON.parse(recentSearch);
				let isContain;
				const arraysByType = _.filter(arrays, elem => elem.type === item.type) || [];

				if (arraysByType.length > 0) {
					isContain = _.find(arrays, elem => elem.content === item.content && elem.type === item.type);
				}

				if (!isContain) {
					if (arraysByType.length > getItemCountRecentSearch() - 1) {
						arraysByType.pop();
					}
					arraysByType.unshift(item);

					_.remove(arrays, elem => elem.type === item.type);

					arrays = _.union(arrays, arraysByType);

					localStorage.setItem('recentSearch', JSON.stringify(arrays));
				}
			} else {
				const arrays = [item];
				localStorage.setItem('recentSearch', JSON.stringify(arrays));
			}

			if (
				!isDicomScope &&
				(lastSearchedData.current.value !== searchText || lastSearchedData.current.scope !== scope)
			) {
				if (scope === searchScopes.helpElk) {
					newData = await fetchData(
						null,
						{
							content: searchText,
							appname: 'oai',
							endpoint: selectedLanguage,
						},
						true
					).catch(console.error);
				} else {
					newData = await fetchData(null, null, true).catch(console.error);
				}
				savedDuringLoad.current += 1;
				setScopeLoading(true);
			}

			lastSearchedData.current = {
				scope,
				value: searchText,
			};
			if (appModeIsPatientPortal) {
				setShowDialogHeader(false);
			} else if (select.length === 1 && select[0].label === 'Marketplace') {
				const URL = `/marketplace/appdirectory?name=${searchText}${
					additionalSearchParams?.vendor ? `&author=${additionalSearchParams.vendor}` : ''
				}`;
				const alreadyNavigate = `${location.pathname}${location.search}` === URL;
				if (!alreadyNavigate) {
					navigate(URL);
				}

				if (setParentData) {
					setParentData(newData || data); // Update and stay in App Directory with search results
				}
				closeSearchDialog();
			} else if (select.length === 1 && select[0].label === 'Help') {
				openArticlesSearchResult();
				closeSearchDialog();
			} else {
				setTabsValue();
				closeSearchDialog();
			}

			const shouldBeLoading = Boolean(loading || !!fetchTimeout.current);
			if (!scopeLoading && shouldBeLoading) {
				setScopeLoading(shouldBeLoading);
			}
		}
	};

	const openArticlesSearchResult = () => {
		navigate('/help/articlesresult');
		setSearchData(data);
	};

	const setTabsValue = () => {
		if (select.length === 1) {
			updateSearchTabs({ tab: select[0].label, select, search: searchText });
		} else {
			updateSearchTabs({ tab: 'All', select, search: searchText });
		}
		updateTabVisibility(true);
		setSearchData(data);
	};

	const clearSearchCriteria = () => {
		if (appModeIsWorklist) {
			setSelect([]);
		}
		setSearchText('');
	};

	const onOrganizationChange = async (e, value) => {
		e?.preventDefault();

		setDevices([]);
		setOrganization(null);

		if (value) {
			await fetchData(orgDataLoader, {
				summary: 'Text',
				name: value,
				privileges: 'DICOM Search',
				page: 1,
				isManaging: true,
			});
		}
	};

	const fetchDevices = async value => {
		if (value.id) {
			setDevices([]);
			setOrganization(value);
			await fetchData(deviceDataLoader, {
				summary: true,
				deviceType: 'dicom-entity,dicom-web',
				page: 1,
				managingorganization: value.id,
				features: 'QueryRetrieve',
				status: 'active',
				'associateddevice:missing': 'false',
			});
		}
	};

	const onDeviceChange = async value => {
		if (organization) {
			setDevices([]);
			await fetchData(deviceDataLoader, {
				deviceName: value,
				summary: true,
				deviceType: 'dicom-entity,dicom-web',
				page: 1,
				managingorganization: organization.id,
				features: 'QueryRetrieve',
				status: 'active',
			});
		}
	};

	const setDicomTexts = value => {
		setDicomSearchText(value);
	};

	const onDicomSearchFinished = results => {
		lastSearchedData.current = {
			scope,
			value: searchText,
		};
		updateSearchTabs({ tab: select[0].label, select });
		setSearchData(results);
		setScopeLoading(false);
		setDicomDialog(false);
	};

	const onDicomSearchStarted = () => {
		updateSearchTabs('Study');
		updateTabVisibility(true);
		setDicomDialog(false);
		setDialog(false);
		setScopeLoading(true);
	};

	const closeDicomDialog = () => {
		updateScope('searchAll');
		setDicomDialog(false);
		setSelect(prev => prev.filter(item => item.label !== 'Dicom'));
	};

	useEffect(() => {
		setTimeout(() => {
			if (scopeLoading && !loading) setScopeLoading(false);
		}, 0);
	}, [!!loading]);

	useEffect(() => {
		isDicom && setDicomDialog(true);
	}, [isDicom]);

	const onRemoveCategory = category => {
		updateScope('searchAll');
		select.length === 1 && setSearchText('');
		if (category.label === 'Dicom') {
			closeDicomDialog();
		}
		if (category.label === 'Marketplace') {
			setSearchText('');
		}
		setSelect(prev => prev.filter(item => item.label !== category.label));
	};

	return (
		<ClickAwayListener onClickAway={isClickAwayDisabled ? () => {} : closeSearchDialog}>
			<div ref={ref} onClick={handleSearchClick}>
				<div
					className={`${styles.searchBar}  ${dialog || isDicomDialog ? styles.searchBarActive : ''} ${
						metaSearchVrBar && vrViaDvToolbar && collapseSearchBarForVR ? styles.searchBarCollapsed : ''
					}`}
					data-testid="search-bar"
				>
					{select?.length > 0 && !vrViaDvToolbar ? (
						<div className={styles.activeCategoryList}>
							{select.map(item => (
								<div key={item.label} className={styles.activeCategory}>
									<span className={styles.activeCategoryLabel}>{item.label}</span>
									<div
										className={styles.crossIcon}
										data-testid={`crossIcon-${item.label}`}
										onClick={() => onRemoveCategory(item)}
									>
										<CrossIcon />
									</div>
								</div>
							))}
						</div>
					) : (
						<div className={styles.searchIcon}>
							<GlobalSearchIcon />
						</div>
					)}
					<InputBase
						autoComplete="off"
						className={styles.searchInput}
						data-testid="top-search"
						id="top-search"
						inputRef={inputRef}
						placeholder={isDicom ? '' : 'Search'}
						readOnly={isDicom}
						value={isDicomScope ? dicomSearchText : searchText}
						onChange={handleChange}
						onClick={openSearchDialog}
						onKeyDown={e => e.key === 'Enter' && handleInputSaved(e) && clearSearchCriteria()}
					/>
					{!!loading && !isDicomDialog && !vrViaDvToolbar && (
						<div className={styles.loadingProgress}>
							<CircularProgress color="success" size={20} sx={{ color: '#03DAC5' }} />
						</div>
					)}
					{((searchText && !isDicomScope) || (dicomSearchText && isDicomScope)) && !vrViaDvToolbar && (
						<div className={styles.clearIcon} data-testid="clear-search-icon" onClick={clearSearchInput}>
							<ClearIcon />
						</div>
					)}
				</div>
				{dialog && !isDicomDialog && (
					<SearchDialogV2
						additionalSearchParams={additionalSearchParams}
						closeSearchDialog={closeSearchDialog}
						data={data}
						dialog={dialog}
						handleSaveRecentlyViewed={handleSaveRecentlyViewed}
						inputRef={inputRef}
						isDicomDialog={isDicomDialog}
						isLoadingUserId={isLoadingUserId}
						loggedInUserId={loggedInUserId}
						openArticlesSearchResult={openArticlesSearchResult}
						scope={scope}
						searchText={searchText}
						select={select}
						setIsClickAwayDisabled={setIsClickAwayDisabled}
						setIsRecentSearch={setIsRecentSearch}
						setParentData={setParentData}
						setSearchText={setSearchText}
						setSelect={setSelect}
						setTabsValue={setTabsValue}
						showDialogHeader={showDialogHeader}
						onGoToUser={goToUser}
					/>
				)}
				{isDicomDialog && (
					<div className={`dicom-dialog ${styles.dicomForm}`}>
						<DicomForm
							ref={dicomRef}
							checkedFields={checkedFields}
							closeDicomDialog={closeDicomDialog}
							device={device}
							devices={devices}
							fetchDevices={fetchDevices}
							fieldValuesObj={fieldValuesObj}
							handleChange={onOrganizationChange}
							handleDeviceChange={onDeviceChange}
							handleDicomSearch={onDicomSearchFinished}
							isLoading={!!loading}
							organization={organization}
							organizations={organizations}
							setCheckedFields={setCheckedFields}
							setDevice={setDevice}
							setDicomTexts={setDicomTexts}
							setFieldValuesObj={setFieldValuesObj}
							onSearchStarted={onDicomSearchStarted}
						/>
					</div>
				)}
			</div>
		</ClickAwayListener>
	);
};

export default SearchBarV2;
