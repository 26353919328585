import AppointmentStatusOptions from '@worklist-2/ui/src/components/CalendarCard/AppointmentStatusOptions';
import {
	DEFAULT_PARKING_APPOINTMENT_STATUS,
	DEFAULT_PARKING_APPOINTMENT_STATUS_COLOR,
} from '@worklist-2/worklist/src/SchedulerV2/constants';

// NOTE: This is temporary, mapping needs to adjusted on a per organization basis
// Based off of the Workflow Steps
export const appointmentStatusToStudyStatusMapper = status => {
	switch (status) {
		case 'proposed':
			return { code: '20', display: 'ORDERED' };
		case 'pending':
			return { code: '30', display: 'SCHEDULED' };
		case 'booked':
			return { code: '50', display: 'CONFIRMED' };
		case 'arrived':
			return { code: '70', display: 'ARRIVED' };
		case 'checked-in':
			return { code: '90', display: 'READYFORSCAN' };
		case 'noshow':
			return { code: '85', display: 'NO SHOW' };
		case 'cancelled':
			return { code: '80', display: 'CANCELLED' };
		case 'approved':
			break;
		default:
			return { code: '30', display: 'SCHEDULED' };
	}
};

export const studyStatusToAppointmentStatusMapper = status => {
	let statusMap = new Map([
		[{ code: '20', display: 'ORDERED' }, 'proposed'],
		[{ code: '30', display: 'SCHEDULED' }, 'pending'],
		[{ code: '50', display: 'CONFIRMED' }, 'booked'],
		[{ code: '70', display: 'ARRIVED' }, 'arrived'],
		[{ code: '80', display: 'CANCELLED' }, 'cancelled'],
		[{ code: '85', display: 'NO SHOW' }, 'noshow'],
		[{ code: '90', display: 'READYFORSCAN' }, 'checked-in'],
	]);

	const found = statusMap.find((_, { code, display }) => {
		return code === status || display === status?.toUpperCase();
	});

	return found || DEFAULT_PARKING_APPOINTMENT_STATUS;
};

export const appointmentStatusToColorMapper = status => {
	const validStatuses = AppointmentStatusOptions.filter(option => option.status !== '');
	const color = validStatuses.find(option => option.status === status)?.iconColor;

	return color || DEFAULT_PARKING_APPOINTMENT_STATUS_COLOR;
};

export const studyStatusToColorMapper = status => {
	const validStatuses = AppointmentStatusOptions.filter(option => option.status !== '');
	const appointmentStatus = studyStatusToAppointmentStatusMapper(status);
	const color = validStatuses.find(option => option.status === appointmentStatus)?.iconColor;

	return color || DEFAULT_PARKING_APPOINTMENT_STATUS_COLOR;
};

export const studyStatusToParkingAppointmentStatusMapper = status => {
	if (!status) return;

	const statusShouldStaySame = ['cancelled', 'noshow'].includes(status?.toLowerCase());

	if (statusShouldStaySame) return status;

	return 'requested';
};
