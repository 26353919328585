export const findDifferentFields = (array, operationType = 'merge') => {
	const fieldNames =
		operationType === 'merge'
			? [
					'Patient Name',
					'Issuer of Patient ID',
					'Patient ID',
					'Birth Date',
					'Gender',
					'Address',
					'Phone Number',
					'State',
					'Country',
					'City',
			  ]
			: ['Patient Name', 'Issuer of Patient ID', 'Patient ID', 'Birth Date', 'Gender'];
	const differentFields = [];

	for (const fieldName of fieldNames) {
		const valueSet = new Set();

		for (const item of array) {
			let value;

			// eslint-disable-next-line default-case
			switch (fieldName) {
				case 'Patient Name':
					value = item?.patientFullName || 'N/A';
					break;
				case 'Issuer of Patient ID':
					value = item.managingOrganization || 'N/A';
					break;
				case 'Patient ID':
					value = item?.patientID || 'N/A';
					break;
				case 'Birth Date':
					value = item?.birthDate || 'N/A';
					break;
				case 'Gender':
					value = item?.gender || 'N/A';
					break;
				case 'Address':
					value = item?.patientAddress || 'N/A';
					break;
				case 'Phone Number':
					value = item?.cellPhone || 'N/A';
					break;
				case 'State':
					value = item?.state || 'N/A';
					break;
				case 'Country':
					value = item?.country || 'N/A';
					break;
				case 'City':
					value = item?.city || 'N/A';
					break;
			}

			valueSet.add(value ? value.toLowerCase() : value);
			if (valueSet.size > 1) {
				differentFields.push(fieldName);
				break;
			}
		}
	}

	return differentFields;
};
