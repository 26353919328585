// core
import React, { useCallback, useImperativeHandle, useState } from 'react';
// api
import { searchScopes, useConfig, useFhirDataLoader } from '@worklist-2/core/src';
import { fhirExtensionUrls } from '@worklist-2/core/src/fhir/extension';
// components
import Suggest from '../Suggest';
// libraries
import { useIsAuthenticated } from '@azure/msal-react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
// mui
import Button from '@mui/material/Button';
// partials
import { getSubscriptionPayload, getTaskPayload } from './data';
import { LoadingWrapper } from './LoadingWrapper';
import { SearchFieldsSelector } from './SearchFieldsSelector';
import { useTranslation } from 'react-i18next';

const DicomForm = React.forwardRef(
	// not using an arrow function because we want it to be named for debugging purposes
	(
		{
			checkedFields,
			device,
			devices,
			fieldValuesObj,
			isLoading,
			organization,
			organizations,
			// callbacks
			closeDicomDialog,
			fetchDevices,
			handleChange,
			handleDeviceChange,
			handleDicomSearch,
			onSearchStarted,
			setCheckedFields,
			setDevice,
			setDicomTexts,
			setFieldValuesObj,
		},
		ref
	) => {
		useImperativeHandle(ref, () => ({
			resetFields: () => {
				setFieldValuesObj({
					'Anatomic Focus': [],
					Gender: [],
					Modality: [],
					'Study Priority': [],
					'Study Status': [],
				});
				setCheckedFields([1, 2, 3]);
			},
		}));

		// API

		const __config = useConfig();
		const isAuthenticated = useIsAuthenticated();
		const fhirDataLoaderSub = useFhirDataLoader({
			scope: 'subscription',
		});
		const fhirDataLoaderTask = useFhirDataLoader({
			scope: searchScopes.task,
		});

		// State

		const [dataLoaderSub] = useState(fhirDataLoaderSub);
		const [dataLoaderTask] = useState(fhirDataLoaderTask);
		const [error, setError] = useState('');
		const [isLoadingDevices, setIsLoadingDevices] = useState(false);
		const [loading, setLoading] = useState(false);
		const [showWarning, setShowWarning] = useState(false);
		const { t } = useTranslation('Search');

		// Helper functions

		// We need to optimize this in the future so that only 1 connection will be created if nothing has changed
		const connectAPISocket = useCallback(async subscriptionId => {
			try {
				// 2. get accession token from auth
				const tokenData = await fetchAccessToken(subscriptionId);

				// 3. Create signalR connection
				const connection = createSignalRConnection(tokenData.url, {
					accessTokenFactory: () => tokenData.accessToken,
				});

				connection.on('taskUpdated', processResponseData);
				connection.onclose(() => log('closing the connection'));

				startConnection(connection);
			} catch (e) {
				console.error(e);
				throw e;
			}
		});

		const createSignalRConnection = (signalRUrl, options) =>
			new HubConnectionBuilder().withUrl(signalRUrl, options).build();

		const fetchAccessToken = async id => {
			try {
				return await axios
					.get(`${__config.auth.omegaai.auth_api}/token/SignalRConnectionInfo?hub=task&user=${id}`)
					.then(({ data }) => data);
			} catch (e) {
				console.error(e);
				throw e;
			}
		};

		const log = status => console.log(`SignalR is ${status}...`);

		const processResponseData = data => {
			if (data.reasonCode.text === 'DicomQuery' && data.status === 'completed') {
				handleDicomSearch(
					(data?.output || []).map(result => ({
						...JSON.parse(result.valueString),
						resourceType: 'DICOMResult',
						for: data.for,
						owner: data.owner,
						managingOrg: _.find(data.extension, ['url', fhirExtensionUrls.common.organization]),
					}))
				);

				setLoading(false);
			}
		};

		const saveData = useCallback(
			async (loader, props, payload) => {
				if (isAuthenticated) {
					try {
						return await loader.save(props, payload).then(saveResult => saveResult.data);
					} catch (e) {
						console.error(e);
						throw e;
					}
				} else throw new Error('Unauthenticated');
			},
			[isAuthenticated]
		);

		const startConnection = connection => {
			log('connecting');
			connection
				.start()
				.then(() => {
					log('connected');
				})
				.catch(err => {
					console.error(err);
					setTimeout(() => {
						startConnection(connection);
					}, 2000);
				});
		};

		// Callbacks

		const onCloseDicomSearch = () => {
			setLoading(false);
			setError('');
		};

		const onDeviceChange = e => {
			e.preventDefault();

			setIsLoadingDevices(true);
			handleDeviceChange(e.target.value).finally(() => setIsLoadingDevices(false));
			setShowWarning(false);
		};

		const onDeviceSelect = (e, value) => {
			if (e) {
				e.preventDefault?.();
				setDevice(value);
				setShowWarning(false);
			}
		};

		const onOrganizationChange = e => {
			e.preventDefault();
			setDevice(null);
			handleChange(e, e.target.value);
			setShowWarning(false);
		};

		const onOrganizationSelect = (e, value) => {
			if (e) {
				e.preventDefault?.();

				if (value.id) {
					setIsLoadingDevices(true);
					fetchDevices(value).finally(() => setIsLoadingDevices(false));
				} else {
					handleChange(e, '');
				}

				setDevice(null);
				setShowWarning(false);
			}
		};

		const onSubmit = useCallback(
			async e => {
				e.preventDefault();
				if (loading) return;

				try {
					const subscriptionId = JSON.parse(localStorage.getItem('sessionId'));

					if (!(device && organization)) {
						setShowWarning(true);
						return;
					}

					if (device.rawData) {
						setDicomTexts(
							`Organization: (${organization.text.div}) Device: (${device.rawData.distinctIdentifier})`
						);
					} else {
						setShowWarning(true);
						setDevice('');
						return;
					}

					const extension =
						device.extension.find(ext =>
							[
								'http://www.ramsoft.com/fhir/dicomserverconfig/associateddevice',
								'http://www.ramsoft.com/fhir/dicomserverconfig/managingorganization',
							].includes(ext.url)
						) || null;

					if (extension) {
						setLoading(true);

						onSearchStarted();

						// 1. save subscription resource
						await saveData(
							dataLoaderSub,
							{
								managingorganizationid: organization.id,
								resourcetype: 'task',
								type: 'websocket',
								subtype: 'signalr',
							},
							getSubscriptionPayload(extension, subscriptionId)
						);

						// SignalR
						await connectAPISocket(subscriptionId);
						await saveData(dataLoaderTask, {}, getTaskPayload(device, extension, subscriptionId));
					}
				} catch (ex) {
					setLoading(false);
					setError('Something went wrong with search');
				}
			},
			[
				loading,
				device,
				organization,
				setDicomTexts,
				setDevice,
				onSearchStarted,
				saveData,
				dataLoaderSub,
				connectAPISocket,
				dataLoaderTask,
			]
		);

		return (
			<form className="dicom-form" onSubmit={onSubmit}>
				<LoadingWrapper error={error} isLoading={loading} onCloseDicomSearch={onCloseDicomSearch}>
					<div
						style={{
							position: 'relative',
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
						}}
					>
						<div className="form-organization">
							<Suggest
								clearOnBlur
								externalCall
								fullWidth
								data={organizations}
								error={showWarning && !organization}
								errorText={
									showWarning && !organization
										? t('Please fill in the organization field before searching')
										: undefined
								}
								isLoading={!isLoadingDevices && isLoading}
								optionId="text.div"
								placeholder={t('Search')}
								testid="organization-search"
								text={t('Organization')}
								value={organization}
								onInputChange={onOrganizationChange}
								onSelect={onOrganizationSelect}
							/>

							<Suggest
								clearOnBlur
								externalCall
								fullWidth
								className="mt-2"
								data={devices}
								disabled={!devices.length > 0 && !organization}
								error={showWarning && !device}
								errorText={
									showWarning && !device
										? t('Please fill in the device field before searching')
										: undefined
								}
								isLoading={isLoadingDevices}
								optionId="rawData.distinctIdentifier"
								placeholder={t('Search')}
								testid="device-search"
								text={t('Device')}
								value={device}
								onInputChange={onDeviceChange}
								onSelect={onDeviceSelect}
							/>
						</div>

						<SearchFieldsSelector
							checkedFields={checkedFields}
							fieldValuesObj={fieldValuesObj}
							organization={organization}
							setCheckedFields={setCheckedFields}
							setFieldValuesObj={setFieldValuesObj}
						/>

						<div className="footer">
							<Button
								className="cancel-btn"
								color="primary"
								data-cy="cancel-btn"
								data-testid="dicomCancelButton"
								variant="outlined"
								onClick={closeDicomDialog}
							>
								{t('CANCEL')}
							</Button>
							<Button
								className="submit-btn"
								color="primary"
								data-cy="search-btn"
								data-testid="dicomSubmitButton"
								disabled={loading}
								type="submit"
								variant="contained"
							>
								{t('SEARCH')}
							</Button>
						</div>
					</div>
				</LoadingWrapper>
			</form>
		);
	}
);

DicomForm.propTypes = {
	checkedFields: PropTypes.array,
	device: PropTypes.object,
	devices: PropTypes.array,
	organization: PropTypes.object,
	organizations: PropTypes.array,
	closeDicomDialog: PropTypes.func,
	fetchDevices: PropTypes.func,
	fieldValuesObj: PropTypes.object,
	handleDeviceChange: PropTypes.func,
	handleChange: PropTypes.func,
	handleDicomSearch: PropTypes.func,
	setCheckedFields: PropTypes.func,
	setDevice: PropTypes.func,
	setDicomTexts: PropTypes.func,
	setFieldValuesObj: PropTypes.func,
};

export default DicomForm;
