import React from 'react';
import Box from '@mui/material/Box';
import CallMergeOutlinedIcon from '@mui/icons-material/CallMergeOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TocIcon from '@mui/icons-material/TocOutlined';

export const MergePatientIcon = () => (
	<Box sx={{ position: 'relative', minWidth: '30px', height: '30px' }}>
		<CallMergeOutlinedIcon
			data-testid="call-merge-outlined-icon"
			sx={{ color: '#fff', fontWeight: '700', strokeWidth: '2' }}
		/>
		<AccountCircleIcon
			data-testid="account-circle-icon"
			sx={{
				color: '#fff',
				fontWeight: '700',
				width: '15px',
				position: 'absolute',
				height: '15px',
				bottom: '5px',
				right: '3px',
			}}
		/>
	</Box>
);

export const MergeStudyIcon = () => (
	<Box sx={{ position: 'relative', minWidth: '30px', height: '30px' }}>
		<CallMergeOutlinedIcon
			data-testid="call-merge-outlined-icon"
			sx={{ color: '#fff', fontWeight: '700', strokeWidth: '2' }}
		/>
		<TocIcon
			data-testid="toc-icon"
			sx={{
				color: '#fff',
				fontWeight: '700',
				width: '15px',
				position: 'absolute',
				height: '15px',
				bottom: '5px',
				right: '3px',
			}}
		/>
	</Box>
);
