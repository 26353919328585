import { fhirExtensionUrls, getExtensionValueReference } from '@worklist-2/core/src';
import moment from 'moment';
import add from 'date-fns/add';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';
import { rrulestr } from 'rrule';

import { getAppointmentTriggers } from './appointmentTrigger';
import { enrichPayloadWithServiceInfo } from './dataProcessHelpers';
import { DEFAULT_PARKING_APPOINTMENT_STATUS, PARKING_INDEX_MULTIPLY_COEF } from '../constants';
import { createConsultingPhysicianNode } from '@rs-ui/components/Drawers/NewOrderDrawer/orderApi';
import {
	extractHealthcareServiceInfo,
	removeTimeZonOffsetFromRruleString,
} from '@worklist-2/worklist/src/SchedulerV2/utils';

export function createNewAppointmentPayload(
	study,
	service,
	start,
	minutesDuration,
	priority,
	status,
	userInfo,
	consultingPhysician,
	parkingIndex = null
) {
	const end = start ? add(start, { minutes: minutesDuration }) : null;
	let extension = start ? getAppointmentTriggers(start) : [];

	if (service) {
		const managingOrganizationId = getExtensionValueReference(
			service?.providedBy,
			fhirExtensionUrls.organization.managingOrganization
		)?.id;

		extension.push({
			url: fhirExtensionUrls.common.managingOrganization,
			valueString: managingOrganizationId,
		});
	}

	if (!service) {
		extension = [
			...extension,
			{
				url: fhirExtensionUrls.appointment.parkingIndex,
				valueString: parkingIndex ?? '0',
			},
		];
	}

	if (study?.comment && userInfo) {
		const { id: userId, display: userName } = userInfo;

		extension.push({
			url: fhirExtensionUrls.appointment.noteTime,
			valueDateTime: new Date().toISOString(),
		});

		extension.push({
			url: fhirExtensionUrls.appointment.author,
			valueReference: {
				id: userId,
				reference: `Practicioner/${userId}`,
				display: userName,
			},
		});
	}
	if (consultingPhysician && Array.isArray(consultingPhysician)) {
		extension = [
			...extension,
			{
				url: 'http://www.ramsoft.com/fhir/StructureDefinition/consulting-physician',
				extension: [...createConsultingPhysicianNode(consultingPhysician)],
			},
		];
	}
	return {
		resourceType: 'Appointment',

		start: start ? start : '',
		end: start ? end : '',
		priority,
		minutesDuration,
		status,
		description: study.description,
		comment: study?.comment,
		participant: [
			{
				actor: {
					id: study.subject?.id,
					reference: study.subject?.reference,
					display: study.subject?.display,
				},
				required: 'required',
				status: 'accepted',
			},
			service
				? {
						actor: {
							id: service?.id,
							reference: `HealthcareService/${service?.id}`,
							display: service?.name,
						},
						required: 'required',
						status: 'accepted',
				  }
				: undefined,
		],
		basedOn: [{ reference: study.basedOn[0].reference }],
		supportingInformation: [
			{
				id: study.id,
				reference: `ImagingStudy/${study.id}`,
			},
			userInfo?.id && !service
				? {
						id: userInfo?.id,
						reference: `Practitioner/${userInfo?.id}`,
				  }
				: undefined,
		],
		extension: extension,
		parkingIndex,
	};
}

export const createNewBlockedTimeOrReservationPayload = (
	service,
	start,
	minutesDuration,
	notes,
	proactTimeZoneConversionBlockTime,
	rruleSetString,
	isReservation,
	reservationInfo,
	proactDisableTimeZoneFromAppointments
) => {
	// An rruleSet string is a serialized representation of an RRuleSet object from the rrule library that
	// defines patterns to generate events
	let end = add(start, { minutes: minutesDuration });
	const managingOrganizationId = getExtensionValueReference(
		service.providedBy,
		fhirExtensionUrls.organization.managingOrganization
	)?.id;
	const eventTypeExt = isReservation
		? [
				{
					url: fhirExtensionUrls.appointment.reservation,
					valueBoolean: true,
				},
				{
					url: fhirExtensionUrls.appointment.reservationName,
					valueString: reservationInfo?.reservationName,
				},
				{
					url: fhirExtensionUrls.appointment.reservationColor,
					valueString: reservationInfo?.reservationColor,
				},
		  ]
		: [
				{
					url: fhirExtensionUrls.appointment.blockedTime,
					valueBoolean: true,
				},
		  ];

	const extension = [
		...eventTypeExt,
		{
			url: fhirExtensionUrls.common.managingOrganization,
			valueString: managingOrganizationId,
		},
	];
	const participant = [
		{
			actor: {
				id: service?.id,
				reference: `HealthcareService/${service?.id}`,
				display: service?.name,
				type: 'HealthcareService',
			},
			required: 'required',
			status: 'accepted',
		},
	];
	if (rruleSetString?.length > 0) {
		const rruleSet = rrulestr(rruleSetString, {
			forceset: true,
		});

		const until = new Date(rruleSet._rrule[0].options.until);

		if (proactDisableTimeZoneFromAppointments) {
			end = new Date(until.getFullYear(), until.getMonth(), until.getDate(), end.getHours(), end.getMinutes());
		} else {
			// For the recurring event, set the repeat end date of the recurring event to 'end'. To avoid time zone issues, just get the date from the rule
			const { timezoneIANA } = extractHealthcareServiceInfo(service);
			const repeatEndDate = proactTimeZoneConversionBlockTime ? utcToZonedTime(until, timezoneIANA) : until;
			end = new Date(
				repeatEndDate.getFullYear(),
				repeatEndDate.getMonth(),
				repeatEndDate.getDate(),
				end.getHours(),
				end.getMinutes()
			);
		}

		extension.push({
			url: fhirExtensionUrls.appointment.recurringBlockedTime,
			valueString: proactDisableTimeZoneFromAppointments
				? removeTimeZonOffsetFromRruleString(rruleSetString)
				: rruleSetString,
		});
	}

	return {
		resourceType: 'Appointment',
		start,
		end,
		minutesDuration,
		status: 'booked',
		description: notes,
		participant: participant,
		extension: extension,
	};
};

export const createBundleNewBlockedTimePayload = (
	blockedTimeSlotArr,
	service,
	notes,
	proactTimeZoneConversionBlockTime,
	proactDisableTimeZoneFromAppointments
) => {
	const { timezoneIANA } = extractHealthcareServiceInfo(service);
	return {
		id: 'bundle-transaction',
		resourceType: 'Bundle',
		type: 'transaction',
		entry: blockedTimeSlotArr?.map(slot => {
			const payload = createNewBlockedTimeOrReservationPayload(
				service,
				slot.start,
				slot.minutesDuration,
				notes,
				proactTimeZoneConversionBlockTime
			);
			const start = proactTimeZoneConversionBlockTime
				? zonedTimeToUtc(payload.start, timezoneIANA)
				: payload.start;
			const end = proactTimeZoneConversionBlockTime ? zonedTimeToUtc(payload.end, timezoneIANA) : payload.end;

			payload.start = proactDisableTimeZoneFromAppointments
				? moment(payload.start).format('YYYY-MM-DDTHH:mm:ss')
				: start;
			payload.end = proactDisableTimeZoneFromAppointments
				? moment(payload.end).format('YYYY-MM-DDTHH:mm:ss')
				: end;

			if (!_.isEmpty(slot)) {
				return {
					request: {
						method: 'POST',
						url: proactDisableTimeZoneFromAppointments ? 'Appointment/?isWallClock=true' : 'Appointment/',
					},
					resource: payload,
				};
			}
		}),
	};
};

export const createNewReservePayload = (formData, service, date, start, end, minutesDuration, currentColor) => {
	return {
		resourceType: 'AppointmentReserve',
		reserveName: formData.reserveName,
		resources: formData.resources,
		date,
		start,
		end,
		minutesDuration,
		repeat: formData.repeat,
		notes: formData.notes,
		currentColor,
		id: Date.now().toString(36) + Math.random().toString(36).substring(2), // temporarily
		participant: [
			{
				actor: {
					id: service?.id,
					reference: `HealthcareService/${service?.id}`,
					display: service?.name,
				},
				required: 'required',
				status: 'accepted',
			},
		],
	};
};

export const createNewBusyPayload = (service, start, minutesDuration, notes) => {
	const end = add(start, { minutes: minutesDuration });
	const managingOrganizationId = getExtensionValueReference(
		service.providedBy,
		fhirExtensionUrls.organization.managingOrganization
	)?.id;
	const extension = [
		{
			url: fhirExtensionUrls.appointment.busyTime,
			valueBoolean: true,
		},
		{
			url: fhirExtensionUrls.common.managingOrganization,
			valueString: managingOrganizationId,
		},
	];
	return {
		resourceType: 'Appointment',

		start,
		end,
		minutesDuration,
		status: 'booked',
		description: notes,

		participant: [
			{
				actor: {
					id: service?.id,
					reference: `HealthcareService/${service?.id}`,
					display: service?.name,
					type: 'HealthcareService',
				},
				required: 'required',
				status: 'accepted',
			},
		],
		extension: extension,
	};
};

export const createUpdateEventPayload = (
	appointment,
	start,
	end,
	calendarService,
	study,
	userInfo,
	parkingIndex = null
) => {
	let payload = {
		// deep cloning the apt data to make sure that none of it gets modified
		...cloneDeep(appointment),
		start: start ? start : '',
		end: end ? end : '',
	};

	if (study) {
		payload.supportingInformation = [
			{
				id: study.id,
				reference: `ImagingStudy/${study.id}`,
			},
		];
	}

	if (!calendarService && parkingIndex !== null && parkingIndex !== 'undefined') {
		const parkingIdxInfoIndex = payload.extension.findIndex(({ url }) => url.includes('parkingIndex'));
		const practitionerInfoIndex = payload?.supportingInformation?.findIndex(({ reference }) =>
			reference.includes('Practitioner')
		);

		if (~parkingIdxInfoIndex) {
			payload.extension[parkingIdxInfoIndex] = {
				url: fhirExtensionUrls.appointment.parkingIndex,
				valueString: parkingIndex,
			};
		} else {
			payload.extension = [
				...payload.extension,
				{
					url: fhirExtensionUrls.appointment.parkingIndex,
					valueString: parkingIndex,
				},
			];
		}

		if (~practitionerInfoIndex) {
			payload.supportingInformation[practitionerInfoIndex] = {
				id: userInfo?.id,
				reference: `Practitioner/${userInfo?.id}`,
			};
		} else {
			payload.supportingInformation = [
				...payload.supportingInformation,
				{
					id: userInfo?.id,
					reference: `Practitioner/${userInfo?.id}`,
				},
			];
		}

		payload.parkingIndex = parkingIndex;

		payload.participant = payload?.participant?.filter(
			({ actor }) => !actor.reference?.includes('HealthcareService')
		);
	}

	if (calendarService) {
		payload = enrichPayloadWithServiceInfo(payload, calendarService);

		payload.supportingInformation = payload?.supportingInformation?.filter(
			({ reference }) => !reference.includes('Practitioner')
		);

		payload.extension = payload.extension.filter(({ url }) => !url.includes('parkingIndex'));
	}

	delete payload.duration;
	delete payload.colIndex;
	delete payload.isLoading;
	delete payload.widthDivider;
	delete payload.expandedWidthDivider;
	delete payload.maximumConcurrentEvents;
	delete payload.overlapingEventIds;

	return payload;
};

export const createBundleAppointmentUpdatePayload = (appointmentArr, loggedInUser, nextParkingIndex) => {
	let parkingIndex = nextParkingIndex;
	return {
		id: 'bundle-transaction',
		resourceType: 'Bundle',
		type: 'transaction',
		entry: appointmentArr.map((appointment, index) => {
			if (!_.isEmpty(appointment)) {
				const newStatus = ['noshow', 'cancelled'].includes(appointment.status)
					? appointment.status
					: DEFAULT_PARKING_APPOINTMENT_STATUS;
				if (index > 0) {
					parkingIndex = parkingIndex + PARKING_INDEX_MULTIPLY_COEF;
				}
				return {
					request: {
						method: 'PUT',
						url: `Appointment/${appointment.id}`,
					},
					resource: {
						...createUpdateEventPayload(appointment, null, null, null, null, loggedInUser, parkingIndex),
						status: newStatus,
					},
				};
			}
		}),
	};
};

export const createUpdateReservePayload = (reserve, start, end, service) => {
	if (!reserve || !service) return;

	const data = {
		...reserve,
		start,
		end,
	};

	return enrichPayloadWithServiceInfo(data, service);
};
