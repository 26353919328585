import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '@worklist-2/core/src';
import initCornerstoneWadoImageLoader from '../cornerstoneWADOImageLoader/initCornerstoneWadoImageLoader';
import initVolumeLoader from '../cornerstone/initVolumeLoader';
import initCornerstone, { htj2kMixedOptions } from '../cornerstone/initCornerstone';
import initCornerstoneTools from '../cornerstoneTools/initCornerstoneTools';
import addCornerstoneTools from '../cornerstoneTools/addCornerstoneTools';
import createDefaultRenderingEngine from '../cornerstone/createDefaultRenderingEngine';
import { ToolGroupManager } from '@cornerstonejs/tools';
import { cache, utilities } from '@cornerstonejs/core';
import { useImageViewerStudiesContext } from '../contexts/ImageViewerStudiesContext';
import { useImageViewerExternalContext } from '../ImageViewerExternal/ImageViewerExternalContext';
import ReferenceLinesTool from '../cornerstoneTools/ReferenceLinesTool';
import CrosshairsTool from '../cornerstoneTools/CrosshairsTool';
import getDefaultRenderingEngine from '../cornerstone/getDefaultRenderingEngine';
import { thumbnailrenderingEngineId } from '../components/CornerstoneThumbnail/CornerstoneThumbnail';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useSearchParams } from 'react-router-dom';

const { imageRetrieveMetadataProvider } = utilities;
export const MAX_CACHE_SIZE_1GB = 1073741824;

const useInitCornerstoneLibraries = ({ internalManagingOrganizationID, studyInstanceUID, blumeToken }) => {
	const wonIvForceGpuRendering = useBooleanFlagValue('won-iv-force-gpu-rendering');
	const ivWonIncreasestorage = useBooleanFlagValue('IV-WON-INCREASESTORAGE');
	const wonIvHardwarecon = useBooleanFlagValue('WON-IV-HARDWARECON');
	const wonIvHtj2Kpl = useBooleanFlagValue('WON-IV-HTJ2KPL');
	const wonIvMmDivideHardwarecon = useBooleanFlagValue('won-iv-mm-divide-hardwarecon');
	const { accessToken } = useAuth();

	const { seriesRef } = useImageViewerStudiesContext();

	const [cornerstoneInitialized, setCornerstoneInitialized] = useState(false);
	const { creatorUserId, isUploaded } = useImageViewerExternalContext();
	const [searchParams] = useSearchParams();

	const wonIvForceGpuRenderingRef = useRef(wonIvForceGpuRendering);
	const ivWonIncreasestorageRef = useRef(ivWonIncreasestorage);
	const wonIvHardwareconRef = useRef(wonIvHardwarecon);
	const wonIvHtj2KplRef = useRef(wonIvHtj2Kpl);
	const wonIvMmDivideHardwareconRef = useRef(wonIvMmDivideHardwarecon);

	wonIvForceGpuRenderingRef.current = wonIvForceGpuRendering;
	ivWonIncreasestorageRef.current = ivWonIncreasestorage;
	wonIvHardwareconRef.current = wonIvHardwarecon;
	wonIvHtj2KplRef.current = wonIvHtj2Kpl;
	wonIvMmDivideHardwareconRef.current = wonIvMmDivideHardwarecon;

	const initCornerstoneLibraries = async () => {
		window.ImageViewer3DInitialized = true;

		initCornerstone({ wonIvForceGpuRenderingRef });

		let numberOfIVScreens = 1;
		if (wonIvMmDivideHardwareconRef.current) {
			numberOfIVScreens = Number(searchParams.get('numOfIVScreens'));
		}

		initCornerstoneWadoImageLoader({ wonIvHardwareconRef, wonIvMmDivideHardwareconRef, numberOfIVScreens });
		initVolumeLoader();
		initCornerstoneTools();
		addCornerstoneTools();

		if (ivWonIncreasestorageRef.current) {
			cache.setMaxCacheSize(MAX_CACHE_SIZE_1GB * 4);
		} else {
			cache.setMaxCacheSize(MAX_CACHE_SIZE_1GB);
		}

		if (wonIvHtj2KplRef.current) {
			imageRetrieveMetadataProvider.add('stack', htj2kMixedOptions);
		}
	};

	useEffect(() => {
		cornerstoneWADOImageLoader.configure({
			beforeSend: (xhr, imageId) => {
				const imageIdSeries = seriesRef?.current?.find(s => s.imageIds?.includes(imageId));
				const headerManagingOrganizationId =
					imageIdSeries?.managingOrganizationId ?? internalManagingOrganizationID;
				const headerStudyInstanceUID = imageIdSeries?.studyInstanceUID ?? studyInstanceUID;

				return {
					authorization: accessToken || blumeToken,
					'ramsoft-internalmanagingorganizationid': headerManagingOrganizationId,
					StudyInstanceUID: headerStudyInstanceUID,
					IsUploaded: isUploaded,
					CreatorBlumeUserId: creatorUserId,
				};
			},
			useWebWorkers: true,
			decodeConfig: {
				convertFloatPixelDataToInt: false,
			},
		});
	}, [accessToken, internalManagingOrganizationID, studyInstanceUID, blumeToken]);

	useEffect(() => {
		if (!window.ImageViewer3DInitialized) {
			initCornerstoneLibraries();
		}

		const renderingEngine = createDefaultRenderingEngine();

		setCornerstoneInitialized(true);

		return () => {
			const allToolGroups = ToolGroupManager.getAllToolGroups();

			allToolGroups.forEach(toolGroup => {
				toolGroup.setToolDisabled(ReferenceLinesTool.toolName);
				toolGroup.setToolDisabled(CrosshairsTool.toolName);
			});

			renderingEngine.destroy();

			const thumbnailRenderingEngine = getDefaultRenderingEngine(thumbnailrenderingEngineId);

			if (thumbnailRenderingEngine) {
				thumbnailRenderingEngine.destroy();
			}
		};
	}, []);

	return {
		cornerstoneInitialized,
	};
};

export default useInitCornerstoneLibraries;
