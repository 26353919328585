import {
	getInstanceDerivationDescription,
	getInstanceInstitutionName,
	getInstanceStationName,
	getInstanceStudyComments,
	getSeriesDescription,
	getStudyDescription,
} from '@rs-ui/views/ImageViewerView3D/utils/utils';
import {
	WindowCenterWidth,
	acquisitionMatrix,
	bodyPartThickness,
	compressionForce,
	contrastAgent,
	convolutionKernel,
	current,
	currentOrPriorIndicator,
	dateOfBirth,
	echoTime,
	echoTrainLength,
	exposure,
	exposureTime,
	flipAngle,
	frameNumber,
	frameNumberTotalFrames,
	getAcquisitionDisplay,
	getAgeDisplay,
	getImageCompressionDisplay,
	getOverlayInstanceNumber,
	getStudyDateDisplay,
	inversionTime,
	kvp,
	mrAcquisitionType,
	numberOfAverages,
	organDose,
	patientID,
	pixelBandwidth,
	positionerPrimaryAngle,
	reconstructionDiameter,
	repetitionTime,
	sequenceName,
	sequenceVariant,
	seriesNumber,
	sex,
	sharpnessUnsharpMaskDegree,
	sliceLocation,
	sliceThickness,
	smartImageCompression,
	spacingBetweenSlices,
	studyID,
	techName,
	triggerTime,
	viewcodeTag,
} from '../ImageViewerViewportOverlayUtilsV2';

export const position = {
	top: 'top',
	center: 'center',
	bottom: 'bottom',
};

export const alignment = {
	left: 'left',
	center: 'center',
	right: 'right',
};

export const lateralityMapper = {
	L: 'left',
	R: 'right',
};

export const positionDict = {
	topLeft: {
		position: position.top,
		alignment: alignment.left,
	},
	topMiddle: {
		position: position.top,
		alignment: alignment.center,
	},
	topRight: {
		position: position.top,
		alignment: alignment.right,
	},
	leftMiddle: {
		position: position.center,
		alignment: alignment.left,
	},
	rightMiddle: {
		position: position.center,
		alignment: alignment.right,
	},
	downLeft: {
		position: position.bottom,
		alignment: alignment.left,
	},
	downMiddle: {
		position: position.bottom,
		alignment: alignment.center,
	},
	downRight: {
		position: position.bottom,
		alignment: alignment.right,
	},
};

export const DICOM_NATURALIZED_DICT = {
	// improve phase 2 + title. & pass in conf tag ONLY
	StudyComments: { label: 'Study Comments (0032,1066)', value: getInstanceStudyComments },
	StudyDescription: { label: 'Study Description (0008,1030)', value: getStudyDescription },
	SeriesDescription: { label: 'Series Description (0008,103E)', value: getSeriesDescription },
	DerivationDescription: { label: 'Derivation Description (0008,2111)', value: getInstanceDerivationDescription },
	TechName: { label: "Tech's name (0008,1070)", value: techName },
	StationName: { label: 'Station Name (0008,1010)', value: getInstanceStationName },
	InstitutionName: { label: 'Institution Name (0008,0080)', value: getInstanceInstitutionName },
	Age: { label: 'Age (0010,1010)', value: getAgeDisplay },
	InstanceNumber: { label: 'Instance Number (0020,0013)', value: getOverlayInstanceNumber },
	SliceLocation: { label: 'Slice Location (0020,1041)', value: sliceLocation },
	CurrentOrPriorIndicator: { label: 'Current or Prior indicator', value: currentOrPriorIndicator },
	Acquisition: { label: 'Acquisition', value: getAcquisitionDisplay },
	StudyDate: { label: 'Study Date (0008,0020)', value: getStudyDateDisplay },
	ImageCompression: { label: 'Image Compression (0028,2110)', value: getImageCompressionDisplay },
	ViewcodeTag: { label: 'Viewcode Tag (0020,0037)', value: viewcodeTag },
	WindowCenterWidth: { label: 'Window Center & Width (0028,1050)', component: WindowCenterWidth },
	SharpnessUnsharpMaskDegree: {
		label: 'Sharpness & Unsharp Mask Degree (0008,0016a)',
		value: sharpnessUnsharpMaskDegree,
	},
	ConvolutionKernel: { label: 'Convolution Kernel (0018,1210)', value: convolutionKernel },
	AcquisitionMatrix: { label: 'Acquisition Matrix (0018,1310)', value: acquisitionMatrix },
	NumberOfAverages: { label: 'Number of averages (0018,0083)', value: numberOfAverages },
	EchoTrainLength: { label: 'Echo Train Length (0018,0091)', value: echoTrainLength },
	MRAcquisitionType: { label: 'MR Acquisition Type (0018,0023)', value: mrAcquisitionType },
	SequenceName: { label: 'Sequence Name (0018,0024)', value: sequenceName },
	SequenceVariant: { label: 'Sequence Variant (00180021)', value: sequenceVariant },
	PatientID: { label: 'Patient ID (0010,0020)', value: patientID },
	DateOfBirth: { label: 'Date of Birth (0010,0030)', value: dateOfBirth },
	StudyID: { label: 'Study ID (0020,0010)', value: studyID },
	SeriesNumber: { label: 'Series Number (0020,0011)', value: seriesNumber },
	Sex: { label: 'Sex (0010,0040)', value: sex },
	SliceThickness: { label: 'Slice Thickness (0018,0050)', value: sliceThickness },
	SpacingBetweenSlices: { label: 'Spacing Between Slices (0018,0088)', value: spacingBetweenSlices },
	ContrastAgent: { label: 'Contrast Agent (0018,0010)', value: contrastAgent },
	KVP: { label: 'KVP (0018,0060)', value: kvp },
	Current: { label: 'Current (0018,1151)', value: current },
	Exposure: { label: 'Exposure (0018,1152)', value: exposure },
	ExposureTime: { label: 'Exposure Time (0018,1150)', value: exposureTime },
	CompressionForce: { label: 'Compression Force (0018,11a2)', value: compressionForce },
	ReconstructionDiameter: { label: 'Reconstruction Diameter (0018,1100)', value: reconstructionDiameter },
	OrganDose: { label: 'Organ Dose (0040,0316)', value: organDose },
	PositionerPrimaryAngle: {
		label: 'Positioner Primary Angle (0018,1510)',
		value: positionerPrimaryAngle,
	},
	BodyPartThickness: { label: 'Body Part Thickness (0018,11a0)', value: bodyPartThickness },
	RepetitionTime: { label: 'Repetition Time (0018,0080)', value: repetitionTime },
	EchoTime: { label: 'Echo Time (0018,0081)', value: echoTime },
	InversionTime: { label: 'Inversion Time (0018,0082)', value: inversionTime },
	TriggerTime: { label: 'Trigger Time (0018,1060)', value: triggerTime },
	FlipAngle: { label: 'Flip Angle (0018,1314)', value: flipAngle },
	PixelBandwidth: { label: 'Pixel Bandwidth (0018,0095)', value: pixelBandwidth },
	FrameNumber: { label: 'Frame Number', value: frameNumber },
	FrameNumberTotalFrames: { label: 'Frame Number/Total Frames', value: frameNumberTotalFrames },
	SmartImageCompression: { label: 'Smart Image Compression', value: smartImageCompression },
};

export const STUDY_INDICATOR = {
	Current: 'Current',
	Prior: 'Prior',
};
