export const PROTOCOL = {
	protocol_id: 'ProtocolID',
	organization_id: 'managingorganizationid',
	organization_name: 'managingorganizationname',
	layout: 'Layout',
	viewport: 'ViewPorts',
	internal_user_id: 'InternalUserID',
	name: 'Hanging Protocol Name',
	description: 'Hanging Protocol Description',
	level: 'Hanging Protocol Level',
	creator: 'Hanging Protocol Creator',
	timestamp: 'Hanging Protocol Creation DateTime',
	public: 'Public Protocol',
	definition_sequence: 'Hanging Protocol Definition Sequence',
	priors_referenced: 'Number of Priors Referenced',
	compare_mode: 'Compare Mode Exists',
	modality: 'modality',
	default_for_modality: 'defaultForModality',
	stages: 'Stages',
};

export const PROTOCOL_DEFAULT_VALUES = {
	[PROTOCOL.name]: '',
	[PROTOCOL.priors_referenced]: 1,
	[PROTOCOL.compare_mode]: 'N', //should be based on the dropdown value in the viewport
	[PROTOCOL.public]: 'Y',
	[PROTOCOL.description]: '',
	[PROTOCOL.level]: '',
	[PROTOCOL.definition_sequence]: [
		{
			'Item 1': {
				Modality: '',
				'Anatomic Region Sequence': {
					'Item 1': ['T-D3000', 'SRT', 'Chest'],
					Laterality: '',
					BodyPart: '',
					'Procedure Code Sequence': '',
					'Reason for Requested Procedure Code Sequence': '',
				},
			},
		},
	],
	[PROTOCOL.default_for_modality]: false,
};

export const STAGE_DEFAULT_VALUE = {
	monitors: [],
	layout: { x: 0, y: 0 },
};
