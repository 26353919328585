import { useEffect } from 'react';
import { useFhirDataLoader, searchScopes } from '@worklist-2/core/src';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const useSavePrevReport = (saveOnMount = true) => {
	const fhirReportLoader = useFhirDataLoader({
		scope: searchScopes.diagnosticReport,
	});
	const galaxyDvNewSeparateAutoSaveApi = useBooleanFlagValue('galaxy-dv-new-separate-auto-save-api');
	const galaxyDvAutoSaveOnUmountBugFix = useBooleanFlagValue('galaxy-dv-auto-save-on-umount-bug-fix');

	const handleSaveReport = async () => {
		const prelimPayload = localStorage.getItem('prelimPayload');
		if (!prelimPayload) return;

		const { document, payload } = JSON.parse(prelimPayload);

		if (galaxyDvNewSeparateAutoSaveApi) {
			await fhirReportLoader.update(`${document.id}/save`, payload);
		} else {
			await fhirReportLoader.update(`${document.id}?callDone=false`, payload);
		}

		localStorage.removeItem('prelimPayload');
	};

	useEffect(() => {
		if (galaxyDvAutoSaveOnUmountBugFix ? saveOnMount : true) {
			handleSaveReport();
		}
	}, []);

	return handleSaveReport;
};

export default useSavePrevReport;
