import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { CardInfoRow } from './CardInfoRow';
import { MergeDrawerCard } from './MergeDrawerCard';

import { useSelection } from '@worklist-2/core/src/context/SelectionContext';
import getUserFullName from '@worklist-2/core/src/fhir/resource/columnMapping/utils/getUserFullName';
import { getReadableBirthDate } from '../../../utils/birthdayUtils';
import { useTranslation } from 'react-i18next';

const getPatientFirstAndLastNames = name => {
	if (!name || name.length === 0) return ['', ''];

	const nameSlices = getUserFullName(name).split(' ');

	return nameSlices.length > 1 ? [...nameSlices] : [name, ''];
};

export const MergePatientDrawerCard = ({ data, cardStyles, highlightedFields }) => {
	const { subject, studyID, managingOrganization, patientID, birthDate, gender = '' } = data;

	const { selectedMergeStudy, setSelectedMergeStudy } = useSelection();
	const [isChecked, setChecked] = useState(false);

	const [firstName, lastName] = getPatientFirstAndLastNames(subject?.display);
	const { t } = useTranslation(['workList', 'codePool']);

	useEffect(() => {
		const isCurrentlyChecked = selectedMergeStudy?.studyID === studyID;
		setChecked(isCurrentlyChecked);
	}, [selectedMergeStudy]);

	const handleCheck = e => {
		if (e.target.checked) {
			setSelectedMergeStudy(data);
		} else {
			setSelectedMergeStudy(null);
		}
	};

	return (
		<>
			{data && (
				<MergeDrawerCard cardStyles={cardStyles} icon={AccountCircleIcon}>
					<Box
						sx={{
							marginTop: 2,
						}}
					>
						<CardInfoRow
							data={[
								{
									label: t('workListDrawer.lastName'),
									value: lastName,
									size: 6,
									highlighted: highlightedFields.includes('patientName'),
								},
								{
									label: t('workListDrawer.firstName'),
									value: firstName,
									size: 6,
									highlighted: highlightedFields.includes('patientName'),
								},
							]}
						/>
					</Box>

					<Box
						sx={{
							marginTop: 3,
						}}
					>
						<CardInfoRow
							data={[
								{
									label: t('workListDrawer.birthDate'),
									value: getReadableBirthDate(birthDate),
									size: 6,
									highlighted: highlightedFields.includes('birthDate'),
								},
								{
									label: t('workListDrawer.sex'),
									value: gender ? t(`codePool:sex.${gender.toUpperCase()}`) : 'N/A',
									size: 6,
									highlighted: highlightedFields.includes('gender'),
								},
							]}
						/>
					</Box>

					<Box sx={{ marginTop: 2.5 }}>
						<CardInfoRow
							data={[
								{
									label: t('workListDrawer.patientId'),
									value: patientID,
									size: 6,
									highlighted: highlightedFields.includes('patientID'),
								},
								{
									label: t('workListDrawer.patientIdIssuer'),
									value: managingOrganization,
									size: 6,
									highlighted: highlightedFields.includes('managingOrganization'),
								},
							]}
						/>
					</Box>

					<Box
						sx={{
							display: 'flex',
							padding: '10px',
						}}
					>
						<Checkbox
							checked={isChecked}
							checkedIcon={<RadioButtonCheckedIcon />}
							icon={<RadioButtonUncheckedIcon sx={{ fill: '#42A5F5' }} />}
							sx={{
								height: '20px',
								width: '20px',
								mr: '8px',
							}}
							onChange={handleCheck}
						/>
						<Typography
							noWrap
							sx={{
								color: 'rgba(255, 255, 255, 0.6)',
								fontSize: '14px',
							}}
						>
							{t('workListDrawer.mergePatientMessage')}
						</Typography>
					</Box>
				</MergeDrawerCard>
			)}
		</>
	);
};
