import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export const MergeDrawerCard = ({ icon: Icon, cardStyles, children, isSelected }) => (
	<Box
		data-testid="drawer-card"
		sx={{
			display: 'flex',
			borderRadius: '10px',
			padding: '1px',
			background: isSelected ? 'linear-gradient(139deg, #568AF3 0%, rgba(86, 138, 243, 0) 61.68%)' : '#3D3D3D',
			...cardStyles,
		}}
	>
		<Box
			sx={{
				width: '350px',
				display: 'flex',
				background: '#3D3D3D',
				borderRadius: '10px',
				padding: '10px',
			}}
		>
			<Box
				sx={{
					width: '40px',
				}}
			>
				<Icon />
			</Box>

			<Grid
				item
				// md={8}
				// sm={8}
				sx={{
					width: '90%',
				}}
				// xs={8}
			>
				{children}
			</Grid>
		</Box>
	</Box>
);
