/* Generated automagically by FhirStarter - DO NOT EDIT */

import { FhirDomainResource } from '../base';
import workListLayoutSchema from './schemata/workListLayoutSchema';
/************************************************************************************************************************
Resource: WorkListLayout
Reference: N/A
Proprietary RamSoft resource
************************************************************************************************************************/

export default class WorkListLayoutResource extends FhirDomainResource {
	id;
	name;
	active;
	practitioner;
	role;
	organization;
	flagsave;
	isCurrent;
	isDefault;

	constructor(resourceString) {
		super(resourceString, workListLayoutSchema);
		this.resourceType = 'WorkListLayout';
		this.populateFields();
	}
}
