import { getExtensionValueReference } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';
import { fhirExtensionUrls } from '@worklist-2/core/src';
import {
	generateOccurrencesWithoutDst,
	generateOccurrences,
	extractInfoFromEvent,
	extractAppointmentInfo,
	getReserveInternalAppointments,
	extractAppointmentHealthcareServiceId,
	isRruleStringInWallClock,
} from '@worklist-2/worklist/src/SchedulerV2/utils';
import _ from 'lodash';
import { add } from 'date-fns';

export const transformToHealthcareServices = (data, focusedEvent, selectedResources) => {
	return data.map(({ resource }) => {
		const isFocusedResource = extractAppointmentHealthcareServiceId(focusedEvent) === resource.id;

		const isSelected = focusedEvent
			? false
			: selectedResources?.length == 0 || selectedResources?.includes(resource.id);

		return {
			...resource,
			id: resource.id,
			name: resource.name,
			organization: getExtensionValueReference(
				resource.providedBy,
				fhirExtensionUrls.organization.managingOrganization
			)?.display,
			location: resource.location?.display,
			selected: isFocusedResource || isSelected,
		};
	});
};

export const transformToAppointments = (
	data,
	focusedEvent,
	selectedHealthcareServices,
	proactTimeZoneConversionBlockTime
) => {
	let occurrences = [];

	const newData = _.isArray(data)
		? data.map(({ resource }) => {
				const { healthcareServiceId } = extractAppointmentInfo(resource);
				const service = selectedHealthcareServices?.find(i => i.id === healthcareServiceId);
				// If the resouce is a recurring event, generate occurrences that will be displayed on calendar
				// and remove the recurring event from the data list, so it does not display on the calendar
				const resourceOccurrences = transformRecurringEventIntoOccurrences(
					resource,
					service,
					proactTimeZoneConversionBlockTime
				);
				if (resourceOccurrences?.length > 0) {
					occurrences = [...occurrences, ...resourceOccurrences];
					return null;
				} else {
					return transformSingleEventIntoCalendarEvent(resource, focusedEvent, data);
				}
		  })
		: [];

	const filteredData = newData?.filter(resource => resource);

	return [...occurrences, ...filteredData];
};

/**
 * Transform a recurring event into events based on the occurrences of the recurring event.
 * The transformed occurrences will be displayed on the calendar instead of the recurring event itself
 * @recurringEvent {object} a recurring event
 * @service {object} healthcare service object
 * @returns an array occurrences of the recurring event
 */
export const transformRecurringEventIntoOccurrences = (recurringEvent, service, proactTimeZoneConversionBlockTime) => {
	if (!recurringEvent) {
		return;
	}

	const eventDetails = extractInfoFromEvent(recurringEvent);

	let occurrences = [];

	if (eventDetails?.rruleSetString?.length > 0) {
		const occurrenceList = isRruleStringInWallClock(eventDetails.rruleSetString)
			? generateOccurrences(eventDetails?.rruleSet)
			: generateOccurrencesWithoutDst(eventDetails?.rruleSet, service, proactTimeZoneConversionBlockTime);

		occurrences = occurrenceList?.map(item => {
			const occurrence = _.cloneDeep(recurringEvent);

			occurrence.start = new Date(item);
			occurrence.end = add(occurrence.start, { minutes: recurringEvent?.minutesDuration });
			occurrence.recurringEventId = recurringEvent.id;
			occurrence.id = `${recurringEvent.id}-${occurrence.start.getTime()}-${occurrence.end.getTime()}`;

			return occurrence;
		});
	}

	return occurrences;
};

/**
 * Transform an appointment, single blocked time or reservation event into an event with a required data structure.
 * The transformed events willl be displayed on the calendar instead of the recurring event itself
 * @event {object} an appointment, single blocked time or reservation event
 * @focusedEvent {object} the event that is currently focused on the calendar
 * @data {array} an array of the events that are displayed on the calendar
 * @returns an appointment, single blocked time or reservation event object
 */
export const transformSingleEventIntoCalendarEvent = (event, focusedEvent, data) => {
	if (!event) {
		return;
	}

	const { parkingIndex, isInsideParkingStation, modality, isReservation } = extractAppointmentInfo(event);
	const internalAppts = isReservation ? getReserveInternalAppointments(event, data) : undefined;
	const internalAppointmentsIds = internalAppts?.map(i => i?.id);

	return {
		...event,
		duration: event?.minutesDuration,
		start: new Date(event?.start),
		end: new Date(event?.end),
		isInsideParkingStation,
		modality,
		parkingIndex: parkingIndex ? parseInt(parkingIndex, 10) : undefined,
		focused: event?.id === focusedEvent?.id,
		internalAppointmentsIds,
	};
};
