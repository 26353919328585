import axios from 'axios';
import loadAndAppendMetadataToSeries from './loadAndAppendMetadataToSeries';
import { getSeriesModality, getSeriesUID } from '../utils/utils';
import { MGViewCodes } from '../../HangingProtocolView/features/ViewCodes/ViewCodes';
import seriesMatchViewCodeCondition from '../utils/seriesMatchViewCodeCondition';
import { sortSeries } from '../utils/sortSeriesThumbnails';

const appedAdditionalDataToSeries = (series, managingOrganizationId) =>
	series.map((item, index) => {
		let mammographyViewCode;

		if (getSeriesModality(item).includes('MG')) {
			mammographyViewCode = MGViewCodes.filter(MGViewCode => !MGViewCode.includes('Any')).find(MGViewCode =>
				seriesMatchViewCodeCondition({
					viewCode: MGViewCode,
					series: item,
				})
			);
		}

		return {
			...item,
			uniqueId: `${getSeriesUID(item)}${index}`,
			mammographyViewCode,
			managingOrganizationId,
		};
	});

const loadSeriesWithMetadata = async ({
	__config,
	managingOrganizationId,
	studyInstanceUID,
	wonIvSeriesSplitter,
	is3D,
	wonIvPlaneSplitter,
	wonIvDwiseriessplitter,
	wonIvDisablexasplit,
	cacheLinks,
	wonIvInitImageMetadata,
	wonIvEnhancedcurvedsort,
	wonIvRefactorsorting,
	wonIvStillsfirst,
	isBlumeApp,
	creatorUserId,
	isUploaded,
}) => {
	const headers = {
		'ramsoft-internalmanagingorganizationid': managingOrganizationId,
	};
	let url = `${__config.data_sources.dicom_web}/studies/${studyInstanceUID}/series?BlumePatientId=${
		creatorUserId || null
	}`;
	if (isBlumeApp) {
		url = `${__config.data_sources.blume}studies/${studyInstanceUID}/series?creatorBlumeUserId=${creatorUserId}&isUploaded=${isUploaded}`;
	}
	const response = await axios.get(url, {
		headers,
	});

	if (response?.data) {
		const _series = await loadAndAppendMetadataToSeries({
			__config,
			headers,
			studyInstanceUID,
			series: response.data,
			wonIvSeriesSplitter,
			is3D,
			wonIvPlaneSplitter,
			wonIvDwiseriessplitter,
			wonIvDisablexasplit,
			cacheLinks,
			useCornerstone: true,
			managingOrganizationId,
			wonIvInitImageMetadata,
			wonIvEnhancedcurvedsort,
			wonIvRefactorsorting,
			isBlumeApp,
			creatorUserId,
			isUploaded,
		});

		const seriesWithMetadata = _series.filter(item => item.metadata.length);
		const sortedSeries = sortSeries(appedAdditionalDataToSeries(seriesWithMetadata, managingOrganizationId));

		if (wonIvStillsfirst) {
			const [common, cine] = sortedSeries.reduce(
				([p, f], i) =>
					i.metadata?.[0]?.['00280008']?.Value?.[0] && i.metadata?.[0]?.['00080060']?.Value?.[0] === 'US'
						? [p, [...f, i]]
						: [[...p, i], f],
				[[], []]
			);

			return [...common, ...cine];
		}
		return sortedSeries;
	}

	return [];
};

export default loadSeriesWithMetadata;
