import { intersection } from 'lodash';
import getDay from 'date-fns/getDay';
import { fhirExtensionUrls } from '@worklist-2/core/src/fhir/extension';
import { DAYS_OF_WEEK_SHORT, HOUR_LIMIT } from '../constants';
import {
	convertRRuleSetStringToRRuleSet,
	generateOccurrencesWithoutDst,
	generateOccurrences,
	generateISOStringWithoutOffset,
	isRruleStringInWallClock,
} from './rruleHelper';
import { extractHealthcareServiceInfo } from './dataProcessHelpers';
import { utcToZonedTime } from 'date-fns-tz';

export const getStartDateEndDate = (timeSlot, dayDate) => {
	const { availableEndTime, availableStartTime } = timeSlot;

	const start = availableStartTime.split(':');
	const end = availableEndTime.split(':');

	const startDate = new Date(dayDate);
	startDate.setHours(start[0]);
	startDate.setMinutes(start[1]);

	const endDate = new Date(dayDate);
	endDate.setHours(end[0]);
	endDate.setMinutes(end[1]);

	return { startDate, endDate };
};

export const checkInsideWorkingHours = (appointment, availableSlots) =>
	availableSlots.some(
		({ startDate, endDate }) =>
			new Date(appointment.start).getTime() >= startDate.getTime() &&
			new Date(appointment.end).getTime() <= endDate.getTime()
	);

export const findAppointmentWithTargetStudyId = (data, studyId) =>
	Array.isArray(data) && studyId
		? data?.find(
				d => d?.supportingInformation?.[0]?.id === studyId && d.status !== 'cancelled' && d.status !== 'noshow'
		  )
		: null;

export const getAvailableWorkingSlotsForDay = (healthcareServiceToCheck, dayToCheck) =>
	healthcareServiceToCheck?.availableTime
		?.map(at =>
			at.daysOfWeek.includes(DAYS_OF_WEEK_SHORT[getDay(dayToCheck)])
				? getStartDateEndDate(at, dayToCheck)
				: undefined
		)
		.filter(Boolean) || [];

export const getHealthcareServiceInfo = (participant, healthcareServices) => {
	const serviceId = participant?.find(p => p.actor?.reference?.includes('HealthcareService'))?.actor?.id;

	return healthcareServices.find(hs => hs.id === serviceId);
};

export const serviceModalityMatches = (sourceService, targetService) => {
	const sourceServiceModality = sourceService?.extension
		?.find(ext => ext.url == fhirExtensionUrls.common.modality)
		?.extension?.map(m => m.valueCode);

	const targetServiceModality = targetService?.extension
		?.find(ext => ext.url == fhirExtensionUrls.common.modality)
		?.extension?.map(m => m.valueCode);

	// targetServiceModality = undefined if it supports all modalities, so sourceService and targetService modalities are matching
	return targetServiceModality === undefined || intersection(sourceServiceModality, targetServiceModality).length > 0;
};

export const checkAppointmentAndServiceModalityMatches = (appointment, service) => {
	if (!appointment || !service) return;

	const serviceModality = service?.extension
		?.find(ext => ext.url == fhirExtensionUrls.common.modality)
		?.extension?.map(m => m.valueCode);

	const { modality: appointmentModality } = extractAppointmentInfo(appointment);

	// serviceModality = undefined if it supports all modalities, so sourceService and targetService modalities are matching
	// Skip this check for blocked time and reservation. This is to fix PRO-4152
	// Appointments created by Blume will not have modality until after a study has been scheduled - we need to allow for modifications to these appointments
	return (
		serviceModality === undefined ||
		checkIsReservation(appointment) ||
		checkIsBlockedTime(appointment) ||
		!appointmentModality ||
		intersection(serviceModality, appointmentModality).length > 0
	);
};

export const extractAppointmentInfo = appointment => {
	if (!appointment) return;

	const parkingIndex = appointment?.extension?.find(({ url }) => url?.includes('parkingIndex'))?.valueString;

	const isInsideParkingStation = !!appointment?.extension?.find(({ url }) => url?.includes('parkingIndex'));

	// will be changed to correct fhir extensionUrls
	const isAutoScheduled =
		appointment?.extension?.find(({ url }) => url?.includes('isAutoScheduled'))?.valueBoolean || false;

	const isReservation = checkIsReservation(appointment);

	const healthcareServiceId = extractAppointmentHealthcareServiceId(appointment);

	const modality = appointment?.extension
		?.find(({ url }) => url === fhirExtensionUrls.common.modality)
		?.extension?.map(({ valueCoding }) => valueCoding?.code);

	return { parkingIndex, isInsideParkingStation, modality, healthcareServiceId, isReservation, isAutoScheduled };
};

export const createEventListFromAppointmentResponse = (
	response,
	eventType,
	timezoneIANA,
	proactTimeZoneConversionBlockTime
) =>
	response && response.total > 0
		? response.entry
				?.filter(entry =>
					eventType === 'appointment'
						? !entry?.resource?.extension?.find(
								({ url }) =>
									url === fhirExtensionUrls.appointment.blockedTime ||
									url === fhirExtensionUrls.appointment.reservation
						  )?.valueBoolean
						: eventType === 'reservation'
						? entry?.resource?.extension?.find(
								({ url }) => url === fhirExtensionUrls.appointment.reservation
						  )?.valueBoolean
						: entry?.resource?.extension?.find(
								({ url }) => url === fhirExtensionUrls.appointment.blockedTime
						  )?.valueBoolean
				)
				.map(entry => ({
					...entry.resource,
					duration: entry.resource.minutesDuration,
					start: proactTimeZoneConversionBlockTime
						? utcToZonedTime(new Date(entry.resource.start), timezoneIANA)
						: new Date(entry.resource.start),
					end: proactTimeZoneConversionBlockTime
						? utcToZonedTime(new Date(entry.resource.end), timezoneIANA)
						: new Date(entry.resource.end),
					isAppointment: eventType === 'appointment',
				}))
		: [];

export const filterEventsByEventType = (eventArr, eventType) =>
	Array.isArray(eventArr) && eventArr.length > 0
		? eventArr
				.filter(resource =>
					eventType === 'appointment'
						? !resource?.extension?.find(
								({ url }) =>
									url === fhirExtensionUrls.appointment.blockedTime ||
									url === fhirExtensionUrls.appointment.reservation
						  )?.valueBoolean
						: eventType === 'reservation'
						? resource?.extension?.find(({ url }) => url === fhirExtensionUrls.appointment.reservation)
								?.valueBoolean
						: resource?.extension?.find(({ url }) => url === fhirExtensionUrls.appointment.blockedTime)
								?.valueBoolean
				)
				.map(item => ({
					...item,
					duration: item.minutesDuration,
					isAppointment: eventType === 'appointment',
				}))
		: [];

export const checkIsBlockedTime = eventData =>
	eventData?.extension?.find(({ url }) => url === fhirExtensionUrls.appointment.blockedTime)?.valueBoolean || false;

export const checkIsReservation = eventData =>
	eventData?.extension?.find(({ url }) => url === fhirExtensionUrls.appointment.reservation)?.valueBoolean || false;

// Extract details without occurrences from a single or recurring event
export const extractInfoFromEvent = eventData => {
	const eventDetails = {};
	if (eventData) {
		const index = eventData?.extension?.findIndex(
			({ url }) =>
				url === fhirExtensionUrls.appointment.recurringBlockedTime ||
				url === fhirExtensionUrls.appointment.recurringReservation
		);
		if (index > -1) {
			// Recurring event details
			const rruleSetString = eventData?.extension[index]?.valueString || '';
			const rruleSet = convertRRuleSetStringToRRuleSet(rruleSetString);

			eventDetails.recurringExtIndex = index;
			eventDetails.rruleSetString = rruleSetString;
			eventDetails.rruleSet = rruleSet;
		}

		// Single event details
		eventDetails.isBlockedTime = checkIsBlockedTime(eventData);
		eventDetails.isReservation = checkIsReservation(eventData);

		if (eventDetails.isReservation) {
			const nameExtIndex = eventData?.extension?.findIndex(
				({ url }) => url === fhirExtensionUrls.appointment.reservationName
			);
			const colorExtIndex = eventData?.extension?.findIndex(
				({ url }) => url === fhirExtensionUrls.appointment.reservationColor
			);

			if (nameExtIndex > -1) {
				eventDetails.nameExtIndex = nameExtIndex;
				eventDetails.reservationName = eventData.extension[nameExtIndex]?.valueString;
			}

			if (colorExtIndex > -1) {
				eventDetails.colorExtIndex = colorExtIndex;
				eventDetails.reservationColor = eventData.extension[colorExtIndex]?.valueString;
			}
		}
	}

	return {
		colorExtIndex: eventDetails?.colorExtIndex,
		isBlockedTime: eventDetails?.isBlockedTime,
		isReservation: eventDetails?.isReservation,
		nameExtIndex: eventDetails?.nameExtIndex,
		recurringExtIndex: eventDetails?.recurringExtIndex,
		reservationColor: eventDetails?.reservationColor,
		reservationName: eventDetails?.reservationName,
		rruleSet: eventDetails?.rruleSet,
		rruleSetString: eventDetails?.rruleSetString,
	};
};

// Extract details with occurrences from a recurring event
export const occurenceAndEventBreakdown = (
	recurringEventData,
	service,
	proactTimeZoneConversionBlockTime,
	proactDisableTimeZoneFromAppointments
) => {
	const rruleInfo = extractInfoFromEvent(recurringEventData);
	const rruleSet = rruleInfo?.rruleSet;
	const allOccurrences = isRruleStringInWallClock(rruleInfo?.rruleSetString)
		? generateOccurrences(rruleSet)
		: generateOccurrencesWithoutDst(rruleSet, service, proactTimeZoneConversionBlockTime);
	const { timezoneIANA } = extractHealthcareServiceInfo(service);
	const exdateInZonedTime =
		rruleSet?._exdate?.length > 0
			? rruleSet?._exdate?.map(date => {
					const startDate =
						allOccurrences?.length > 0
							? allOccurrences[0]
							: proactTimeZoneConversionBlockTime
							? utcToZonedTime(new Date(rruleSet._rrule[0].options.dtstart), timezoneIANA)
							: new Date(rruleSet._rrule[0].options.dtstart);
					const exceptedDate = proactTimeZoneConversionBlockTime ? utcToZonedTime(date, timezoneIANA) : date;
					// Get hours from start date to avoid daylight savings time issue
					return proactDisableTimeZoneFromAppointments
						? new Date(generateISOStringWithoutOffset(date))
						: new Date(
								exceptedDate.getFullYear(),
								exceptedDate.getMonth(),
								exceptedDate.getDate(),
								startDate.getHours(),
								startDate.getMinutes()
						  );
			  })
			: [];

	return {
		allOccurrences,
		pastOccurrences: allOccurrences?.filter(occurrence => occurrence < new Date()),
		futureOccurrences: allOccurrences?.filter(occurrence => occurrence >= new Date()),
		pastExceptedDatesInZonedTime: exdateInZonedTime?.filter(date => date < new Date()),
		futureExceptedDatesInZonedTime: exdateInZonedTime?.filter(date => date >= new Date()),
		...rruleInfo,
	};
};

// Extracts orderId from the Appointment.
// There can be 2 variants of the reference string: [ServiceRequest/94419] and [/ServiceRequest/94419]
export const extractAppointmentOrderId = appointment => {
	if (!appointment || !appointment?.basedOn) return;

	const reference = appointment.basedOn?.[0]?.reference;
	const parts = reference?.split('/')?.filter(part => part !== '');

	// Return the last part of reference string that contains the actual orderId
	return parts?.[parts?.length - 1];
};

export const extractAppointmentHealthcareServiceId = appointment => {
	if (!appointment) return;

	const actor = appointment?.participant?.find(i => i?.actor?.reference?.includes('HealthcareService'))?.actor;

	if (!actor?.reference) return;

	const healthcareServiceRegex = /HealthcareService\/(\d+)/;
	const referenceId = actor?.reference?.match(healthcareServiceRegex)?.[1];

	return actor?.id || referenceId;
};

export const checkAppointmentObsolete = appointment => {
	if (!appointment) return;

	const timeLimit = HOUR_LIMIT * 60 * 60 * 1000; // 48h
	const appointmentStartTime = new Date(appointment?.start).getTime();
	const pastTimeLimit = new Date(new Date().getTime() - timeLimit).getTime();

	return appointmentStartTime < pastTimeLimit;
};

export const getAppointmentTimeString = appointment => {
	if (!appointment) return;

	const { start, end } = appointment;

	const startTimeString = start?.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
	});

	const endTimeString = end?.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
	});

	return { startTimeString, endTimeString };
};

const getBlockEventData = eventData => {
	const eventDetails = {};

	eventDetails.isBlockedTime = checkIsBlockedTime(eventData);

	const index = eventData?.extension?.findIndex(
		({ url }) =>
			url === fhirExtensionUrls.appointment.recurringBlockedTime ||
			url === fhirExtensionUrls.appointment.recurringReservation
	);
	if (index > -1) {
		// Recurring event details
		const rruleSetString = eventData?.extension[index]?.valueString || '';
		const rruleSet = convertRRuleSetStringToRRuleSet(rruleSetString);

		eventDetails.recurringExtIndex = index;
		eventDetails.rruleSetString = rruleSetString;
		eventDetails.rruleSet = rruleSet;
	}
	return eventDetails;
};

const getReservationData = eventData => {
	const eventDetails = {};

	eventDetails.isReservation = checkIsReservation(eventData);

	if (eventDetails.isReservation) {
		const nameExtIndex = eventData?.extension?.findIndex(
			({ url }) => url === fhirExtensionUrls.appointment.reservationName
		);
		const colorExtIndex = eventData?.extension?.findIndex(
			({ url }) => url === fhirExtensionUrls.appointment.reservationColor
		);

		if (nameExtIndex > -1) {
			eventDetails.nameExtIndex = nameExtIndex;
			eventDetails.reservationName = eventData.extension[nameExtIndex]?.valueString;
		}

		if (colorExtIndex > -1) {
			eventDetails.colorExtIndex = colorExtIndex;
			eventDetails.reservationColor = eventData.extension[colorExtIndex]?.valueString;
		}
	}
	return eventDetails;
};

export const extractEventData = appointment => {
	if (!appointment) return {};

	const healthcareServiceId = extractAppointmentHealthcareServiceId(appointment);

	const modality = appointment?.extension
		?.find(({ url }) => url === fhirExtensionUrls.common.modality)
		?.extension?.map(({ valueCoding }) => valueCoding?.code);

	const parkingIndex = appointment?.extension?.find(
		({ url }) => url === fhirExtensionUrls.appointment.parkingIndex
	)?.valueString;

	const isInsideParkingStation = !!appointment?.extension?.find(
		({ url }) => url === fhirExtensionUrls.appointment.parkingIndex
	);

	const isAutoScheduled =
		appointment?.extension?.find(({ url }) => url === fhirExtensionUrls.appointment.isAutoScheduled)
			?.valueBoolean || false;

	const reservationData = getReservationData(appointment);
	const blockEventData = getBlockEventData(appointment);

	return {
		id: appointment?.id,
		healthcareServiceId,
		modality,
		studyId: appointment?.supportingInformation?.[0]?.id,
		isAutoScheduled,
		parkingIndex,
		isInsideParkingStation,
		...blockEventData,
		...reservationData,
	};
};
