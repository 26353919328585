import { getStudyInstanceUID } from '../utils/utils';
import { useImageViewerMeasurementContext } from '../contexts/ImageViewerMeasurementContext';
import loadSeriesWithMetadata from '../api/loadSeriesWithMetadata';
import { useCache, useConfig } from '@worklist-2/core/src';
import { useImageViewerStudiesContext } from '../contexts/ImageViewerStudiesContext';
import { getKeyImages } from '../api/keyImage';
import { useImageViewerView3DContext } from '../contexts/ImageViewerView3DContext';
import { BROADCAST_EVENTS } from '../contexts/consts/consts';
import { useImageViewerMultiMonitorContext } from '../contexts/ImageViewerMultiMonitorContext';
import { useSearchParams } from 'react-router-dom';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

export const useLoadStudyData = () => {
	const __config = useConfig();
	const wonIvSeriesSplitter = useBooleanFlagValue('won-iv-series-splitter');
	const wonIvPlaneSplitter = useBooleanFlagValue('won-iv-plane-splitter');
	const wonIvDisablexasplit = useBooleanFlagValue('WON-IV-DISABLEXASPLIT');
	const wonIvEnhancedcurvedsort = useBooleanFlagValue('WON-IV-ENHANCEDCURVEDSORT');
	const wonIvStillsfirst = useBooleanFlagValue('WON-IV-STILLSFIRST');
	const wonIvRefactorsorting = useBooleanFlagValue('WON-IV-REFACTORSORTING');
	const wonIvRefactorLoaddataZustand = useBooleanFlagValue('WON-IV-REFACTOR-LOADDATA-ZUSTAND');
	const { cacheLinks } = useCache();

	const { loadingManagerRef } = useImageViewerView3DContext();
	const { addNewSeries, setSeries, onSetKeyImages } = useImageViewerStudiesContext();
	const { measurementLoader, loadMeasurements } = useImageViewerMeasurementContext();
	const { postMessage } = useImageViewerMultiMonitorContext();

	const [searchParams] = useSearchParams();
	const creatorUserId = searchParams.get('creatorBlumeId') || null;

	const loadStudiesSeries = async ({ studies, studiesObjArr: sObjArr }) => {
		const studiesObjArr =
			sObjArr ||
			studies.map(study => ({
				studyInstanceUID: getStudyInstanceUID(study),
				managingOrganizationId: study.managingOrganizationId,
			}));
		const promises = studiesObjArr
			.filter(study => !loadingManagerRef.current.studySeries[study.studyInstanceUID])
			.map(async study => {
				loadingManagerRef.current.studySeries[study.studyInstanceUID] = true;

				return loadSeriesWithMetadata({
					__config,
					managingOrganizationId: study.managingOrganizationId,
					studyInstanceUID: study.studyInstanceUID,
					wonIvSeriesSplitter,
					wonIvDisablexasplit,
					wonIvEnhancedcurvedsort,
					wonIvRefactorsorting,
					wonIvStillsfirst,
					cacheLinks,
					wonIvPlaneSplitter,
					is3D: true,
					creatorUserId,
				});
			});

		const series = [];
		const seriesBatch = await Promise.all(promises);

		seriesBatch.forEach(item => series.push(...item));

		if (!series.length) {
			return;
		}

		postMessage({
			event: BROADCAST_EVENTS.STUDY_SERIES_UPDATE,
			value: series,
		});

		if (wonIvRefactorLoaddataZustand) {
			addNewSeries(series);
		} else {
			setSeries(prevState => [
				...prevState,
				...series.filter(
					ns =>
						!prevState.some(
							s =>
								s.studyInstanceUID === ns.studyInstanceUID &&
								s.uniqueId === ns.uniqueId &&
								s.managingOrganizationId === ns.managingOrganizationId
						)
				),
			]);
		}
	};

	const loadStudiesMeasurements = ({ studies, studiesObjArr: sObjArr }) => {
		const studiesObjArr =
			sObjArr ||
			studies.map(study => ({
				studyInstanceUID: getStudyInstanceUID(study),
				managingOrganizationId: study.managingOrganizationId,
			}));
		studiesObjArr
			.filter(study => !loadingManagerRef.current.studyMeasurements[study.studyInstanceUID])
			.forEach(study => {
				loadingManagerRef.current.studyMeasurements[study.studyInstanceUID] = true;

				loadMeasurements(measurementLoader, {
					StudyInstanceUID: study.studyInstanceUID,
					InternalManagingOrgId: study.managingOrganizationId,
				});
			});
	};

	const loadStudiesKeyImages = async ({ studies, studiesObjArr: sObjArr }) => {
		const studiesObjArr =
			sObjArr ||
			studies.map(study => ({
				studyInstanceUID: getStudyInstanceUID(study),
				managingOrganizationId: study.managingOrganizationId,
			}));
		const promises = studiesObjArr
			.filter(study => !loadingManagerRef.current.studyKeyImages[study.studyInstanceUID])
			.map(async study => {
				loadingManagerRef.current.studyKeyImages[study.studyInstanceUID] = true;

				return getKeyImages({
					__config,
					studyInstanceUID: study.studyInstanceUID,
					internalManagingId: study.managingOrganizationId,
				});
			});

		const keyImages = [];
		const keyImagesBatch = await Promise.all(promises);

		keyImagesBatch.forEach(item => keyImages.push(...item));

		if (!keyImages.length) {
			return;
		}

		await onSetKeyImages(prevState => [...prevState, ...keyImages]);
	};

	const loadStudiesData = async ({ studies, studiesObjArr }) => {
		await loadStudiesSeries({ studies, studiesObjArr });
		loadStudiesMeasurements({ studies, studiesObjArr });
		await loadStudiesKeyImages({ studies, studiesObjArr });
	};

	return {
		loadStudiesData,
		loadStudiesSeries,
		loadStudiesMeasurements,
		loadStudiesKeyImages,
	};
};

export default useLoadStudyData;
