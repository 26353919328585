// Core

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Material UI

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// Custom

import { lightTheme } from '@worklist-2/ui/src/themes';

import {
	FormToolBody,
	FormToolHeader,
	FormToolFieldset,
	FormToolGrid,
	FormToolTextField,
	FormToolDateField,
	FormToolSelectField,
	FormToolCheckboxes,
	FormToolCheckboxGrid,
	FormToolRadioGroupField,
	FormToolSurveyTable,
	FormToolSignature,
	FormToolSubheader,
} from '@worklist-2/ui/src/components/FormTool';
import { mediaDesktopL, mediaTablet, useAppModeContext, useConfig } from '@worklist-2/core/src';
import axios from 'axios';

import PDFFiller from '../PDFFiller/PDFFiller';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

// Component
// As of now, contains examples
const FormQuestionType = ({
	question,
	isReadOnly,
	handleInput,
	translate,
	formId,
	isCompact,
	userSignature,
	isEditActive,
	fromHistory,
	setPdfInstance,
	pdfUrl,
	pdfWidth,
	pdfHeight,
}) => {
	const __config = useConfig();
	const { isPatientPortalMode } = useAppModeContext();
	const appModeIsPatientPortal = isPatientPortalMode();
	const [fileUrl, setFileUrl] = useState(null);
	const handleInputData = (e, index) => {
		if (handleInput) {
			handleInput(question.unique_id, index, e.target.value);
		}
	};

	const handleDateInput = value => {
		if (handleInput) {
			handleInput(question.unique_id, null, value);
		}
	};

	const getBlobFile = useCallback(
		async (fileName, blobType) => {
			axios
				.get(
					appModeIsPatientPortal
						? `${__config.data_sources.blume}Form/attachment/${formId}?fileName=${fileName}`
						: `${__config.data_sources.fhir}/Form/attachment/${formId}?fileName=${fileName}`,
					{
						responseType: 'blob',
					}
				)
				.then(response => {
					if (response) {
						let options = {};
						if (blobType) {
							options = { type: blobType };
						}
						const urlFile = window.URL.createObjectURL(new Blob([response.data], options));
						setFileUrl(urlFile);
					}
				})
				.catch(err => {
					console.error(err);
				});
		},
		[__config, formId]
	);

	useEffect(() => {
		if (['uploadImage', 'video'].indexOf(question.type) > -1 && question?.description?.fileName) {
			getBlobFile(question.description.fileName);
		}
		if (['pdf'].indexOf(question.type) > -1 && question?.fileName) {
			if (pdfUrl) {
				setFileUrl(pdfUrl);
				return;
			}
			getBlobFile(question.fileName, 'application/pdf');
		}
	}, []);

	switch (question.type) {
		case 'multipleChoice':
			return (
				<FormToolFieldset error={question?.error} id={question?.id} isCompact={isCompact}>
					<FormToolRadioGroupField
						answer={question?.answer}
						description={question.description}
						isCompact={isCompact}
						isReadOnly={isReadOnly}
						options={question.options}
						questionType="multipleChoice"
						required={question.required}
						title={question.question}
						translate={translate}
						onChangeData={handleDateInput}
					/>
				</FormToolFieldset>
			);

		case 'multipleChoiceGrid':
			return (
				<FormToolFieldset error={question?.error} id={question?.id} isCompact={isCompact}>
					<FormToolSurveyTable
						answer={question?.answer}
						description={question.description}
						error={question?.error}
						isCompact={isCompact}
						isReadOnly={isReadOnly}
						options={question.answerOptions}
						questions={question.options}
						required={question.required}
						title={question.question}
						onChangeData={handleInputData}
					/>
				</FormToolFieldset>
			);

		case 'paragraph':
			return (
				<FormToolFieldset
					id={question?.id}
					isCompact={isCompact}
					required={question.required}
					title={question.question}
				>
					<FormToolTextField
						multiline
						defaultValue={
							question?.answer?.length > 0 ? question.answer[question.answer.length - 1]?.value : ''
						}
						disabled={isReadOnly}
						error={question?.error}
						isCompact={isCompact}
						maxRows={4}
						minRows={isCompact ? 1 : 4}
						onChange={e => handleInputData(e, null)}
					/>
				</FormToolFieldset>
			);

		case 'dropdown':
			return (
				<FormToolFieldset
					id={question?.id}
					isCentered={isCompact}
					isCompact={isCompact}
					isRow={isCompact}
					isSpaceBetween={isCompact}
					required={question.required}
					title={question.question}
				>
					<FormToolSelectField
						answer={question?.answer}
						disabled={isReadOnly}
						error={question?.error}
						isCompact={isCompact}
						label={translate('Select')}
						options={question.options}
						onChangeData={handleInputData}
					/>
				</FormToolFieldset>
			);

		case 'date':
			return (
				<FormToolFieldset
					id={question?.id}
					isCentered={isCompact}
					isCompact={isCompact}
					isRow={isCompact}
					isSpaceBetween={isCompact}
					required={question.required}
					title={question.question}
				>
					<FormToolGrid columns={isCompact ? 1 : 2} isCompact={isCompact} sx={{ width: '100%' }}>
						<FormToolDateField
							InputAdornmentProps={{
								position: 'start',
							}}
							answer={
								question?.answer?.length > 0 ? question.answer[question.answer.length - 1]?.value : ''
							}
							disabled={isReadOnly}
							error={question?.error}
							inputFormat="MM/dd/yyyy"
							isCompact={isCompact}
							label={isCompact ? '' : translate('mm/dd/yyyy')}
							onChangeData={handleDateInput}
						/>
					</FormToolGrid>
				</FormToolFieldset>
			);

		case 'shortAnswer':
			return (
				<FormToolFieldset
					id={question?.id}
					isCompact={isCompact}
					required={question.required}
					title={question.question}
				>
					{!!question.options &&
						question.options.map((option, shortAnswerIndex) => (
							<FormToolGrid key={`Grid-${shortAnswerIndex}`} columns={1} isCompact={isCompact}>
								<Stack
									direction="row"
									spacing={2}
									sx={{
										height: isCompact ? 'fit-content' : '50px',
										alignItems: isCompact && 'center',
									}}
									width="100%"
								>
									<Typography
										style={{
											whiteSpace: 'pre-line',
											color: '#000000',
											width: '60%',
											height: isCompact ? 'fit-content' : '50px',
											overflow: 'auto',
											fontSize: '14px',
											lineHeight: isCompact ? '16px' : '24px',
										}}
									>
										{option.description}
									</Typography>
									<FormToolTextField
										defaultValue={
											question?.answer?.length > 0
												? question.answer[question.answer.length - 1]?.value[shortAnswerIndex]
												: ''
										}
										disabled={isReadOnly}
										error={
											question?.error
												? !question?.indexErrors?.length ||
												  question?.indexErrors?.includes(shortAnswerIndex)
												: question?.error
										}
										isCompact={isCompact}
										placeholder={translate ? translate('Your Answer') : 'Your Answer'}
										style={{
											width: isCompact ? '50%' : '68%',
										}}
										sx={{
											'& .MuiOutlinedInput-root': {
												height: isCompact ? '40px' : '56px',
												color: 'rgba(0,0,0,1)',
												'.MuiInputBase-input.Mui-disabled': {
													color: 'rgba(0,0,0,0.26)',
												},
											},
										}}
										onChange={e => handleInputData(e, shortAnswerIndex)}
									/>
								</Stack>
							</FormToolGrid>
						))}
				</FormToolFieldset>
			);

		case 'checkbox':
			return (
				<FormToolFieldset error={question?.error} id={question?.id} isCompact={isCompact}>
					<FormToolCheckboxes
						answer={question?.answer}
						description={question.description}
						error={question?.error}
						isCompact={isCompact}
						isReadOnly={isReadOnly}
						options={question.options}
						required={question.required}
						title={question.question}
						translate={translate}
						onChangeData={handleDateInput}
					/>
				</FormToolFieldset>
			);

		case 'checkboxGrid':
			return (
				<FormToolFieldset error={question?.error} id={question?.id} isCompact={isCompact}>
					<FormToolCheckboxGrid
						answer={question?.answer}
						description={question.description}
						error={question?.error}
						isCompact={isCompact}
						isReadOnly={isReadOnly}
						options={question.answerOptions}
						questions={question.options}
						required={question.required}
						title={question.question}
						onChangeData={handleDateInput}
					/>
				</FormToolFieldset>
			);

		case 'signature':
			return (
				<FormToolFieldset
					isCompact
					error={question?.error}
					id={question?.id}
					required={question.required}
					title={question.question}
				>
					<FormToolSignature
						isCompact
						fromHistory={fromHistory}
						isEditActive={isEditActive}
						isReadOnly={isReadOnly}
						question={question}
						translate={translate}
						userSignature={
							userSignature?.length
								? userSignature
								: question?.answer?.length
								? question?.answer[0]?.value
								: null
						}
						onChangeData={handleDateInput}
					/>
				</FormToolFieldset>
			);

		case 'textBox':
			return (
				<FormToolFieldset
					id={question?.id}
					isCompact={isCompact}
					required={question.required}
					title={question.question}
				>
					<Typography
						style={{
							whiteSpace: 'pre-line',
							color: 'rgba(0, 0, 0, 0.6)',
							fontSize: isCompact && '14px',
						}}
					>
						{question.description}
					</Typography>
				</FormToolFieldset>
			);
		case 'uploadImage':
			return (
				<FormToolFieldset id={question?.id} required={question.required} title={question.question}>
					<Box
						height="100%"
						style={{
							width: '100%',
							position: 'relative',
						}}
					>
						<Box
							component="img"
							src={fileUrl}
							sx={{
								objectFit: 'contain',
								maxWidth: '640px',
							}}
						/>
					</Box>
				</FormToolFieldset>
			);
		case 'pdf':
			return (
				<Box
					sx={{
						position: 'relative',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<PDFFiller
						height={appModeIsPatientPortal ? '82vh' : pdfHeight}
						isReadOnly={isReadOnly}
						pdfFileName={question?.fileName}
						pdfSrc={fileUrl}
						setPdfInstance={setPdfInstance}
						showDownload={!(fromHistory || fromHistory === 0)} // do not display if view mode from history
						showPrint={!(fromHistory || fromHistory === 0)} // do not display if view mode from history
						width={pdfWidth || '100%'}
					/>
				</Box>
			);
		default:
			return null;
	}
};
const FormTool = ({
	formTitle,
	headerString,
	formDescription,
	colour,
	banner,
	logo,
	questionsData,
	handleFormData,
	onSave,
	onCloseClick,
	showCloseBtn = true,
	showBackBtn = false,
	isReadOnly = false,
	progressPercentage = '0%',
	noDefaultBanner,
	formId,
	pageRef,
	descriptionColor = 'currentColor',
	isValidForm,
	isCompact,
	sectionName = 'Section',
	fromHistory = false,
	setPdfInstance,
	pdfUrl,
	pdfWidth,
	pdfHeight,
	onSubmit,
}) => {
	const { t } = useTranslation('appointments');
	const { isPatientPortalMode } = useAppModeContext();
	const appModeIsPatientPortal = isPatientPortalMode();
	const isPdfForm = questionsData?.[0]?.[0]?.type === 'pdf';
	const phoenixBlumeViewFormsInFullScreen = useBooleanFlagValue('phoenix-blume-view-forms-in-full-screen');
	const formBodyHeight = fromHistory
		? isPdfForm
			? 'unset'
			: isReadOnly
			? 'calc(100vh - 155px)'
			: 'calc(100vh - 130px)'
		: 'calc(100vh - 90px)';
	return (
		<ThemeProvider theme={lightTheme}>
			<Box>
				<Stack
					alignItems="center"
					direction="row"
					sx={{
						'--element-progress-height': '4px',

						background: showBackBtn ? 'transparent' : '#ffffff',
						boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
						marginBottom: isPdfForm ? 0 : 'var(--element-progress-height)',
						padding: '15px 20px',
						position: 'relative',
					}}
				>
					{showBackBtn && (
						<Box sx={{ mr: '6px' }}>
							<IconButton id="go-back" onClick={onCloseClick}>
								<ArrowBackIcon sx={{ fontSize: 24, color: '#838383' }} />
							</IconButton>
						</Box>
					)}
					{!fromHistory && (
						<Typography
							sx={{
								color: '#838383',
								flex: 'auto',
								fontSize: '20px',
								fontWeight: 500,
							}}
							variant="h2"
						>
							{headerString}
						</Typography>
					)}

					{typeof onCloseClick === 'function' && showCloseBtn ? (
						<IconButton onClick={onCloseClick}>
							<Icon component={CloseIcon} />
						</IconButton>
					) : null}

					{!isPdfForm && (
						<Box
							sx={{
								background: phoenixBlumeViewFormsInFullScreen ? 'none' : 'var(--color-primary)',
								height: 'var(--element-progress-height)',
								left: '0',
								position: 'absolute',
								top: '100%',
								width: progressPercentage, // progress
							}}
						/>
					)}
				</Stack>

				<Stack
					sx={{
						alignItems: 'center',
						maxHeight: formBodyHeight,
						padding: !isPdfForm && fromHistory ? '0 20px' : 0,
						'& ::-webkit-scrollbar': {
							width: '8px',
							height: '8px',
						},
						'& ::-webkit-scrollbar-thumb': {
							backgroundColor: '#C4C4C4',
							borderRadius: '10px',
						},
						'& ::-webkit-scrollbar-track': {
							borderRadius: '10px',
							backgroundColor: '#c4c4c433',
						},
					}}
				>
					<FormToolBody
						isCompact={isCompact}
						sx={{
							minWidth: isPdfForm ? '100%' : '600px',
							[mediaDesktopL]: {
								padding: isPdfForm ? 0 : '24px 30px',
								maxWidth: isPdfForm ? '100%' : 'calc(100% - 60px)',
							},
							[mediaTablet]: {
								padding: isPdfForm ? 0 : '24px 0px',
								minWidth: '100%',
								maxWidth: '100%',
							},
							overflow: 'auto',
							paddingRight: '5px',
							padding: fromHistory ? 0 : '24px 0',
						}}
					>
						{questionsData?.map?.((page, pageIndex) => (
							<Stack
								key={pageIndex}
								ref={el => (pageRef && pageRef.current ? (pageRef.current[pageIndex] = el) : null)} // used for screenshot and pdf
								sx={{
									gap: '5px',
								}}
							>
								{pageIndex > 0 && (
									<Box>
										<Box
											sx={{
												position: 'relative',
												boxSizing: 'border-box',
												height: '50px',
												left: '0',
												top: '10px',
												zIndex: 1,
											}}
										>
											<Typography
												sx={{
													background: '#42A5F5',
													textTransform: 'uppercase',
													color: '#FFFFFF',
													fontSize: '16px',
													letterSpacing: '0.4px',
													borderRadius: '10px',
													whiteSpace: 'nowrap',
													padding: '10px 20px',
													width: 'fit-content',
													height: 'inherit',
												}}
											>
												{`${sectionName} ${pageIndex + 1}`}
											</Typography>
										</Box>
										<FormToolFieldset
											sx={{
												position: 'relative',
												left: '0',
												zIndex: 100,
												width: '100%',
												display: 'flex',
											}}
										>
											<FormToolSubheader
												isCompact
												description={questionsData[pageIndex][0]?.description}
												title={questionsData[pageIndex][0]?.question}
												titleId={`page-id-${pageIndex}`}
											/>
										</FormToolFieldset>
									</Box>
								)}
								{!isPdfForm && pageIndex === 0 && (
									<FormToolHeader
										backgroundColor={colour || '#3a6df1'}
										backgroundImageUrl={noDefaultBanner ? banner : banner || ''}
										description={formDescription}
										descriptionColor={descriptionColor}
										logo={logo}
										title={formTitle}
										titleColor="#ffffff"
									/>
								)}
								{page?.map((question, index) => (
									<FormQuestionType
										key={`Question-${pageIndex}-${index}`}
										formId={formId}
										fromHistory={fromHistory}
										handleInput={handleFormData}
										isCompact={isCompact}
										isReadOnly={isReadOnly}
										pdfHeight={pdfHeight}
										pdfUrl={pdfUrl}
										pdfWidth={pdfWidth}
										question={question}
										setPdfInstance={setPdfInstance}
										translate={t}
									/>
								))}
							</Stack>
						))}
						{onSave && !isReadOnly && (
							<Box
								sx={{
									display: fromHistory ? 'none' : 'flex',
									justifyContent: 'end',
								}}
							>
								<Button
									color="secondary"
									data-testid="next"
									disabled={fromHistory ? false : !isValidForm}
									id="submitFormBtn"
									sx={{
										width: '110px',
										height: '42px',
										borderRadius: '8px',
										color: '#FFF',
										background: 'rgb(66, 165, 245)',
										'&:hover': {
											background: 'rgb(144, 202, 249)',
										},
										'&.Mui-disabled': !appModeIsPatientPortal && {
											color: 'rgba(255, 255, 255, 0.3)',
											boxShadow: 'none',
											backgroundColor: 'rgba(255, 255, 255, 0.12)',
										},
									}}
									variant="contained"
									onClick={async () => {
										if (onSubmit) {
											const isSubmitted = await onSave();

											if ((!isPdfForm || (isPdfForm && isSubmitted)) && onSubmit) {
												onSubmit();
											}

											return;
										}

										await onSave();
									}}
								>
									<Typography
										style={{
											fontSize: '16px',
											fontWeight: '500',
											lineHeight: '19px',
											letterSpacing: '0.15em',
											textTransform: 'uppercase',
										}}
									>
										{t('SUBMIT')}
									</Typography>
								</Button>
							</Box>
						)}
					</FormToolBody>
				</Stack>
			</Box>
		</ThemeProvider>
	);
};

export default FormTool;
export { FormQuestionType };
