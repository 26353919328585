import React, { createContext, useEffect, useContext, useState } from 'react';
import axios from 'axios';

import { useAuth, useConfig } from '@worklist-2/core/src';
import loadAndAppendMetadataToSeries from '@rs-ui/views/ImageViewerView3D/api/loadAndAppendMetadataToSeries';
import { getSeriesUID } from '@rs-ui/views/ImageViewerView3D/utils/utils';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const CacheContext = createContext({});

export const CacheProvider = ({ children }) => {
	const __config = useConfig();
	const { accessToken, loggedInUser } = useAuth();
	const wonIvSeriesSplitter = useBooleanFlagValue('won-iv-series-splitter');
	const wonIvPlaneSplitter = useBooleanFlagValue('won-iv-plane-splitter');
	const sprinterAutoPreCacheImages = useBooleanFlagValue('sprinter-auto-pre-cache-images');
	const sprinterOmegaailinkWebcache = useBooleanFlagValue('sprinter-omegaailink-webcache');
	const [worker, setWorker] = useState(null);
	const [worklist, setWorklist] = useState([]);
	const [cachedStudyID, setCachedStudyID] = useState([]);
	const [cachedSeries, setCachedSeries] = useState([]);
	const [availableLinks, setAvailableLinks] = useState([]);

	const cacheWorkerURL = `${__config.omegaai_url}/scripts/cacheworker.js`;

	useEffect(() => {
		createWorker();
		return () => {
			worker?.terminate();
		};
	}, []);

	useEffect(() => {
		async function asynchronousEffect() {
			if (sprinterOmegaailinkWebcache && accessToken) {
				const response = await axios.get(`${__config.data_sources.fhir}/Device/link/localAddresses`, {
					headers: { Authorization: accessToken },
				});

				if (response?.status == 200 && Array.isArray(response?.data)) {
					setAvailableLinks(response.data);
				}
			} else if (!sprinterOmegaailinkWebcache) {
				setAvailableLinks([]);
			}
		}

		asynchronousEffect();
	}, [sprinterOmegaailinkWebcache, accessToken]);

	useEffect(() => {
		async function asynchronousEffect() {
			if (sprinterAutoPreCacheImages && loggedInUser && worklist && accessToken) {
				setTimeout(async () => {
					let count = 0;
					for (let index = 0; index < worklist.length; index++) {
						const element = worklist[index];
						const record = element;
						if (!cachedStudyID.find(study => study === record.id) && isValidStudy(record)) {
							count++;
							setCachedStudyID(prev => [...prev, record.id]);
							await getMetadata(record);
						}
						if (count >= 5) {
							break;
						}
					}
				}, 3000);
			}
		}

		asynchronousEffect();
	}, [worklist]);

	const createWorker = async () => {
		let res = false;
		try {
			if (!worker) {
				const w = new Worker(cacheWorkerURL);
				setWorker(w);
			}
			res = true;
		} catch (error) {
			console.error(error);
		}
		return res;
	};

	const postMessageToWorker = data => {
		worker && worker.postMessage(data);
	};

	const isValidStudy = data => {
		let ret = false;

		if (loggedInUser) {
			const loggedInUserID = loggedInUser.id.toString();
			const loggedInUserName = loggedInUser.fullName.toLowerCase();
			const loggedInUserDisplayName = loggedInUser.display.toLowerCase();

			const isPerformingPhysician =
				loggedInUserID == getPhysician(data, 'performingPhysicianId') ||
				loggedInUserName == getPhysician(data, 'performingPhysician').toLowerCase() ||
				loggedInUserDisplayName == getPhysician(data, 'performingPhysician').toLowerCase();

			const isReadingPhysician =
				loggedInUserID == getPhysician(data, 'readingPhysicianId') ||
				loggedInUserName == getPhysician(data, 'readingPhysician').toLowerCase() ||
				loggedInUserDisplayName == getPhysician(data, 'readingPhysician').toLowerCase();

			const isReferringPhysician =
				loggedInUserID == getPhysician(data, 'referringPhysicianId') ||
				loggedInUserName == getPhysician(data, 'referringPhysician').toLowerCase() ||
				loggedInUserDisplayName == getPhysician(data, 'referringPhysician').toLowerCase();

			ret = isPerformingPhysician || isReadingPhysician || isReferringPhysician;
		}

		return ret;
	};

	const getPhysician = (data, field) => data[field] || '';

	const prefetchImage = async (studyInstanceUid, internalManagingOrganizationID, seriesData) => {
		try {
			const newSeriesData = [];

			seriesData?.forEach(sd => {
				const seriesUID = getSeriesUID(sd);
				if (!cachedSeries.find(u => u === seriesUID)) {
					newSeriesData.push(sd);
				}
			});

			const params = {
				__config,
				headers: {},
				studyInstanceUID: studyInstanceUid,
				series: newSeriesData,
				wonIvSeriesSplitter,
				is3D: true,
				wonIvPlaneSplitter,
				cacheLinks: availableLinks,
				useCornerstone: false,
				managingOrganizationId: internalManagingOrganizationID,
			};

			const series = await loadAndAppendMetadataToSeries(params);

			if (series && Array.isArray(series) && series.length > 0) {
				for (const s of series) {
					const urls = [];
					if (s.imageIds && Array.isArray(s.imageIds) && s.imageIds.length > 0) {
						s.imageIds.forEach(image => {
							const frameUrl = image.replace('wadors:', '');
							urls.push(frameUrl);
						});
					}

					const seriesUID = getSeriesUID(s);
					if (!cachedSeries.find(u => u === seriesUID)) {
						setCachedSeries(prev => [...prev, seriesUID]);
						postMessageToWorker({
							token: accessToken,
							internalManagingOrganizationID,
							studyInstanceUID: studyInstanceUid,
							urls,
						});
						await new Promise(resolve => setTimeout(resolve, 100));
					}
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const nextStudy = async studyID => {
		if (sprinterAutoPreCacheImages && cachedStudyID.find(i => i === studyID) && accessToken) {
			for (let index = 0; index < worklist.length; index++) {
				const element = worklist[index];
				const record = element;
				if (!cachedStudyID.find(i => i === record?.id) && isValidStudy(record)) {
					setCachedStudyID(prev => [...prev, record?.id]);
					getMetadata(record);
					break;
				}
			}
		}
	};

	const getMetadata = async record => {
		const internalManagingOrganizationID = record?.internalManagingOrganizationID;
		let studyInstanceUid = record?.identifier?.find(item => item.system === 'urn:dicom:uid')?.value;
		studyInstanceUid = studyInstanceUid?.replace('urn:oid:', '');
		const url = `${__config.data_sources.dicom_web}/studies/${studyInstanceUid}/series`;
		const response = await axios.get(url, {});
		if (response?.data) {
			await prefetchImage(studyInstanceUid, internalManagingOrganizationID, response?.data);
		}
	};

	return (
		<CacheContext.Provider
			value={{
				setWorklist,
				nextStudy,
				cacheLinks: availableLinks,
			}}
		>
			{children}
		</CacheContext.Provider>
	);
};

export const useCache = () => useContext(CacheContext);
export default CacheProvider;
