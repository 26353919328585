import * as SeriesSplitter from './SeriesSplitter';
import ViewCode from './ViewCode';

class SeriesSplitManager {
	LeftBreast = 'L';
	RightBreast = 'R';
	splitters = [];
	wonIvDisablexasplit = false;

	constructor(wonIvPlaneSplitter, wonIvDwiseriessplitter, wonIvDisablexasplit) {
		this.wonIvDisablexasplit = wonIvDisablexasplit;

		this.splitters.push(new SeriesSplitter.MultiEchoSeriesSplitter());
		this.splitters.push(new SeriesSplitter.AnatomicRegionSeriesSplitter());
		this.splitters.push(new SeriesSplitter.PrimaryAnatomicStructureSeriesSplitter());
		this.splitters.push(new SeriesSplitter.SingleImageSeriesSplitter());
		this.splitters.push(new SeriesSplitter.CineSeriesDescriptorSplitter());
		this.splitters.push(new SeriesSplitter.EnhancedMultiFrameSplitter());
		this.splitters.push(new SeriesSplitter.FrameOfReferenceUIDSeriesSplitter());

		if (wonIvPlaneSplitter) {
			this.splitters.push(new SeriesSplitter.PlaneSplitter());
		}
		this.splitters.push(new SeriesSplitter.MultiPhaseAxialCTSplitter());
		this.splitters.push(new SeriesSplitter.DifferentDimensionsSplitter());
		if (wonIvDwiseriessplitter) {
			this.splitters.push(new SeriesSplitter.DiffusionSeriesSplitter());
		}
	}

	SplitSeries(seriesList) {
		let seriesListAfterSplit = seriesList;

		if (seriesList && seriesList.length > 0) {
			this.splitters.forEach((curSplitter) => {
				let newSeriesList = [];

				seriesList.forEach((series) => {
					const modalityCode = series?.['00080060']?.Value?.[0];

					// US Studies would apply Cine Splitter only, skip other splitters
					if (modalityCode == 'US' && !(curSplitter instanceof SeriesSplitter.CineSeriesDescriptorSplitter)) {
						newSeriesList.push(series);
					} // Only apply DiffusionSeriesSplitter on MR studies, skip other modalities
					else if (curSplitter instanceof SeriesSplitter.DiffusionSeriesSplitter && modalityCode != 'MR') {
						newSeriesList.push(series);
					} else if (modalityCode == 'SC') {
						//skip splitter if modality is SC
						newSeriesList.push(series);
					} else if (this.wonIvDisablexasplit && modalityCode == 'XA') {
						//skip splitter if modality is XA
						newSeriesList.push(series);
					} else {
						let splitFramesetList = curSplitter.SplitSeries(series);

						if (splitFramesetList && splitFramesetList.length > 0) {
							newSeriesList = newSeriesList.concat(splitFramesetList);
						} else {
							newSeriesList.push(series);
						}
					}
				});

				if (newSeriesList.length >= seriesListAfterSplit.length) {
					seriesListAfterSplit = newSeriesList;
				}

				if (curSplitter.constructor.name == 'SingleImageSeriesSplitter') {
					newSeriesList = this.SortMGSeries(seriesList);
				}
			});

			//Setup FramesetID
			let counter = [];

			seriesListAfterSplit.forEach((series) => {
				let seriesNumber = this.getSeriesNumber(series),
					instanceToken = series['metadata'],
					framesetIDPrefix = '';

				if (instanceToken) {
					let firstInstanceToken = instanceToken.at(0);

					if (firstInstanceToken) {
						const modalityCode = this.getModalityCode(series);

						if (modalityCode && modalityCode == 'MG') {
							let lateralityToken = this.getImageLaterality(firstInstanceToken),
								viewCodeToken = this.getViewCode(firstInstanceToken);

							if (!lateralityToken && !viewCodeToken) {
								if (seriesNumber) {
									framesetIDPrefix = seriesNumber;
								} else {
									framesetIDPrefix = '1';
								}
							} else {
								framesetIDPrefix =
									(lateralityToken ?? '') + (viewCodeToken ? this.GetMGViewLabel(viewCodeToken) : '');
							}
						} else {
							if (seriesNumber) {
								framesetIDPrefix = seriesNumber;
							} else {
								framesetIDPrefix = '1';
							}
						}
						counter.push(framesetIDPrefix);

						series['frameSetID'] =
							framesetIDPrefix + '.' + counter.filter((c) => c == framesetIDPrefix).length;
					}
				}
			});
		}

		return seriesListAfterSplit;
	}

	GetMGViewLabel(viewCodeLiteral) {
		const viewCode = ViewCode.find((v) => v.code == viewCodeLiteral);
		return viewCode.description;
	}

	SortMGSeries(seriesList, DisplayMGViewsInAnatomicalPosition = false) {
		let FirstLaterality = DisplayMGViewsInAnatomicalPosition ? this.LeftBreast : this.RightBreast,
			NextLaterality = DisplayMGViewsInAnatomicalPosition ? this.RightBreast : this.LeftBreast,
			CurImageLaterality = '',
			FindLaterality = '';

		for (let i = 0; i < seriesList.length; i++) {
			seriesList[i]['frameSetID'] = i.toString();
		}

		let SeriesListToAppend = [],
			SeriesListLeftOvers = seriesList,
			CurSeriesIndex = 0,
			AppendedSeries = false,
			IncrementIndex = false;

		seriesList.every(() => {
			{
				if (CurSeriesIndex >= SeriesListLeftOvers.length) {
					return false;
				}

				AppendedSeries = false;
				IncrementIndex = true;
				var CurSeries = SeriesListLeftOvers.at(CurSeriesIndex);

				let instanceToken = CurSeries['metadata'];

				if (instanceToken) {
					let firstInstanceToken = instanceToken.at(0),
						modalityCode = this.getModalityCode(CurSeries);

					if (firstInstanceToken && modalityCode && modalityCode == 'MG') {
						let lateralityToken = this.getImageLaterality(firstInstanceToken);
						if (lateralityToken) {
							CurImageLaterality = lateralityToken;

							if (CurImageLaterality == FirstLaterality) {
								SeriesListToAppend.push(CurSeries);
								AppendedSeries = true;
								FindLaterality = NextLaterality;
							} else {
								FindLaterality = FirstLaterality;
							}

							let viewModifierCode = this.getViewModifierCode(firstInstanceToken) ?? '',
								viewCode = this.getViewCode(firstInstanceToken),
								MatchingSeries = SeriesListLeftOvers.map((t) =>
									this.IsMatching(t, viewCode, viewModifierCode, FindLaterality)
								).find((t) => t);

							if (MatchingSeries) {
								SeriesListLeftOvers = SeriesListLeftOvers.filter(
									(t) => t['frameSetID'] != MatchingSeries['frameSetID']
								);
								SeriesListToAppend.push(MatchingSeries);
							}

							if (CurImageLaterality == NextLaterality) {
								SeriesListToAppend.push(CurSeries);
								AppendedSeries = true;
							}

							if (AppendedSeries) {
								SeriesListLeftOvers = SeriesListLeftOvers.filter(
									(t) => t['frameSetID'] != CurSeries['frameSetID'].toString()
								);
								IncrementIndex = false;
							}
						}
					}
				}

				if (IncrementIndex) {
					CurSeriesIndex++;
				}

				return true;
			}
		});

		SeriesListLeftOvers.forEach((s) => {
			SeriesListToAppend.push(s);
		});

		for (let i = 0; i < SeriesListToAppend.length; i++) {
			SeriesListToAppend.at(i)['frameSetID'] = '';
		}

		return SeriesListToAppend;
	}

	SortByInstanceNumber(seriesList) {
		seriesList.forEach((series) => {
			series.metadata = series.metadata.sort((a, b) => {
				const firstInstanceNumber = parseInt(a?.['00200013']?.Value?.[0]);
				const secondInstanceNumber = parseInt(b?.['00200013']?.Value?.[0]);

				return firstInstanceNumber - secondInstanceNumber;
			});
		});

		return seriesList;
	}

	IsMatching = (seriesToken, viewCode, viewModifierCode, laterality) => {
		let matching = false,
			modalityCode = this.getModalityCode(seriesToken);

		if (modalityCode && modalityCode == 'MG') {
			let instanceToken = seriesToken['metadata'];
			if (instanceToken) {
				let firstInstanceToken = instanceToken.at(0);
				if (firstInstanceToken) {
					let lateralityToken = this.getImageLaterality(firstInstanceToken),
						viewCodeToken = this.getViewCode(firstInstanceToken),
						viewModifierCodeToken = this.getViewModifierCode(firstInstanceToken);

					matching =
						!!lateralityToken &&
						!!viewCodeToken &&
						lateralityToken == laterality &&
						viewCodeToken == viewCode;

					if (matching && viewModifierCode) {
						matching = !!viewModifierCodeToken && viewModifierCodeToken == viewModifierCode;
					}
				}
			}
		}

		return matching ? seriesToken : null;
	};

	getSeriesNumber(seriesJSon) {
		return seriesJSon?.['00200011']?.Value?.[0];
	}

	getModalityCode(seriesJSon) {
		return seriesJSon?.['00080060']?.Value?.[0];
	}

	getImageLaterality(seriesJSon) {
		return seriesJSon?.['00200062']?.Value?.[0];
	}

	getViewCode(seriesJSon) {
		return seriesJSon?.['00540220']?.Value?.find((x) => x[this.CODE_VALUE])?.[this.CODE_VALUE]?.Value?.[0];
	}

	getViewModifierCode(seriesJSon) {
		return seriesJSon?.['00540222']?.Value?.[0];
	}
}

export default SeriesSplitManager;
