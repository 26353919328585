import _ from 'lodash';

const getLocationData = extJson => {
	const extData = JSON.parse(extJson);
	const address = extData.address[0];
	const locationName = _.get(address, 'line[0]', 'Unknown Location');
	const formattedAddress = `${address?.line[0]}, ${address?.city}, ${address?.state}, ${address?.country}, ${address?.postalCode}`;

	return { name: locationName, address: formattedAddress };
};

export default getLocationData;
