import { fhirExtensionUrls } from '@worklist-2/core/src/fhir/extension';

import {
	checkIsReservation,
	checkIsBlockedTime,
	extractAppointmentInfo,
	getAvailableWorkingSlotsForDay,
	checkAppointmentAndServiceModalityMatches,
} from './appointmentInfoHelpers';

import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import addMinutes from 'date-fns/addMinutes';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import format from 'date-fns/format';
import { enUS } from 'date-fns/locale';
import _ from 'lodash';

export const getCalendarSuggestedTimes = (selectedParkingCard, columnAppointments, service, day) => {
	const availableTimes = getCalendarAvailableTimes(selectedParkingCard, columnAppointments, service, day);
	return availableTimes.length ? [truncateGapAccordingAppointment(availableTimes[0], selectedParkingCard)] : [];
};

export const getCalendarAvailableTimes = (selectedParkingCard, columnAppointments, service, day) => {
	const isModalityMatches = checkAppointmentAndServiceModalityMatches(selectedParkingCard, service);

	if (!isModalityMatches) return [];

	const currentTime = new Date();
	const availableWorkingSlotsForDay = getAvailableWorkingSlotsForDay(service, day);
	const relatedAppointments = getAppointmentsForWorkingTime(columnAppointments, day);
	const gaps = findAvailableTimeGaps(currentTime, availableWorkingSlotsForDay[0], relatedAppointments, service);

	return gaps.filter(gap => gap.duration >= selectedParkingCard?.duration);
};

export const getAppointmentsForWorkingTime = (appointments, day) => {
	const endOfTheDay = endOfDay(day);
	const startOfTheDay = startOfDay(day);

	// Get the appointments related to working time of the provided day
	return appointments
		.filter(a => {
			try {
				return areIntervalsOverlapping(a, {
					start: startOfTheDay,
					end: endOfTheDay,
				});
			} catch (error) {
				return false;
			}
		})
		.map(a => {
			const start = isBefore(a.start, startOfTheDay) ? startOfTheDay : a.start;
			const end = isAfter(a.end, endOfTheDay) ? endOfTheDay : a.end;

			delete a.colIndex;
			delete a.widthDivider;
			delete a.expandedWidthDivider;
			delete a.maximumConcurrentEvents;
			delete a.overlapingEventIds;

			return {
				...a,
				end,
				start,
				duration: differenceInMinutes(end, start),
			};
		})
		.sort((a, b) => a.start.getTime() - b.start.getTime());
};

export const findAvailableTimeGaps = (currentTime, workingTime, appointments, service) => {
	const gaps = [];

	if (isAfter(currentStart, workingTime?.endDate)) return [];

	let currentStart = isBefore(currentTime, workingTime?.startDate) ? workingTime?.startDate : currentTime;

	const isAllowedOverbooking = service?.extension?.find(
		({ url }) => url === fhirExtensionUrls.healthcareService.overBooking
	)?.valueBoolean;

	// Iterate over the appointments related to this working time
	for (const appointment of appointments) {
		const { start, end } = appointment;

		// Check if there is a gap between the current appointment and the previous one
		if (start > currentStart) {
			const duration = differenceInMinutes(start, currentStart);
			gaps.push({ start: currentStart, end: start, duration });
		}

		// Update the current start time to the end of the current appointment
		currentStart = isBefore(end, currentTime) || isAllowedOverbooking ? currentTime : end;
	}

	// Check if there is a gap between the last appointment and the end of the working time
	if (currentStart < workingTime?.endDate) {
		const duration = differenceInMinutes(workingTime?.endDate, currentStart);
		gaps.push({ start: currentStart, end: workingTime?.endDate, duration });
	}

	return gaps;
};

export const getServicesSuggestedTime = services => {
	if (!services?.length || !services?.[0]?.suggestedTimes?.length) return;

	const { start, end } = services[0]?.suggestedTimes[0];
	const startTime = new Date(start);
	const endTime = new Date(end);
	const formattedDate = format(startTime, 'eee, MMM d', { locale: enUS });

	const formattedStartTime = format(startTime, 'hh:mm a', { locale: enUS });
	const formattedEndTime = format(endTime, 'hh:mm a', { locale: enUS });
	const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;

	return { date: formattedDate, range: formattedTimeRange, startTime, endTime, service: services[0] };
};

export const truncateGapAccordingAppointment = (gap, selectedParkingCard) => {
	const _gap = _.cloneDeep(gap);
	_gap.duration = selectedParkingCard.duration;
	_gap.end = addMinutes(_gap.start, selectedParkingCard.duration);

	return _gap;
};

export const isDateAfterCurrentTime = (currentTime, date) => {
	const endDate = endOfDay(date);
	return isAfter(endDate, currentTime);
};

export const isDateValid = date => {
	// Check undefined, Invalid Date, invalid year and null
	return !isNaN(date) && date instanceof Date && date?.getFullYear()?.toString()?.length == 4;
};

export const checkAppointmentsTimeOverlap = (appointment, appointmentToCompare) => {
	if (!appointment || !appointmentToCompare) return;

	const { start, end } = appointment;
	const { start: startToCompare, end: endToCompare } = appointmentToCompare;

	const _start = new Date(start);
	const _end = new Date(end);
	const _startToCompare = new Date(startToCompare);
	const _endToCompare = new Date(endToCompare);

	const isStartOverlap =
		(isAfter(_startToCompare, _start) && isBefore(_startToCompare, _end)) ||
		(isAfter(_start, _startToCompare) && isBefore(_start, _endToCompare));
	const isEndOverlap =
		(isAfter(_endToCompare, _start) && isBefore(_endToCompare, _end)) ||
		(isAfter(_end, _startToCompare) && isBefore(_end, _endToCompare));
	const isStartOrEndEqual = isEqual(_startToCompare, _start) || isEqual(_endToCompare, _end);

	return isStartOverlap || isEndOverlap || isStartOrEndEqual;
};

export const checkReserveConflictTime = (reserve, appointments) => {
	if (!reserve || !appointments?.length) return;

	return appointments.some(apt => {
		const isTimeOverlap = checkAppointmentsTimeOverlap(reserve, apt);
		const isNotSameReserve = apt?.id !== reserve?.id;
		const isConflictEventType = checkIsBlockedTime(reserve) !== checkIsBlockedTime(apt); // overlapping block booking is allowed

		return isTimeOverlap && isNotSameReserve && isConflictEventType;
	});
};

export const checkAppointmentsForSpecificTime = (day, hours, minutes, appointments) => {
	const start = new Date(day);
	start.setHours(hours);
	start.setMinutes(minutes);
	start.setSeconds(0);

	return appointments?.some(apt => {
		return (
			isAfter(start, apt?.start) &&
			isBefore(start, apt?.end) &&
			!checkIsReservation(apt) &&
			!checkIsBlockedTime(apt)
		);
	});
};

export const checkReserveForSpecificTime = (day, hours, minutes, appointments) => {
	const start = new Date(day);
	start.setHours(hours);
	start.setMinutes(minutes);
	start.setSeconds(0);

	return appointments?.some(apt => {
		const isTimeOverlap = isAfter(start, apt?.start) && isBefore(start, apt?.end);
		const isStartEqual = isEqual(apt?.start, start);
		return (isTimeOverlap || isStartEqual) && checkIsReservation(apt);
	});
};

// Validate appointment time against blocked time at the same healthcare service
export const isAppointmentTimeConflicted = (calendarData, startDateTime, endDateTime, service) =>
	isEventDateTimeConflicted(calendarData, startDateTime, endDateTime, service, 'appointment');

// Validate reservation time against blocked time and existing reservations at the same healthcare service
export const isReservationTimeConflicted = (calendarData, reservationData, startDateTime, endDateTime, service) =>
	isEventDateTimeConflicted(calendarData, startDateTime, endDateTime, service, 'reservation', reservationData);

// Validate blocked time against reservations at the same healthcare service
export const isBlockedTimeConflicted = (calendarData, startDateTime, endDateTime, service) =>
	isEventDateTimeConflicted(calendarData, startDateTime, endDateTime, service, 'blockedTime');

export const isEventDateTimeConflicted = (calendarData, startDateTime, endDateTime, service, eventType, eventData) => {
	let isConflicted = false;
	if (
		Array.isArray(calendarData) &&
		calendarData.length > 0 &&
		isDateValid(startDateTime) &&
		isDateValid(endDateTime)
	) {
		const conflictAppointments = calendarData?.filter(
			event =>
				event?.participant?.some(item => item?.actor?.id === service?.id) &&
				new Date(event.start).toDateString() === startDateTime?.toDateString() &&
				event?.extension?.find(({ url }) =>
					eventType === 'appointment'
						? url === fhirExtensionUrls.appointment.blockedTime
						: eventType === 'reservation'
						? url === fhirExtensionUrls.appointment.blockedTime ||
						  (url === fhirExtensionUrls.appointment.reservation && eventData?.id !== event.id)
						: url === fhirExtensionUrls.appointment.reservation
				)?.valueBoolean
		);
		const aptStart = new Date(
			endDateTime?.getFullYear(),
			startDateTime?.getMonth(),
			startDateTime?.getDate(),
			startDateTime.getHours(),
			startDateTime.getMinutes()
		).getTime();
		const aptEnd = new Date(
			endDateTime?.getFullYear(),
			endDateTime?.getMonth(),
			endDateTime?.getDate(),
			endDateTime.getHours(),
			endDateTime.getMinutes()
		).getTime();

		isConflicted = conflictAppointments?.some(event => {
			const eventStart = new Date(event?.start).getTime();
			const eventEnd = new Date(event?.end).getTime();

			return aptStart < eventEnd && aptEnd > eventStart;
		});
	}

	return isConflicted;
};

export const checkAppointmentsDateTimeEqual = (appointment, appointmentToCompare) => {
	if (!appointment || !appointmentToCompare) return;

	const { start, end } = appointment;
	const { start: startToCompare, end: endToCompare } = appointmentToCompare;

	return isEqual(start, startToCompare) && isEqual(end, endToCompare);
};

export const getAppointmentsForTimeRange = (start, end, appointments, service, ignoreAppts = []) => {
	if (!appointments?.length || !service) return [];

	const range = { start, end };

	return appointments?.filter(apt => {
		const { healthcareServiceId } = extractAppointmentInfo(apt);
		const isIgnoreItem = ignoreAppts.map(i => i?.id).includes(apt?.id);
		return checkAppointmentsTimeOverlap(range, apt) && service?.id === healthcareServiceId && !isIgnoreItem;
	});
};
