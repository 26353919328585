import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
const App = lazy(() => import('./App.jsx'));
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
	ConfigProvider,
	UserAuthProvider,
	AppModeProvider,
	SelectorContextProvider,
	HelpCenterContextProvider,
	MultiscreenProvider,
	CacheProvider,
	PatientAlertsProvider,
	ImportTabProvider,
} from '@worklist-2/core/src';
import { darkTheme, ChatGlobalContextProvider } from '@worklist-2/ui';

const HelpPopOver = lazy(() => import('@worklist-2/ui/src/views/HelpView/HelpCenter/HelpPopOver'));
import { CypressHistorySupport } from 'cypress-react-router';
const UnauthenticatedPage = lazy(() =>
	import(/* webpackChunkName: "UnauthenticatedPage" */ './routes/UnauthenticatedPage')
);
import { ThemeProvider } from '@mui/material/styles';

// Libraries
import { datadogRum } from '@datadog/browser-rum';
import i18n from './i18n.js';
import moment from 'moment';
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { OpenFeatureProvider, OpenFeature } from '@openfeature/react-sdk';
import DevCycleProvider from '@devcycle/openfeature-web-provider';

moment.locale(navigator.language || 'en'); // set user's browser language as global locale for moment

const config = JSON.parse(process.env.NODE_CONFIG);

if (!config.data_dog?.applicationId.includes('DATADOG_APPLICATION_ID')) {
	datadogRum.init({
		applicationId: config.data_dog.applicationId,
		clientToken: config.data_dog.clientToken,
		site: config.data_dog.site,
		service: 'omegaai', // config.data_dog.service,
		env: config.data_dog.env,
		sessionSampleRate: 100,
		sessionReplaySampleRate: 100, // if not included, the default is 100
		trackResources: true,
		trackLongTasks: true,
		trackUserInteractions: true,
		version: config.data_dog.version,
		defaultPrivacyLevel: 'allow',
		allowedTracingUrls: [config.data_sources.fhir],
		enableExperimentalFeatures: ['feature_flags'],
	});

	datadogRum.startSessionReplayRecording();
}

/**
 *
 * @returns application router based on pathname
 * We need termsofservice and privacy policy seprate from theentire application
 */
const getApplication = () => {
	let pathname = window.location.pathname.toLocaleLowerCase();
	let unautherticatedUtls = ['/termsofservice', '/privacypolicy', '/region', '/language', '/news', '/helppopup'];
	if (unautherticatedUtls.includes(pathname)) {
		return (
			<Router basename={config.router_basename}>
				<HelpCenterContextProvider
					PopOverComponent={
						<Suspense fallback={null}>
							<HelpPopOver />
						</Suspense>
					}
				>
					<ThemeProvider theme={darkTheme}>
						<SelectorContextProvider>
							<Routes>
								<Route
									path="termsOfService"
									element={
										<Suspense fallback={null}>
											<UnauthenticatedPage page="terms" />
										</Suspense>
									}
								/>
								<Route
									path="privacyPolicy"
									element={
										<Suspense fallback={null}>
											<UnauthenticatedPage page="privacy" />
										</Suspense>
									}
								/>
								<Route
									path="region"
									element={
										<Suspense fallback={null}>
											<UnauthenticatedPage page="region" />
										</Suspense>
									}
								/>
								<Route
									path="language"
									element={
										<Suspense fallback={null}>
											<UnauthenticatedPage page="language" />
										</Suspense>
									}
								/>
								<Route
									path="news"
									element={
										<Suspense fallback={null}>
											<UnauthenticatedPage page="news" />
										</Suspense>
									}
								/>
								<Route
									path="helppopup"
									element={
										<Suspense fallback={null}>
											<UnauthenticatedPage page="helppopup" />
										</Suspense>
									}
								/>
							</Routes>
						</SelectorContextProvider>
					</ThemeProvider>
				</HelpCenterContextProvider>
			</Router>
		);
	} else {
		return (
			<Router basename={config.router_basename}>
				<CypressHistorySupport />
				<AppModeProvider>
					<ImportTabProvider>
						<ChatGlobalContextProvider>
							<Suspense fallback={null}>
								<UserAuthProvider>
									<PatientAlertsProvider>
										<MultiscreenProvider>
											<CacheProvider>
												<App />
											</CacheProvider>
										</MultiscreenProvider>
									</PatientAlertsProvider>
								</UserAuthProvider>
							</Suspense>
						</ChatGlobalContextProvider>
					</ImportTabProvider>
				</AppModeProvider>
			</Router>
		);
	}
};

const msalConfig = {
	auth: {
		clientId: config.auth.omegaai.msal.client_id,
		authority: config.auth.omegaai.msal.authority,
		// most likely going to be /home
		redirectUri: config.auth.omegaai.msal.redirect_uri,
		knownAuthorities: config.auth.omegaai.msal.known_authorities,
	},
	cache: {
		cacheLocation: config.auth.omegaai.msal.cache.location,
		storeAuthStateInCookie:
			// this should almost always be false...I'm almost positive setting this to true breaks everything
			config.auth.omegaai.msal.cache.store_auth_state_in_cookie,
	},
	system: {
		//https://learn.microsoft.com/en-us/entra/identity-platform/msal-logging-js#logging-levels
		loggerOptions: {
			logLevel: LogLevel.Error,
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
				}
			},
			piiLoggingEnabled: false,
		},
		allowRedirectInIframe: true,
	},
};

let userContext;

try {
	const pca = new PublicClientApplication(msalConfig);

	if (pca) {
		const activeUser = pca.getActiveAccount();

		if (activeUser) {
			userContext = {
				kind: 'multi',
				user: {
					name: activeUser.name?.trim(),
					key: activeUser.username?.toUpperCase(),
					email: activeUser.username,
				},
				entity: {
					name: `${config.resource_group}`.trim(),
					key: `${config.resource_group}`.trim().toUpperCase(),
				},
			};
		} else {
			userContext = {
				kind: 'multi',
				entity: {
					name: `${config.resource_group}`.trim(),
					key: `${config.resource_group}`.trim().toUpperCase(),
				},
			};
		}
	} else {
		userContext = {
			kind: 'multi',
			entity: {
				name: `${config.resource_group}`.trim(),
				key: `${config.resource_group}`.trim().toUpperCase(),
			},
		};
	}
} catch (error) {
	console.error(error);
}

let devCycleUserContext;

try {
	const pca = new PublicClientApplication(msalConfig);

	if (pca) {
		const activeUser = pca.getActiveAccount();

		if (activeUser) {
			devCycleUserContext = {
				user_id: activeUser.username?.toUpperCase(),
				name: activeUser.name?.trim(),
				email: activeUser.username?.toUpperCase(),
				customData: {
					entity: `${config.resource_group}`.trim().toUpperCase(),
				},
			};
		} else {
			devCycleUserContext = {
				user_id: 'DEFAULT',
				customData: {
					entity: `${config.resource_group}`.trim().toUpperCase(),
				},
			};
		}
	} else {
		devCycleUserContext = {
			user_id: 'DEFAULT',
			customData: {
				entity: `${config.resource_group}`.trim().toUpperCase(),
			},
		};
	}
} catch (error) {
	console.error(error);
}

const devCycleProvider = new DevCycleProvider(config.devCycleKey, {
	disableRealtimeUpdates: true,
});

OpenFeature.setContext(devCycleUserContext);
await OpenFeature.setProviderAndWait(devCycleProvider);

const devCycleClient = devCycleProvider.devcycleClient;
devCycleClient?.subscribe('variableEvaluated:*', (key, variable) => {
	datadogRum.addFeatureFlagEvaluation(key, variable.value);
});

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
	ReactDOM.hydrate(
		<React.StrictMode>
			<OpenFeatureProvider suspend={true}>
				<Suspense fallback={null}>
					<ConfigProvider>{getApplication()}</ConfigProvider>
				</Suspense>
			</OpenFeatureProvider>
		</React.StrictMode>,
		rootElement
	);
} else {
	ReactDOM.render(
		<React.StrictMode>
			<OpenFeatureProvider suspend={true}>
				<Suspense fallback={null}>
					<ConfigProvider>{getApplication()}</ConfigProvider>
				</Suspense>
			</OpenFeatureProvider>
		</React.StrictMode>,
		rootElement
	);
}
