import { useConfig } from '@worklist-2/core/src/context/ConfigContext';
import axios from 'axios';

export const MERGE_PATIENT_HEADERS = {
	'Synchronous-MergePatient': true,
};

export const useMergePatient = () => {
	const __config = useConfig();

	const mergePatient = (
		selectedResources,
		selectedMergeStudy,
		replacedFields = [],
		resourceType = 'ImagingStudy'
	) => {
		const keepPatientId =
			resourceType === 'ImagingStudy' ? selectedMergeStudy.subject.id : selectedMergeStudy.patientInternalID;

		const url = `${
			__config.data_sources.fhir
		}/patient/merge/?keepPatientId=${keepPatientId}&replacePatientId=${selectedResources
			.filter(s =>
				resourceType === 'ImagingStudy'
					? s.id !== selectedMergeStudy.id
					: s.patientInternalID !== selectedMergeStudy.patientInternalID
			)
			.map(s => (resourceType === 'ImagingStudy' ? s.subject.id : s.patientInternalID))}`;

		return axios.post(url, replacedFields, { headers: MERGE_PATIENT_HEADERS });
	};

	return mergePatient;
};
