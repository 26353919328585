import { v4 as uuid } from 'uuid';
import { useImageViewerLayoutContext } from '../contexts/ImageViewerLayoutContext';
import { useSearchParams } from 'react-router-dom';
import { useImageViewerMultiMonitorContext } from '../contexts/ImageViewerMultiMonitorContext';
import { useOnSetLayoutItems } from './useOnSetLayoutItems';
import { ramsoft } from '@cornerstonejs/core';
import { renderingEngineId } from '../contexts/ImageViewerCornerstoneContext';
import useResetActiveTool from './useResetActiveTool';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

export const useAssignImageToLayout = () => {
	const [searchParams] = useSearchParams();
	const studyInstanceUID = searchParams.get('StudyInstanceUIDs');
	const wonIvCustomtoolbar = useBooleanFlagValue('WON-IV-CUSTOMTOOLBAR');
	const {
		isMPRView,
		isFusionView,
		setMPR,
		previousLayout,
		setIsFusionView,
		setIsMPRView,
		setLayoutSize,
		layoutItems,
		activeViewportId,
		highlightedViewport,
		setHighlightedViewport,
		setActiveViewportId,
	} = useImageViewerLayoutContext();
	const { screenNumber: screenNumberIndex } = useImageViewerMultiMonitorContext();

	const { onSetLayoutItems } = useOnSetLayoutItems();
	const { resetActiveTool } = useResetActiveTool();

	const onDragDrop = ({
		layoutItemId,
		draggableSeries,
		imageIndex = 0,
		screenNumber = screenNumberIndex,
		isKeyImage,
	}) => {
		if (!draggableSeries) {
			return;
		}

		if (isMPRView || isFusionView) {
			if (isMPRView && draggableSeries.isAvailableMPR) {
				return setMPR({
					layoutItem: { series: draggableSeries },
				});
			}
			previousLayout.current = null;

			const newLayoutItem = {
				id: uuid(),
				rows: 1,
				columns: 1,
				series: draggableSeries,
				studyInstanceUID: draggableSeries?.studyInstanceUID || studyInstanceUID,
				initialInstanceIndex: imageIndex,
				rules: [],
				viewports: [
					{
						id: uuid(),
					},
				],
				screenNumber,
			};

			if (isFusionView) {
				setIsFusionView(false);
			} else {
				setIsMPRView(false);
			}

			setLayoutSize({
				rows: 1,
				columns: 1,
			});
			onSetLayoutItems([newLayoutItem]);

			return;
		}

		let changedLayoutItem;
		onSetLayoutItems(prev =>
			prev.map(layoutItem => {
				if (layoutItem.id === layoutItemId) {
					if (layoutItem.viewports?.find(viewport => viewport.id === activeViewportId)) {
						resetActiveTool();
					}

					const count = layoutItem.rows * layoutItem.columns;
					const isTileMode = count > 1 && layoutItem.series?.imageIds?.length > 1;
					const canSetTileMode = !isKeyImage && draggableSeries?.imageIds?.length > 1;
					if (isTileMode && canSetTileMode) {
						return {
							...layoutItem,
							id: uuid(),
							series: draggableSeries,
							studyInstanceUID: draggableSeries?.studyInstanceUID || studyInstanceUID,
							initialInstanceIndex: imageIndex,
							viewports: Array(count)
								.fill()
								.map(() => ({ id: uuid() })),
							screenNumber,
						};
					}

					const getSeries = () => {
						if (isKeyImage && draggableSeries) {
							return {
								...draggableSeries,
								imageIds: [draggableSeries.imageIds[imageIndex]],
							};
						}

						return draggableSeries;
					};

					changedLayoutItem = {
						...layoutItem,
						id: uuid(),
						rows: 1,
						columns: 1,
						series: getSeries(),
						studyInstanceUID: draggableSeries?.studyInstanceUID || studyInstanceUID,
						initialInstanceIndex: isKeyImage ? 0 : imageIndex,
						viewports: [
							{
								id: uuid(),
							},
						],
						screenNumber,
						isKeyImage,
						keyImageIndex: imageIndex,
					};

					return changedLayoutItem;
				}
				return layoutItem;
			})
		);

		if (highlightedViewport && highlightedViewport.id === layoutItemId && changedLayoutItem) {
			setHighlightedViewport(changedLayoutItem);
		} else {
			wonIvCustomtoolbar && setActiveViewportId(null);
		}

		setTimeout(() => {
			ramsoft.resize?.({ renderingEngineId });
		}, 100);
	};

	const onSeriesDoubleClick = (series, index, isKeyImage) => {
		const emptyLayoutItem = layoutItems?.find(layoutItem => !layoutItem.series);
		if (emptyLayoutItem) {
			onDragDrop({ layoutItemId: emptyLayoutItem.id, imageIndex: index, draggableSeries: series, isKeyImage });
		} else {
			const currentLayoutItem = layoutItems?.find(layoutItem =>
				layoutItem.viewports.find(viewport => viewport.id === activeViewportId)
			);

			const newItem = currentLayoutItem ? currentLayoutItem.id : layoutItems?.[0]?.id;
			const screenNumber = currentLayoutItem ? currentLayoutItem.screenNumber : layoutItems?.[0]?.screenNumber;

			onDragDrop({ layoutItemId: newItem, imageIndex: index, draggableSeries: series, screenNumber, isKeyImage });
		}
	};

	return {
		onDragDrop,
		onSeriesDoubleClick,
	};
};
