/* Generated automagically by FhirStarter - but please feel free to edit this file */
import { fhirExtensionUrls } from '../../extension';
import { getExtensionValueString } from './utils';
const ServiceRequestMapping = {
	identifier: {
		label: 'Identifier',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.identifier;
		},
	},
	id: {
		label: 'Id',
		options: { filter: false, sort: false },
		searchParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.id;
		},
	},
	patientName: {
		label: 'Patient Name',
		getDisplay: resource => {
			return resource?.subject.display;
		},
	},
	patientId: {
		label: 'Patient Name',
		getDisplay: resource => {
			return resource?.subject.id;
		},
	},
	accessionNumber: {
		label: 'Accession #',
		getDisplay: resource => {
			return resource?.identifier.find(elem => elem?.type?.coding?.some(item => item.code === 'ACSN'))?.value;
		},
		searchParameter: 'accessionNumber',
	},
	fillerOrderNumber: {
		label: 'Filler Order #',
		getDisplay: resource => {
			return resource?.identifier.find(elem => elem?.type?.coding?.some(item => item.code === 'FILL'))?.value;
		},
	},
	referringPhysician: {
		label: 'Referring Physician',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'referringPhysician',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.common.referringPhysician);
		},
	},
	referringFacilityID: {
		label: 'Referring Facility ID',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'referringFacilityID',
		getDisplay: resource => {
			const requester = resource.requester;
			if (requester?.reference?.includes('Organization')) {
				return requester.id;
			}
		},
	},
	cancellationReason: {
		label: 'Cancellation Reason',
		options: { filter: false, sort: false },
		filterType: 'single-select',
		searchParameter: 'cancellationReason',
		getDisplay: resource => {
			return getExtensionValueString(resource, fhirExtensionUrls.procedureRequest.cancellationReason);
		},
	},
	instantiatesCanonical: {
		label: 'Instantiates Canonical',
		options: { filter: false, sort: false },
		searchParameter: 'instantiatesCanonical',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.instantiatesCanonical;
		},
	},
	instantiatesUri: {
		label: 'Instantiates Uri',
		options: { filter: false, sort: false },
		searchParameter: 'instantiatesUri',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.instantiatesUri;
		},
	},
	basedOn: {
		label: 'Based On',
		options: { filter: false, sort: false },
		searchParameter: 'basedOn',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.basedOn;
		},
	},
	replaces: {
		label: 'Replaces',
		options: { filter: false, sort: false },
		searchParameter: 'replaces',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.replaces;
		},
	},
	requisition: {
		label: 'Requisition',
		options: { filter: false, sort: false },
		searchParameter: 'requisition',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.requisition;
		},
	},
	status: {
		label: 'Status',
		options: { filter: false, sort: false },
		searchParameter: 'status',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.status.toUpperCase();
		},
	},
	encounterId: {
		label: 'Encounter ID',
		options: { filter: false, sort: false },
		searchParameter: 'encounter',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.encounter.id;
		},
	},
	reasonCodes: {
		label: 'Reason Code',
		options: { filter: false, sort: false },
		searchParameter: 'reasonCode',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.reasonCode?.map(reasonCode => reasonCode?.coding[0]);
		},
	},
	orderDateTime: {
		label: 'Order Date/Time',
		options: { filter: false, sort: false },
		searchParameter: 'occurrenceDateTime',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.occurrenceDateTime;
		},
	},
	consultingPhysicians: {
		label: 'Consulting Physicians',
		options: { filter: false, sort: false },
		searchParameter: 'consultingPhysician',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.consultingPhysician;
		},
	},
	intent: {
		label: 'Intent',
		options: { filter: false, sort: false },
		searchParameter: 'intent',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.intent;
		},
	},
	category: {
		label: 'Category',
		options: { filter: false, sort: false },
		searchParameter: 'category',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.category;
		},
	},
	priority: {
		label: 'Priority',
		options: { filter: false, sort: false },
		searchParameter: 'priority',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return capitalizeFirstLetter(resource?.priority);
		},
	},
	doNotPerform: {
		label: 'Do Not Perform',
		options: { filter: false, sort: false },
		searchParameter: 'doNotPerform',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.doNotPerform;
		},
	},
	code: {
		label: 'Code',
		options: { filter: false, sort: false },
		searchParameter: 'code',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.code;
		},
	},
	orderDetail: {
		label: 'Order Detail',
		options: { filter: false, sort: false },
		searchParameter: 'orderDetail',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.orderDetail;
		},
	},
	quantityQuantity: {
		label: 'Quantity Quantity',
		options: { filter: false, sort: false },
		searchParameter: 'quantityQuantity',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.quantityQuantity;
		},
	},
	quantityRatio: {
		label: 'Quantity Ratio',
		options: { filter: false, sort: false },
		searchParameter: 'quantityRatio',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.quantityRatio;
		},
	},
	quantityRange: {
		label: 'Quantity Range',
		options: { filter: false, sort: false },
		searchParameter: 'quantityRange',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.quantityRange;
		},
	},
	subject: {
		label: 'Subject',
		options: { filter: false, sort: false },
		searchParameter: 'subject',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.subject;
		},
	},
	occurrencePeriod: {
		label: 'Occurrence Period',
		options: { filter: false, sort: false },
		searchParameter: 'occurrencePeriod',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.occurrencePeriod;
		},
	},
	occurrenceTiming: {
		label: 'Occurrence Timing',
		options: { filter: false, sort: false },
		searchParameter: 'occurrenceTiming',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.occurrenceTiming;
		},
	},
	asNeededBoolean: {
		label: 'As Needed Boolean',
		options: { filter: false, sort: false },
		searchParameter: 'asNeededBoolean',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.asNeededBoolean;
		},
	},
	asNeededCodeableConcept: {
		label: 'As Needed Codeable Concept',
		options: { filter: false, sort: false },
		searchParameter: 'asNeededCodeableConcept',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.asNeededCodeableConcept;
		},
	},
	authoredOn: {
		label: 'Authored On',
		options: { filter: false, sort: false },
		searchParameter: 'authoredOn',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.authoredOn;
		},
	},
	requester: {
		label: 'Requester',
		options: { filter: false, sort: false },
		searchParameter: 'requester',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.requester;
		},
	},
	performerType: {
		label: 'Performer Type',
		options: { filter: false, sort: false },
		searchParameter: 'performerType',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.performerType;
		},
	},
	performer: {
		label: 'Performer',
		options: { filter: false, sort: false },
		searchParameter: 'performer',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.performer;
		},
	},
	locationCode: {
		label: 'Location Code',
		options: { filter: false, sort: false },
		searchParameter: 'locationCode',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.locationCode;
		},
	},
	locationReference: {
		label: 'Location Reference',
		options: { filter: false, sort: false },
		searchParameter: 'locationReference',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.locationReference;
		},
	},
	reasonReference: {
		label: 'Reason Reference',
		options: { filter: false, sort: false },
		searchParameter: 'reasonReference',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.reasonReference;
		},
	},
	insurance: {
		label: 'Insurance',
		options: { filter: false, sort: false },
		searchParameter: 'insurance',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.insurance;
		},
	},
	supportingInfo: {
		label: 'Supporting Info',
		options: { filter: false, sort: false },
		searchParameter: 'supportingInfo',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.supportingInfo;
		},
	},
	specimen: {
		label: 'Specimen',
		options: { filter: false, sort: false },
		searchParameter: 'specimen',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.specimen;
		},
	},
	bodySite: {
		label: 'Body Site',
		options: { filter: false, sort: false },
		searchParameter: 'bodySite',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.bodySite;
		},
	},
	note: {
		label: 'Note',
		options: { filter: false, sort: false },
		searchParameter: 'note',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.note;
		},
	},
	patientInstruction: {
		label: 'Patient Instruction',
		options: { filter: false, sort: false },
		searchParameter: 'patientInstruction',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.patientInstruction;
		},
	},
	relevantHistory: {
		label: 'Relevant History',
		options: { filter: false, sort: false },
		searchParameter: 'relevantHistory',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.relevantHistory;
		},
	},
};

const capitalizeFirstLetter = string => {
	return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export default ServiceRequestMapping;
