import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import GenericDrawer from '../GenericDrawer';

import { MergeDrawerCardsWrap, MergeDrawerContentSection } from './styles';
import {
	MergeDrawerButtons,
	MergePatientDrawerBasicTooltip,
	MergePatientDrawerCard,
	MergePatientDrawerMatchNameTooltip,
} from './partials';

import { useSelection } from '@worklist-2/core/src';
import { useMergePatient } from './hooks';

const drawerWidth = 487;

const findDifferentFields = array => {
	const fieldNames = ['patientName', 'managingOrganization', 'patientID', 'birthDate', 'gender'];
	const differentFields = [];

	for (const fieldName of fieldNames) {
		const valueSet = new Set();

		for (const item of array) {
			const value = fieldName === 'patientName' ? item.subject?.display : item[fieldName];
			valueSet.add(value);

			if (valueSet.size > 1) {
				differentFields.push(fieldName);
				break;
			}
		}
	}

	return differentFields;
};

export const getSelectedStudiesToRemove = (selectedResources, mergeStudyId) =>
	selectedResources.filter(s => s.id !== mergeStudyId).map(s => ({ id: s.id }));

export const MergePatientDrawer = ({
	drawerOpen,
	setDrawerOpen,
	t,
	toastUtility,
	setStudiesToRemove,
	...otherProps
}) => {
	const { selectedResources, selectedMergeStudy, handleResourceReset } = useSelection();
	const [isMergeAlreadyClicked, setMergeAlreadyClicked] = useState(false);

	const isCardListVisible = selectedResources.length > 0;

	const highlightedFields = useMemo(() => findDifferentFields(selectedResources), [selectedResources]);

	const mergePatient = useMergePatient();

	const handleMerge = () => {
		if (!isMergeAlreadyClicked && highlightedFields.length) {
			setMergeAlreadyClicked(true);

			mergePatient(selectedResources, selectedMergeStudy)
				.then(res => {
					if (res?.status !== 200) throw new Error();

					toastUtility(true, t('workList:mergePatientToast'));

					handleResourceReset();

					const studiesToRemove = getSelectedStudiesToRemove(selectedResources, selectedMergeStudy.id);
					setStudiesToRemove(studiesToRemove);

					setMergeAlreadyClicked(false);
					setDrawerOpen(false);
				})
				.catch(err => {
					toastUtility(true, t('Failed to merge patients'));
					setMergeAlreadyClicked(false);
				});
		}
	};

	return (
		<>
			{isCardListVisible && (
				<GenericDrawer
					headerDividerFlag
					showBackBtn
					customHeaderTextProps={{
						variant: 'h6',
						sx: {
							fontSize: '20px',
							lineHeight: '24px',
							letterSpacing: '0.15px',
							mr: 'auto',
						},
					}}
					drawerOpen={drawerOpen}
					setDrawerOpen={setDrawerOpen}
					title={t('workList:selectedResourcesTooltip.mergePatient')}
					width={drawerWidth}
					{...otherProps}
				>
					<MergeDrawerContentSection>
						<MergeDrawerCardsWrap>
							{isCardListVisible &&
								selectedResources.map((i, idx) => (
									<MergePatientDrawerCard
										key={i.id + idx}
										cardStyles={{ width: '75%', mb: '17px' }}
										data={i}
										highlightedFields={highlightedFields}
										t={t}
									/>
								))}
						</MergeDrawerCardsWrap>
					</MergeDrawerContentSection>

					<MergeDrawerButtons
						isActive={selectedMergeStudy}
						t={t}
						tooltip={
							isMergeAlreadyClicked || !highlightedFields.length
								? MergePatientDrawerBasicTooltip
								: MergePatientDrawerMatchNameTooltip
						}
						onSubmit={handleMerge}
					/>
				</GenericDrawer>
			)}
		</>
	);
};

MergePatientDrawer.propTypes = {
	/**
	 * Lifted State - determines if the drawer is open or not
	 */
	drawerOpen: PropTypes.bool,
	/**
	 * Setter function for the drawerOpen state
	 */
	setDrawerOpen: PropTypes.func,
};
