// core
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// libraries
import {
	eventSubscribe,
	eventUnsubscribe,
	getColumnMapping,
	scopeMapping,
	searchScopes,
	TWILIO_SYNC_EVENT_TYPE,
	useAuth,
	useCache,
	useColumnList,
	useConfig,
	useFhirDataLoader,
	useSelection,
} from '@worklist-2/core/src';
import { localFiltering, isWithinTimeWindow } from '@worklist-2/ui/src/components/utils/gridUtils';

import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { ErrorBoundary } from 'react-error-boundary';

//mui
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

// grid
import ErrorResult from '@worklist-2/ui/src/components/ErrorResult';
import { loadValueSet } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';
import { useTranslation } from 'react-i18next';
import { MemoizedDataGrid as DataGrid } from '../../DataGrid/DataGrid';
import { WorklistWheel } from '@worklist-2/ui/src/components/WorklistWheel/WorklistWheel';
import GridToolbar from '../GridToolbar';
import { useWorklist } from '@rs-ui/components/Worklist/Worklist/contexts/WorklistContext';
import { CUSTOM_FILTER_OPTIONS_LIST, WORKLIST_GRID_COLUMN_VALUESET_EXCLUDE_LIST } from '@rs-ui/helpers/constants';
import { logDebug } from '@rs-core/utils/logger';

const RsGrid = ({
	name,
	children,
	exactMatch,
	selectableRows,
	scope,
	title,
	columns,
	setCurrWorklistColumns,
	searchExtra,
	staticFilters,
	tableBodyHeight = 'calc(100vh - 160px)',
	gridHeight = 'calc(100vh - 160px)',
	wheelMenuItems,
	filters,
	setFilters,
	columnSizing,
	setColumnSizing,
	ready,
	setReady,
	sort,
	setSort,
	isSingleSort,
	onRowSelect,
	onWheelClick,
	onWheelRowSelect,
	onColumnOrderChange,
	canResizeColumns,
	setRowsToRemove,
	rowsToRemove,
	enableRowDnd,
	enableRowClick,
	setParsedStudiesIds,
	extraColumns,
	setDataGridRefValue,
	gridToolBarChildren,
	onDoubleClickInRow,
	doubleClickTimeout,
	enableCustomStudyPriority,
	gridCustomHeader,
	showGridToolbar,
	onScrollChange,
	scrollPosition,
}) => {
	const __config = useConfig();
	// this will be used selected record for mouse wheel
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [filterMenuItems, setFilterMenuItems] = useState({});
	const dataGridRef = useRef(null);
	const doubleClickRef = useRef(null);
	const loadDataRef = useRef(null);
	const { setWorklist } = useCache();
	const { t } = useTranslation('root');
	const cacheKey =
		filters || sort || searchExtra
			? filters
					?.map(f => f.label + f.values + f.searchParameter)
					.join('-')
					.concat(sort?.join('+'))
					.concat(JSON.stringify(searchExtra))
			: '';

	const [gridColumns, setGridColumns] = useState(columns);

	const { authorized, userRoleForOrganizations } = useAuth();

	const dataLoader = useFhirDataLoader({
		scope,
	});
	const fhirDataLoaderValueSet = useFhirDataLoader({
		scope: searchScopes.valueSet,
	});
	// the total number of records we've loaded
	const [totalRecords, setTotalRecords] = useState(0);

	// used to track if a page has been loaded for the first time so that we can decide whether
	// to display a skeleton or a loading circle when obtaining rows
	const [readyForData, setReadyForData] = setReady ? [ready, setReady] : useState(false);

	const [parsedData, setParsedData] = useState([]);

	const columnMapping = useMemo(() => getColumnMapping(scopeMapping[scope]), [scope]);

	const [shouldUpdateAfterFiltersChanged, setShouldUpdateAfterFiltersChanged] = useState(false);
	const [lastAppliedFilters, setLastAppliedFilters] = useState([]);
	const { selectedResources = [] } = useSelection();

	const [enableDownloadButton, setEnableDownloadButton] = useState(false);

	const wheelExists = !!wheelMenuItems;
	const wheelMenuItemsVisible = wheelMenuItems?.filter(item => item.visible);

	const [searchedManagingOrg, setSearchedManagingOrg] = useState(null);

	useEffect(() => {
		let syncEventProcessor;
		let eventQueue = {};

		const listener = e => {
			logDebug('Grid', 'Twilio Sync Event Received', e.detail);

			const { syncObjects } = e.detail;
			syncObjects.forEach(syncObject => {
				const { id, isActive } = syncObject;
				/*
					Based on the study creation date time, we can tell if this study is been added recently or not.
					We only need to add newly created studies into the worklist using Flag isAdding:
					Do not insert unnecessary updated records in the grid.
				*/
				const isNew = isWithinTimeWindow(syncObject.data?.createdDateTimeUTC);

				const studyRes = localFiltering(syncObject.data, filters, columnMapping);

				// data could be filtered out so studyRes will be null
				eventQueue[id] = {
					data: studyRes,
					id: syncObject.id,
					isAdding: isNew,
					isDeleting: !isActive,
				};
			});
		};
		// need to refactor, but shouldn't perform syncing unless it's the Worklist Grid
		if (scope === searchScopes.all) {
			eventSubscribe(TWILIO_SYNC_EVENT_TYPE.IMAGING_STUDY, listener);

			const processEvents = () => {
				const events = Object.values(eventQueue);

				if (events?.length > 0) {
					dataGridRef?.current?.batchInsertUpdateRows(events, {});
				}

				eventQueue = {}; // Clear the eventQueue after processing
			};

			syncEventProcessor = setInterval(processEvents, 5000);
		}

		return () => {
			if (syncEventProcessor) {
				clearInterval(syncEventProcessor);
			}
			eventUnsubscribe(TWILIO_SYNC_EVENT_TYPE.IMAGING_STUDY, listener);
		};
	}, [dataGridRef?.current?.insertUpdateRow, dataGridRef?.current?.batchInsertUpdateRows, sort]);

	useEffect(() => {
		if (!rowsToRemove) {
			return;
		}

		dataGridRef?.current?.removeRows(rowsToRemove);
		setRowsToRemove(null);
	}, [rowsToRemove, dataGridRef?.current?.removeRows]);

	useEffect(() => {
		if (setDataGridRefValue) {
			setDataGridRefValue(dataGridRef);
		}
	}, [dataGridRef?.current, dataGridRef?.current?.insertUpdateRow]);

	useEffect(() => {
		if (authorized && !_.isEmpty(gridColumns) && name !== 'teaching-folder') {
			setReadyForData(true);
		}
	}, [authorized, gridColumns]);

	useEffect(() => {
		// There could be cases where columns are loaded asynchronously - we should not continue in that case
		if (!_.isEmpty(columns)) {
			// we always want the id; this allows us to persist attributes between loads, rather than treating
			// each row as a new item every time we fetch data... the id column is always hidden
			if (!_.includes(columns, 'id')) {
				setGridColumns(['id', ...columns]);
			} else {
				setGridColumns(columns);
			}
		}
	}, [columns]);

	useEffect(() => {
		if (wheelExists) {
			document.addEventListener('click', event => {
				try {
					const ignoreClickOnMeElement = document.getElementById(`wheel-wrapper`);
					const isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
					const clickedElement = event.target;
					if (
						!isClickInsideElement &&
						!clickedElement.getAttribute('class').includes('MuiTableCell') &&
						!clickedElement.getAttribute('data-mui-internal-clone-element')
					) {
						if (wheelExists) {
							setWheelOpen(false);
							setSelectedRow(null);
						}
					}
				} catch (error) {
					if (wheelExists) {
						setWheelOpen(false);
						setSelectedRow(null);
					}
				}
			});
		}
	}, []);

	useEffect(() => {
		const isFiltersChanged = !_.isEqual(filters, lastAppliedFilters);

		if (filters && isFiltersChanged) {
			setShouldUpdateAfterFiltersChanged(true);
			setLastAppliedFilters([...filters]);
		} else {
			setShouldUpdateAfterFiltersChanged(false);
		}
	}, [filters]);

	// New method for DataGrid to handle fetching data. Data storage is handled by the DataGrid component itself,
	loadDataRef.current = (pageNum, pageSize) => {
		localStorage.setItem('worklistFilter', JSON.stringify([...(filters || []), ...(staticFilters || [])]));
		localStorage.setItem('worklistStartCount', 0);

		return dataLoader
			.load({
				page: pageNum,
				count: pageSize,
				value: [...(filters || []), ...(staticFilters || [])],
				sort: [...(sort || [])],
				extraValue: searchExtra,
				exactMatch: !!exactMatch,
			})
			.then(res => {
				if (name == 'worklist') {
					// this can cause a re-render so we may need to optimize
					setWorklist(res || []);
				}
				return res || [];
			});
	};

	const getValueSet = async (valueSetType, displayValue = 'display', capitalizeOptions = false, extraParams = {}) =>
		loadValueSet(fhirDataLoaderValueSet, valueSetType, displayValue, capitalizeOptions, ['display'], extraParams);

	// load value set for top level filter menu
	useEffect(() => {
		const selectedOrg = filters?.find(f => f.searchParameter === 'internalManagingOrganizationID')?.values;

		// set the managing org when selecting a specific org from the filter
		setSearchedManagingOrg(selectedOrg);

		_.map(filters, item => {
			if (item.searchParameter?.length > 0) {
				const searchValue = item?.searchValueSet || item?.searchParameter;
				const menuItems = filterMenuItems;

				// In case the logged user belongs to only one organization, use custom study statuses from this organization for the filter panel
				const managingOrgId =
					userRoleForOrganizations?.length === 1 ? userRoleForOrganizations[0].organizationId : selectedOrg;

				// only load if menuitem doesn't contains valueset for the searchValue
				if (!menuItems[searchValue] && !WORKLIST_GRID_COLUMN_VALUESET_EXCLUDE_LIST.includes(item.label)) {
					if (enableCustomStudyPriority && searchValue === 'priority' && managingOrgId) {
						axios
							.get(`${__config.data_sources.fhir}/Priority?organization=${managingOrgId}&_sort=priority`)
							.then(result => {
								if (result?.data?.entry) {
									menuItems[searchValue] = result.data.entry.map(e => ({
										code: e.resource.id,
										display: e.resource.display,
									}));
								}
							});
					} else {
						if (CUSTOM_FILTER_OPTIONS_LIST.includes(searchValue)) {
							menuItems[searchValue] = item?.customFilterOptions;
							setFilterMenuItems(menuItems);
							return;
						}
						if (!filterMenuItems[searchValue]) {
							getValueSet(
								searchValue,
								['accountStatus', 'requeststatus'].includes(searchValue) ? 'code' : 'display',
								false,
								item?.valueSetExtraParam
							).then(res => {
								if (!menuItems[searchValue]) {
									menuItems[searchValue] = res;
									// For statusIndicator, the value list is not in coodPool, it's defined in ImagingStudyWorklistMapping
									if (searchValue === 'statusIndicator') {
										menuItems[searchValue] = item?.customFilterOptions;
									}
									setFilterMenuItems(menuItems);
								}
							});
						}
					}
				}
			}
		});
	}, [filters, userRoleForOrganizations]);

	// load value set for filter related to the managing organization
	useEffect(() => {
		const menuItems = filterMenuItems;
		const managingOrgId =
			searchedManagingOrg ||
			(userRoleForOrganizations?.length === 1 ? userRoleForOrganizations?.[0]?.organizationId : null);

		if (managingOrgId) {
			axios
				.get(
					`${__config.data_sources.fhir}/studyStatus?organization=${managingOrgId}&_sort=statusValue&active=true`
				)
				.then(result => {
					if (result?.data?.entry) {
						const statusItems = result.data.entry.map(e => ({
							code: e.resource.id,
							display: e.resource.status,
						}));
						menuItems.status = statusItems;
						menuItems.studyStatus = statusItems;
					}
				});
		}

		setFilterMenuItems(menuItems);
	}, [searchedManagingOrg]);

	const handleRowClick = useCallback((data, rowIndex, event) => {
		// set page start count for calling next study in Document Viewer
		const nearestMultiple = Math.floor(rowIndex / 50) * 50;
		localStorage.setItem('worklistStartCount', nearestMultiple);

		if (onRowSelect) {
			onRowSelect(data);
		}
		event.preventDefault();
		event.stopPropagation();

		setSelectedRecord(data);
		data.resourceType === 'ImagingStudyWorklist' && setVisitedWorklist(data.id);

		// Checking # of Clicks
		if (event.detail === 2) {
			doubleClickRef.current && clearTimeout(doubleClickRef.current);
			setWheelOpen(false);
			const imageViewerItem = {
				label: 'Image Viewer',
				icon: 'imageviewer',
				visible: false,
			};
			onWheelClick(imageViewerItem, data);
			onDoubleClickInRow();
		} else {
			setSelectedRow(rowIndex);

			setWheelXPos(event.clientX);
			setWheelYPos(event.clientY);
			doubleClickRef.current = setTimeout(() => {
				setWheelOpen(true);
			}, doubleClickTimeout);

			onWheelRowSelect?.(data.internalManagingOrganizationID, data.referringFacilityId);
		}
	}, []);

	useEffect(() => {
		// When columns are changed, remove filters for columns that are no longer visible
		if (gridColumns?.length > 0) {
			setFilters(f => _.filter(f, e => gridColumns.includes(e.columnIdentity)));
		}
	}, [gridColumns]);

	const { columnArray: colList, filtersFunc } = useColumnList({
		scope,
		columns: gridColumns,
		filters,
		setFilters,
		sortOrder: sort,
		setSort,
		isSingleSort,
		columnSizing,
		enableCustomStudyPriority,
		gridCustomHeader,
	});

	const columnList = useMemo(() => {
		if (colList?.length > 0 && extraColumns && gridColumns?.length == colList?.length) {
			// iterate through actionButtons, then call createColumn for each item. Depending on the index, insert it into the columnList. If no index is present, add it to the end
			extraColumns.forEach(column => {
				if (column?.index >= 0) {
					colList.splice(column.index, 0, column);
				} else {
					colList.push(column);
				}
			});
		}

		// adding valueset option list to filter menu items so we dont need to load again after filter changes
		colList.forEach(cl => {
			const optionList = cl?.header?.props?.itemList;

			if (!filterMenuItems[cl.name] && optionList?.length > 0) {
				filterMenuItems[cl.name] = optionList;
			}
		});

		return colList;
	}, [colList, extraColumns, filterMenuItems]);

	// Use to clear the input value of Suggest Filter
	const clearSuggestFilter = col => {
		const elem = col.fieldRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];

		if (elem) {
			elem.click();
		}
	};

	// Clear column filter values
	const clearColumnFilterValues = col => {
		switch (col.filterType) {
			case 'date-time': {
				// Select the reset button
				const labelText = `[data-cy="${col.label}_filter_reset"]`;
				const elem = document?.querySelector(labelText);
				elem?.click();
				break;
			}
			case 'text-search': {
				const labelText = `[data-cy="${col.label}_clear"]`;
				const elem = document?.querySelector(labelText);
				elem?.click();
				break;
			}
			case 'date-range': {
				const labelText = `[data-cy="${col.label}_filter"]`;
				const elem = document?.querySelector(labelText)?.getElementsByTagName('input')[0];
				elem.value = null;
				const resetElem = document?.querySelector(`[data-cy="${col.label}_dateTimeReset"]`);
				resetElem?.click();
				break;
			}
			case 'suggest': {
				const selector = document?.querySelector(`[data-cy="${col.label}_filter"]`);
				const elem = selector?.getElementsByTagName('input')[0];
				// Click X button to clear the search value in the textbox in case the filter has not been saved yet
				const buttonElem = selector?.getElementsByTagName('button')[0];
				buttonElem?.click();
				elem.blur(); // Lose focus on the column header
				break;
			}
			default: {
				const buttonElem = document
					.querySelector(`[data-cy="${col.label}_filter"]`)
					?.getElementsByTagName('button')[0];
				if (buttonElem?.title === 'Clear') {
					buttonElem.click();
				}
			}
		}
	};

	const clearColumnFilter = col => {
		if (col.fieldRef?.current) {
			if (col.fieldRef.current?.value) {
				col.fieldRef.current.value = '';
			}

			// Remove the input value of Suggest filter
			clearSuggestFilter(col);
		}
		clearColumnFilterValues(col);
	};

	const removeFilter = name => {
		filtersFunc(name, []);
		const idx = _.findIndex(columnList, elem => elem.name === name);

		if (idx >= 0) {
			const col = columnList[idx];
			clearColumnFilter(col);
		}
	};

	const handleClearFilters = () => {
		setFilters([]);
		columnList.forEach(col => clearColumnFilter(col));
		// reset sort as well
		setSort && setSort([]);
	};

	const onDownloadClick = target => {
		dataGridRef.current.handleExport(target);
	};

	useEffect(() => {
		if (parsedData.length) {
			const idsArr = [];
			parsedData.map(item => {
				idsArr.push({ id: item.id?.props.text, modality: item.modality?.props.text });
			});
			setParsedStudiesIds([...idsArr]);
		}
	}, [parsedData]);

	const handleWheelClick = useCallback(
		item => {
			if (onWheelClick) {
				onWheelClick(item, selectedRecord);
			}
		},
		[selectedRecord]
	);

	const handleWheelClose = useCallback(() => {
		setWheelOpen(false);
		setSelectedRow(null);
	}, [selectedRow]);

	const [wheelXPos, setWheelXPos] = useState(-100);
	const [wheelYPos, setWheelYPos] = useState(-100);
	const { wheelOpen, setWheelOpen, visitedWorklist, setVisitedWorklist } = useWorklist();

	const gridId = useMemo(() => _.uniqueId('omegaai-grid'), []);

	useEffect(() => {
		setParsedData(data => data);
	}, [gridId]);

	return (
		<ErrorBoundary FallbackComponent={<ErrorResult />}>
			{readyForData ? (
				<Box
					className="app-content"
					data-testid="new-worklist-grid"
					sx={{ pl: name === 'study-history' || name === 'teaching-folder' ? '0px' : '24px' }}
				>
					{showGridToolbar && (
						<GridToolbar
							capsules={filters}
							enableDownloadButton={enableDownloadButton}
							filterMenuItems={filterMenuItems}
							gridName={title}
							scope={scope}
							sort={sort}
							totalRows={totalRecords}
							onCapsuleFilter={filtersFunc}
							onClearFilters={handleClearFilters}
							onDownloadClick={onDownloadClick}
							onRemoveCapsule={removeFilter}
						>
							{gridToolBarChildren ?? gridToolBarChildren}
						</GridToolbar>
					)}
					<DataGrid
						ref={dataGridRef}
						cacheKey={cacheKey}
						canResizeColumns={canResizeColumns}
						columnSizing={columnSizing}
						columns={columnList}
						enableRowDnd={enableRowDnd}
						gridCustomHeader={gridCustomHeader}
						height={gridHeight}
						name={name}
						scrollPosition={scrollPosition}
						selectedResources={selectedResources}
						selectedRow={selectedRow}
						setColumnSizing={setColumnSizing}
						setCurrWorklistColumns={setCurrWorklistColumns}
						setEnableDownloadButton={setEnableDownloadButton}
						setTotalRecords={setTotalRecords}
						shouldUpdateAfterFiltersChanged={shouldUpdateAfterFiltersChanged}
						visitedWorklist={visitedWorklist}
						onLoadDataRef={loadDataRef}
						onRowClick={enableRowClick && handleRowClick}
						onScrollChange={onScrollChange}
						onUpdateAfterFiltersChanged={setShouldUpdateAfterFiltersChanged}
					/>
				</Box>
			) : (
				<>
					<Skeleton
						style={{
							height: 32,
							paddingTop: '18px',
							paddingBottom: '18px',
							marginLeft: '24px',
							marginRight: '0px',
						}}
						variant="text"
					/>
					<Skeleton
						style={{
							height: tableBodyHeight,
							paddingTop: '0px',
							paddingBottom: '0px',
							marginLeft: '24px',
							marginRight: '0px',
						}}
						variant="rectangular"
					/>
				</>
			)}

			{/* WORKLIST WHEEL */}
			<div id="wheel-wrapper">
				{wheelMenuItemsVisible?.length > 0 ? (
					<WorklistWheel
						data-cy="wheeloption"
						isOpen={wheelOpen}
						items={wheelMenuItemsVisible}
						position="fixed"
						x={wheelXPos}
						y={wheelYPos}
						onClick={handleWheelClick}
						onClose={handleWheelClose}
					/>
				) : null}
			</div>
			{children}
		</ErrorBoundary>
	);
};

RsGrid.propTypes = {
	/**
	 * The scope of the search
	 */
	scope: PropTypes.string,
	/**
	 * The title displayed on the grid
	 */
	title: PropTypes.string,
	/**
	 * A toolbar to be set at the top of the grid
	 */
	toolbar: PropTypes.element,
	/**
	 * A list of column names to be displayed in the grid
	 */
	columns: PropTypes.arrayOf(PropTypes.string).isRequired,
	/**
	 * A function that is called whenever a row is selected
	 */
	onRowSelect: PropTypes.func,
	/**
	 * Flag indicating whether the grid should search for exact matches
	 */
	exactMatch: PropTypes.bool,
	/**
	 * Flag indicating whether the rows display a checkbox which can be selected
	 */
	selectableRows: PropTypes.bool,
	/**
	 * Extra parameters to add on to the end of a query string
	 */
	searchExtra: PropTypes.object,
	/**
	 * A callback invoked after a wheel menu item has been clicked
	 */
	onWheelClick: PropTypes.func,
	/*
	 * Items for display on the wheel
	 */
	wheelMenuItems: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			icon: PropTypes.string,
			onHover: PropTypes.func,
		})
	),
	/**
	 * A callback function invoked when column order has been changed by dragging
	 */
	onColumnOrderChange: PropTypes.func,
	/**
	 * Array of filter objects
	 */
	filters: PropTypes.arrayOf(PropTypes.object),
	/**
	 * Setter function for filters
	 */
	setFilters: PropTypes.func,
	/**
	 * Button to be shown at end of row when hovering on row
	 * */
	rowEndButton: PropTypes.object,
	/**
	 * Determines if you can/can't resize columns
	 */
	canResizeColumns: PropTypes.bool,
	/**
	 * Determines if you single/multiple columns sort
	 */
	isSingleSort: PropTypes.bool,
	/**
	 * Function that sets rows to remove. We need it here to clear the array after a removal operation.
	 */
	setRowsToRemove: PropTypes.func,
	/**
	 * Object containing an array of objects containing study IDs that should be removed from the grid.
	 * Example: [ { id: '123' }, { id: '124' } ]
	 */
	rowsToRemove: PropTypes.array,
	/**
	 * Determines if row DnD shall be enabled
	 */
	enableRowDnd: PropTypes.bool,
	/**
	 * Enable the Row Click handler
	 */
	enableRowClick: PropTypes.bool,
	/**
	 * Children of GridToolBar to be rendered
	 */
	gridToolBarChildren: PropTypes.element,
	/**
	 * worklist double click timeout in milliseconds
	 */
	doubleClickTimeout: PropTypes.number,
	/**
	 * flag to turn on custom study priority for grid
	 */
	enableCustomStudyPriority: PropTypes.bool,
	/**
	 * flag to turn on custom grid header, currently only used in worklist and teaching folder grid
	 */
	gridCustomHeader: PropTypes.bool,
	/**
	 * flag to show grid tool bar
	 */
	showGridToolbar: PropTypes.bool,
	/**
	 * Function to set the current the scroll position
	 */
	onScrollChange: PropTypes.func,
	/**
	 * The scroll position
	 */
	scrollPosition: PropTypes.shape({
		scrollTop: PropTypes.number,
		scrollLeft: PropTypes.number,
	}),
};

RsGrid.defaultProps = {
	title: 'Worklist',
	columns: [],
	canResizeColumns: false,
	isSingleSort: false,
	enableRowDnd: true,
	enableRowClick: true,
	doubleClickTimeout: 250,
	enableCustomStudyPriority: false,
	gridCustomHeader: false,
	showGridToolbar: true,
	onScrollChange: () => {},
	scrollPosition: {
		scrollTop: 0,
		scrollLeft: 0,
	},
};

export default RsGrid;
