import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
	mediaMobile,
	useIsDesktopXL,
	useIsTabletOnly,
	useIsDesktopL,
	useIsMobile,
	useAppModeContext,
	useImportTabContext,
} from '@worklist-2/core/src';
import { useAppointmentContext } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentContext/AppointmentContext';

const CardLayout = ({ shareTab, gridItemList }) => {
	const isTabletOnly = useIsTabletOnly();
	const isDesktopXL = useIsDesktopXL();
	const isDesktopL = useIsDesktopL();
	const isMobile = useIsMobile();
	const { sideBarIsOpen } = useImportTabContext();
	const { isOpen: isOpenedRightSidebar } = useAppointmentContext();

	const { isWorklistMode } = useAppModeContext();

	const getCardXS = () => {
		if (isWorklistMode()) {
			return 0;
		}

		if (isDesktopXL) {
			if (sideBarIsOpen || isOpenedRightSidebar || shareTab) {
				return 4;
			}

			return 3;
		}

		if (isDesktopL) {
			if (isOpenedRightSidebar && shareTab) {
				return 12;
			}

			if (isOpenedRightSidebar || shareTab) {
				return 6;
			}

			return 4;
		}

		if (isTabletOnly) {
			return 6;
		}

		if (isMobile) {
			return 12;
		}
	};

	return (
		<Grid
			container
			columnSpacing={isTabletOnly ? 1.25 : 2.5}
			rowSpacing={1.25}
			sx={{
				marginTop: '0px',
				justifyContent: 'flex-start',
				[mediaMobile]: {
					marginLeft: '0',
					width: '100%',
					'& > .MuiGrid-item': {
						padding: '0',
						width: '100%',
						'&:not(:last-of-type)': {
							marginBottom: '20px',
						},
					},
				},
			}}
		>
			{_.map(gridItemList, (gridItem, index) => (
				<Grid key={index} item xs={getCardXS()}>
					{gridItem}
				</Grid>
			))}
		</Grid>
	);
};

CardLayout.propTypes = {
	/**
	 * List of items available to be selected.
	 */
	gridItemList: PropTypes.arrayOf(PropTypes.object),
};

export default CardLayout;
