import { omit } from 'lodash';
import { Base64 } from 'js-base64';
import { DICOM_NATURALIZED_DICT, positionDict } from '../consts/consts';

export const parseHTML = html => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');
	const paragraphs = doc.querySelectorAll('p');
	return Array.from(paragraphs).map(p =>
		Array.from(p.childNodes)
			.map(n => {
				if (n.nodeType === 3 && n.textContent.trim() !== '') {
					return { label: n.textContent.trim() };
				}
				if (n.nodeType === 1 && n.getAttribute('data-type') === 'bookmark') {
					return {
						label: n.getAttribute('data-display'),
						tag: n.getAttribute('data-code'),
					};
				}
			})
			.filter(n => n !== undefined)
	);
};

export const generateHTML = data =>
	data
		.map(paragraph => {
			const pContent = paragraph
				.map(element => {
					if (element.tag) {
						return `<span class="mention" data-code="${element.tag}" data-display="${element.label}" title="${element.label}" data-type="bookmark">${element.label}</span>`;
					}
					return element.label;
				})
				.join(' ');
			return `<p>${pContent}</p>`;
		})
		.join('');

export const getColor = color => `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

const getColorShadow = color => {
	const backgroundBrightness = 255 - perceivedBrightness(color);
	return `0 0 1px rgb(${backgroundBrightness}, ${backgroundBrightness}, ${backgroundBrightness}),
			0 0 1px rgb(${backgroundBrightness}, ${backgroundBrightness}, ${backgroundBrightness}), 
			0 0 1px rgb(${backgroundBrightness}, ${backgroundBrightness}, ${backgroundBrightness})`;
};

export const parseConfig = (config, enableTextShadow) => {
	if (!config) {
		return;
	}

	//new ver of config
	if (config.styles) {
		return config;
	}
	//old ver of config
	const styles = {
		color: getColor(config.color),
		fontFamily: config.font,
		...(enableTextShadow
			? {
					'& *': {
						textShadow: getColorShadow(config.color),
					},
			  }
			: {}),
	};
	const sectionsConfig = Object.entries(omit(config, ['color', 'font'])).reduce((agg, [positionKey, v]) => {
		const column = parseHTML(Base64.decode(v));
		const normalizedOutdatedTags = column.map(row =>
			row.reduce((acc, item) => {
				if (item.tag) {
					const isExisted = findKeyByValue(item.tag);
					if (isExisted) {
						return [...acc, { ...item, tag: isExisted }];
					}
					return acc;
				}
				return [...acc, item];
			}, [])
		);
		const { position, alignment } = positionDict[positionKey];
		return { ...agg, [position]: { ...agg[position], [alignment]: normalizedOutdatedTags } };
	}, {});

	return { styles, sectionsConfig };
};

const perceivedBrightness = ({ r, g, b }) => 0.299 * r + 0.587 * g + 0.114 * b;

export const findKeyByValue = value => {
	const isCodeTag = /(\d{4},\d{4}|\(\d{4},\d{4}\))/.test(value);
	if (isCodeTag) {
		const cleanedValue = value.replace(/[()]/g, '');

		for (const key in DICOM_NATURALIZED_DICT) {
			if (DICOM_NATURALIZED_DICT[key].label.toUpperCase().includes(cleanedValue.toUpperCase())) {
				return key;
			}
		}
	} else {
		if (DICOM_NATURALIZED_DICT[value]) {
			return value;
		}
		return null;
	}
};
