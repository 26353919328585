/* Generated automagically by FhirStarter - but please feel free to edit this file */
import { fhirExtensionUrls } from '../../extension';
import { getUserFullName, sortCompareDate } from './utils';

const AuditEventMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
			excludeFromSave: true,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	type: {
		label: 'Type',
		filterType: 'text-search',
		searchParameter: 'type',
		getDisplay: resource => {
			return resource?.type?.display;
		},
	},
	subtype: {
		label: 'Subtype',
		filterType: 'text-search',
		searchParameter: 'subtype',
		getDisplay: resource => {
			return resource.subtype != null ? resource.subtype[0]?.display : '';
		},
	},
	action: {
		label: 'Action',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => {
			return resource.action;
		},
	},
	recorded: {
		label: 'Recorded',
		options: { filter: true, sort: false },
		filterType: 'date-range',
		searchParameter: 'recorded',
		getDisplay: resource => {
			let value = resource.recorded;
			if (value != null) {
				let dateTime = new Date(value);
				return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
			}
		},
		toolTip: true,
	},
	purposeOfEvent: {
		label: 'Purpose of Event',
		filterType: 'text-search',
		searchParameter: 'purposeOfEvent',
		getDisplay: resource => {
			return resource.purposeOfEvent ? resource.purposeOfEvent[0]?.coding[0]?.display : '';
		},
	},
	outcome: {
		label: 'Outcome',
		filterType: 'text-search',
		searchParameter: 'outcome',
		getDisplay: resource => {
			return resource.outcome;
		},
	},
	entityType: {
		label: 'Entity Type',
		filterType: 'single-select',
		searchValueSet: 'auditEventEntityType',
		searchParameter: 'entitytype',
		getDisplay: resource => {
			return resource.subtype != null ? resource.entity[0]?.type?.display : '';
		},
	},
	entityRole: {
		label: 'Entity Role',
		filterType: 'text-search',
		searchParameter: 'entityRole',
		getDisplay: resource => {
			return resource.subtype != null ? resource.entity[0]?.role?.display : '';
		},
	},
	accessionNumber: {
		label: 'Accession #',
		filterType: 'text-search',
		searchParameter: 'accessionNumber',
		getDisplay: resource => {
			return resource.extension.find(
				elem => elem.url.toLowerCase() === fhirExtensionUrls.auditEvent.accessionNumber.toLowerCase()
			)?.valueString;
		},
	},
	accessDatetime: {
		label: 'Access Datetime',
		filterType: 'date-range',
		searchParameter: 'accessDatetime',
		options: {
			filter: true,
			sort: true,
			sortCompare: sortCompareDate,
		},
		getDisplay: resource => {
			let value = resource?.period?.start;
			if (value != null) {
				let dateTime = new Date(value);
				return dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString();
			}
		},
		toolTip: true,
	},
	patientName: {
		label: 'Patient Name',
		filterType: 'text-search',
		searchParameter: 'patientName',
		getDisplay: resource => {
			return getUserFullName(
				resource.extension.find(
					elem => elem.url.toLowerCase() === fhirExtensionUrls.auditEvent.patientName.toLowerCase()
				)?.valueString,
				true
			);
		},
	},
	patientId: {
		label: 'Patient ID',
		filterType: 'text-search',
		searchParameter: 'patientID',
		getDisplay: resource => {
			return resource.extension.find(
				elem => elem.url.toLowerCase() === fhirExtensionUrls.auditEvent.patientID.toLowerCase()
			)?.valueString;
		},
	},
	managingOrganization: {
		label: 'Managing Organization',
		options: { filter: true, sort: true },
		filterType: 'suggest',
		searchParameter: 'managingOrganization',
		suggestScope: {
			resource: 'organization',
			label: 'name',
			extraParam: {
				ismanaging: true,
				active: true,
				_summary: 'text',
				_elements: 'name',
			},
		},
		getDisplay: resource => {
			return resource.extension.find(
				elem => elem.url.toLowerCase() === fhirExtensionUrls.auditEvent.managingOrganization.toLowerCase()
			)?.valueReference?.display;
		},
	},
	imagingOrganization: {
		label: 'Imaging Organization',
		options: { filter: true, sort: true },
		searchParameter: 'imagingOrganization',
		filterType: 'suggest',
		suggestScope: {
			resource: 'organization',
			label: 'name',
			extraParam: {
				isimaging: true,
				active: true,
				_summary: 'text',
				_elements: 'name',
			},
		},
		getDisplay: resource => {
			return resource.extension.find(
				elem => elem.url.toLowerCase() === fhirExtensionUrls.auditEvent.imagingFacility.toLowerCase()
			)?.valueReference?.display;
		},
	},
	studyStatus: {
		label: 'Study Status',
		options: {
			filter: true,
			sort: true,
			setCellHeaderProps: () => ({ style: { width: '250px' } }),
		},
		filterType: 'multi-select',
		searchParameter: 'studyStatus',
		searchValueSet: 'status',
		getDisplay: resource => {
			return resource.extension.find(
				elem => elem.url.toLowerCase() === fhirExtensionUrls.auditEvent.studyStatus.toLowerCase()
			)?.valueString;
		},
	},
	visitNumber: {
		label: 'Visit #',
		filterType: 'text-search',
		searchParameter: 'visitNumber',
		getDisplay: resource => {
			return resource.extension.find(
				elem => elem.url.toLowerCase() === fhirExtensionUrls.auditEvent.visitNumber.toLowerCase()
			)?.valueString;
		},
	},
	username: {
		label: 'User Name',
		filterType: 'text-search',
		searchParameter: 'userName',
		getDisplay: resource => {
			return resource.agent != null ? resource.agent[0]?.name : '';
		},
	},
	userLogin: {
		label: 'Login Email',
		filterType: 'text-search',
		searchParameter: 'userlogin',
		getDisplay: resource => {
			return resource.agent != null ? resource.agent[0]?.altId : '';
		},
	},
	ipAddress: {
		label: 'IP Address',
		filterType: 'text-search',
		searchParameter: 'ipAddress',
		getDisplay: resource => {
			let agent = resource.agent;
			if (agent) {
				for (let i = 0; i < agent.length; i++) {
					if (agent[i].network && agent[i].network.type === '2') {
						//type 1 has been defined as Computer Name
						//type 2 has been defined as IP Address
						return agent[i].network?.address;
					}
				}
			}
			return '';
		},
	},
	requestQuery: {
		label: 'Request Query',
		options: { filter: false, sort: false },
		searchParameter: '',
		getDisplay: resource => {
			let entity = resource.entity;
			if (entity) {
				return entity[0]?.query ? atob(entity[0].query) : '';
			}
		},
	},
	outcomeDescription: {
		label: 'Outcome Desc',
		filterType: 'text-search',
		searchParameter: 'outcomeDesc',
		getDisplay: resource => {
			return resource.outcomeDesc;
		},
	},
};

export default AuditEventMapping;
