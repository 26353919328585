import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { getUserFullName } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';

const getNameInitials = name => {
	if (name && name.length > 0) {
		const nameSlices = getUserFullName(name).split('workList: ');

		return nameSlices.length > 1 ? `${nameSlices[0]?.[0] || ''}${nameSlices[1]?.[0] || ''}` : name[0];
	}

	return '...';
};

export const MergeOrderDrawerHeader = ({ patientName, patientID, t }) => (
	<Box
		sx={{
			minHeight: '100px',
			width: '100%',
			background: 'linear-gradient(180deg, #272727 0%, rgba(61, 61, 61, 0) 100%)',
			filter: 'drop-shadow(-50px 30px 50px rgba(0, 0, 0, 0.1))',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}
	>
		<Box
			sx={{
				width: '40px',
				height: '40px',
				borderRadius: '10px',
				background: '#414141',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginRight: '10px',
			}}
		>
			<Typography>{getNameInitials(patientName)}</Typography>
		</Box>

		<Box sx={{ display: 'flex', width: '70%' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '50%',
				}}
			>
				<Box>
					<Typography
						sx={{
							color: 'rgba(255, 255, 255, 0.6)',
							fontSize: '14px',
						}}
					>
						{t('workList:mergeStudyDrawer.patientName')}
					</Typography>
				</Box>
				<Box>
					<Typography
						sx={{
							fontSize: '14px',
							lineHeight: '20px',
						}}
					>
						{getUserFullName(patientName)}
					</Typography>
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '50%',
				}}
			>
				<Typography
					sx={{
						color: 'rgba(255, 255, 255, 0.6)',
						fontSize: '14px',
					}}
				>
					{t('workList:mergeStudyDrawer.patientId')}
				</Typography>

				<Typography
					sx={{
						fontSize: '14px',
						lineHeight: '20px',
					}}
				>
					{patientID}
				</Typography>
			</Box>
		</Box>
	</Box>
);
