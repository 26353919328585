import { create } from 'zustand';
import axios from 'axios';

import { uploadFileToServer } from '@worklist-2/core/src';
// eslint-disable-next-line import/no-unresolved
import formUtils from '@rs-ui/views/utils/formUtils';

import getForm from '../api/getForm';
import saveForm from '../api/saveForm';
import { removeErrorAttribute, getCompletedFormData } from '../utils/forms';
import { FORM_FILLED_FAILURE } from '../analytics/eventTypes';
import sendAnalyticsEvent from '../analytics';
import { commonMiddlewares } from './middlewares';

export const useFormStore = create(
	commonMiddlewares((set, get) => ({
		// state
		__config: null,
		formsByPatientId: {},

		isOpen: false,
		isSubmitting: false,
		selectedForm: null,
		date: null,
		type: null,
		patientId: null,
		studyId: null,
		appointmentId: null,
		appointmentStatus: null,
		studyForms: null,
		setHideForms: null,

		// Setters
		setIsOpen: bool => set(() => ({ isOpen: bool })),
		setSelectedForm: selectedForm => set(() => ({ selectedForm })),
		setSharedStates: ({ __config }) => set(() => ({ __config }), false, 'forms/setSharedStates'),

		// Actions
		initFormDrawer: async ({
			date,
			type,
			patientId,
			studyId,
			appointmentId,
			appointmentStatus,
			studyForms,
			setHideForms,
			onOpen,
		}) => {
			if (onOpen) onOpen();

			set(
				() => ({
					isOpen: true,
					date,
					type: type || null,
					patientId,
					studyId: studyId || null,
					appointmentId: appointmentId || null,
					appointmentStatus: appointmentStatus || null,
					studyForms,
					setHideForms,
				}),
				false,
				'forms/initFormDrawer'
			);
		},

		closeFormDrawer: async () => {
			set(
				() => ({
					isOpen: false,
					isSubmitting: false,
					selectedForm: null,
					date: null,
					type: null,
					patientId: null,
					studyId: null,
					appointmentId: null,
					appointmentStatus: null,
					studyForms: null,
					setHideForms: null,
				}),
				false,
				'forms/closeFormDrawer'
			);
		},

		getFormsByPatientId: async ({ patientId }) => {
			try {
				const { __config, formsByPatientId } = get();
				const forms = formsByPatientId[patientId];

				if (forms) return forms;

				const response = await getForm({ __config, patientId });

				if (response?.entry) {
					set(
						() => ({
							formsByPatientId: {
								...formsByPatientId,
								[patientId]: response?.entry || [],
							},
						}),
						false,
						'forms/getFormsByPatientId'
					);
					return response?.entry || [];
				}
			} catch (err) {
				return [];
			}
		},

		updateForms: ({ patientId, formId }) => {
			const { formsByPatientId } = get();

			set(
				() => ({
					formsByPatientId: {
						...formsByPatientId,
						[patientId]: formsByPatientId[patientId]?.map(d => ({
							...d,
							resource: {
								...d?.resource,
								completed: d?.resource?.id === formId ? true : d?.resource?.completed,
							},
						})),
					},
				}),
				false,
				'forms/updateForms'
			);
		},

		processSaveAnswerData: ({ formData, hasUpdate = false, appModeIsPatientPortal, closeDrawer }) => {
			const { __config, savePdfForm, selectedForm } = get();

			/* eslint-disable */
			formData.referenceTemplate = formData.id;
			delete formData.id;
			formData.template = false;
			formData.patient = selectedForm.patient;
			formData.study = selectedForm.study;
			/* eslint-enable */

			saveForm({ __config, data: formData })
				.then(async result => {
					console.log('Saving Form successfully');

					if (isPdfForm) {
						// Save pdf answer

						await savePdfForm({
							formId: result?.id,
							pdfFileName: result?.pdfFileName,
							pdfInstance,
							appModeIsPatientPortal,
						});
					}

					if (hasUpdate) {
						updateForm(getCompletedFormData(result));
					}
				})
				.catch(() => {
					console.error('Fail to save form');
				})
				.finally(closeDrawer);
		},

		savePdfForm: async ({ formId, pdfFileName, pdfInstance, appModeIsPatientPortal }) => {
			if (!pdfInstance) return;

			const { __config } = get();

			const { documentViewer, annotationManager } = pdfInstance.Core;

			const xfdfString = await annotationManager.exportAnnotations();

			const doc = documentViewer.getDocument();

			const data = await doc.getFileData({ xfdfString });

			const arr = new Uint8Array(data);
			const uploadFileAnswer = new File([arr], pdfFileName, { type: 'application/pdf' });
			const fileData = new FormData();

			fileData.append('file', uploadFileAnswer);

			const resData = await uploadFileToServer(
				appModeIsPatientPortal
					? `${__config.data_sources.blume}/Form/attachment/${formId}?isReplaceFile=true`
					: `${__config.data_sources.fhir}/Form/attachment/${formId}?isReplaceFile=true`,
				fileData
			);

			if (resData?.length) console.log('Saving Form successfully');

			return true;
		},

		saveData: async ({
			appModeIsPatientPortal,
			appointmentId,
			autoSave = false,
			closeDrawer,
			data,
			forceCloseDrawer = true,
			formData,
			isPdfForm,
			patientId,
			pdfInstance,
			saveDocumentReference,
			setFormData,
			setLastSyncedForm,
			setValidateFormData,
			studyId,
			updateForm,
			useFhirUrl,
			getBlobStringFromBlumeForm,
		}) => {
			try {
				const { __config, processSaveAnswerData } = get();

				let countOfUnAnsweredRequiredFields = 0;
				let validatedFormData = {};
				let firstErrorItemId = '';

				if (isPdfForm) {
					validatedFormData = { ...formData };
					validatedFormData.completed = true;
				} else if (!autoSave) {
					setValidateFormData(true);
					validatedFormData = await formUtils.validateForm(formData, true);
					validatedFormData.completed = true;
					countOfUnAnsweredRequiredFields = validatedFormData?.formTemplate?.content
						.flat(1)
						.filter(question => question?.error === true)?.length;

					// Scroll to first error item
					firstErrorItemId = validatedFormData?.formTemplate?.content
						.flat(1)
						.find(question => question?.error === true)?.id;
					if (firstErrorItemId) {
						const element = document.getElementById(firstErrorItemId);
						element?.scrollIntoView();
					}
				} else {
					validatedFormData = await removeErrorAttribute(formData);
				}

				if (countOfUnAnsweredRequiredFields === 0) {
					if (!data.completed && data.type === 'technologist') {
						// For saving new Answer Technologist Form
						updateForm(() =>
							processSaveAnswerData({ formData: validatedFormData, appModeIsPatientPortal, closeDrawer })
						);
					} else if (validatedFormData.template) {
						// For saving new Answer Other Blume Form
						processSaveAnswerData({
							formData: validatedFormData,
							hasUpdate: true,
							appModeIsPatientPortal,
							closeDrawer,
						});
					} else if ((!validatedFormData || validatedFormData.resourceType !== 'BlumeForm') && !autoSave) {
						// For saving DocumentReference
						saveDocumentReference(studyId, patientId, appointmentId);
					} else {
						let succeed;

						// For editing answer
						set(() => ({ isSubmitting: true }));

						let result;

						if (!autoSave && !isPdfForm) {
							const blob = await getBlobStringFromBlumeForm();
							const url = `${__config.data_sources.blume}form/v2/${data.id}`;
							const multipartFormData = new FormData();
							multipartFormData.append('files', blob, data.title);
							multipartFormData.append('json', JSON.stringify(validatedFormData));
							result = await axios({
								method: 'put',
								url,
								data: multipartFormData,
								headers: { 'Content-Type': 'multipart/form-data' },
							});
						} else if (!isPdfForm) {
							result = await axios.put(
								`${useFhirUrl ? `${__config.data_sources.fhir}/` : __config.data_sources.blume}Form/${
									data.id
								}`,
								validatedFormData
							);
						}

						// Save pdf answer
						if (isPdfForm) {
							if (!pdfInstance) return;

							const { __config } = get();

							const { documentViewer, annotationManager } = pdfInstance.Core;

							const xfdfString = await annotationManager.exportAnnotations();

							const doc = documentViewer.getDocument();

							const fileData = await doc.getFileData({ xfdfString });

							const arr = new Uint8Array(fileData);

							const url = `${__config.data_sources.blume}form/v2/${data.id}?isPdfForm=true&fileName=${data.pdfFileName}`;
							const multipartFormData = new FormData();
							multipartFormData.append('json', JSON.stringify(validatedFormData));
							result = await axios({
								method: 'put',
								url,
								data: multipartFormData,
								headers: { 'Content-Type': 'multipart/form-data' },
							});

							const uploadFileAnswer = new File([arr], data.pdfFileName, { type: 'application/pdf' });
							const formData = new FormData();
							formData.append('file', uploadFileAnswer);

							const resData = await uploadFileToServer(
								`${__config.data_sources.blume}/Form/attachment/${result.data.id}?isReplaceFile=true`,
								formData
							);

							if (resData?.length) console.log('Saving Form successfully');
							succeed = true;
						}

						const form = getCompletedFormData(result);

						if (updateForm) {
							updateForm(form);
						}

						if (forceCloseDrawer) {
							if (closeDrawer) closeDrawer();
							setLastSyncedForm(form);
						}

						set(() => ({ isSubmitting: false }));

						return succeed;
					}
				} else {
					setFormData(validatedFormData);
				}
			} catch (err) {
				set(() => ({ isSubmitting: false }));
				sendAnalyticsEvent(FORM_FILLED_FAILURE, {
					appointmentId,
					studyId,
					formId: data.id,
					timestamp: Date.now(),
				});
			}
		},
	}))
);
