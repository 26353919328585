// core
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth, useTabContext, useAppModeContext, mediaTablet } from '@worklist-2/core/src';
// mui
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
// partials
import SearchResultItem, { checkAccess } from './SearchResultItem';
import SendDrawer from '@rs-ui/components/Worklist/WorklistGrid/SendDrawer';
import { NoResultsCallout } from './NoResultsCallout';
// libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const SearchDataSet = ({
	data,
	isAutoSuggest = false,
	isLoadingUserId,
	getOnClick,
	onClick: _onClick,
	handleTabChange,
}) => {
	const { searchTab, validOrganizations, internalPatients } = useTabContext();

	// State

	const [dataSet, setDataSet] = useState([]);
	const { isWorklistMode, isHelpCenterMode, isMarketplaceMode } = useAppModeContext();
	const appModeIsWorklist = isWorklistMode();
	const appModeIsHelpCenter = isHelpCenterMode();
	const appModeIsMarketplace = isMarketplaceMode();
	const { t } = useTranslation('Search');
	const metaEmergencySearch = useBooleanFlagValue('meta-emergency-search');
	const { loggedInUser } = useAuth();

	// Variables

	const itemCountPerSection = isAutoSuggest ? 2 : 4;

	const resourceTypes = [
		{ key: 'Patient' },
		{
			key: 'ImagingStudyWorklist',
			label: 'Study',
		},
		{ key: 'ServiceRequest', label: 'Order' },
		{ key: 'Encounter', label: 'Visit' },
		{ key: 'Organization' },
		{ key: 'Practitioner', label: 'User' },
		{
			key: 'BlumeStudy',
			labelPatientPortal: t('My Studies'),
			tabName: 'Studies',
		},
		{
			key: 'StudySharedWithMe',
			labelPatientPortal: t('Shared With Me'),
			tabName: 'Shared',
		},
		{
			key: 'helpcenter',
			label: 'Help Center',
			labelPatientPortal: t('Help Center'),
			tabName: 'Help Center',
		},
		{
			key: 'MarketplaceApp',
			label: 'Marketplace App',
		},
	];

	// Effects

	useEffect(() => {
		if (data?.length > 0) {
			const newDataSet = [];

			for (const field of resourceTypes) {
				const filteredData = _.filter(data, item => (item.resourceType ?? item.ResourceType) == field.key);

				if (filteredData?.length > 0) {
					if (appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace) {
						newDataSet.push({
							data: filteredData,
							field: field.label || field.key,
							tab: field.label || field.key,
						});
					} else if (filteredData?.length > 0) {
						newDataSet.push({
							data: filteredData,
							field: field.labelPatientPortal,
							tab: field.tabName,
						});
					}
				}
			}

			setDataSet(newDataSet);
		}
	}, [data, searchTab, isAutoSuggest]);

	const SectionTitle = styled(({ ...props }) => <Typography {...props} />)(() =>
		isAutoSuggest
			? appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace
				? {
						marginLeft: '1rem !important',
						opacity: 0.6,
						marginTop: '0.5rem !important',
						marginBottom: '0.5rem !important',
						textTransform: 'uppercase',
				  }
				: {
						marginBottom: '1rem !important',
						color: '#393939',
						fontWeight: '400px',
						fontSize: '14px',
						[mediaTablet]: {
							textTransform: 'uppercase',
							lineHeight: '20px',
							marginBottom: '0 !important',
						},
				  }
			: {
					marginBottom: '1rem !important',
					color: appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace ? '' : '#393939',
					fontWeight: appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace ? '' : '400px',
					fontSize: appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace ? '' : '14px',
					[mediaTablet]: {
						textTransform: 'uppercase',
						lineHeight: '20px',
						marginBottom: '0 !important',
					},
			  }
	);

	return (
		<>
			{dataSet?.length ? (
				dataSet.map(ds => (
					<section key={ds.field}>
						<SectionTitle isAutoSuggest={isAutoSuggest}>{t(_.upperCase(ds.field))}</SectionTitle>

						{ds.data.slice(0, itemCountPerSection).map(item => {
							const isAccess = checkAccess(
								item,
								validOrganizations,
								internalPatients,
								loggedInUser?.id,
								metaEmergencySearch
							);
							const onClick = _onClick || getOnClick(item);
							const cursorType = isAccess ? 'pointer' : 'default';

							return (
								<ListItemButton
									key={item.id}
									className={isAutoSuggest ? 'searchDialogItem' : 'searchItem'}
									data-cy={`dataset-${ds.field}`}
									sx={{
										cursor: cursorType,
										marginBottom:
											appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace
												? '1rem'
												: undefined,
										borderRadius:
											appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace
												? '6px'
												: '',
										padding:
											appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace
												? '16px 20px'
												: '',
										paddingLeft:
											appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace
												? '16px'
												: '1px',

										background:
											appModeIsWorklist || appModeIsHelpCenter || appModeIsMarketplace
												? '#393939'
												: '#FFFFFF',
									}}
									onClick={isAccess ? onClick : () => {}}
								>
									<SearchResultItem
										showButton
										SendDrawer={SendDrawer}
										isAccess={isAccess}
										item={item}
										showProgress={
											isLoadingUserId &&
											item.id === isLoadingUserId &&
											item.resourceType === 'Practitioner'
										}
										type="search"
										onGoTo={onClick}
									/>
								</ListItemButton>
							);
						})}

						<MoreResultsButton
							appModeIsWorklist={appModeIsWorklist}
							isVisible={ds.data.length > 4 && !isAutoSuggest}
							tabLabel={ds.field}
							tabName={ds.tab}
							onClick={handleTabChange}
						/>
					</section>
				))
			) : !isAutoSuggest ? (
				<NoResultsCallout />
			) : null}
		</>
	);
};

SearchDataSet.propTypes = {
	data: PropTypes.array,
	isAutoSuggest: PropTypes.bool,
	getOnClick: PropTypes.func,
	handleTabChange: PropTypes.func,
	handleRelatedTabData: PropTypes.func,
};

export default SearchDataSet;

export const MoreResultsButton = ({ tabLabel, tabName, isVisible, onClick: _onClick, appModeIsWorklist }) => {
	const onClick = useCallback(() => {
		_onClick(tabName);
	}, [tabName]);
	const { t } = useTranslation('Search');

	return isVisible ? (
		<Typography
			sx={{
				color: 'rsPrimary.main',
				cursor: 'pointer',
				display: 'inline-block',
				marginBottom: appModeIsWorklist ? '1rem' : '40px',
				letterSpacing: '1.25px',
				fontSize: '14px',
				lineHeight: '16px',
				textTransform: 'uppercase',
				fontWeight: appModeIsWorklist ? '400' : '500',
				[mediaTablet]: {
					fontSize: '12px',
				},
			}}
			variant="subtitle1"
			onClick={onClick}
		>
			{appModeIsWorklist
				? t(`MORE` + ` ${_.upperCase(tabLabel)} ` + `RESULTS`)
				: `${t('MORE')} ${tabLabel} ${t('RESULTS')}`}
		</Typography>
	) : null;
};
