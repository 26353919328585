/* Generated automagically by FhirStarter - but please feel free to edit this file */
import { fhirExtensionUrls } from '../../extension';
const OrganizationMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	identifier: {
		label: 'Identifier',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.identifier;
		},
	},
	active: {
		label: 'Active',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.active;
		},
	},
	type: {
		label: 'Type',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.type;
		},
	},
	name: {
		label: 'Name',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: 'name', // This is needed for Suggest columns in Worklist grid
		getDisplay: resource => {
			return resource.name;
		},
	},
	alias: {
		label: 'Alias',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.alias;
		},
	},
	telecom: {
		label: 'Telecom',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.telecom;
		},
	},
	address: {
		label: 'Address',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.address;
		},
	},
	partOf: {
		label: 'Part Of',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.partOf;
		},
	},
	contact: {
		label: 'Contact',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.contact;
		},
	},
	endpoint: {
		label: 'Endpoint',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource.endpoint;
		},
	},
	accessionNumber: {
		label: 'Accession #',
		getDisplay: resource => {
			return resource.accessionNumber;
		},
	},
	modality: {
		label: 'Modality',
		filterType: 'multi-select',
		getDisplay: resource => {
			return resource.modality.map(elem => elem.code).join(' | ');
		},
	},
	patientName: {
		label: 'Patient Name',
		getDisplay: resource => {
			return resource.subject.display;
		},
	},
	managingOrganization: {
		label: 'Managing Organization',
		options: { filter: true, sort: true },
		filterType: 'suggest',
		getDisplay: resource => {
			return resource.managingOrganization;
		},
	},
	studyStatus: {
		label: 'Study Status',
		getDisplay: resource => {
			return resource.extension.find(elem => elem.url === fhirExtensionUrls.common.studyStatus).valueCoding
				.display;
		},
	},
	studyDescription: {
		label: 'Study Description',
		getDisplay: resource => {
			return resource.description;
		},
	},
};
export default OrganizationMapping;
