import React, { createContext, useEffect, useContext, useRef, useState, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import {
	compressObjectToArrayBuffer,
	decompressArrayBufferToObject,
	useAuth,
	useRouter,
	logDebug,
	logError,
	logInfo,
	logWarn,
} from '@worklist-2/core/src';
import { addParameter } from '@worklist-2/core/src/utils/url';
import { BROADCAST_EVENTS, LAYOUT_TYPE } from '@worklist-2/ui/src/views/ImageViewerView3D/contexts/consts/consts';
import addSynchronizedScreen from '@worklist-2/ui/src/views/ImageViewerView3D/utils/addSynchronizedScreen';
import removeSynchronizedScreen from '@worklist-2/ui/src/views/ImageViewerView3D/utils/removeSynchronizedScreen';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useSearchParams } from 'react-router-dom';
import { useGlobalStore } from '@worklist-2/core/src/store';

export const MultiscreenContext = createContext({});

const BROADCAST_CHANNEL_NAME = 'MULTISCREEN';

export const MultiscreenProvider = ({ children }) => {
	const wonIvMmRefactorScreenSync = useBooleanFlagValue('WON-IV-MM-REFACTOR-SCREEN-SYNC');
	const galaxyDvPerformancePhase2 = useBooleanFlagValue('galaxy-dv-performance-phase-2');
	const wonIvBroadcastMsgCompress = useBooleanFlagValue('won-iv-broadcast-msg-compress');

	const { setCurrentStudyInfo } = useGlobalStore();

	const [redirectId, setRedirectId] = useState(uuid());

	const permissionStatus = useRef(null);
	const screenDetails = useRef(null);
	const screenNumber = useRef(null);
	const isCurrentScreen = useRef(false);
	const isExternalRedirect = useRef(false);
	const { displaySettingsRef } = useAuth();
	const { goTo } = useRouter();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		canAccessMultimonitorFunctionality();
	}, []);

	const getScreenNumber = useCallback(() => {
		if (!screenNumber.current) {
			screenNumber.current = searchParams.get('screenNumber');
		}

		return screenNumber.current;
	}, [searchParams]);

	const getIsCurrentScreen = useCallback(() => {
		if (!isCurrentScreen.current) {
			isCurrentScreen.current = searchParams.get('isCurrentScreen');
		}

		return isCurrentScreen.current;
	}, [searchParams]);

	const multiscreenBroadcastChannel = useRef(new BroadcastChannel(BROADCAST_CHANNEL_NAME));
	const synchronizedScreens = useRef([]);
	const ivSynchronizedScreens = useRef([]);
	const [isOpenedDocumentViewer, setIsOpenedDocumentViewer] = useState(false);
	const [isOpenedImageViewer, setIsOpenedImageViewer] = useState(false);
	const [isOpenedDocument, setIsOpenedDocument] = useState({});
	const [ivLayoutType, setIvLayoutType] = useState(LAYOUT_TYPE.DEFAULT);
	const [isShownIvBackdrop, setIsShownIvBackdrop] = useState(false);

	const onSynchronizedScreensChange = () => {
		setIsOpenedDocumentViewer(
			Boolean(
				synchronizedScreens.current.find(synchronizedScreen =>
					synchronizedScreen.url?.includes('document-viewer')
				)
			)
		);

		setIsOpenedImageViewer(
			Boolean(
				synchronizedScreens.current.find(synchronizedScreen =>
					synchronizedScreen.url?.includes('imageviewer3d')
				)
			)
		);
	};

	const _postMessage = data => {
		try {
			logDebug('Multiscreen Context', '_postMessage Start', { data, timestamp: Date.now() });
			const message = {
				screenNumber: getScreenNumber(),
				url: location.href,
				...data,
			};

			if (wonIvBroadcastMsgCompress) {
				// compress message before sending
				const compressedBuffer = compressObjectToArrayBuffer(message);
				multiscreenBroadcastChannel.current?.postMessage(compressedBuffer);
				logDebug('Multiscreen Context', '_postMessage after compressed', {
					compressedBufferLength: compressedBuffer?.length,
					timestamp: Date.now(),
				});
			} else {
				multiscreenBroadcastChannel.current?.postMessage(message);
			}
		} catch (error) {
			logError('Multiscreen Context', '_postMessage', error);
		}
	};

	const onMessageReceive = e => {
		let message = e.data;
		logDebug('Multiscreen Context', 'onMessageReceive Start', { timestamp: Date.now() });
		if (wonIvBroadcastMsgCompress) {
			// decompress message after receiving
			message = decompressArrayBufferToObject(e.data);
			logDebug('Multiscreen Context', 'onMessageReceive after decompressed', {
				event: message.event,
				screenNumber: message.screenNumber,
				timestamp: Date.now(),
			});
		}

		if (getScreenNumber() === message.screenNumber) {
			return;
		}

		if (message.event === BROADCAST_EVENTS.REQUEST_SYNCHRONIZE) {
			addSynchronizedScreen({
				synchronizedScreens,
				newScreen: message,
			});

			onSynchronizedScreensChange();

			_postMessage({
				event: BROADCAST_EVENTS.RECEIVE_SYNCHRONIZE,
			});

			return;
		}

		if (message.event === BROADCAST_EVENTS.RECEIVE_SYNCHRONIZE) {
			addSynchronizedScreen({
				synchronizedScreens,
				newScreen: message,
			});

			onSynchronizedScreensChange();

			return;
		}

		if (message.event === BROADCAST_EVENTS.WINDOW_CLOSED) {
			removeSynchronizedScreen({ synchronizedScreens, screenNumber: message.screenNumber });

			onSynchronizedScreensChange();

			return;
		}

		if (message.event === BROADCAST_EVENTS.ROUTE_CHANGED) {
			if (message.targetScreenNumber != getScreenNumber()) {
				return;
			}
			if (galaxyDvPerformancePhase2) {
				const currentStudyInfo = message?.currentStudy ? JSON.parse(message?.currentStudy) : {};
				setCurrentStudyInfo(currentStudyInfo);
				logDebug('Multiscreen Context', 'onMessageReceive studyId', {
					studyId: currentStudyInfo?.id,
					timestamp: Date.now(),
				});
			}

			setRedirectId(uuid());
			goTo.any(message.url);
			setIsShownIvBackdrop(false);

			_postMessage({
				event: BROADCAST_EVENTS.URL_CHANGED,
			});

			return;
		}

		if (message.event === BROADCAST_EVENTS.URL_CHANGED) {
			addSynchronizedScreen({
				synchronizedScreens,
				newScreen: message,
			});

			onSynchronizedScreensChange();
		}

		if (message.event === BROADCAST_EVENTS.DICTATION_DEVICE_ADDED) {
			window.location.reload();
		}

		if (message.event === BROADCAST_EVENTS.EXTERNAL_REDIRECT) {
			isExternalRedirect.current = true;
		}

		if (message.event === BROADCAST_EVENTS.USER_LOGOUT && !!getScreenNumber() && !getIsCurrentScreen()) {
			window.close();
		}
	};

	const ON_WINDOW_CLOSED = () => {
		_postMessage({
			event: BROADCAST_EVENTS.WINDOW_CLOSED,
		});
	};

	const addListeners = () => {
		multiscreenBroadcastChannel.current.addEventListener('message', onMessageReceive);
		window.addEventListener('beforeunload', ON_WINDOW_CLOSED);
	};

	const removeListeners = () => {
		if (!wonIvMmRefactorScreenSync) {
			ON_WINDOW_CLOSED();
		}

		multiscreenBroadcastChannel.current.removeEventListener('message', onMessageReceive);
		multiscreenBroadcastChannel.current.close();
	};

	useEffect(() => {
		addListeners();

		_postMessage({
			event: BROADCAST_EVENTS.REQUEST_SYNCHRONIZE,
		});

		return () => {
			removeListeners();
		};
	}, []);

	const numberOfScreenInfo = async () => {
		const screensInfo = await self.getScreenDetails().catch(e => {
			logError('Multiscreen Context', 'numberOfScreenInfo', e);
			return null;
		});

		return screensInfo.screens;
	};

	const canAccessMultimonitorFunctionality = async () => {
		try {
			if (window.screen.isExtended && 'getScreenDetails' in self) {
				permissionStatus.current = await navigator.permissions.query({
					name: 'window-placement',
				});
				return permissionStatus.current && permissionStatus.current.state === 'granted';
			}
		} catch (e) {
			return false;
		}
	};

	const getScreenDetailsWithWarningAndFallback = async (screenNumber, requestPermission = false) => {
		if ('getScreenDetails' in self) {
			if (
				!screenDetails.current &&
				((permissionStatus.current && permissionStatus.current.state === 'granted') ||
					(permissionStatus.current && permissionStatus.current.state === 'prompt' && requestPermission))
			) {
				screenDetails.current = await self.getScreenDetails().catch(e => {
					logError('Multiscreen Context', 'getScreenDetailsWithWarningAndFallback', e);
					return null;
				});
			}

			if (screenDetails.current && screenDetails.current.screens.length > 1)
				logInfo('Multiscreen Context', 'Clear any warning.');
			else if (screenDetails.current && screenDetails.current.screens.length == 1)
				logWarn(
					'Multiscreen Context',
					'Please extend your desktop over multiple screens for full demo functionality'
				);
			else if (requestPermission || permissionStatus.state === 'denied')
				logWarn(
					'Multiscreen Context',
					'Please allow the Window Placement permission for full demo functionality'
				);
			if (screenDetails.current) {
				if (
					Number.isInteger(screenNumber) &&
					screenNumber >= 0 &&
					screenNumber < screenDetails.current.screens.length
				) {
					return screenDetails.current.screens[screenNumber];
				}
				return screenDetails.current.screens[screenDetails.current.screens.length];
			}
		}

		return [window.screen];
	};

	const getScreenDetailsByScreenNumber = async screenNumber =>
		getScreenDetailsWithWarningAndFallback(screenNumber).then(screen => {
			if (screen.length >= 1) {
				if (screen.length <= screenNumber) return screen[screenNumber];
				return screen[screen.length - 1];
			}
			return screen;
		});

	const openToSettingScreen = async (url, name, externalUrl, isMandatory = false, record) => {
		let result = false;
		let screensInfo = null;

		screensInfo = await self.getScreenDetails().catch(e => {
			logError('Multiscreen Context', 'openToSettingScreen', e);
			return null;
		});

		if (
			displaySettingsRef &&
			displaySettingsRef.current?.enabled &&
			screensInfo &&
			screensInfo.screens.length > 1
		) {
			const myscreen = displaySettingsRef.current?.setting?.find(
				s => s.numberofScreen == screensInfo.screens.length
			);

			if (myscreen) {
				const ViewerScreens = myscreen.displaySetting.filter(ds => ds.display == name);
				const currentscreenNumber =
					getScreenNumber() || screensInfo.screens.findIndex(s => s == screensInfo.currentScreen);
				const ivScreens = myscreen.displaySetting.filter(ds => ds.display == 'IV');
				const numOfIVScreens = ivScreens?.length;

				const updatedURL = addParameter(url, 'numOfIVScreens', numOfIVScreens);
				if (ViewerScreens?.length) {
					_postMessage({
						event: BROADCAST_EVENTS.EXTERNAL_REDIRECT,
					});
					result = true;

					ViewerScreens.forEach(ViewerScreen => {
						let fullURL = addParameter(updatedURL, 'screenNumber', ViewerScreen.screenNumber);
						const fullName = `${name}${ViewerScreen.screenNumber}`;
						const screenIsOpened = synchronizedScreens.current.find(
							screen => screen.screenNumber == ViewerScreen.screenNumber
						);

						if (currentscreenNumber == ViewerScreen.screenNumber) {
							if (externalUrl) {
								window.open(fullURL);
							} else {
								fullURL = addParameter(fullURL, 'isCurrentScreen', 1);

								goTo.any(fullURL);
							}
						} else {
							if (screenIsOpened) {
								_postMessage({
									event: BROADCAST_EVENTS.ROUTE_CHANGED,
									url: fullURL,
									targetScreenNumber: ViewerScreen.screenNumber,
									currentStudy: JSON.stringify(record || {}),
								});

								return;
							}

							const screen = screensInfo.screens[ViewerScreen.screenNumber];

							const options = {
								x: screen.availLeft,
								y: screen.availTop,
								width: screen.availWidth,
								height: screen.availHeight,
							};
							options.url = fullURL;

							openWindow(options, fullName);
						}
					});
				} else if (isMandatory) {
					result = true;
					const screen = screensInfo.screens[currentscreenNumber];

					const options = {
						x: screen.availLeft,
						y: screen.availTop,
						width: screen.availWidth,
						height: screen.availHeight,
					};
					options.url = updatedURL;
					openWindow(options, name);
				}
			}
		}
		return result;
	};

	const openToAnotherScreen = async (url, name) => {
		let screensInfo = null;

		screensInfo = await self.getScreenDetails().catch(e => {
			logError('Multiscreen Context', 'openToAnotherScreen', e);
			return null;
		});

		if (screensInfo.screens.length > 1) {
			const screen = screensInfo.screens[screensInfo.screens.findIndex(s => s != screensInfo.currentScreen)];

			const options = {
				x: screen.availLeft,
				y: screen.availTop,
				width: screen.availWidth,
				height: screen.availHeight,
			};
			options.url = url;
			return openWindow(options, name);
		}
		goTo.any(url);
	};

	const openToScreen = (screenNumber, url) =>
		getScreenDetailsByScreenNumber(screenNumber).then(screen => {
			const options = {
				x: screen.availLeft,
				y: screen.availTop,
				width: screen.availWidth,
				height: screen.availHeight,
			};
			options.url = url;
			return openWindow(options);
		});

	const openWindow = (options = null, name = '_blank') => {
		if (!options || !options.url) {
			options = {
				url: openWindowUrlInput.value,
				x: openWindowLeftInput.value,
				y: openWindowTopInput.value,
				width: openWindowWidthInput.value,
				height: openWindowHeightInput.value,
			};
		}

		const features = getFeaturesFromOptions(options);

		return window.open(options.url, name, features);
	};

	const getFeaturesFromOptions = options =>
		`left=${options.x},top=${options.y},width=${options.width},height=${options.height}fullscreen=1`;

	const toggleElementFullscreen = async (element, screenId) =>
		getScreenDetailsWithWarningAndFallback(screenId).then(async screen => {
			if (screen) {
				await element.requestFullscreen({ screen });
			} else if (document.fullscreenElement == element) {
				document.exitFullscreen();
			} else {
				await element.requestFullscreen();
			}
		});

	const fullScreen = async () => {
		await document.documentElement.requestFullscreen();
	};

	const toggleFullscreen = async screenId => toggleElementFullscreen(document.documentElement, screenId);

	/**
	 * Multi screen next if document is next
	 */
	const channel = channelName => {
		try {
			return new BroadcastChannel(channelName);
		} catch (error) {
			return null;
		}
	};

	const postNextMessage = (viewer, postMessage) => {
		try {
			channel(viewer)?.postMessage({
				message: postMessage,
				origin: window.location.origin,
			});
			channel(viewer)?.close();
		} catch (error) {
			logError('Multiscreen Context', '_postMessage after Next', error);
		}
	};

	const postMessageToMultiScreenBroadcastChannel = eventType => {
		_postMessage({ event: eventType });
	};

	return (
		<MultiscreenContext.Provider
			value={{
				canAccessMultimonitor: canAccessMultimonitorFunctionality,
				getScreenDetails: getScreenDetailsWithWarningAndFallback,
				toggleFullscreen,
				openToScreen,
				fullScreen,
				openToAnotherScreen,
				openToSettingScreen,
				postNextMessage,
				numberOfScreenInfo,
				getScreenDetailsByScreenNumber,
				isOpenedDocumentViewer,
				setIsOpenedDocumentViewer,
				isOpenedImageViewer,
				postMessageToMultiScreenBroadcastChannel,
				redirectId,
				setRedirectId,
				isOpenedDocument,
				setIsOpenedDocument,
				isExternalRedirect,
				_postMessage,
				ivLayoutType,
				setIvLayoutType,
				ivSynchronizedScreens,
				isShownIvBackdrop,
				setIsShownIvBackdrop,
			}}
		>
			{children}
		</MultiscreenContext.Provider>
	);
};

export const useMultiscreen = () => useContext(MultiscreenContext);
export default MultiscreenProvider;
