// @flow

import _ from 'lodash';
import { fhirExtensionUrls, generateRandomString, findExtensionIndex } from '@worklist-2/core/src';

const calculateBMI = (weight, height) => {
	const heightMeter = parseFloat(height / 100);

	return (weight / (heightMeter * heightMeter)).toFixed(1);
};

const calculateBMIStatus = (weight, height) => {
	const bmi = calculateBMI(weight, height);

	if (bmi < 18.6) {
		return BmiPossibleOptions[0];
	}
	if (bmi < 24.9) {
		return BmiPossibleOptions[1];
	}
	if (bmi < 29.9) {
		return BmiPossibleOptions[2];
	}
	return BmiPossibleOptions[3];
};

const vitalCodes = {
	bodyHeight: '8302-2',
	bodyWeight: '29463-7',
	heartRate: '8867-4',
	diastolicBlood: '8462-4',
	systolicBlood: '8480-6',
	bloodPressure: '85354-9',
};

const BmiPossibleOptions = ['Underweight', 'Normal', 'Overweight', 'Obese'];

const SmokingStatus = [
	{
		code: '266919005',
		display: 'Never smoker',
	},
	{
		code: '266927001',
		display: 'Unknown if ever smoked',
	},
];

const FILE_ICON = {
	'application/msword': 'DOC',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC', // docx
	'application/pdf': 'PDF',
	'image/jpeg': 'JPG',
	'image/png': 'PNG',
};

class Patient {
	constructor(patient) {
		this.patient = patient;
	}

	patient = {};

	patientIdCode = 'MR';

	patientSSNCode = 'SS';

	patientDriverLicenseCode = 'DL';

	patientConfidentialitySystem = 'http://hl7.org/fhir/v3/Confidentiality';

	static valueSetNames = [
		// #DELETE: LEFTOVER CODE ! DO NOT USE ANYMORE ! USE THE GLOBAL `useValueSets()` HOOK INSTEAD !
		'sex',
		'maritalStatus',
		'language',
		'confidentiality',
		'race',
		'ethnicity',
		'linkType',
		'employmentStatus',
		'state',
		'relationship',
		'specialArrangement',
		'specialCourtesy',
	];

	valueSetLists = {}; // #DELETE: LEFTOVER CODE ! DO NOT USE ANYMORE ! USE THE GLOBAL `useValueSets()` HOOK INSTEAD !

	alcoholObservations: any[] = [];

	smokingObservations: any[] = [];

	vitalObservations: any[] = [];

	init = (
		valueSetsObject,
		vitalObservations,
		alcoholObservations,
		smokerObservations,
		encounters,
		diagnosticReports
	) => {
		this.setPatientName();
		this.setPatientIdentifier();
		this.setPatientId();
		this.setPatientSSN();
		this.setPatientDriverLicense();
		this.setPatientRace();
		this.setPatientEthnicity();

		this.setPatientExtension();
		this.setPatientBirthSex();
		this.setPatientMothersMaidenName();
		this.setPatientMaritalStatus();
		this.setPatientCommunication();
		this.setPatientLanguage();
		this.setPatientLanguagePreffered();
		this.setPatientEncounterClass();
		this.setPatientSpecialCourtesy();

		this.setPatientMeta();
		this.setPatientSecurity();
		this.setPatientConfidentiality();

		this.setPatientAddress();

		this.setPatientTelecom();
		this.setPatientTelecomUniqueId();

		this.setPatientLink();
		this.setPatientEmployer();
		this.setPatientEmploymentStatus();

		this.setPatientNotes();

		this.setObservationInfo(vitalObservations);
		this.setHasDrinking(alcoholObservations);
		this.setHasSmoking(smokerObservations);
		this.setIsVIP(encounters);
		this.setDocuments(diagnosticReports);

		this.valueSetLists = valueSetsObject; // #DELETE: LEFTOVER CODE ! DO NOT USE ANYMORE ! USE THE GLOBAL `useValueSets()` HOOK INSTEAD !
	};

	setPatient = newPatient => {
		this.patient = newPatient;
	};

	parseDocument = doc => ({
		id: doc.id,
		fileName: doc.content[0].attachment.title,
		description: doc.description?.replaceAll('<br>', ' ').replaceAll('?', ''),
		extension: FILE_ICON[doc?.content?.[0]?.attachment?.contentType || 'application/pdf'],
	});

	addDocument = document => {
		const docs = this.parseDocument(document);
		this.patient.documents.push(docs);
	};

	removeDocument = document => {
		this.patient.documents = this.patient.documents.filter(d => d.id != document.id);
	};

	setDocuments = diagnosticReports => {
		if (diagnosticReports) {
			const documentList = diagnosticReports.map(doc => this.parseDocument(doc));

			this.patient.documents = documentList;
		}
	};

	setIsVIP = encounters => {
		if (encounters?.length > 0) {
			this.patient.isVIP = false;
			encounters.forEach(element => {
				if (element.resource.hospitalization?.specialCourtesy?.[0]?.coding[0].code == 'VIP') {
					this.patient.isVIP = true;
				}
			});
		}
	};

	setHasDrinking = alcoholObservations => {
		if (alcoholObservations) {
			this.patient.hasDrinking = alcoholObservations.length > 0;

			if (alcoholObservations.length > 0) {
				this.patient.drinkingStatus = {
					code: alcoholObservations[0].resource.valueCodeableConcept.coding[0].code,
					display: alcoholObservations[0].resource.valueCodeableConcept.coding[0].display,
				};
			}

			this.alcoholObservations = alcoholObservations;
		}
	};

	setHasSmoking = smokerObservations => {
		if (smokerObservations?.length > 0) {
			this.patient.hasSmoking = false;

			smokerObservations.forEach(element => {
				if (
					SmokingStatus.indexOf(el => el.code == element.resource.valueCodeableConcept.coding[0].code) == -1
				) {
					this.patient.hasSmoking = true;
				}
			});

			this.patient.smokingStatus = {
				code: smokerObservations[0].resource.valueCodeableConcept.coding[0].code,
				display: smokerObservations[0].resource.valueCodeableConcept.coding[0].display,
			};

			this.smokingObservations = smokerObservations;
		}
	};

	setObservationInfo = vitalObservations => {
		const vitals = {};
		if (vitalObservations?.length > 0) {
			vitalObservations.forEach(element => {
				if (element.resource.code.coding[0].code == vitalCodes.bodyHeight) {
					vitals.bodyHeight = element.resource.valueQuantity.value;
				}
				if (element.resource.code.coding[0].code == vitalCodes.bodyWeight) {
					vitals.bodyWeight = element.resource.valueQuantity.value;
				}

				if (element.resource.code.coding[0].code == vitalCodes.heartRate) {
					vitals.heartRate = element.resource.valueQuantity.value;
				}

				if (element.resource.code.coding[0].code == vitalCodes.bloodPressure) {
					vitals.diastolicBlood = element.resource.component.find(
						el => el.code.coding[0].code === vitalCodes.diastolicBlood
					).valueQuantity.value;
					vitals.systolicBlood = element.resource.component.find(
						el => el.code.coding[0].code === vitalCodes.systolicBlood
					).valueQuantity.value;
				}
			});

			this.patient.vitalObservationInfo = {
				bmi: vitals.bodyWeight && vitals.bodyHeight && calculateBMIStatus(vitals.bodyWeight, vitals.bodyHeight),
				bmiValue: vitals.bodyWeight && vitals.bodyHeight && calculateBMI(vitals.bodyWeight, vitals.bodyHeight),
				bodyHeight: vitals.bodyHeight,
				bodyWeight: vitals.bodyWeight,
				heartRate: vitals.heartRate,
				systolicBlood: vitals.systolicBlood,
				diastolicBlood: vitals.diastolicBlood,
			};

			this.vitalObservations = vitalObservations;
		}
	};

	setObservations = observations => {
		const vitalObservations = _.cloneDeep(this.vitalObservations) || [];
		const smokerObservations = _.cloneDeep(this.smokingObservations) || [];
		const alcoholObservations = _.cloneDeep(this.alcoholObservations) || [];

		if (observations?.length > 0) {
			observations.forEach(element => {
				if (element.resource.code.coding[0].code == vitalCodes.bodyHeight) {
					const indexToUpdate = vitalObservations.findIndex(
						initialObj => initialObj.resource.code.coding[0].code === vitalCodes.bodyHeight
					);

					if (indexToUpdate !== -1) {
						vitalObservations[indexToUpdate] = element;
					} else {
						vitalObservations.push(element);
					}
				}
				if (element.resource.code.coding[0].code == vitalCodes.bodyWeight) {
					const indexToUpdate = vitalObservations.findIndex(
						initialObj => initialObj.resource.code.coding[0].code === vitalCodes.bodyWeight
					);

					if (indexToUpdate !== -1) {
						vitalObservations[indexToUpdate] = element;
					} else {
						vitalObservations.push(element);
					}
				}

				if (element.resource.code.coding[0].code == vitalCodes.heartRate) {
					const indexToUpdate = vitalObservations.findIndex(
						initialObj => initialObj.resource.code.coding[0].code === vitalCodes.heartRate
					);

					if (indexToUpdate !== -1) {
						vitalObservations[indexToUpdate] = element;
					} else {
						vitalObservations.push(element);
					}
				}

				if (element.resource.code.coding[0].code == vitalCodes.bloodPressure) {
					const indexToUpdate = vitalObservations.findIndex(
						initialObj => initialObj.resource.code.coding[0].code === vitalCodes.bloodPressure
					);

					if (indexToUpdate !== -1) {
						vitalObservations[indexToUpdate] = element;
					} else {
						vitalObservations.push(element);
					}
				}

				if (element.resource.code.coding[0].code == '72166-2') {
					const indexToUpdate = smokerObservations.findIndex(
						initialObj => initialObj.resource.code.coding[0].code === '72166-2'
					);

					if (indexToUpdate !== -1) {
						smokerObservations[indexToUpdate] = element;
					} else {
						smokerObservations.push(element);
					}
				}

				if (element.resource.code.coding[0].code == '69643-7') {
					const indexToUpdate = alcoholObservations.findIndex(
						initialObj => initialObj.resource.code.coding[0].code === '69643-7'
					);

					if (indexToUpdate !== -1) {
						alcoholObservations[indexToUpdate] = element;
					} else {
						alcoholObservations.push(element);
					}
				}
			});
		}

		this.setObservationInfo(vitalObservations);
		this.setHasDrinking(alcoholObservations);
		this.setHasSmoking(smokerObservations);
	};

	setPatientName = () => {
		if (!Array.isArray(this.patient.name) || this.patient.name.length < 1) {
			this.patient.name = [{}];
		}

		if (!Array.isArray(this.patient.name?.[0]?.given)) {
			this.patient.name[0].given = [];
		}

		// Make sure given is of length 2
		switch (this.patient.name[0].given.length) {
			case 0:
				this.patient.name[0].given = ['', ''];
				break;
			case 1:
				this.patient.name[0].given.push('');
				break;
			default:
			// do nothing
		}

		if (!this.patient.name[0].family) {
			this.patient.name[0].family = '';
		}

		if (!this.patient.name[0]?.prefix) {
			this.patient.name[0].prefix = [];
		}

		if (!this.patient.name[0]?.suffix) {
			this.patient.name[0].suffix = [];
		}

		// Format: lastName^firstName^middleName
		if (!this.patient.name[0].text) {
			this.patient.name[0].text = '^' + `${this.patient.name[0].given?.join('^')}`;
			if (this.patient.name[0].family) {
				this.patient.name[0].text = this.patient.name[0].family + this.patient.name[0].text;
			}
			this.patient.name[0].text = this.patient.name[0].text.trim();
			// Remove trailing ^
			this.patient.name[0].text = _.trimEnd(this.patient.name[0].text, '^ ');
		}
	};

	setPatientIdentifier = () => {
		if (!this.patient.identifier) {
			this.patient.identifier = [];
		}
	};

	setPatientId = () => {
		if (this.notFoundIndex(this.getPatientIdIndex())) {
			this.patient.identifier.push(this.getDefaultPatientIdentifier(this.patientIdCode));
		}
	};

	setPatientSSN = () => {
		if (this.notFoundIndex(this.getPatientSSNIndex())) {
			this.patient.identifier.push(this.getDefaultPatientIdentifier(this.patientSSNCode));
		}
	};

	setPatientDriverLicense = () => {
		if (this.notFoundIndex(this.getPatientDriverLicenseIndex())) {
			this.patient.identifier.push(this.getDefaultPatientIdentifier(this.patientDriverLicenseCode));
		}
	};

	setPatientExtension = () => {
		if (!this.patient.extension) {
			this.patient.extension = [];
		}
	};

	setPatientBirthSex = () => {
		if (this.notFoundIndex(this.getPatientBirthSexIndex())) {
			this.patient.extension.push({
				url: fhirExtensionUrls.patient.birthSex,
				valueCode: '',
			});
		}
	};

	setPatientMothersMaidenName = () => {
		if (this.notFoundIndex(this.getPatientMothersMaidenNameIndex())) {
			this.patient.extension.push({
				url: fhirExtensionUrls.patient.mothersMaidenName,
				valueHumanName: {
					family: '',
					given: ['', ''],
					text: '',
				},
			});
		}
	};

	setPatientMaritalStatus = () => {
		if (!this.patient.maritalStatus) {
			this.patient.maritalStatus = {
				coding: [
					{
						system: '',
						code: '',
						display: '',
					},
				],
			};
		}
	};

	setPatientCommunication = () => {
		if (!this.patient.communication) {
			this.patient.communication = [];
		}
	};

	setPatientLanguage = () => {
		if (this.notFoundIndex(this.getPatientLanguageIndex())) {
			this.patient.communication.push({
				language: {
					coding: [],
				},
				preffered: true,
			});
		}
	};

	setPatientLanguagePreffered = () => {
		this.patient.communication[this.getPatientLanguageIndex()].preffered = true;
	};

	setPatientMeta = () => {
		if (!this.patient.meta) {
			this.patient.meta = [];
		}
	};

	setPatientSecurity = () => {
		if (!this.patient.meta.security) {
			this.patient.meta.security = [];
		}
	};

	setPatientConfidentiality = () => {
		if (this.notFoundIndex(this.getPatientConfidentialityIndex())) {
			this.patient.meta.security.push({
				code: '',
				display: '',
				system: this.patientConfidentialitySystem,
			});
		}
	};

	setPatientAddress = () => {
		if (!this.patient.address) {
			this.patient.address = [
				{
					city: '',
					country: '',
					line: [],
					postalCode: '',
					state: '',
				},
			];
		}
	};

	setPatientTelecom = () => {
		if (!this.patient.telecom) {
			this.patient.telecom = [];
		}
	};

	setPatientTelecomUniqueId = () => {
		this.patient.telecom = this.patient.telecom.map(item => ({
			...item,
			__uniqueId: generateRandomString(16),
		}));
	};

	setPatientLink = () => {
		if (!this.patient.link) {
			this.patient.link = [];
		}

		this.patient.link = this.patient.link.map(item => ({
			phantom: true,
			linkedPatientId: item.other?.identifier?.value,
			type: item.type,
			other: item.other,
		}));
	};

	setPatientRace = () => {
		if (this.notFoundIndex(this.getPatientRaceIndex())) {
			this.patient.extension.push({
				extension: [],
				url: fhirExtensionUrls.patient.race,
			});
		}
	};

	setPatientEthnicity = () => {
		if (this.notFoundIndex(this.getPatientEthnicityIndex())) {
			this.patient.extension.push({
				extension: [],
				url: fhirExtensionUrls.patient.ethnicity,
			});
		}
	};

	setPatientEmployer = () => {
		if (this.notFoundIndex(findExtensionIndex(this.patient.extension, fhirExtensionUrls.patient.employer))) {
			this.patient.extension.push({
				valueString: '',
				url: fhirExtensionUrls.patient.employer,
			});
		}
	};

	setPatientEmploymentStatus = () => {
		if (
			this.notFoundIndex(findExtensionIndex(this.patient.extension, fhirExtensionUrls.patient.employmentStatus))
		) {
			this.patient.extension.push({
				valueCoding: {
					code: '',
					display: '',
				},
				url: fhirExtensionUrls.patient.employmentStatus,
			});
		}
	};

	setPatientEncounterClass = () => {
		if (this.notFoundIndex(findExtensionIndex(this.patient.extension, fhirExtensionUrls.patient.encounterClass))) {
			this.patient.extension.push({
				valueCoding: {
					code: 'out-patient',
					display: 'Out-Patient',
				},
				url: fhirExtensionUrls.patient.encounterClass,
			});
		}
	};

	setPatientSpecialCourtesy = () => {
		if (this.notFoundIndex(findExtensionIndex(this.patient.extension, fhirExtensionUrls.patient.specialcourtesy))) {
			this.patient.extension.push({
				valueCoding: {
					code: '',
					display: '',
				},
				url: fhirExtensionUrls.patient.specialcourtesy,
			});
		}
	};

	setPatientNotes = () => {
		if (this.notFoundIndex(this.getPatientNotesIndex())) {
			this.patient.extension.push({
				extension: [],
				url: fhirExtensionUrls.patient.notes,
			});
		}
	};

	getPatientIdIndex = () => this.patient.identifier?.findIndex(item => item.type.coding[0].code === 'MR');

	getPatientSSNIndex = () => this.patient.identifier?.findIndex(item => item.type.coding[0].code === 'SS');

	getPatientDriverLicenseIndex = () => this.patient.identifier?.findIndex(item => item.type.coding[0].code === 'DL');

	getPatientBirthSexIndex = () =>
		this.patient.extension?.findIndex(item => item.url === fhirExtensionUrls.patient.birthSex);

	getPatientMothersMaidenNameIndex = () =>
		this.patient.extension?.findIndex(item => item.url === fhirExtensionUrls.patient.mothersMaidenName);

	getPatientSpecialCourtesyIndex = () =>
		this.patient.extension?.findIndex(item => item.url === fhirExtensionUrls.patient.specialcourtesy);

	getPatientConfidentialityIndex = () =>
		this.patient.meta.security?.findIndex(item => item.system === this.patientConfidentialitySystem);

	getPatientLanguageIndex = () => this.patient.communication?.findIndex(item => item.hasOwnProperty('language'));

	getPatientRaceIndex = () => this.patient.extension?.findIndex(item => item.url === fhirExtensionUrls.patient.race);

	getPatientEthnicityIndex = () =>
		this.patient.extension?.findIndex(item => item.url === fhirExtensionUrls.patient.ethnicity);

	getPatientNotesIndex = () =>
		this.patient.extension?.findIndex(item => item.url === fhirExtensionUrls.patient.notes);

	getPatientEncounterClassIndex = () =>
		this.patient.extension?.findIndex(item => item.url === fhirExtensionUrls.patient.encounterClass);

	getPatientChangeRequestStatus = field => {
		let changeRequestStatus = null;
		this.patient.extension?.forEach(item => {
			if (item.url === fhirExtensionUrls.patient.changeRequestStatus) {
				item.extension?.forEach(element => {
					if (element.url === field) changeRequestStatus = element.valueString;
				});
			}
		});
		return changeRequestStatus;
	};

	getPatientChangeRequestIndex = () =>
		this.patient.extension?.findIndex(item => item.url === fhirExtensionUrls.patient.changeRequestStatus);

	getDefaultPatientIdentifier = code => ({
		type: {
			coding: [
				{
					system: 'http://hl7.org/fhir/ValueSet/identifier-type',
					code,
				},
			],
		},
		system: '',
		value: '',
	});

	setGenders = items => {
		this.genders = items.map(item => item.display);
	};

	setMaritalStatuses = items => {
		this.maritalStatuses = items.map(item => ({
			code: item.code,
			display: item.display,
			system: '',
		}));
	};

	setLanguages = items => {
		this.languages = items.map(item => ({
			code: item.id,
			display: item.display,
		}));
	};

	setConfidentialities = items => {
		this.confidentialities = items.map(item => ({
			code: item.code,
			display: item.display,
			system: this.patientConfidentialitySystem,
		}));
	};

	setRaces = items => {
		this.races = items.map(item => {
			const systemExtension = item.extension.find(extensionItem => extensionItem.url === 'System');

			return {
				code: item.code,
				display: item.display,
				system: systemExtension.valueString,
			};
		});
	};

	setEthnicities = items => {
		this.ethnicities = items.map(item => ({
			code: item.code,
			display: item.display,
		}));
	};

	setLinkTypes = items => {
		this.linkTypes = items.map(item => ({
			code: item.code,
			display: item.display,
		}));
	};

	setEmploymentStatus = items => {
		this.employmentStatuses = items;
	};

	notFoundIndex = index => index === -1;
}

/**
 * Parses array of single-key objects into a single object with all of their said keys
 *
 * @param {array} valueSets
 * @returns object where keys represent individual value sets
 *
 * @example
 * // input
 * [
 *      { 'maritalStatus': [array_of_values ] },
 *      { 'state': [array_of_values ] },
 *      ...
 * ]
 *
 * // output
 * {
 *      'maritalStatus': [array_of_values],
 *      'state': [array_of_values ]
 * }
 */
export const parsePatientValueSets = (valueSets?: any[] = []): Record<string, any[]> => {
	let _valueSets = valueSets;

	// Converts array of objects to object with keys
	_valueSets = valueSets.reduce((accumulator, currentObject) => ({ ...accumulator, ...currentObject }), {});

	return _valueSets;
};

export default Patient;
