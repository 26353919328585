import React, { useEffect } from 'react';

const StaticDialAction = ({ FabProps, children }) => {
	useEffect(() => {
		if (FabProps.ref) {
			const { ref } = FabProps;
			const value = {
				focus: () => null,
			};
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref) {
				ref.current = value;
			}
		}
	}, [FabProps]);

	return <>{children}</>;
};

export default StaticDialAction;
