import axios from 'axios';

const loadMetadata = async ({
	__config,
	headers,
	studyInstanceUID,
	seriesUID,
	isPartialmetadata = false,
	wonIvInitImageMetadata,
	isBlumeApp,
	creatorUserId,
	isUploaded,
}) => {
	let baseurl = `${__config.data_sources.dicom_web}/studies/${studyInstanceUID}/series/${seriesUID}/metadata?BlumePatientId=${creatorUserId}`;
	if (isBlumeApp) {
		baseurl = `${__config.data_sources.blume}studies/${studyInstanceUID}/series/${seriesUID}/metadata?creatorBlumeUserId=${creatorUserId}&isUploaded=${isUploaded}`;
	}
	const url = new URL(baseurl);

	if (wonIvInitImageMetadata) {
		url.searchParams.append('type', 'initimagemetadata');
	} else if (isPartialmetadata) {
		url.searchParams.append('type', 'partialmetadata');
	}

	const response = await axios.get(url, {
		headers,
	});

	if (Array.isArray(response?.data)) {
		return response.data;
	}

	return [];
};

export default loadMetadata;
