/* Generated automagically by FhirStarter - but please feel free to edit this file */
import { fhirExtensionUrls } from '../../extension';
const AllergyIntoleranceMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource?.id;
		},
	},
	active: {
		label: 'Active',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.active;
		},
	},
	name: {
		label: 'Name',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: 'name',
		getDisplay: resource => {
			let nameArray = resource?.name;
			if (!!nameArray && nameArray.length > 0) {
				return nameArray[0]?.text;
			} else {
				return '';
			}
		},
	},
	onsetDateTime: {
		label: 'Onset Date Time',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.onsetDateTime;
		},
	},
	recordedDate: {
		label: 'recorded Date',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.recordedDate;
		},
	},
	criticality: {
		label: 'criticality',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			return resource?.criticality;
		},
	},
	code: {
		label: 'code',
		options: { filter: false, sort: false },
		filterType: 'none',
		getDisplay: resource => {
			var codeArr = [];
			if (resource) {
				var code = resource.code;
				if (code) {
					for (var item in code) {
						codeArr.push(item.code);
					}
				}
			}
			return codeArr.toString();
		},
	},
	display: {
		label: 'display',
		options: { filter: true, sort: true },
		filterType: 'suggest',
		getDisplay: resource => {
			var displayArr = [];
			if (resource) {
				var code = resource.code;
				if (code) {
					for (var item in code) {
						displayArr.push(item.display);
					}
				}
			}
			return displayArr.toString();
		},
	},
	severity: {
		label: 'severity',
		options: { filter: true, sort: true },
		filterType: 'suggest',
		getDisplay: resource => {
			return resource.extension.find(
				elem => elem.url.toLowerCase() === fhirExtensionUrls.allergyIntolerance.severity.toLowerCase()
			)?.valueString;
		},
	},
};
export default AllergyIntoleranceMapping;
