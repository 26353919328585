import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { searchScopes, useFhirDataLoader, useLoading } from '@worklist-2/core/src';
import PriorityIcon from './PriorityIcon';

const SingleSelectAutocomplete = ({
	size,
	width,
	fullWidth,
	onChange,
	error,
	helperText,
	cellLabel,
	searchValueSet,
	cellValue,
	sx,
	InputLabelProps,
	InputProps,
	placeholder,
	t,
}) => {
	const [options, setOptions] = useState([]);
	const [selectedValue, setSelectedValue] = useState(cellValue);

	const dataLoader = useFhirDataLoader({
		scope: searchScopes.valueSet,
	});

	const { loading, setLoading } = useLoading();

	const translatedCellLabel = t(`workList:imagingWorkListColumns.${cellLabel}`);

	const fetchData = () => {
		try {
			if (!loading && searchValueSet) {
				return dataLoader.load({ 'name:exact': searchValueSet }).then(res => {
					if (res?.length > 0) {
						const optionList = res?.map(item => ({ code: item.code, display: item.display }));
						// For Priority, need to sort the options by the required order that the options are sorted by code - ascending
						let sortedPriorityOptions = [];
						if (searchValueSet === 'priority') {
							sortedPriorityOptions = optionList.sort((a, b) => +a.code - +b.code);
						}
						setOptions(
							searchValueSet === 'priority' ? sortedPriorityOptions : _.orderBy(optionList, 'display')
						);
					} else {
						return Promise.resolve();
					}
				});
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const renderListItem = (props, option) => (
		<li {...props} style={{ display: 'flex', flexDirection: 'column', padding: '0px 8px' }}>
			<div
				data-info="option"
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					width: '100%',
					padding: '1px',
				}}
			>
				<PriorityIcon optionName={option?.display} />
				{searchValueSet ? t(`codePool:${searchValueSet}.${option?.display}`) : option?.display}
			</div>
		</li>
	);
	return (
		<Autocomplete
			autoHighlight
			disableClearable
			freeSolo
			defaultValue={selectedValue}
			getOptionLabel={option => {
				const value = option?.display ?? option;
				return searchValueSet ? t(`codePool:${searchValueSet}.${value?.toUpperCase()}`) : value;
			}}
			loading={loading}
			noOptionsText={t('noOptionsText')}
			options={options}
			renderInput={params => (
				<TextField
					{...params}
					InputLabelProps={{
						...params.InputLabelProps,
						...InputLabelProps,
					}}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position="start" sx={{ ml: '2px', mr: '0px' }}>
								<PriorityIcon optionName={selectedValue?.display ?? selectedValue} />
							</InputAdornment>
						),
						placeholder,
						...InputProps,
					}}
					error={error}
					helperText={helperText}
					label={translatedCellLabel}
					variant="outlined"
				/>
			)}
			renderOption={renderListItem}
			size={size}
			sx={{
				...sx,
				...(fullWidth ? '' : { width }),
			}}
			value={selectedValue}
			onChange={(event, value) => {
				setSelectedValue(value);
				onChange && onChange(value);
			}}
		/>
	);
};

SingleSelectAutocomplete.defaultProps = {
	size: 'medium',
	width: 200,
	fullWidth: false,
	InputLabelProps: {},
	InputProps: {},
};

SingleSelectAutocomplete.propTypes = {
	/**
	 * Component size. Can be `small` or `medium`
	 * */
	size: PropTypes.oneOf(['small', 'medium']),
	/**
	 * Controls the width of the component. If `fullWidth` is true, this value does not take effect.
	 */
	width: PropTypes.number,
	/**
	 * Determines if the component should take up the full width of the container.
	 */
	fullWidth: PropTypes.bool,
	/**
	 * Handler for when an option (user) is selected.
	 * */
	onChange: PropTypes.func,
	/**
	 * Form validation error for SingleSelectAutocomplete.
	 */
	error: PropTypes.bool,
	/**
	 * Form valiadation error message for SingleSelectAutocomplete.
	 */
	helperText: PropTypes.string,
	/**
	 *  Label of the autocomplete dropdown
	 */
	cellLabel: PropTypes.string,
	/**
	 *  A string that will be used for fetching default data in CodePool
	 */
	searchValueSet: PropTypes.string,
	/**
	 *  Value of the cell
	 */
	cellValue: PropTypes.string,
	/**
	 *  Styling
	 */
	sx: PropTypes.object,
	/**
	 *  Props for input label
	 */
	InputLabelProps: PropTypes.object,
	/**
	 *  Props for input field
	 */
	InputProps: PropTypes.object,
	/**
	 *  Place holder text for the autocomplete - input field
	 */
	placeholder: PropTypes.string,
	/**
	 *  Translation function
	 */
	t: PropTypes.func,
};

export default SingleSelectAutocomplete;
