import { getUserFullName } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';
import { PARKING_INDEX_MULTIPLY_COEF } from '../constants';
import { checkAppointmentsTimeOverlap } from './timeHelpers';
import { checkIsBlockedTime, extractAppointmentInfo } from './appointmentInfoHelpers';
import { fhirExtensionUrls } from '@worklist-2/core/src/fhir/extension';
import { cloneDeep } from 'lodash';

export const getWeekDays = weekDay => {
	const week = [];
	const weekDayDate = new Date(weekDay);

	weekDayDate.setDate(weekDayDate.getDate() - weekDayDate.getDay());

	for (var i = 0; i < 7; i++) {
		week.push(new Date(weekDayDate));
		weekDayDate.setDate(weekDayDate.getDate() + 1);
	}

	return week;
};

export const getNameInitials = name => {
	if (!name) return '...';

	const nameParts = getUserFullName(name).split(' ');

	return nameParts.length > 1 ? `${nameParts[0]?.[0] || ''}${nameParts[1]?.[0] || ''}` : name[0];
};

export const getPriorityByIndex = index => {
	const priorityIndex = Number(index);

	switch (priorityIndex !== null || priorityIndex !== undefined) {
		case priorityIndex > 0 && priorityIndex < 3:
			return 'URGENT';
		case priorityIndex >= 3 && priorityIndex < 6:
			return 'ASAP';
		case priorityIndex >= 6 && priorityIndex < 9:
			return 'STAT';
		default:
			return 'ROUTINE';
	}
};

export const isDropInsideElement = (dropCoords, elementCoords) => {
	if (!dropCoords || !elementCoords) return false;

	const { x: dropX, y: dropY } = dropCoords;
	const { top, left, bottom, right } = elementCoords;

	return dropX >= left && dropX <= right && dropY >= top && dropY <= bottom;
};

export const calculateParkIndexByNeighbors = (prev, next) => {
	const noPrev = !prev || prev?.isEmpty;
	const noNext = !next || next?.isEmpty;

	if (noPrev && noNext) return;

	const _nextIdx = parseInt(next?.parkingIndex, 10);
	const _prevIdx = parseInt(prev?.parkingIndex, 10);

	if (noPrev && !noNext) return _nextIdx - PARKING_INDEX_MULTIPLY_COEF;

	if (!noPrev && noNext) return _prevIdx + PARKING_INDEX_MULTIPLY_COEF;

	return (_nextIdx + _prevIdx) / 2;
};

export const findItemByImagingStudyId = (item, source) => {
	if (!item || !source) return;

	return source.find(
		i => i?.supportingInformation?.find(j => j?.reference?.includes('ImagingStudy'))?.id === item?.id
	);
};

export const getReserveConflictingAppointments = (reserve, appointments) => {
	if (!reserve || !appointments?.length) return;

	return appointments.filter(apt => {
		const isTimeOverlap = checkAppointmentsTimeOverlap(reserve, apt);
		const isNotSameReserve = apt?.id !== reserve?.id;

		return isTimeOverlap && isNotSameReserve;
	});
};

export const getReserveInternalAppointments = (reserve, appointments) => {
	if (!reserve || !appointments?.length) return;

	const { healthcareServiceId: reserveHealthcareServiceId } = extractAppointmentInfo(reserve);

	return appointments.filter(apt => {
		const { healthcareServiceId: aptHealthcareServiceId } = extractAppointmentInfo(apt);
		const isTimeOverlap = checkAppointmentsTimeOverlap(reserve, apt);
		const isNotSameReserve = apt?.id !== reserve?.id;
		const isNotBlockedTime = !checkIsBlockedTime(apt);
		const isSameHealthcareService = aptHealthcareServiceId === reserveHealthcareServiceId;

		return isTimeOverlap && isNotSameReserve && isSameHealthcareService && isNotBlockedTime;
	});
};

export const extractHealthcareServiceInfo = service => {
	if (!service) return;

	const isAllowedOverbooking = service?.extension?.find(
		({ url }) => url === fhirExtensionUrls.healthcareService.overBooking
	)?.valueBoolean;

	const timezoneIANA = service?.providedBy?.extension?.find(
		({ url }) => url === fhirExtensionUrls.organization.ianaTimezone
	)?.valueString;

	const modality = service?.extension
		?.find(ext => ext?.url === fhirExtensionUrls.common.modality)
		?.extension?.map(m => m?.valueCode);

	const id = service.id;

	return { isAllowedOverbooking, timezoneIANA, modality, id };
};

export const enrichPayloadWithServiceInfo = (payload, service) => {
	if (!payload || !service) return;

	const _payload = cloneDeep(payload);

	const resourceParticipantIndex = _payload?.participant?.findIndex(({ actor }) =>
		actor.reference.includes('HealthcareService')
	);

	const actor = {
		id: service?.id,
		reference: `HealthcareService/${service?.id}`,
		display: service?.name,
	};

	if (~resourceParticipantIndex) {
		_payload.participant[resourceParticipantIndex].actor = actor;
	} else {
		_payload.participant.push({
			actor,
			required: 'required',
			status: 'accepted',
		});
	}

	return _payload;
};
