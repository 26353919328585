/* eslint-disable class-methods-use-this */
import { getEnabledElement, ramsoft } from '@cornerstonejs/core';
import { cursors, Enums, state, drawing, BaseTool } from '@cornerstonejs/tools';
import { hideElementCursor } from '@cornerstonejs/tools/dist/esm/cursors/elementCursor';
import { removeAnnotation } from '@cornerstonejs/tools/dist/esm/stateManagement/annotation/annotationState';
import { triggerAnnotationRenderForViewportIds } from '@cornerstonejs/tools/dist/esm/utilities';
import { getViewportIdsWithToolToRender } from '@cornerstonejs/tools/dist/esm/utilities/viewportFilters';
import { setCursorForElement } from '@cornerstonejs/tools/dist/esm/cursors';
import { renderingEngineId } from '../contexts/consts/consts';

const QUADRANT = {
	I: 'I',
	II: 'II',
	III: 'III',
	IV: 'IV',
};

const anticlockwiseQuadrantStateMachine = {
	[QUADRANT.I]: {
		next: QUADRANT.IV,
	},
	[QUADRANT.II]: {
		next: QUADRANT.I,
	},
	[QUADRANT.III]: {
		next: QUADRANT.II,
	},
	[QUADRANT.IV]: {
		next: QUADRANT.III,
	},
};

const clockwiseQuadrantStateMachine = {
	[QUADRANT.I]: {
		next: QUADRANT.II,
	},
	[QUADRANT.II]: {
		next: QUADRANT.III,
	},
	[QUADRANT.III]: {
		next: QUADRANT.IV,
	},
	[QUADRANT.IV]: {
		next: QUADRANT.I,
	},
};

function minmax(array, accessor) {
	const result = [Number.MAX_VALUE, Number.MIN_VALUE];

	array.forEach(item => {
		const value = accessor(item);
		if (value < result[0]) {
			result[0] = value;
		}
		if (value > result[1]) {
			result[1] = value;
		}
	});

	return result;
}

function min(a, b) {
	return a > b ? b : a;
}

function center(x1, x2) {
	return (x2 - x1) / 2 + x1;
}

const GOTO_NEXT_QUADRANT = new CustomEvent('GOTO_NEXT_QUADRANT');
const RESET_QUADTOOL = new CustomEvent('RESET_QUADTOOL');

class QuadZoomTool extends BaseTool {
	static toolName;

	editData;
	isDrawing;
	isQuadMode = false;
	quadData = null;

	constructor(
		toolProps = {},
		defaultToolProps = {
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {},
		}
	) {
		super(toolProps, defaultToolProps);
	}

	setQuadToolLockSource({ isQuadMode, sourceViewportId, targetViewportId, syncProperties }) {
		const self = this;
		self.syncProperties = syncProperties;
		const element = document.getElementById(sourceViewportId);

		function handleGotoNext() {
			try {
				const { enabledElement } = self;
				self.goToNextQuadrant(element, enabledElement);
				self.syncProperties(sourceViewportId, targetViewportId);
			} catch (e) {
				console.log(e);
			}
		}

		function handleResetQuadTool() {
			self.resetQuadTool(element);
		}

		if (isQuadMode) {
			if (!this.listenersAdded) {
				element.addEventListener(GOTO_NEXT_QUADRANT.type, handleGotoNext);
				element.addEventListener(RESET_QUADTOOL.type, handleResetQuadTool);
				this.listenersAdded = true;
			}
		} else {
			element.removeEventListener(GOTO_NEXT_QUADRANT.type, handleGotoNext);
			element.removeEventListener(RESET_QUADTOOL.type, handleResetQuadTool);
			this.listenersAdded = false;
		}
	}

	setQuadToolLockTarget({ isQuadMode, sourceViewportId, targetViewportId, syncProperties }) {
		if (!this.getQuadMode()) {
			this.isQuadLock = isQuadMode;
		}
		this.syncProperties = syncProperties;
		this.setQuadMode(isQuadMode);
		this.quadLockedSourceViewportId = sourceViewportId;
		this.quadLockedTargetViewportId = targetViewportId;
	}

	setQuadMode(mode) {
		this.isQuadMode = !!mode;
	}

	getQuadMode() {
		return !!this.isQuadMode;
	}

	getIsFlippedHorizontally() {
		const { viewport } = this.enabledElement;

		return viewport.flipHorizontal;
	}

	setQuadToolDirection(direction) {
		this.direction = direction;
	}

	getQuadToolDirection() {
		let { direction } = this;

		if (this.getIsFlippedHorizontally()) {
			direction = direction === 'clockwise' ? 'anticlockwise' : 'clockwise';
		}

		return direction;
	}

	resetQuadTool(element) {
		try {
			this.setQuadMode(false);
			this.initEditData(element);
			this.quadData = null;
			const { renderingEngine, viewport } = this.enabledElement;
			this.stopAnnotation(element, renderingEngine);
			this.loadCameraState(this.cameraStateBuffer);
			ramsoft.alignVerticalCenter({ renderingEngineId, viewportId: viewport?.id });
		} catch (e) {
			/* empty */
		}

		if (this.isQuadLock) {
			this.triggerResetInLockSource();
			this.syncProperties(this.quadLockedSourceViewportId, this.quadLockedTargetViewportId);
			this.quadLockedSourceViewportId = undefined;
			this.quadLockedTargetViewportId = undefined;
			this.isQuadLock = undefined;
		}
	}

	triggerResetInLockSource() {
		const sourceElement = document.getElementById(this.quadLockedSourceViewportId);

		sourceElement.dispatchEvent(RESET_QUADTOOL);
	}

	preMouseDownCallback(evt) {
		const eventDetail = evt.detail;
		const { currentPoints, element } = eventDetail;
		const worldPos = currentPoints.world;

		const enabledElement = getEnabledElement(element);
		this.enabledElement = enabledElement;
		const { viewport, renderingEngine } = enabledElement;
		this.enabledElement = enabledElement;

		if (this.getQuadMode()) {
			if (this.isQuadLock) {
				try {
					this.triggerGotoNextInLockSource();
					return;
				} catch (e) {
					console.log(e);
				}
				return;
			}

			this.goToNextQuadrant(element, enabledElement);
			return;
		}

		this.initEditData(element);

		if (this.getEdgeCoordinates()) {
			this.autoZoomMode = true;
		}

		if (!this.autoZoomMode) {
			this.initAnnotation(viewport, worldPos);
			this.startAnnotation(element, renderingEngine);
		} else {
			ramsoft.fitViewport?.({ renderingEngineId, viewportId: viewport?.id });
			ramsoft.alignTop({ renderingEngineId, viewportId: viewport?.id });
			if (this.direction === 'clockwise') {
				ramsoft.alignRight({ renderingEngineId, viewportId: viewport?.id });
			} else {
				ramsoft.alignLeft({ renderingEngineId, viewportId: viewport?.id });
			}
			const canvasCoords = this.findRelativeEdgeCanvasCoords(viewport);
			this.initZoom(element, enabledElement, canvasCoords);
		}

		evt.preventDefault();

		return true;
	}

	getEdgeCoordinates() {
		const edgeDetection = this.configuration?.edgeDetection;
		if (!edgeDetection) {
			return null;
		}
		const regex = /(\d+)x(\d+)\+(\d+)\+(\d+)/;
		const match = edgeDetection.match(regex);

		if (Array.isArray(match) && match.length === 5) {
			return match;
		}

		return null;
	}

	getImageHeight() {
		return this.configuration?.height;
	}

	getImageWidth() {
		return this.configuration?.width;
	}

	triggerGotoNextInLockSource() {
		const sourceElement = document.getElementById(this.quadLockedSourceViewportId);

		sourceElement.dispatchEvent(GOTO_NEXT_QUADRANT);
	}

	goToNextQuadrant(element, enabledElement) {
		const { currentQuadrant } = this.quadData;
		const direction = this.getQuadToolDirection();
		if (direction === 'clockwise') {
			const { next } = clockwiseQuadrantStateMachine[currentQuadrant];
			setCursorForElement(element, `QUAD_CURSOR_C${next}`);
			this.quadData.currentQuadrant = next;
		} else {
			const { next } = anticlockwiseQuadrantStateMachine[currentQuadrant];
			setCursorForElement(element, `QUAD_CURSOR_AC${next}`);
			this.quadData.currentQuadrant = next;
		}
		this._zoomQuadrant(enabledElement);
	}

	initEditData(element) {
		const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

		this.editData = {
			viewportIdsToRender,
			movingTextBox: false,
			newAnnotation: true,
			hasMoved: false,
		};
	}

	initAnnotation(viewport, worldPos) {
		const camera = viewport.getCamera();
		const { viewPlaneNormal, viewUp } = camera;

		const annotation = {
			invalidated: true,
			highlighted: true,
			metadata: {
				toolName: this.getToolName(),
				viewPlaneNormal: [...viewPlaneNormal],
				viewUp: [...viewUp],
				referencedImageId: '',
				FrameOfReferenceUID: viewport.getFrameOfReferenceUID(),
			},
			data: {
				handles: {
					points: [[...worldPos], [...worldPos], [...worldPos], [...worldPos]],
				},
			},
		};

		this.editData.annotation = annotation;
	}

	startAnnotation(element, renderingEngine) {
		this._activateDraw(element);
		this.isDrawing = true;
		hideElementCursor(element);
		triggerAnnotationRenderForViewportIds(renderingEngine, this.editData?.viewportIdsToRender || []);
	}

	stopAnnotation(element, renderingEngine) {
		this._deactivateDraw(element);
		removeAnnotation(this.editData?.annotation?.annotationUID, element);
		triggerAnnotationRenderForViewportIds(renderingEngine, this.editData?.viewportIdsToRender || []);
		this.isDrawing = false;
	}

	renderAnnotation(enabledElement, svgDrawingHelper) {
		if (!this.isDrawing) {
			return false;
		}

		const { viewport } = enabledElement;
		const { annotation } = this.editData;

		const { annotationUID, data } = annotation;
		const { points } = data.handles;
		const canvasCoords = points.map(p => viewport.worldToCanvas(p));
		const color = 'rgb(255, 255, 255)';

		// If rendering engine has been destroyed while rendering
		if (!viewport.getRenderingEngine()) {
			console.warn('Rendering Engine has been destroyed');
			return false;
		}

		const rectangleUID = '0';
		drawing.drawRect(svgDrawingHelper, annotationUID, rectangleUID, canvasCoords[0], canvasCoords[3], {
			color,
			width: '1',
		});

		return true;
	}

	_activateDraw(element) {
		state.isInteractingWithTool = true;

		if (!element) return;

		element.addEventListener(Enums.Events.MOUSE_UP, this._mouseUpCallback);
		element.addEventListener(Enums.Events.MOUSE_DRAG, this._mouseDragCallback);
		element.addEventListener(Enums.Events.MOUSE_MOVE, this._mouseDragCallback);
		element.addEventListener(Enums.Events.MOUSE_CLICK, this._mouseUpCallback);
	}

	_deactivateDraw(element) {
		state.isInteractingWithTool = false;

		if (!element) return;

		element.removeEventListener(Enums.Events.MOUSE_UP, this._mouseUpCallback);
		element.removeEventListener(Enums.Events.MOUSE_DRAG, this._mouseDragCallback);
		element.removeEventListener(Enums.Events.MOUSE_MOVE, this._mouseDragCallback);
		element.removeEventListener(Enums.Events.MOUSE_CLICK, this._mouseUpCallback);
	}

	_mouseUpCallback = evt => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation, newAnnotation, hasMoved } = this.editData;
		const { data } = annotation;

		if (newAnnotation && !hasMoved) {
			return;
		}

		const enabledElement = getEnabledElement(element);
		this.enabledElement = enabledElement;
		const { renderingEngine, viewport } = enabledElement;

		this.stopAnnotation(element, renderingEngine);

		const { points } = data.handles;
		const canvasCoords = points.map(p => viewport.worldToCanvas(p));

		this.initZoom(element, enabledElement, canvasCoords);
	};

	_mouseDragCallback = evt => {
		this.isDrawing = true;

		const eventDetail = evt.detail;
		const { element, currentPoints } = eventDetail;

		const enabledElement = getEnabledElement(element);
		this.enabledElement = enabledElement;
		const { viewport, renderingEngine } = enabledElement;

		this.computeDragPoints(viewport, currentPoints);

		this.editData.annotation.invalidated = true;

		this.editData.hasMoved = true;

		triggerAnnotationRenderForViewportIds(renderingEngine, this.editData?.viewportIdsToRender || []);
	};

	findRelativeEdgeCanvasCoords(viewport) {
		const devicePixelRatio = window?.devicePixelRatio || 1;
		const canvasHeight = viewport.canvas.height / devicePixelRatio;
		const canvasWidth = viewport.canvas.width / devicePixelRatio;
		const height = this.getImageHeight();
		const width = this.getImageWidth();

		const edgeDetection = this.getEdgeCoordinates();

		const [, edWidth, edHeight, edOffsetX, edOffsetY] = edgeDetection;

		const { direction } = this;

		let redCanvasCoords = [];

		function calcWhenHeightSame() {
			const rCanvasWidth = canvasHeight * (width / height);
			const rCanvasHeight = canvasHeight;

			const redWidth = edWidth * (rCanvasWidth / width);
			const redHeight = edHeight * (rCanvasHeight / height);

			const redOffsetX = edOffsetX * (rCanvasWidth / width);
			const redOffsetY = edOffsetY * (rCanvasHeight / height);

			const delta = direction === 'clockwise' ? canvasWidth - rCanvasWidth : 0;

			redCanvasCoords = [
				[delta + redOffsetX, redOffsetY],
				[delta + redOffsetX + redWidth, redOffsetY],
				[delta + redOffsetX, redOffsetY + redHeight],
				[delta + redOffsetX + redWidth, redOffsetY + redHeight],
			];
		}

		function calcWhenWidthSame() {
			const rCanvasWidth = canvasWidth;
			const rCanvasHeight = canvasWidth * (height / width);

			const redWidth = edWidth * (rCanvasWidth / width);
			const redHeight = edHeight * (rCanvasHeight / height);

			const redOffsetX = edOffsetX * (rCanvasWidth / width);
			const redOffsetY = edOffsetY * (rCanvasHeight / height);

			redCanvasCoords = [
				[redOffsetX, redOffsetY],
				[redOffsetX + redWidth, redOffsetY],
				[redOffsetX, redOffsetY + redHeight],
				[redOffsetX + redWidth, redOffsetY + redHeight],
			];
		}

		const imageRatio = height / width;
		const canvasRatio = canvasHeight / canvasWidth;

		if (imageRatio >= canvasRatio) {
			calcWhenHeightSame();
		} else {
			calcWhenWidthSame();
		}

		return redCanvasCoords;
	}

	initZoom(element, enabledElement, canvasCoords) {
		const quadrantPoints = this._getQuadrantPoints(element, canvasCoords);
		const direction = this.getQuadToolDirection();
		this.quadData = {
			currentQuadrant: direction === 'clockwise' ? QUADRANT.I : QUADRANT.II,
			quadrantPoints,
		};

		if (direction === 'clockwise') {
			setCursorForElement(element, `QUAD_CURSOR_CI`);
		} else {
			setCursorForElement(element, `QUAD_CURSOR_ACII`);
		}

		this.setQuadMode(true);
		this.saveCameraState(enabledElement);
		this._zoomQuadrant(enabledElement);
	}

	computeDragPoints(viewport, currentPoints) {
		const { annotation } = this.editData;
		const { data } = annotation;

		const worldPos = currentPoints.world;

		const { points } = data.handles;

		points[3] = [...worldPos];

		const bottomLeftCanvas = viewport.worldToCanvas(points[0]);
		const topRightCanvas = viewport.worldToCanvas(points[3]);

		const bottomRightCanvas = [topRightCanvas[0], bottomLeftCanvas[1]];
		const topLeftCanvas = [bottomLeftCanvas[0], topRightCanvas[1]];

		const bottomRightWorld = viewport.canvasToWorld(bottomRightCanvas);
		const topLeftWorld = viewport.canvasToWorld(topLeftCanvas);

		points[1] = bottomRightWorld;
		points[2] = topLeftWorld;
	}

	_getQuadrantPoints(element, canvasCoords) {
		const enabledElement = getEnabledElement(element);
		this.enabledElement = enabledElement;
		const { viewport } = enabledElement;
		const camera = viewport.getCamera();

		const { focalPoint, position, parallelScale } = camera;

		const [x1, x2] = minmax(canvasCoords, x => x[0]);
		const [y1, y2] = minmax(canvasCoords, x => x[1]);
		const cx = center(x1, x2);
		const cy = center(y1, y2);

		const center1 = viewport.canvasToWorld([center(x1, cx), center(y1, cy)]);
		const center2 = viewport.canvasToWorld([center(cx, x2), center(y1, cy)]);
		const center3 = viewport.canvasToWorld([center(cx, x2), center(cy, y2)]);
		const center4 = viewport.canvasToWorld([center(x1, cx), center(cy, y2)]);

		const d1 = min(Math.abs(x2 - x1), Math.abs(y2 - y1));
		const d2 = min(element.clientHeight, element.clientWidth);
		const newScale = (parallelScale * d1) / d2;

		return {
			[QUADRANT.I]: {
				start: [x1, y1],
				end: [cx, cy],
				cx: center(x1, cx),
				focalPoint: [center1[0], center1[1], focalPoint[2]],
				position: [center1[0], center1[1], position[2]],
				prev: QUADRANT.IV,
				next: QUADRANT.II,
			},
			[QUADRANT.II]: {
				start: [cx, y1],
				end: [x2, cy],
				cx: center(cx, x2),
				focalPoint: [center2[0], center2[1], focalPoint[2]],
				position: [center2[0], center2[1], position[2]],
				prev: QUADRANT.I,
				next: QUADRANT.III,
			},
			[QUADRANT.III]: {
				start: [cx, cy],
				end: [x2, y2],
				cx: center(cx, x2),
				focalPoint: [center3[0], center3[1], focalPoint[2]],
				position: [center3[0], center3[1], position[2]],
				prev: QUADRANT.II,
				next: QUADRANT.IV,
			},
			[QUADRANT.IV]: {
				start: [x1, cy],
				end: [cx, y2],
				cx: center(x1, cx),
				focalPoint: [center4[0], center4[1], focalPoint[2]],
				position: [center4[0], center4[1], position[2]],
				prev: QUADRANT.III,
				next: QUADRANT.I,
			},
			parallelScale: newScale,
		};
	}

	saveCameraState(enabledElement) {
		const { viewport } = enabledElement;

		this.cameraStateBuffer = viewport.getCamera();
	}

	loadCameraState(cameraState) {
		if (!this.enabledElement) {
			return;
		}
		const { viewport } = this.enabledElement;

		try {
			viewport.setCamera(cameraState);
			viewport.render();
		} catch (e) {
			/* empty */
		}
	}

	_zoomQuadrant() {
		const { quadrantPoints, currentQuadrant } = this.quadData;
		const { parallelScale } = quadrantPoints;
		const { focalPoint, position } = quadrantPoints[currentQuadrant];

		this.loadCameraState({
			focalPoint,
			position,
			parallelScale,
		});
	}
}

QuadZoomTool.toolName = 'Quad Zoom';

cursors.registerCursor(
	QuadZoomTool.toolName,
	cursors.CursorSVG.RectangleROI.iconContent,
	cursors.CursorSVG.RectangleROI.viewBox
);

const clockwiseI = `
<g id="Layer_1-2" data-name="Layer 1">
    <g class="cls-4">
      <g>
        <g>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="12.96" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-2" x="12.96" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 23.64) rotate(-180)"/>
          <rect fill="#7ffd8c" class="cls-3" x="4.03" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 23.64) rotate(-180)"/>
        </g>
        <path fill="#7ffd8c" class="cls-2" d="M20.3,6.46L19.44.1l-2.06,2.35c-.63-.68-1.39-1.25-2.26-1.67-1.82-.89-3.89-1.01-5.81-.36-1.92.67-3.47,2.04-4.36,3.86-.34.69-.57,1.43-.69,2.17h2.42c.1-.38.23-.76.4-1.13.62-1.26,1.68-2.2,2.99-2.66,1.32-.45,2.74-.37,4,.25.67.32,1.24.79,1.71,1.34l-1.92,2.2h6.42Z"/>
      </g>
    </g>
  </g>`;

const clockwiseII = `
<g id="Layer_1-2" data-name="Layer 1">
    <g class="cls-4">
      <g>
        <g>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="12.96" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" class="cls-2" x="12.96" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 23.64) rotate(-180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 23.64) rotate(-180)"/>
        </g>
        <path fill="#7ffd8c" class="cls-2" d="M20.3,6.46L19.44.1l-2.06,2.35c-.63-.68-1.39-1.25-2.26-1.67-1.82-.89-3.89-1.01-5.81-.36-1.92.67-3.47,2.04-4.36,3.86-.34.69-.57,1.43-.69,2.17h2.42c.1-.38.23-.76.4-1.13.62-1.26,1.68-2.2,2.99-2.66,1.32-.45,2.74-.37,4,.25.67.32,1.24.79,1.71,1.34l-1.92,2.2h6.42Z"/>
      </g>
    </g>
  </g>`;

const clockwiseIII = `
<g id="Layer_1-2" data-name="Layer 1">
    <g class="cls-4">
      <g>
        <g>
          <rect fill="#7ffd8c" class="cls-3" x="12.96" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-2" x="12.96" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 23.64) rotate(-180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 23.64) rotate(-180)"/>
        </g>
        <path fill="#7ffd8c" class="cls-2" d="M20.3,6.46L19.44.1l-2.06,2.35c-.63-.68-1.39-1.25-2.26-1.67-1.82-.89-3.89-1.01-5.81-.36-1.92.67-3.47,2.04-4.36,3.86-.34.69-.57,1.43-.69,2.17h2.42c.1-.38.23-.76.4-1.13.62-1.26,1.68-2.2,2.99-2.66,1.32-.45,2.74-.37,4,.25.67.32,1.24.79,1.71,1.34l-1.92,2.2h6.42Z"/>
      </g>
    </g>
  </g>`;

const clockwiseIV = `
<g id="Layer_1-2" data-name="Layer 1">
    <g class="cls-4">
      <g>
        <g>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="12.96" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" class="cls-3" x="4.03" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-2" x="12.96" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 23.64) rotate(-180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 23.64) rotate(-180)"/>
        </g>
        <path fill="#7ffd8c" class="cls-2" d="M20.3,6.46L19.44.1l-2.06,2.35c-.63-.68-1.39-1.25-2.26-1.67-1.82-.89-3.89-1.01-5.81-.36-1.92.67-3.47,2.04-4.36,3.86-.34.69-.57,1.43-.69,2.17h2.42c.1-.38.23-.76.4-1.13.62-1.26,1.68-2.2,2.99-2.66,1.32-.45,2.74-.37,4,.25.67.32,1.24.79,1.71,1.34l-1.92,2.2h6.42Z"/>
      </g>
    </g>
  </g>`;

const anticlockwiseI = `
<g id="Layer_1-2" data-name="Layer 1">
	<g class="cls-4">
		<g>
			<g>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="12.96" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-2" x="12.96" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 23.64) rotate(-180)"/>
          <rect fill="#7ffd8c" class="cls-3" x="4.03" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 23.64) rotate(-180)"/>
			</g>
        <path fill="#7ffd8c" class="cls-2" d="M3.7,6.45L4.56.09l2.06,2.35c.63-.68,1.39-1.25,2.26-1.67,1.82-.89,3.89-1.01,5.81-.36,1.92.67,3.47,2.04,4.36,3.86.34.69.57,1.43.69,2.17h-2.42c-.1-.38-.23-.76-.4-1.13-.62-1.26-1.68-2.2-2.99-2.66-1.32-.45-2.74-.37-4,.25-.67.32-1.24.79-1.71,1.34l1.92,2.2H3.7Z"/>
		</g>
	</g>
</g>`;

const anticlockwiseII = `
<g id="Layer_1-2" data-name="Layer 1">
	<g class="cls-4">
		<g>
			<g>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="12.96" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" class="cls-2" x="12.96" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 23.64) rotate(-180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 23.64) rotate(-180)"/>
        </g>
        <path fill="#7ffd8c" class="cls-2" d="M3.7,6.45L4.56.09l2.06,2.35c.63-.68,1.39-1.25,2.26-1.67,1.82-.89,3.89-1.01,5.81-.36,1.92.67,3.47,2.04,4.36,3.86.34.69.57,1.43.69,2.17h-2.42c-.1-.38-.23-.76-.4-1.13-.62-1.26-1.68-2.2-2.99-2.66-1.32-.45-2.74-.37-4,.25-.67.32-1.24.79-1.71,1.34l1.92,2.2H3.7Z"/>
		</g>
	</g>
</g>`;

const anticlockwiseIII = `
<g id="Layer_1-2" data-name="Layer 1">
	<g class="cls-4">
		<g>
			<g>
          <rect fill="#7ffd8c" class="cls-3" x="12.96" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-2" x="12.96" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 23.64) rotate(-180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 23.64) rotate(-180)"/>
        </g>
        <path fill="#7ffd8c" class="cls-2" d="M3.7,6.45L4.56.09l2.06,2.35c.63-.68,1.39-1.25,2.26-1.67,1.82-.89,3.89-1.01,5.81-.36,1.92.67,3.47,2.04,4.36,3.86.34.69.57,1.43.69,2.17h-2.42c-.1-.38-.23-.76-.4-1.13-.62-1.26-1.68-2.2-2.99-2.66-1.32-.45-2.74-.37-4,.25-.67.32-1.24.79-1.71,1.34l1.92,2.2H3.7Z"/>
		</g>
	</g>
</g>`;

const anticlockwiseIV = `
<g id="Layer_1-2" data-name="Layer 1">
	<g class="cls-4">
		<g>
			<g>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="12.96" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" class="cls-3" x="4.03" y="16.86" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 40.86) rotate(180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-2" x="12.96" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(33.07 23.64) rotate(-180)"/>
          <rect fill="#7ffd8c" opacity="0.7" class="cls-3" x="4.03" y="8.25" width="7.14" height="7.14" rx="1.48" ry="1.48" transform="translate(15.21 23.64) rotate(-180)"/>
			</g>
        <path fill="#7ffd8c" class="cls-2" d="M3.7,6.45L4.56.09l2.06,2.35c.63-.68,1.39-1.25,2.26-1.67,1.82-.89,3.89-1.01,5.81-.36,1.92.67,3.47,2.04,4.36,3.86.34.69.57,1.43.69,2.17h-2.42c-.1-.38-.23-.76-.4-1.13-.62-1.26-1.68-2.2-2.99-2.66-1.32-.45-2.74-.37-4,.25-.67.32-1.24.79-1.71,1.34l1.92,2.2H3.7Z"/>
		</g>
	</g>
</g>`;

cursors.registerCursor('QUAD_CURSOR_CI', clockwiseI, { x: 24, y: 24 });
cursors.registerCursor('QUAD_CURSOR_CII', clockwiseII, { x: 24, y: 24 });
cursors.registerCursor('QUAD_CURSOR_CIII', clockwiseIII, { x: 24, y: 24 });
cursors.registerCursor('QUAD_CURSOR_CIV', clockwiseIV, { x: 24, y: 24 });

cursors.registerCursor('QUAD_CURSOR_ACI', anticlockwiseI, { x: 24, y: 24 });
cursors.registerCursor('QUAD_CURSOR_ACII', anticlockwiseII, { x: 24, y: 24 });
cursors.registerCursor('QUAD_CURSOR_ACIII', anticlockwiseIII, { x: 24, y: 24 });
cursors.registerCursor('QUAD_CURSOR_ACIV', anticlockwiseIV, { x: 24, y: 24 });

export default QuadZoomTool;
