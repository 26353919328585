import React, { createContext, useContext, useState, useRef } from 'react';
import { LAYOUT_TYPE } from './consts/consts';
import useStateRef from '../hooks/useStateRef';
import { useMultiscreen } from '@rs-core/hooks';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const ImageViewerView3DContext = createContext({});

const ImageViewerView3DContextProvider = ({ children }) => {
	const wonIvMmRefactorScreenSync = useBooleanFlagValue('WON-IV-MM-REFACTOR-SCREEN-SYNC');

	const [layoutType, setLayoutType] = useState(LAYOUT_TYPE.DEFAULT);
	const [isSplitView, setIsSplitView] = useState(false);
	const [resizeDiff, setResizeDiff] = useState(0);
	const [focusedSection, setFocusedSection] = useState('imageViewer');
	const [isShownBackdrop, setIsShownBackdrop] = useState(false);
	const [isFullScreen, setIsFullScreen, isFullScreenRef] = useStateRef(false);
	const { ivLayoutType, setIvLayoutType } = useMultiscreen();

	const hotkeysManagerRef = useRef({
		enabled: false,
		hotkeys: {
			ctrlKey: {},
			altKey: {},
			metaKey: {},
			shiftKey: {},
			ctrlShiftKey: {},
		},
	});

	const loadingManagerRef = useRef({
		priorStudiesLoaded: false,
		studySeries: {},
		studyMeasurements: {},
		studyKeyImages: {},
	});

	return (
		<ImageViewerView3DContext.Provider
			value={{
				layoutType: wonIvMmRefactorScreenSync ? ivLayoutType : layoutType,
				setLayoutType: wonIvMmRefactorScreenSync ? setIvLayoutType : setLayoutType,
				isSplitView,
				setIsSplitView,
				resizeDiff,
				setResizeDiff,
				focusedSection,
				setFocusedSection,
				hotkeysManagerRef,
				isShownBackdrop,
				setIsShownBackdrop,
				isFullScreen,
				setIsFullScreen,
				isFullScreenRef,
				loadingManagerRef,
			}}
		>
			{children}
		</ImageViewerView3DContext.Provider>
	);
};

const useImageViewerView3DContext = () => useContext(ImageViewerView3DContext);

export { useImageViewerView3DContext, ImageViewerView3DContextProvider, ImageViewerView3DContext };
