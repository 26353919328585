import React, { useEffect, useRef, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import { useConfig, useAppModeContext, pdfUtil } from '@worklist-2/core/src';

import { useTranslation } from 'react-i18next';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

const PDFFiller = ({
	pdfSrc,
	setPdfInstance,
	pdfFileName,
	width = 880,
	height = '85vh',
	isReadOnly = false,
	showDownload = true,
	showPrint = true,
	groupButtonsProps,
}) => {
	const { t } = useTranslation('form');

	// PDF container
	const containerRef = useRef(null);

	// The instace used to handle internal behavior
	const [instance, setInstance] = useState(null);

	// State to check the print dialog is open or not
	const [isPrintOpen, setIsPrintOpen] = useState(false);

	// The state to show/hide group buttons based on mouse over
	const [isMouseOver, setIsMouseOver] = useState(false);

	// App config
	const __config = useConfig();

	// Patient portal information
	const { isPatientPortalMode } = useAppModeContext();
	const appModeIsPatientPortal = isPatientPortalMode();

	// Initial pdf instance
	useEffect(() => {
		const container = containerRef.current;
		let WebViewer;
		(async function () {
			if (instance) {
				if (pdfSrc) {
					instance.UI?.loadDocument(pdfSrc, { filename: pdfFileName });
				}
				return;
			}

			// Do not create instance when there are no any hosting.
			if (!__config.blume_base_url && !__config.omegaai_url) return;

			// Initial instance webviewer
			WebViewer = await import('@pdftron/webviewer');
			const localInstance = await WebViewer.default(
				{
					// Detect app is blume or worklist and load resources from public folder of them.
					path: appModeIsPatientPortal ? formatUrl(__config.blume_base_url) : formatUrl(__config.omegaai_url),
					licenseKey: __config.pdf_tron_license,
				},
				container
			);
			localInstance.UI.loadDocument(null, { onLoadingProgress: true });
			// Core instances
			const { FitMode } = localInstance.UI;

			// Disable header
			localInstance.UI.disableElements([
				'header',
				'pageNavOverlay',
				'toolsHeader',
				'toolStylePopup',
				'textPopup',
				'contextMenuPopup',
				'annotationCommentButton',
				'annotationStyleEditButton',
				'linkButton',
			]);

			// Viewer fit with width
			localInstance.UI.setFitMode(FitMode.FitWidth);

			// Detect print dialog is open or not
			localInstance.UI.addEventListener('visibilityChanged', event => {
				if (event?.detail?.element === 'printModal') {
					setIsPrintOpen(event.detail.isVisible);
				}
			});

			setInstance(localInstance);
			if (setPdfInstance) {
				setPdfInstance(localInstance);
			}
		})();
		return () => {
			// Remove from cache
			window.URL.revokeObjectURL(pdfSrc);
			if (instance) {
				instance.UI.closeDocument();
			}
			if (setPdfInstance) {
				setPdfInstance(null);
			}
		};
	}, []);

	// Load document
	useEffect(() => {
		async function asynchronousEffect() {
			if (instance && pdfSrc) {
				await instance.UI.loadDocument(pdfSrc, { filename: pdfFileName });
				const { annotationManager, documentViewer, Annotations } = instance.Core;
				const signatureTool = documentViewer.getTool(instance.Core.Tools.ToolNames.SIGNATURE);
				// Disable rotation control in case new signature
				signatureTool.addEventListener('signatureReady', signature => {
					signature.disableRotationControl();
				});

				documentViewer.addEventListener('annotationsLoaded', () => {
					const annots = annotationManager.getAnnotationsList();
					annots.forEach(annot => {
						if (annot instanceof Annotations.WidgetAnnotation) {
							annot.fieldFlags.set('ReadOnly', isReadOnly);
						}

						if (annot instanceof Annotations.SignatureWidgetAnnotation && annot.annot) {
							annot.annot.NoMove = isReadOnly;
							// Disable rotation control in case existed signature
							annot.annot.disableRotationControl();
						}
					});
				});
				if (!isReadOnly) {
					// Fill color for input fields
					Annotations.WidgetAnnotation.getCustomStyles = widget => {
						if (widget instanceof Annotations.TextWidgetAnnotation && widget.sg?.tagName === 'INPUT') {
							return {
								'background-color': 'lightblue',
								color: 'black',
							};
						}
						if (widget instanceof Annotations.PushButtonWidgetAnnotation) {
							return {
								'background-color': 'black',
								color: 'white',
							};
						}
						if (
							widget instanceof Annotations.CheckButtonWidgetAnnotation ||
							widget instanceof Annotations.RadioButtonWidgetAnnotation
						) {
							return {
								'background-color': 'lightgray',
								opacity: 0.8,
							};
						}
					};
				} else {
					// Disable signature tool and turn on read only mode
					annotationManager.enableReadOnlyMode();
					instance.UI.disableElements(['annotationPopup']);
				}
			}
		}

		asynchronousEffect();
	}, [pdfSrc, instance]);

	/**
	 * formatUrl
	 *  Detect and fix wrong url format
	 * @param {*} url
	 * @returns
	 */
	const formatUrl = url => {
		if (!url) return;
		return `${url.endsWith('/') ? url : `${url}/`}webviewer`;
	};

	/**
	 * handleClickDownload
	 * down load pdf content
	 */
	const handleClickDownload = () => {
		pdfUtil.download(instance, pdfFileName);
	};

	/**
	 * handleClickPrint
	 *  Print pdf
	 */
	const handleClickPrint = () => {
		pdfUtil.print(instance);
	};

	return (
		<Box
			data-testid="pdf-container"
			sx={{
				height,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width,
				backgroundColor: 'transparent',
			}}
			onMouseLeave={() => {
				setIsMouseOver(false);
			}}
			onMouseOver={() => {
				setIsMouseOver(true);
			}}
		>
			{isReadOnly && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width,
						height: '35px',
						backgroundColor: 'rgb(232,232,232)',
						borderRadius: '10px 10px 0 0',
						textAlign: 'center',
						verticalAlign: 'middle',
						color: 'rgb(121,121,121)',
					}}
				>
					<LockOutlinedIcon />
					<Box
						sx={{
							color: 'rgb(121,121,121)',
						}}
					>
						{t('Readonly')}
					</Box>
				</Box>
			)}
			<div ref={containerRef} style={{ width, height }} />
			{isMouseOver && !isPrintOpen && (
				<Box
					sx={{
						bottom: '10px',
						position: 'absolute',
						display: 'flex',
						gap: '6px',
						zIndex: 10,
						left: '50%',
						transform: 'translateX(-50%)',
						...groupButtonsProps?.sx,
					}}
				>
					{showDownload && (
						<Tooltip title={t('Download')}>
							<Box
								sx={{
									padding: '11px',
									background: '#393939',
									borderRadius: '90px',
									boxShadow: '-2px 4px 5px rgba(0, 0, 0, 0.05)',
									width: '40px',
									height: '40px',
									boxSizing: 'border-box',
									position: 'relative',
									cursor: 'pointer',
								}}
								onClick={handleClickDownload}
							>
								<DownloadOutlinedIcon
									sx={{
										color: 'rgba(255, 255, 255, 0.6)',
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%,-50%)',
									}}
								/>
							</Box>
						</Tooltip>
					)}
					{showPrint && (
						<Tooltip title={t('Print')}>
							<Box
								sx={{
									padding: '11px',
									background: '#393939',
									borderRadius: '90px',
									boxShadow: '-2px 4px 5px rgba(0, 0, 0, 0.05)',
									width: '40px',
									height: '40px',
									boxSizing: 'border-box',
									position: 'relative',
									cursor: 'pointer',
								}}
								onClick={handleClickPrint}
							>
								<PrintOutlinedIcon
									sx={{
										color: 'rgba(255, 255, 255, 0.6)',
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%,-50%)',
									}}
								/>
							</Box>
						</Tooltip>
					)}
				</Box>
			)}
		</Box>
	);
};

export default PDFFiller;
