import { RRule, RRuleSet, rrulestr, datetime } from 'rrule';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import add from 'date-fns/add';
import addMinutes from 'date-fns/addMinutes';
import { isDateValid } from './timeHelpers';
import { getAvailableWorkingSlotsForDay, checkInsideWorkingHours } from './appointmentInfoHelpers';
import { extractHealthcareServiceInfo } from '@worklist-2/worklist/src/SchedulerV2/utils/dataProcessHelpers';

export const convertRRuleSetStringToRRuleSet = rruleSetString => {
	return rruleSetString?.length > 0
		? rrulestr(rruleSetString, {
				forceset: true,
		  })
		: new RRuleSet();
};

export const generateISOStringWithoutOffset = date =>
	isDateValid(date) ? new Date(date).toISOString().replace('Z', '') : date;

export const removeTimeZonOffsetFromRruleString = rruleString =>
	rruleString?.length > 0 ? rruleString.replaceAll('Z', '') : rruleString;

export const generateRRuleString = (rruleInfo, calendarService, exceptedDateArr, reservationList, t) => {
	const EVENT_REPEAT_TYPE = [t('blockedTime.noRepeat'), t('blockedTime.daily'), t('blockedTime.weekly')];
	let rruleSet = new RRuleSet();

	if (
		[EVENT_REPEAT_TYPE[1], EVENT_REPEAT_TYPE[2]].includes(rruleInfo?.freq) &&
		isDateValid(rruleInfo?.dtStart) &&
		isDateValid(rruleInfo?.until)
	) {
		const startDateTime = convertLocalDatesToLocalDatesInUtcFormat([rruleInfo.dtStart])[0];

		const endDateTime = convertLocalDatesToLocalDatesInUtcFormat([rruleInfo.until])[0];

		const weeklyRule = {
			freq: RRule.WEEKLY,
			interval: rruleInfo?.interval ?? 1,
			byweekday: rruleInfo?.byWeekday, // an array of integers, one of the weekday constants (RRule.MO, RRule.TU, etc), or an array of these constants. 0 == RRule.MO
			dtstart: startDateTime,
			until: endDateTime,
		};

		const dailyRule = {
			freq: RRule.DAILY,
			interval: rruleInfo?.interval ?? 1,
			dtstart: startDateTime,
			until: endDateTime,
		};

		// Create an RRuleSet instance that is a collection of RRule instances
		// An RRule instance is a recurrence rule that defines a pattern for recurring events in the rrule library
		rruleSet.rrule(new RRule(EVENT_REPEAT_TYPE[2] === rruleInfo?.freq ? weeklyRule : dailyRule));

		const nonWorkingDates = [];
		const occurrences = generateOccurrences(rruleSet);
		if (calendarService?.workingDays?.length > 0 && occurrences?.length > 0) {
			occurrences.map(occurrence => {
				// Exclude the non working days of the healthcare service from the rruleSet
				if (!calendarService?.workingDays?.some(item => item.code == occurrence?.getDay())) {
					nonWorkingDates.push(occurrence);
				} else {
					// Exclude the working days that occurrence time is out of the working hours
					const availableWorkingSlotsForDay = getAvailableWorkingSlotsForDay(
						calendarService?.service,
						occurrence
					);

					const isInsideWorkingHours = checkInsideWorkingHours(
						{
							start: occurrence,
							end: addMinutes(new Date(occurrence), calendarService?.durationInMinutes),
						},
						availableWorkingSlotsForDay
					);
					if (!isInsideWorkingHours) {
						nonWorkingDates.push(occurrence);
					}
				}
			});
		}

		// exclude the dates having conflicted reservations
		const convertedReservations = [];
		const newOccurrences = generateOccurrences(rruleSet);
		if (Array.isArray(reservationList) && reservationList.length > 0 && newOccurrences?.length > 0) {
			reservationList.forEach(reservation => {
				newOccurrences?.map(occurrence => {
					const occurrenceStart = occurrence;
					const startTimeInMinutes = rruleInfo?.dtStart?.getHours() * 60 + rruleInfo?.dtStart?.getMinutes();
					const endTimeInMinutes = rruleInfo?.until.getHours() * 60 + rruleInfo?.until.getMinutes();
					const durationInMinutes = endTimeInMinutes - startTimeInMinutes;
					const occurrenceEnd = add(occurrenceStart, { minutes: durationInMinutes });
					if (
						occurrenceStart?.getTime() < reservation?.end?.getTime() &&
						occurrenceEnd?.getTime() > reservation?.start?.getTime()
					) {
						convertedReservations.push(occurrence);
					}
				});
			});
		}

		const exceptDates = [...exceptedDateArr, ...nonWorkingDates, ...convertedReservations];

		if (exceptDates?.length > 0) {
			convertLocalDatesToLocalDatesInUtcFormat(exceptDates).forEach(date => rruleSet.exdate(date));
		}
	}

	// Not sure why the rruleSet at this point generates occurrences for the excepted dates. This is wrong
	// So this function returns rruleSetString then we need to convert it back to rruleSet if needed as a workaround and at that point
	// it generates occurrences properly - no occurrences for the excepted dates
	return rruleSet.toString();
};

export const generateRRuleSetString = (
	repeatStartDate,
	repeatEndDate,
	byWeekdayArr,
	repeatType,
	calendarService,
	exceptedDateArr,
	reservationList,
	t,
	proactTimeZoneConversionBlockTime,
	interval = 1
) => {
	const EVENT_REPEAT_TYPE = [t('blockedTime.noRepeat'), t('blockedTime.daily'), t('blockedTime.weekly')];
	let rruleSet = new RRuleSet();

	if (
		[EVENT_REPEAT_TYPE[1], EVENT_REPEAT_TYPE[2]].includes(repeatType) &&
		isDateValid(repeatStartDate) &&
		isDateValid(repeatEndDate)
	) {
		const weeklyRule = {
			freq: RRule.WEEKLY,
			interval: interval,
			byweekday: byWeekdayArr, // an array of integers, one of the weekday constants (RRule.MO, RRule.TU, etc), or an array of these constants. 0 == RRule.MO
			dtstart: repeatStartDate,
			until: repeatEndDate,
		};

		const dailyRule = {
			freq: RRule.DAILY,
			interval: interval,
			dtstart: repeatStartDate,
			until: repeatEndDate,
		};

		// Create an RRuleSet instance that is a collection of RRule instances
		// An RRule instance is a recurrence rule that defines a pattern for recurring events in the rrule library
		rruleSet.rrule(new RRule(EVENT_REPEAT_TYPE[2] === repeatType ? weeklyRule : dailyRule));
		const { timezoneIANA } = extractHealthcareServiceInfo(calendarService?.service);

		const nonWorkingDates = [];
		if (calendarService?.workingDays?.length > 0) {
			generateOccurrencesWithoutDst(rruleSet, calendarService?.service, proactTimeZoneConversionBlockTime)?.map(
				occurrence => {
					// Exclude the non working days of the healthcare service from the rruleSet
					if (!calendarService?.workingDays?.some(item => item.code == occurrence?.getDay())) {
						nonWorkingDates.push(occurrence);
					} else {
						// Exclude the working days that occurrence time is out of the working hours
						const availableWorkingSlotsForDay = getAvailableWorkingSlotsForDay(
							calendarService?.service,
							occurrence
						);

						const isInsideWorkingHours = checkInsideWorkingHours(
							{
								start: occurrence,
								end: addMinutes(new Date(occurrence), calendarService?.durationInMinutes),
							},
							availableWorkingSlotsForDay
						);
						if (!isInsideWorkingHours) {
							nonWorkingDates.push(occurrence);
						}
					}
				}
			);
		}

		// exclude the dates having conflicted reservations
		const convertedReservations = [];
		if (Array.isArray(reservationList) && reservationList.length > 0) {
			const occurrences = generateOccurrencesWithoutDst(
				rruleSet,
				calendarService?.service,
				proactTimeZoneConversionBlockTime
			);

			const startDateInZonedTime = utcToZonedTime(repeatStartDate, timezoneIANA);
			const endDateInZoneTime = utcToZonedTime(repeatEndDate, timezoneIANA);
			const startTimeInMinutes = startDateInZonedTime.getHours() * 60 + startDateInZonedTime.getMinutes();
			const endTimeInMinutes = endDateInZoneTime.getHours() * 60 + endDateInZoneTime.getMinutes();
			const durationInMinutes = endTimeInMinutes - startTimeInMinutes;

			const end = utcToZonedTime(rruleSet?._rrule[0]?.options?.until, timezoneIANA);
			const endHours = end.getHours();
			const endMinutes = end.getMinutes();
			const endSeconds = end.getSeconds();
			const endMilliseconds = end.getMilliseconds();
			const eventEndTime = endHours * 3600 + endMinutes * 60 + endSeconds;

			reservationList.forEach(reservation => {
				occurrences?.map(occurrence => {
					const occurrenceStart = occurrence;
					const occurrenceEnd = add(occurrenceStart, { minutes: durationInMinutes });
					const occurrenceEndTime =
						occurrenceEnd.getHours() * 3600 + occurrenceEnd.getMinutes() * 60 + occurrenceEnd.getSeconds();

					// when daylight saving time change is between the recurring start and end date, the recurring end time and the occurrence end time are different
					// So set the recurring end time to the occurrence end time to avoid DST issue
					if (eventEndTime !== occurrenceEndTime) {
						occurrenceEnd.setHours(endHours);
						occurrenceEnd.setMinutes(endMinutes);
						occurrenceEnd.setSeconds(endSeconds);
						occurrenceEnd.setMilliseconds(endMilliseconds);
					}

					if (
						occurrenceStart?.getTime() < reservation?.end?.getTime() &&
						occurrenceEnd?.getTime() > reservation?.start?.getTime()
					) {
						convertedReservations.push(occurrence);
					}
				});
			});
		}

		const exceptDates = [...exceptedDateArr, ...nonWorkingDates, ...convertedReservations];

		if (exceptDates?.length > 0) {
			convertExceptDatesToUtcWithoutDst(
				rruleSet,
				exceptDates,
				timezoneIANA,
				proactTimeZoneConversionBlockTime
			).forEach(date => {
				rruleSet.exdate(date);
			});
		}
	}

	// Not sure why the rruleSet at this point generates occurrences for the excepted dates. This is wrong
	// So this function returns rruleSetString then we need to convert it back to rruleSet if needed as a workaround and at that point
	// it generates occurrences properly - no occurrences for the excepted dates
	return rruleSet.toString();
};

/**
 * Generate an array of occurrences that the start time is always the time of the first occurrence regardless the time zone.
 * So Daylight Savings Time should not affect to the occurrences on the calendar
 * @rruleSet {RRuleSet instance} and RRuleSet instance
 * @returns an array of occurrence dates
 */
export const generateOccurrencesWithoutDst = (rruleSet, service, proactTimeZoneConversionBlockTime) => {
	if (!rruleSet || !service) {
		return;
	}

	const { timezoneIANA } = extractHealthcareServiceInfo(service);

	return rruleSet.all()?.map(occurrence => {
		const convertedOccurrence = utcToZonedTime(occurrence, timezoneIANA);

		// Get time from dtstart to avoid DST issue
		const dtstart = rruleSet?._rrule[0]?.options?.dtstart;
		const dtstartInZonedTime = utcToZonedTime(dtstart, timezoneIANA);
		const dtstartHours = dtstartInZonedTime.getHours();
		const dtstartMinutes = dtstartInZonedTime.getMinutes();
		const dtstartSeconds = dtstartInZonedTime.getSeconds();
		const dtstartMilliseconds = dtstartInZonedTime.getMilliseconds();
		const dtstartTime = dtstartHours * 3600 + dtstartMinutes * 60 + dtstartSeconds;

		const occurrenceTime =
			convertedOccurrence.getHours() * 3600 +
			convertedOccurrence.getMinutes() * 60 +
			convertedOccurrence.getSeconds();

		if (occurrenceTime !== dtstartTime) {
			convertedOccurrence.setHours(dtstartHours);
			convertedOccurrence.setMinutes(dtstartMinutes);
			convertedOccurrence.setSeconds(dtstartSeconds);
			convertedOccurrence.setMilliseconds(dtstartMilliseconds);
		}

		return proactTimeZoneConversionBlockTime ? convertedOccurrence : occurrence;
	});
};

/**
 * Generate an array of occurrences in local time
 * @rruleSet {RRuleSet instance} and RRuleSet instance
 * @returns an array of occurrence dates in local time
 */
export const generateOccurrences = rruleSet => {
	if (!rruleSet) {
		return;
	}

	return rruleSet.all()?.map(occurrence => new Date(generateISOStringWithoutOffset(occurrence)));
};

/**
 * Convert the an array of excepted dates in zoned time to UTC dates without caring about daylight savings time.
 * So Daylight Savings Time should not affect to the recurring events on the calendar
 * @rruleSet {RRuleSet instance} and RRuleSet instance
 * @exceptedDateArr {array} an array of excepted dates in zoned time
 * @timezoneIANA {string} timezone IANA string (organization's time zone)
 * @proactTimeZoneConversionBlockTime {boolean} a feature flag for time zone conversion
 * @returns an array of excepted dates in UTC without caring about daylight savings time
 */
export const convertExceptDatesToUtcWithoutDst = (
	rruleSet,
	exceptedDateArr,
	timezoneIANA,
	proactTimeZoneConversionBlockTime
) => {
	if (!rruleSet || !exceptedDateArr || !timezoneIANA) {
		return;
	}

	return Array.isArray(exceptedDateArr) && exceptedDateArr.length > 0
		? exceptedDateArr.map(date => {
				if (date instanceof Date) {
					const exceptedDate = proactTimeZoneConversionBlockTime
						? zonedTimeToUtc(new Date(date), timezoneIANA)
						: date;

					// Get time from dtstart to avoid DST issue
					const dtstart = rruleSet?._rrule[0]?.options?.dtstart;
					const adjustedDateDueToDst1 = new Date(
						exceptedDate.setHours(
							dtstart?.getHours(),
							dtstart?.getMinutes(),
							dtstart.getSeconds(),
							dtstart.getMilliseconds()
						)
					);

					// Adjust excepted date if timezone offsets of dtstart and the excepted date are different due to DST
					const exceptedDateOffset = exceptedDate.getTimezoneOffset();
					const dtstartOffset = dtstart.getTimezoneOffset();
					const diffInMinutes = dtstartOffset - exceptedDateOffset;

					const adjustedDateDueToDst2 =
						diffInMinutes !== 0
							? add(adjustedDateDueToDst1, { minutes: diffInMinutes })
							: adjustedDateDueToDst1;

					// Adjust excepted date the UTC time slot is 00:00:00 then the adjusted date is wrong due to DST
					const diffInDays = date.getDate() - utcToZonedTime(adjustedDateDueToDst2, timezoneIANA).getDate();

					const adjustedDateDueToDst3 =
						diffInDays !== 0 ? add(adjustedDateDueToDst2, { days: diffInDays }) : adjustedDateDueToDst2;

					return adjustedDateDueToDst3;
				}
		  })
		: exceptedDateArr;
};

/**
 * Convert local date time to local date time having 'Z' offset (UTC) using datetime function from rrule library
 * @dateArr {array} an array of dates
 * @returns an array of local dates having 'Z' offset (UTC) (although having Z but they are local times, not UTC times)
 */
export const convertLocalDatesToLocalDatesInUtcFormat = dateArr => {
	if (!Array.isArray(dateArr)) {
		return;
	}

	const convertedDates = [];

	// For Javascript date, month index is from 0 to 11.
	//  + For example, new Date (2024, 0, 19) => Jan 19, 2024; new Date (2024, 11, 19) => Dec 19, 2024
	// For datetime func from rrule library, it uses 1-based month - index is from 1 to 12
	//  + For example, datetime(2024, 1, 19) =>  Jan 19, 2024; datetime(2024, 12, 19) => Dec 19, 2024
	if (dateArr.length > 0) {
		dateArr.forEach(date => {
			convertedDates.push(
				datetime(date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes())
			);
		});
	}

	return convertedDates;
};

/**
 * Extract rrule info from rruleSet
 * @rruleSet {RRuleSet instance} and RRuleSet instance
 * @returns dtstart, until, interval, byWeekdayArr, freq, exDateArr
 */
export const extractInfoFromRruleSet = rruleSet => {
	if (!rruleSet) {
		return;
	}

	const rruleOptions = rruleSet?._rrule[0]?.options;
	const dtstart = new Date(generateISOStringWithoutOffset(rruleOptions?.dtstart));
	const until = new Date(generateISOStringWithoutOffset(rruleOptions?.until));
	const interval = rruleOptions?.interval;
	const byWeekdayArr = rruleOptions?.byweekday;
	const freq = rruleOptions?.freq;
	const exceptedDates = rruleSet?._exdate;

	return { dtstart, until, interval, byWeekdayArr, freq, exceptedDates };
};

/**
 * Check if date time in rruleString is in wall clock or UTC
 * @rruleString {string} an rrule string
 * @returns a boolean value - true if it's wall clock, false if it's UTC time
 */
export const isRruleStringInWallClock = rruleString => !rruleString?.includes('Z\n');
