import React, { createContext, useRef, useEffect } from 'react';
import { createStore as createZustandStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import levelOfDetails from '../../CustomizeOverlaysView/const/levelsOfDetails';
import { getZustandStoreBroadcastChannelName } from './utils/zustand/getZustandStoreBroadcastChannelName';
import useZustandStoreSelector from './utils/zustand/useZustandStoreSelector';
import useZustandStore from './utils/zustand/useZustandStore';
import { zustandShare } from './utils/zustand/useZustandShare';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const createStore = (props = {}) =>
	createZustandStore(
		subscribeWithSelector(set => ({
			profile: levelOfDetails.overlayConfig_Detailed,
			customOverlays: undefined,
			showOverlay: true,
			setProfile: profile => set({ profile }),
			setCustomOverlays: customOverlays => set({ customOverlays }),
			setShowOverlay: showOverlay => set({ showOverlay }),
			toggleOverlay: () => set(state => ({ showOverlay: !state.showOverlay })),
			...props,
		}))
	);

const useStoreSync = (storeRef, wonIvBroadcastMsgCompress) => {
	useEffect(() => {
		const storeName = 'overlayStore';
		const storeBroadcastChannelName = getZustandStoreBroadcastChannelName(storeName);
		const options = {
			initialize: true,
			ref: storeBroadcastChannelName,
			wonIvBroadcastMsgCompress,
		};

		const [, unsubscribe] = zustandShare('showOverlay', storeRef.current, options);

		return () => unsubscribe();
	}, [storeRef]);
};

const OverlayContext = createContext(null);

const OverlayContextProvider = ({ children }) => {
	const wonIvBroadcastMsgCompress = useBooleanFlagValue('won-iv-broadcast-msg-compress');
	const storeRef = useRef();

	if (!storeRef.current) {
		storeRef.current = createStore();
	}

	useStoreSync(storeRef, wonIvBroadcastMsgCompress);

	return <OverlayContext.Provider value={storeRef.current}>{children}</OverlayContext.Provider>;
};

const useOverlayStore = () => useZustandStore(OverlayContext);

const useOverlayStoreSelector = selector => useZustandStoreSelector(selector, OverlayContext);

export { OverlayContext, OverlayContextProvider, useOverlayStore, useOverlayStoreSelector, createStore };
