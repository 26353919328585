import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import CheckVideoImagesTransferSyntax from '../../utils/CheckVideoImagesTransferSyntax';
import { getInstanceSOPUID, getInstanceSyntax } from '../../utils/utils';
import ImageViewerViewportVideo from '../../ImageViewerViewportVideo';
import { useImageViewerLayoutContext } from '../../contexts/ImageViewerLayoutContext';
import classNames from 'classnames';
import useOnViewportDoubleClick from '../../hooks/useOnViewportDoubleClick';
import { useImageViewerView3DContext } from '../../contexts/ImageViewerView3DContext';
import { useOnSetActiveViewportId } from '@rs-ui/views/ImageViewerView3D/hooks/useOnSetActiveViewportId';

const ImageViewerViewport = ({ viewport, layoutItem, index, CornerstoneViewport, disabledViewportDoubleClick }) => {
	const { isFullScreen } = useImageViewerView3DContext();
	const { activeViewportId } = useImageViewerLayoutContext();

	const { onSetActiveViewportId } = useOnSetActiveViewportId();
	const { onViewportDoubleClick } = useOnViewportDoubleClick();

	const { id } = viewport;
	const instances = layoutItem.series.metadata;

	const shouldRenderVideoViewport = CheckVideoImagesTransferSyntax(getInstanceSyntax(instances?.[0]));

	const shouldRenderCornerstoneViewport = !shouldRenderVideoViewport;

	const isActive = useMemo(() => activeViewportId === id, [activeViewportId, id]);

	return (
		<Box
			className={classNames(isActive && 'active-viewport')}
			data-testid="ImageViewerViewport"
			sx={{
				flex: '1',
				position: 'relative',
				background: '#000',
				boxSizing: 'border-box',
				borderRadius: isFullScreen ? undefined : '10px',
				height: '100%',
				border: isActive ? '1px solid #42A5F5' : '1px solid #000',
			}}
			onContextMenu={() => onSetActiveViewportId?.(id)}
			onDoubleClick={!disabledViewportDoubleClick ? () => onViewportDoubleClick(layoutItem) : undefined}
			onMouseDown={() => onSetActiveViewportId?.(id)}
		>
			{shouldRenderVideoViewport && (
				<ImageViewerViewportVideo
					InstanceSOPUID={getInstanceSOPUID(instances[0])}
					layoutItem={layoutItem}
					viewport={viewport}
				/>
			)}

			{shouldRenderCornerstoneViewport && (
				<CornerstoneViewport
					activeViewportId={activeViewportId}
					index={index}
					layoutItem={layoutItem}
					viewport={viewport}
					onSetActiveViewportId={onSetActiveViewportId}
				/>
			)}
		</Box>
	);
};

export default ImageViewerViewport;
