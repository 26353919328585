// @flow

// core
import React, { type Node } from 'react';
// components
import Avatar from '../Avatar/Avatar';
// libs
import Box from '@mui/material/Box';
import LinkIcon from '@mui/icons-material/Link';
// utils
import { getNameInitials } from '@worklist-2/worklist/src/SchedulerV2/utils/dataProcessHelpers';

interface ILinkAvatarProps {
	text: string;
}

export const LinkAvatar = ({ text }: ILinkAvatarProps): Node => (
	<Box sx={SX.componentWrapper}>
		<Avatar
			backgroundColor="#568AF3"
			fontSize="8px"
			height="23px"
			iconText={getNameInitials(text)}
			label={text}
			transparent={false}
			width="23px"
		/>

		<Box sx={SX.linkIconWrapper}>
			<LinkIcon data-testid="link-icon" sx={SX.linkIcon} />
		</Box>
	</Box>
);

const SX = {
	componentWrapper: {
		position: 'relative',
	},
	linkIconWrapper: {
		position: 'absolute',
		bottom: '3px',
		right: '-4px',
		backgroundColor: '#393939',
		height: '7px',
		borderRadius: '20px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '12px',
	},

	linkIcon: { color: '#42A5F5', fontSize: '12px' },
};
